<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
  >
    <template #content="{ showData, showStringData }">
      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.MunicipalityPrediction.municipality') }}
        </template>
        <template #second-column>
          {{ showStringData({ field: `gemeentenaam` }) }}
        </template>
      </TileDataContentRowTwoColumn>
      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.MunicipalityPrediction.evs') }}
        </template>
        <template #second-column>
          {{ showData({ field: `ev_${year}`, decimals: 0 }) }}
        </template>
      </TileDataContentRowTwoColumn>

      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.MunicipalityPrediction.cpe') }}
        </template>
        <template #second-column>
          {{ showData({ field: `laadpunt_${year}`, decimals: 0 }) }}
        </template>
      </TileDataContentRowTwoColumn>
    </template>

    <template #footer>
      ID: {{ fids[0] }}
    </template>
  </TileDataContent>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TileDataContentRowTwoColumn from '@/components/map/tiles/TileDataContentRowTwoColumn'
import TiledataMixin from '@/mixins/map/tiledata-mixin'

export default {
  name: 'TileDataGemeenteprognose',
  components: { TileDataContentRowTwoColumn, TileDataContent },
  mixins: [TiledataMixin],
}
</script>
