<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { image } from '@/helpers/assets'

import { EventBus } from '@/services/eventbus'

export default {
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'car_pool_parking_areas',
      type: 'symbol',
      icon: 'car-pool-parking',
      sdf: false,
      minzoom: null,
      paint: {
        'icon-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          0,
          11.2,
          0.8,
        ],
      },
      layout: {
        'visibility': 'none',
        'symbol-placement': 'point',
        'symbol-z-order': 'source',
        'icon-allow-overlap': true,
        'icon-image': 'car-pool-parking',
        'icon-size': [ // transition based on zoom
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          0.05,
          18,
          0.2,
        ],
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    source() {
      return {
        url: `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      if (! this.sourceName) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        if (! this.$store.map.hasImage(this.icon)) {
          try {
            this.$store.map.loadImage(image({ name: `legend/${this.icon}.png` }), (error, image) => {
              if (error) throw error

              if (! this.$store.map.hasImage(this.icon)) {
                this.$store.map.addImage(this.icon, image, { 'sdf': this.sdf || false })
              }

              this.addActualLayer()
            })
          } catch (e) {
            this.addActualLayer()
          }
        } else {
          this.addActualLayer()
        }
      })

    },
    addActualLayer() {
      this.$store.map.addSource(this.sourceName, {
        type: 'vector',
        url: this.source.url,
      })

      this.$store.map.addLayer({
        'id': this.layerName,
        'type': this.type,
        'source': this.sourceName,
        'source-layer': this.source.layer,
        'minzoom': this.minzoom || 0,
        'paint': this.paint,
        'layout': this.layout,
      }, this.labelLayer)

      EventBus.$emit('layers.national.loaded', {
        name: this.layerName,
      })
    },
  },
}
</script>
