<template>
  <div class="ManagementDashboard">
    <p v-if="!getLocationCriteria?.length">
      {{ $t("components.navbar.locationCriteria.noData") }}
    </p>
    <div v-else>
      <h4>{{ $t("components.navbar.locationCriteria.title") }}</h4>

      <ul>
        <li
          v-for="criteria in getLocationCriteria"
          :key="criteria"
          :class="{'hidden': !criteria.active}"
        >
          <div v-if="criteria.active">
            {{ criteria.label }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/eventbus'
import { mapGetters } from 'vuex'

export default {
  name: 'LocationCriteria',

  computed: {
    ...mapGetters('config', ['getLocationCriteria', 'getShowLocationCriteria']),
  },
   async created() {
    this.activate()
  },
  beforeDestroy() {
    this.disable()
  },
  methods: {
    activate() {
      this.disable()
      EventBus.$on('overlay-click', this.maybeClose)
    },
    disable() {
      EventBus.$off('overlay-click', this.maybeClose)
    },

    maybeClose() {
      EventBus.$emit('overlay-hide')
    },
  },
}
</script>

<style lang="scss">
.ManagementDashboard {
  position: relative;
  background: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  opacity: 0.999;
  padding: 1rem;
  min-width: 20rem;
  min-height: 5rem;

  p {
    align-self: center;
    margin: 1rem;
    max-width: 100%;
  }
}
</style>
