<template>
  <div
    class="Overlay"
    :class="{ 'Overlay--visible': visible }"
  >
    <div
      class="Overlay__ClickBlokker"
      @click="handleClick"
    />
  </div>
</template>

<script>

import { EventBus } from '@/services/eventbus'

export default {
  name: 'Overlay',
  data() {
    return {
      visible: false,
      autohide: true,
    }
  },
  created() {
    EventBus.$on('overlay', this.show)
    EventBus.$on('overlay-show', this.show)
    EventBus.$on('overlay-hide', this.hide)
  },
  beforeDestroy() {
    EventBus.$off('overlay', this.show)
    EventBus.$off('overlay-show', this.show)
    EventBus.$off('overlay-hide', this.hide)
  },
  methods: {
    show() {
      this.visible = true
      EventBus.$emit('overlay-shown')
    },
    hide() {
      this.visible = false
      EventBus.$emit('overlay-hidden')
    },
    handleClick() {
      if (this.autohide) {
        this.hide()
      }
      EventBus.$emit('overlay-click')
    },
  },
}
</script>

<style lang="scss">
.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  background: black;
  transition: opacity 0.3s ease-in-out;
  z-index: 999; // Required to overlap Bootstrap-vue (file) inputs
  user-select: none;

  &__ClickBlokker {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
    z-index: 999;
    opacity: 0.99;
  }

  &--visible {
    opacity: 0.6;
  }
  &--visible &__ClickBlokker {
    display: block;
  }
}
</style>
