<template>
  <MapBoxPopup
    v-if="coordinates"
    :coordinates="coordinates"
    :show="show"
    @close="handleClose"
  >
    <div class="d-flex flex-column">
      <div
        v-for="(prop, key) in data"
        :key="key"
        class="d-flex flex-column"
      >
        <div class="w-100">
          {{ dataProps[key] }}: {{ sanitizeValue(data[key]) }}
        </div>
      </div>
    </div>
  </MapBoxPopup>
</template>

<script>

import MapBoxPopup from '@/components/common/MapBoxPopup'

import { mapGetters } from 'vuex'

import { provincieCodeByMunicipalityCode } from '@/services/municipalities'

export default {
  name: 'ConstructionPopup',
  components: { MapBoxPopup },
  data() {
    return {
      layers: ['truckparkings_secured', 'truckparkings'],
      show: false,
      coordinates: null,
      // Properties
      data: {},
      dataProps: {
        naam_project: 'Naam',
        transportcapaciteit: 'Transportcapaciteit',
        parkeerplekken__aantal_: '# parkeerplekken',
        truckstoplocaties: 'Type',
      },
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser ||
        this.hasAdminAccess({ code: this.getActiveMunicipality }) ||
        provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality }) === 28
    },
  },
  watch: {
    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality() {
      this.show = false
    },
    hasAccess: {
      immediate: true,
      handler (value) {
        value ? this.enable() : this.disable()
      },
    },
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.disable()
  },
  methods: {
    sanitizeValue (value) {
      let cleanedValue
      if (value.startsWith('Geen ')) {
        cleanedValue = 'Niet beschikbaar'
      } else if (value.startsWith('Transportcapaciteit')) {
        cleanedValue = 'Beschikbaar'
      } else {
        cleanedValue = value
      }
      return cleanedValue
    },
    enable() {
      this.$store.map.on('click', this.layers, this.handleClickMarkerEvent)

      // Cursor
      this.$store.map.on('mouseenter', this.layers, this.showPointer)
      this.$store.map.on('mouseleave', this.layers, this.hidePointer)
    },
    disable() {
      this.$store.map.off('click', this.layers, this.handleClickMarkerEvent)

      // Cursor
      this.$store.map.off('mouseenter', this.layers, this.showPointer)
      this.$store.map.off('mouseleave', this.layers, this.hidePointer)
    },
    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''
    },
    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      let feature = e.features[0]
      if (feature) {

        this.show = false

        this.$nextTick(() => {
          this.coordinates = feature.geometry.coordinates
          this.data = feature.properties
          this.show = true
        })
      }
    },
    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>
