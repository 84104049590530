<template>
  <div>
    <!-- Please make sure that there is only 1 tour per route, otherwise
      multiple tours could open at once -->
    <RulerTour />
  </div>
</template>

<script>
import RulerTour from '@/components/tour/tours/RulerTour.vue'

export default {
  components: {
    RulerTour,
  },
}
</script>
