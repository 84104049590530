/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  loaded: false,
  tilesets: [],
}

const getters = {
  tilesets: state => state.tilesets,
  loaded: state => state.loaded,
}
const actions = {

}
const mutations = {
  setTilesets(state, { tilesets }) {
    state.tilesets = tilesets
    state.loaded = true
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
