const passwordErrorTranslations = ({ errorMessage, fallback }) => {
  let message = fallback || 'Er ging iets mis. Neem a.u.b. contact op.'

  if (errorMessage === 'PasswordStrengthError: Password is too weak') {
    message = 'Dit wachtwoord is niet veilig genoeg.'
  } else if (errorMessage === 'PasswordHistoryError: Password has previously been used') {
    message = 'Dit wachtwoord is eerder gebruikt, kies een nieuw wachtwoord'
  } else if (errorMessage === 'Blacklisted') {
    message = 'Dit wachtwoord is niet toegestaan.'
  } else if (errorMessage === 'The user already exists.') {
    message = 'Dit gebruiker bestaat al.'
  } else if (
    errorMessage === 'Password is required' ||
    errorMessage.includes('String is too short')
  ) {
    message = 'Het wachtwoord mag niet leeg zijn.'
  }

  return message
}

module.exports = {
  passwordErrorTranslations,
}
