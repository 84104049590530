<template>
  <a
    :href="`mailto: ${getEmailAddressSupport}`"
    v-text="getEmailAddressSupport"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('deployment', [
      'getEmailAddressSupport',
    ]),
  },
}
</script>
