

import { labelByCode } from '@/services/municipalities'
// use raw data
import { layers as layerDetails } from '@/data/layerDetails'
import { bboxByCodeByDeployment } from '@/data/bbox'

// Convert the tileset data into a simplified key => value object
//  For the keys we use the part of the source names we standardized in our GIS analysis
//  The value data is limited to the name & id, which is the data we need to store in our config
export const simplifyTilesets = ({ tilesets }) => {

  return tilesets.reduce((result, tileset) => {
    let key = tileset.name.split('-')[0] // name = source
    result[key] = {
      name: tileset.name,
      id: tileset.id,
    }
    return result
  }, {})
}

export const configLayers = ({ deployment, code, tilesets }) => {
  const prefix = `${deployment}_g_${code}_`
  let layers = layerDetails
    .filter(layer => layer.sourceNameHint) // Ignore layers without a source name hint
    .reduce((result, layer) => {

      let key = prefix + layer.sourceNameHint

      if (tilesets[key]) {
        result[layer.id] = {
          source: tilesets[key].name,
          url: 'mapbox://'+tilesets[key].id,
          status: layer.status,
        }
      } else {
        result[layer.id] = {
          source: '',
          url: '',
          status: 'hidden',
        }
      }

      return result
    }, {})

  return layers
}

export const configGenerator = ({ deployment, code, tilesets, models }) => {

  tilesets = simplifyTilesets({ tilesets })

  /**
   * Try to auto select layers for this municipality
   */
  let layers = configLayers({
    deployment, code, tilesets,
  })
  let hasLayers = Object.values(layers).some(layer => layer.source !== '')

  /**
   * The basic configuration options
   */
  return {
    stored: false,

    code: code,
    name: labelByCode({
      code,
    }),
    enabled: true,
    planmode: true,
    upload: true,
    export: {
      enabled: true,
    },
    prognose: {
      enabled: hasLayers,
      limit: 80,
      years: [2022, 2025],
      breakpoints: [
        6,
        12,
        24,
      ],
      models: models,
    },
    map: {
      bounds: bboxByCodeByDeployment[deployment][code] || false, // TODO: no fallback, but very likely not necessary anyhow
    },

    layers,
  }
}
