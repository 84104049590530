<template>
  <div
    v-if="loaded"
    class="Page--Beheer Beheer h-100 d-flex flex-column flex-grow-1"
  >
    <AdminNavBar />
    <div class="Beheer__Main d-flex flex-grow-1">
      <AdminSideBar class="Beheer__SideBar flex-shrink-1" />

      <div class="Beheer__Content d-flex position-relative flex-grow-1 flex-shrink-0">
        <slot />
      </div>
    </div>
    <Overlay />
  </div>
  <div
    v-else
    class="h-100 w-100 d-flex justify-content-center align-items-center"
  >
    <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
    <div class="d-flex align-items-center">
      <b-spinner
        class="mr-3"
        label="Loading..."
      />
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import AdminSideBar from '@/components/sidebar/AdminSideBar'
import AdminNavBar from '@/components/navbar/AdminNavBar'
import Overlay from '@/components/common/Overlay'

import { mapGetters, mapMutations } from 'vuex'
import { checkStatus, returnJson } from '@/helpers/api'

export default {
  name: 'Beheer',
  components: {
    AdminNavBar,
    AdminSideBar,
    Overlay,
  },
  computed: {
    ...mapGetters('config', ['loaded']),
    ...mapGetters('tilesets', {
      hasTilesets: 'loaded',
    }),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  /**
   * Load the municipality information
   */
  created() {

    // Only superusers may access admin pages
    if ( ! this.superuser) {
      this.$router.push({ name: 'Home' })
      return
    }

    /**
     * Get Tilesets
     */
    this.getTilesets()
  },

  methods: {
    ...mapMutations('tilesets', [
      'setTilesets',
    ]),

    /**
     * Get Tilesets from Mapbox when opening the admin app
     *  Only get them once per user session. There will rarely be updates.
     */
    getTilesets: async function() {
      if (this.hasTilesets) return

      const account = process.env.VUE_APP_MAPBOX_ACCOUNT
      const url = `https://api.mapbox.com/tilesets/v1/${account}?limit=100`

      const tilesets = await this.getTileset({ url })

      this.setTilesets({
        tilesets,
      })
    },

    /**
     * Recursively get tilesets 100 at a time
     */
    async getTileset({ url }) {
      let response

      try {
        const token = await this.$auth.getTokenSilently()
        response = await fetch('/api/tilesets', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ url }),
        })
          .then(await checkStatus)
          .then(returnJson)

      } catch (e) {
        return []
      }

      let result = response.tilesets

      if (response.link) {
        const linkedTilesets = await this.getTileset({ url: response.link })
        result = result.concat(linkedTilesets)
      }

      return result
    },
  },
}
</script>

<style lang="scss">
.Beheer {
  &__SideBar {
    width: 300px;
  }
  &__Content {
    width: calc(100% - 300px);
    overflow-y: scroll;
  }
  &__Main {
    max-height: calc(100% - 80px); // 100% - height of nav bar
    overflow-y: hidden;
  }

  @media (max-height: 600px) {
    &__SideBar, &__Content {
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    &__Main {
      flex-direction: column-reverse;
    }
    &__SideBar, &__Content {
      width: 100%;
    }
    &__Content {
      height: 60vh;
      min-height: 60vh;
      box-shadow: 0px 1px 0px 0px rgba(223,226,229,1);
    }
  }
}

</style>
