<template>
  <li
    class="SearchResult pr-3 pl-4 py-1"
    :class="[chargingpoint.refId === getSelectedChargingpointRefId ? 'SearchResult__selected' : '']"
    @click="$emit('select-chargingpoint', {chargingpoint})"
  >
    <div
      v-if="showParticipationComments"
      class="d-flex justify-content-between align-items-center"
    >
      <div v-text="chargingpoint.id" />
      <div
        class="ParticipatieSentimentIndicator"
        :class="{ ParticipatieSentimentIndicatorEmpty: !participationCommentsCountWithSentiment }"
      >
        <div
          class="Positive"
          :style="`width: ${positiveParticipationCommentsShare * 100}%`"
        />
      </div>
      <div class="d-flex align-items-center">
        <BIconEnvelopeFill title="Nieuwe reacties" />
        <div
          class="SearchResult__StatusCount"
          v-text="newParticipationCommentsCount"
        />
      </div>
      <div class="d-flex align-items-center">
        <BIconClockFill title="Nog te verwerken reacties" />
        <div
          class="SearchResult__StatusCount"
          v-text="todoParticipationCommentsCount"
        />
      </div>
    </div>

    <InlineChargingpoint
      v-else
      :chargingpoint="chargingpoint"
    />
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import { BIconEnvelopeFill, BIconClockFill } from 'bootstrap-vue'

import { PARTICIPATION_SENTIMENT } from '@/../shared/valueholders/participation-sentiment'
import InlineChargingpoint from '@/components/common/InlineChargingpoint'

export default {
  name: 'PlanModeSearchItem',
  components: {
    InlineChargingpoint,
    BIconEnvelopeFill,
    BIconClockFill,
  },
  props: {
    chargingpoint: {
      type: Object,
      required: true,
    },
    showParticipationComments: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('planmode', [
      'getSelectedChargingpointRefId',
      'getParticipationCommentsByChargingpointUuid',
    ]),
    participationComments() {
      return this.getParticipationCommentsByChargingpointUuid({ chargingpointUuid: this.chargingpoint.uuid })
    },
    positiveParticipationCommentsCount() {
      return this.participationComments.filter(({ data }) => data.sentiment === PARTICIPATION_SENTIMENT.POSITIVE).length
    },
    negativeParticipationCommentsCount() {
      return this.participationComments.filter(({ data }) => data.sentiment === PARTICIPATION_SENTIMENT.NEGATIVE).length
    },
    positiveParticipationCommentsShare() {
      if (!this.participationCommentsCountWithSentiment) {
        return 0
      }

      return this.positiveParticipationCommentsCount / this.participationCommentsCountWithSentiment
    },
    participationCommentsCountWithSentiment() {
      return this.positiveParticipationCommentsCount + this.negativeParticipationCommentsCount
    },
    newParticipationCommentsCount() {
      return this.participationComments.filter(({ data }) => data.status === 'new').length
    },
    todoParticipationCommentsCount() {
      return this.participationComments.filter(({ data }) => data.status === 'todo').length
    },
  },
}
</script>

<style lang="scss">


.SearchResult {
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 1.5rem;
    height: 16px;
    width: 16px;
    background: transparent;
    background-repeat: no-repeat;
    border-radius: 50%;
    opacity: 0.9;
    background-size: cover;
  }

  .ParticipatieSentimentIndicator {
    flex-grow: 1;
    height: 4px;
    margin: 0 0.5rem;
    background-color: #dc3545;
    border-radius: 0.15rem;

    &.ParticipatieSentimentIndicatorEmpty {
      background-color: lightgray;
    }

    .Positive {
      height: 100%;
      background-color: #5d8c30;
      border-top-left-radius: 0.15rem;
      border-bottom-left-radius: 0.15rem;
    }
  }

  &__StatusCount {
    width: 1.1rem;
    margin-left: 0.3rem;
    margin-right: 0.4rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__selected {
    background-color: var(--darker);
  }

  &:hover {
    background: var(--darker);
    color: var(--darkest);

    &:after {
      opacity: 0.8;
    }
  }
}
</style>
