<template>
  <div
    v-if="isChargingpointSelected"
    class="ActionSidebar"
    :class="{ 'open': open }"
  >
    <div class="Tabs">
      <div
        v-for="tab in tabs"
        :id="tab.id"
        :key="tab.id"
        class="Tab"
        :class="{ 'active': activeTab.value === tab.value }"
        @click="setActiveTab(tab.value)"
      >
        <SvgIcon :icon="tab.icon" />
      </div>

      <div
        v-if="isChargingpointSelected"
        class="Tab ActionSidebar__Collapse"
        @click="toggle"
      >
        <div
          class="ActionSidebar__Collapse__Icon"
          :class="{ rotated: open }"
        >
          <SvgIcon icon="chevron-left-solid" />
        </div>
      </div>
    </div>

    <div
      class="ActiveTab__Close"
      :class="{hasScrollbar: hasScrollbar}"
      @click="close"
    >
      <SvgIcon icon="times-regular" />
    </div>

    <component :is="activeTab.component" />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'
import InfoTab from '@/components/map/sidebar/Tabs/InfoTab'
import MergeTab from '@/components/map/sidebar/Tabs/MergeTab'
import MonitoringTab from '@/components/map/sidebar/Tabs/MonitoringTab'
import OpmerkingenTab from '@/components/map/sidebar/Tabs/OpmerkingenTab'
import ParticipationTab from '@/components/map/sidebar/Tabs/ParticipationTab'
import ProcessInfoTab from '@/components/map/sidebar/Tabs/ProcessInfoTab'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'
import { ACTION_SIDEBAR_TAB } from '@/../shared/valueholders/action-sidebar-tabs'

export default {
  name: 'ActionSidebar',
  components: {
    OpmerkingenTab,
    MonitoringTab,
    ParticipationTab,
    MergeTab,
    InfoTab,
    ProcessInfoTab,
    SvgIcon,
  },
  data() {
    return {
      open: true,
      hasScrollbar: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['isChargingpointSelected', 'getSelectedChargingpoint']),
    ...mapGetters('app', ['getSelectedActionSidebarTab']),
    tabs () {
      const tabs = [
        {
          id: 'info-tab-button',
          value: ACTION_SIDEBAR_TAB.INFO,
          icon: 'info-circle-regular',
          visible: true,
          component: 'InfoTab',
        },
        {
          id: 'merge-tab-button',
          value: ACTION_SIDEBAR_TAB.MERGE,
          icon: 'merge-regular',
          visible: true,
          component: 'MergeTab',
        },
        {
          id: 'comments-tab-button',
          value: ACTION_SIDEBAR_TAB.OPMERKINGEN,
          icon: 'comments-solid',
          visible: true,
          component: 'OpmerkingenTab',
        },
        {
          id: 'reacties-tab-button',
          value: ACTION_SIDEBAR_TAB.PARTICIPATION,
          icon: 'users-regular',
          visible: true,
          component: 'ParticipationTab',
        },
        {
          id: 'monitoring-tab-button',
          value: ACTION_SIDEBAR_TAB.MONITORING,
          icon: 'chart-bar-regular',
          visible: true,
          component: 'MonitoringTab',
        },
        {
          id: 'process-info-tab-button',
          value: ACTION_SIDEBAR_TAB.PROCESS_INFO,
          icon: 'process-diagram',
          visible: true,
          component: 'ProcessInfoTab',
        },
      ]

      return tabs.filter(({ visible }) => visible)
    },
    activeTab() {
      return this.tabs.find(tab => tab.value === this.getSelectedActionSidebarTab)
    },
  },
  watch: {
    isChargingpointSelected() {
      this.toggle()
    },
    getSelectedChargingpoint() {
      this.checkForScrollbar()
    },
    activeTab: {
      immediate: true,
      handler: function()  {
        this.checkForScrollbar()
      },
    },
  },
  methods: {
    ...mapActions('app', ['selectTab']),
    toggle() {
      this.open = !this.open
    },
    close() {
      this.setActiveTab(null)
      /* send signal to close popup and also deselect charging point, which closes the sidebar;
         by that closing is handled in one place (ChargerDetailsPopup & ChargerEditorPopup) */
      EventBus.$emit('deselect-chargingpoint')
    },
    setActiveTab(tabName) {
      this.selectTab({ tabName })

      if (tabName !== null && this.open === false) {
        this.toggle()
      }
    },
    checkForScrollbar() {
      this.waitForPropagation(() => {
        const div = document.querySelector('.ActiveTab')

        if (div === null) {
          return
        }

        this.hasScrollbar = div.scrollHeight > div.clientHeight
      })
    },
    waitForPropagation(CallbackFn) {
      setTimeout(CallbackFn)
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';


$padding: 12px;
$paddingStickyBottom: 50px;
$icon-size: 15px;

$bar-width: 40px;
$container-width: 330px;
$hidden-position: $container-width;
$bar-position: $container-width - $bar-width; // only the tabs are visible
$open-position: 0;

.ActionSidebar {
  position: relative;
  flex: 1 1 auto;
  width: $container-width;
  margin-bottom: 10px;

  background: white;
  display: flex;
  min-height: 0; // fix so that sidebar content doesn't push TileData out of flexbox container
  transition: margin-right .25s ease-out;
  margin-right: - $bar-position; // needs to be margin-right, since transform creates a new bounding box which encapsulates the lightbox

  &.open {
    margin-right: $open-position;
  }

  &__Collapse {
    margin-top: auto;

    &__Icon {
      display: inline-block;
      width: 10px;
      height: 25px;
      transform: rotate(0);
      transition: .25s transform ease-out;
      cursor: pointer;
      margin-left: 2px;
      color: var(--primary);

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  .Tabs {
    width: $bar-width;
    display: flex;
    flex-direction: column;
    padding-top: math.div($padding, 2);
    border-right: 1px solid var(--border-color);
    z-index: 1;

    .svg-container {
      display: inline-block;
    }

    .Tab {
      cursor: pointer;
      font-size: $icon-size;
      padding-top: math.div($padding, 1.5);
      padding-bottom: math.div($padding, 1.5);
      padding-left: $padding;
      color: var(--text-muted);

      &:hover {
        background-color: var(--body-bg);
      }

      &.active {
        color: var(--primary);
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          right: -3px;
          top: math.div($icon-size + $padding, 8);
          width: 3px;
          height: ($icon-size + $padding * 1.2);
          border-radius: 2px;
          border: 2px solid var(--primary);
        }
      }
    }
  }

  .ActiveTab {
    flex: 1 1 auto;
    z-index: 2;
    background: white;
    overflow-y: auto;

    &__Eyebrow {
      font-size: .95rem;
      color: var(--text-muted);

      &__Icon {
        margin-top: -3px;
      }
    }

    &__Header {
      padding: math.div($padding, 1.2) $padding;
      position: relative;
      border-bottom: 1px solid var(--border-color);

      h4 {
        margin-bottom: 0;
        font-size: 1.3rem;
        margin-top: 5px;
        font-weight: 400;
      }
    }

    &__Close {
      position: absolute;
      z-index: 3;
      top: $padding;
      right: $padding;
      cursor: pointer;
      font-size: 1.25rem;

      &.hasScrollbar {
        right: 28px;
      }
    }

    &__Content {
      padding: math.div($padding, 1.2) $padding $paddingStickyBottom;
      overflow-x: hidden;
    }
  }
}

.sidebar-enter {
  margin-right: - $hidden-position !important;
}
.sidebar-enter-active,
.sidebar-leave-active {
  transition: margin-right .25s ease-out;
}
.sidebar-leave-to {
  margin-right: - $hidden-position !important;
}
</style>
