<template>
  <div class="BarChart">
    <GChart
      type="BarChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { numberWithDots } from '@/helpers/number'
import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'BarChart',
  components: { GChart },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Toename elektrische auto\'s',
        },
        isStacked: true,
        legend: { position: 'right', textStyle: { color: labelColor } },
        hAxis: { textPosition: 'out', textStyle: { color: labelColor } },
        vAxis: { textStyle: { bold: true, color: labelColor } },
        bar: { groupWidth: '70%' },
        colors: [
        getCssProperty('secondary'),
        getCssProperty('primary'),
        ],
        height: 122,
        width: 475,
        chartArea: { left: 50, right: 135 },
        focusTarget: 'category',
        animation: {
          duration: 500,
        },
      },
    }
  },
  computed: {
    chartData() {
    const chartSerieLabels = ['year', 'publicDemand', 'privateDemand', { role: 'annotation' }]
    const translatedLabels = chartSerieLabels.map((label, idx) => {
      if (idx <= 2) {
        return this.$i18n.t(`components.factsheets.evmaps.BarChart.${label}`)
      }
      return label
    })

    const data = [translatedLabels]

    Object.entries(this.data).forEach(entry => {
      data.push([entry[0], entry[1][0], entry[1][1], numberWithDots(entry[1][2])])
    })

    return data
  },
  },
}
</script>
