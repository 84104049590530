const roundEveryValue = (obj, decimalen) => {
  const result = {}

  for (const key in obj) {
    result[key] = parseFloat((Math.round(obj[key] * 100) / 100).toFixed(decimalen))
  }

  return result
}

export const computeFactsheetData = ({ data, years }) => {
  let pub = Math.round(data.aandeel_park_publiek_perc)
  const commuterTypes = ['bewoners', 'bezoekers', 'forenzen', 'samen']

  return {
    registeredVehicles: Math.round(data['aantal_personenautos_rdw']),
    evCountTotal: years.reduce((result, year) => {
      result[year] = [
        data[`totaal_ev_${year}_publiek_samen`],
        data[`totaal_ev_${year}_privaat_samen`],
        data[`totaal_ev_${year}_publiek_samen`] + data[`totaal_ev_${year}_privaat_samen`],
      ]
      return result
    }, {}),
    evCountPerGroup: years.reduce((result, year) => {
      result[year] = commuterTypes.map(group => data[`totaal_ev_${year}_publiek_${group}`] + data[`totaal_ev_${year}_privaat_${group}`])
      return result
    }, {}),
    chargerCountTotal: [
      [years[0], data[`totaal_laadpunt_${years[0]}_publiek_samen`], data[`totaal_laadpunt_${years[0]}_privaat_samen`]],
      [years[1], data[`totaal_laadpunt_${years[1]}_publiek_samen`], data[`totaal_laadpunt_${years[1]}_privaat_samen`]],
      [years[2], data[`totaal_laadpunt_${years[2]}_publiek_samen`], data[`totaal_laadpunt_${years[2]}_privaat_samen`]],
    ],
    chargerCountPerGroup: years.map(year => {
      return [
        [year],
        commuterTypes.map(group => data[`totaal_laadpunt_${year}_publiek_${group}`]),
        commuterTypes.map(group => data[`totaal_laadpunt_${year}_privaat_${group}`]),
      ]
    }),
    parking: {
      private: Math.round(100 - pub),
      public: pub,
    },
    currentChargingpoints: {
      public: data['ecomovement_laadpunt_public'],
      company: data['ecomovement_laadpunt_company'],
    },
    chargingpointDualUsage: {
      'public': {
        'inhabitants': roundEveryValue(data['laadpunt_dubbelgebruik_publiek_bewoners'], 1),
        'visitors': roundEveryValue(data['laadpunt_dubbelgebruik_publiek_bezoekers'], 1),
        'commuters': roundEveryValue(data['laadpunt_dubbelgebruik_publiek_forenzen'], 1),
      },
    },
  }
}
