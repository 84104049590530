<template>
  <img
    :src="imageSrc"
    :title="imageTitle"
    :class="['Logo', getCurrentTheme.type]"
  >
</template>
<script>
import { image } from '@/helpers/assets'
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',
  props: {
    organisation: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: 'logo.png',
    },
  },
  computed: {
    ...mapGetters('config', ['getCurrentTheme']),
    imageSrc () {
      return image({ name: this.image })
    },
    imageTitle () {
      return this.organisation + ' logo'
    },
  },
}
</script>

<style scoped lang="scss">
.Logo {
  max-width: 100%;
  max-height: 100%;
  &.custom {
    max-width: 150px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
