<template>
  <tr :class="{ 'TileData__highlighted': highlight }">
    <td class="d-block mr-4">
      <strong style="text-transform: capitalize">
        <slot name="first-column" />
      </strong>
    </td>
    <td class="text-center pr-4">
      <slot name="second-column" />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    highlight: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
