<template>
  <div
    class="Page"
    :class="{
      'Page--landscape': landscape,
      'Page--marginless': marginless
    }"
  >
    <div class="Print w-100 h-100 d-flex flex-column">
      <h1
        v-if="title"
        class="Factsheet__Header"
        v-text="title"
      />

      <h3
        v-if="subTitle"
        class="Factsheet__SubHeader"
        v-text="subTitle"
      />

      <div class="Factsheet__Main position-relative flex-grow-1">
        <slot />
      </div>

      <FactsheetFooter
        v-if="footer"
        :custom-logo="customLogo"
        :small-logo="smallLogo"
        :generated-at="generatedAt"
      >
        <slot name="footer" />
      </FactsheetFooter>
    </div>
  </div>
</template>

<script>
import FactsheetFooter from '@/components/factsheet/FactsheetFooter'

export default {
  name: 'FactsheetPage',
  components: {
    FactsheetFooter,
  },
  props: {
    landscape: {
      type: Boolean,
      default: false,
    },
    marginless: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subTitle: {
      type: String,
      required: false,
      default: null,
    },
    timestamp: {
      type: String,
      required: false,
      default: null,
    },
    smallLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    customLogo: {
      type: String,
      required: false,
      default: () => null,
    },
    footer: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    generatedAt() {
      if (!this.timestamp) {
        return null
      }

      return `Gegenereerd op: ${this.timestamp}`
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';


/**
 * Measurements are taken from a powerpoint "design", which has a different aspect ratio.
 * The factors below are used to help convert pp measurements into pixels
 */
$height: 1.96;
$width: 1.56;
$aspect: math.div($width + $height, 2);

.Page {
  position: relative;
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);

  .Factsheet__Header {
    text-align: center;
    margin-top: 10px * $height;
    line-height: 41px * $height;
    font-size: 28px * $aspect;
    color: var(--primary);
  }

  .Factsheet__SubHeader {
    text-align: center;
    margin-top: -5px * $height;
    margin-bottom: 5px * $height;
    line-height: 12px * $height;
    font-size: 10px * $aspect;
    color: var(--primary);
  }

  .Factsheet__Main {
    width: 600px * $width;
    margin: 0 auto;
    color: #7F7F7F;
  }

  .Print {
    padding: 0.5cm;
    background: white;
  }
}

.Page--landscape {
  width: 29.7cm;
  height: 21cm;
}
.Page--marginless {
  .Print {
    padding: 0
  }
}
</style>
