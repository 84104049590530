import { SOURCE, sourceToSlug } from '@/services/sourceTranslation'

export const importSource = ({ data }) => {
  let source = data.meta?.source
  const sourceImportValue = [data.address?.source, data.meta?.source].find(source => source?.startsWith('import'))

  if ((!source && data.address?.source === 'EcoMovement') || (source && data.address?.source)) {
    source = data.address.source
  }

  source = sourceImportValue || source

  return sourceToSlug({ source })
}

export const introducedBy = (chargingpoint) => {
  const { user, user_name } = chargingpoint.data.properties

  if (user?.type === 'participation') {
    return 'Inwoner'
  }

  if (user?.name) {
    return user.name
  }

  return importSource(chargingpoint) || user_name || SOURCE.EVTOOLS
}
