<template>
  <div class="d-flex align-items-center">
    <b-dropdown
      id="dropdown"
      :disabled="!dropdownItems?.length || disabled"
      variant="outline-primary"
      :text="dropdownText"
      class="ml-1"
    >
      <b-dropdown-item
        v-for="(item, index) in dropdownItems"
        :key="index"
        @click="handleSelectItem(item)"
      >
        {{ formatDate(item) }}
      </b-dropdown-item>
    </b-dropdown>
    <b-button
      v-if="selectedItem"
      variant="danger"
      class="ml-2 pb-0"
      @click="clearSelection"
    >
      <SvgIcon icon="times-regular" />
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'Dropdown',
  components: { SvgIcon },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dropdownItems: [],
      selectedItem: null,
    }
  },
  computed: {
    ...mapGetters('config', ['participationConfigs']),
    ...mapGetters('access', ['getActiveMunicipality']),
    closingDates(){
      return this.participationConfigs[0].allClosingDates
    },
    dropdownText() {
      if (!this.dropdownItems.length) {
        return this.$i18n.t('components.form.dropdown.none')
      }

      return this.selectedItem || this.$i18n.t('components.form.dropdown.selectDate')
    },
  },
  watch: {
    getActiveMunicipality: {
      immediate: true,
      handler (newMun, oldMun) {
        if (newMun !== oldMun) {
          this.initItems()
        }
      },
    },
  },
  methods: {
    initItems() {
      this.dropdownItems = this.closingDates?.sort((a, b) => a.localeCompare(b)) || []
      this.selectedItem = ''
    },
    handleSelectItem(item) {
      this.selectedItem = this.formatDate(item)
      this.$emit('input', item)
    },
    clearSelection() {
      this.selectedItem = ''
      this.$emit('input', '')
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('es-CL')
    },
  },
}
</script>
