<template>
  <div v-if="condition">
    <div class="d-flex pt-2 justify-content-between align-items-center">
      <span class="mr-3">{{ $t(filter.subline) }}</span>
      <Buttons
        :value="filter.active"
        @click="handleBtnClick"
      />
    </div>
  </div>
</template>

<script>
import Buttons from '@/components/form/Buttons'

export default {
  components: { Buttons },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    condition: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handleBtnClick(newValue){
      this.$emit('click', { selectedFilter: { ...this.filter, state: newValue } })
    },
  },
}
</script>
