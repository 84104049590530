import getCssProperty from '@/helpers/cssProperty'
export default class GoogleChartEntry {
  constructor(label, value, annotation, tooltip) {
    this.label = label
    this.value = value
    this.annotation = annotation
    this.tooltip = tooltip
    this.color = getCssProperty('rate-limit-low')
  }

  calculateSignalColor() {
    // calculate colors by given limits
    if (this.value > (this.limit * 0.85)) {
      this.color = getCssProperty('rate-limit-medium')
    }

    if (this.value >= this.limit) {
      this.color = getCssProperty('rate-limit-high')
    }
  }

  addLimit(limit, tooltip) {
    this.limit = limit
    this.limitTooltip = tooltip

    this.calculateSignalColor()
  }

  wrapContentInTooltip(content) {
    return `<div class="ggl-tooltip"> ${content} </div>`
  }

  static createEmpty() {
    return new this(
      '',
      0,
      'Geen data',
      'Voor deze maand staat er helaas <br /> <strong>geen data</strong> beschikbaar',
    )
  }

  static create(label, value, annotation, tooltip) {
    return new this(
      label,
      value,
      annotation,
      tooltip,
    )
  }

  toArray() {
    return [
      this.label,
      this.value,
      this.annotation,
      this.wrapContentInTooltip(this.tooltip),
    ]
  }

  toArrayWithLimit() {
    return [
      this.label,
      this.value,
      this.annotation,
      this.wrapContentInTooltip(this.tooltip),
      this.color,
      this.limit,
      this.wrapContentInTooltip(`Limiet: <strong>${this.limitTooltip}</strong>`),
    ]
  }
}
