import { convertNumberToEuropeanFormat, roundWithDecimals } from '@/helpers/number'

const getNetworkOperatorsByParks = (parks) => {
  const unqiueNetworkOperator = parks.reduce((acc, park) => acc.add(park.networkOperator), new Set())
  return Array.from(unqiueNetworkOperator).join(', ')
}

const getAreaInKm = (parks) => {
  const area = parks.reduce((acc, park) => acc + park.spaceCollective, 0)
  return area / 1000000
}

const aggregateVehicleTypesMetricByPeriodAndYears = (parks, metric, period, years) => {
  return years.reduce((acc, year) => {
    // iterate through each specified year, not the given years of the data
    acc[year] = Object.values(parks).reduce((parksAcc, park) => {
      // aggregate per vehicle type (0 = e-bestel, 1 = e-truck, 2 = e-bak) over all parks
      // todo:: add some error handling
      park[period][metric][year].forEach((vehicleCount, vehicleType) => {
        parksAcc[vehicleType] = parksAcc[vehicleType] + vehicleCount
      })

      return parksAcc
    }, [0, 0, 0])

    // add annotation labels
    acc[year] = acc[year].flatMap((value) => {
      // return non-numbers as-is
      if (typeof value !== 'number') {
        return [value]
      }

      // return duplicated value for annotation
      return [value, value]
    })

    return acc
  }, {})
}

const aggregateVehicleTypesMetric = (parks, metric) => {
  return parks.reduce((acc, park) => {
    park[metric].forEach((vehicleCount, vehicleType) => {
      acc[vehicleType] = acc[vehicleType] + park[metric][vehicleType]
    })
    return acc
  }, [0, 0, 0])
}

const roundEveryValue = (obj, decimals) => {
  const result = {}

  for (const key in obj) {
    if (typeof obj[key] !== 'number') {
      result[key] = obj[key].map((value) => typeof value === 'number' ? roundWithDecimals({ value, decimals }) : value)
      continue
    }

    result[key] = parseFloat((Math.round(obj[key] * 100) / 100).toFixed(decimals))
  }

  return result
}

export const computeFactsheetBedrijventerreinenData = ({ period, years, selectedParks }) => {
  return {
    // general info, not depended on period (day, night or total), nor years
    registeredVehicles: roundEveryValue(aggregateVehicleTypesMetric(selectedParks, 'registeredVehicles'), 0),
    companyCount: selectedParks.reduce((acc, park) => acc + park.companies, 0).toFixed(0),
    companyWithVehiclesCount: selectedParks.reduce((acc, park) => acc + park.companyWithVehiclesCount, 0).toFixed(0),
    lotCount: selectedParks.reduce((acc, park) => acc + park.lotCount, 0).toFixed(0),
    substations: selectedParks.length > 1 ? 'meer dan 1' : selectedParks[0]?.substations || 'Onbekend',
    demandCollective: selectedParks.reduce((acc, park) => acc + park.demandCollective, 0),
    spaceCollective: convertNumberToEuropeanFormat(getAreaInKm(selectedParks).toFixed(2)),
    networkOperator: getNetworkOperatorsByParks(selectedParks),

    // data to aggregate, depended on period (day, night or total) + years
    expectedVehicles: roundEveryValue(
      aggregateVehicleTypesMetricByPeriodAndYears(selectedParks, 'expectedVehicles', period, years),
      0),
    chargepoints: roundEveryValue(
      aggregateVehicleTypesMetricByPeriodAndYears(selectedParks, 'chargepoints', period, years),
      0),
    mw_per_year: roundEveryValue(
      aggregateVehicleTypesMetricByPeriodAndYears(selectedParks, 'mw_per_year', period, years),
      2),
    mwh_per_year: roundEveryValue(
      aggregateVehicleTypesMetricByPeriodAndYears(selectedParks, 'mwh_per_year', period, years),
      0),
  }
}
