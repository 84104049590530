<template>
  <div class="MenuGeneratorLink">
    <router-link
      class="link"
      :to="item.to"
    >
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuGeneratorLink',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.MenuGeneratorLink {
  position: relative;
  margin-bottom: 0.4rem;

  .link {
    &:hover {
      text-decoration-color: var(--success);
      text-underline-position: under;
    }
    &.router-link-active {
      color: black;
      font-weight: 600;
      pointer-events: none;
    }
  }
}
</style>
