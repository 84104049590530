<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { image } from '@/helpers/assets'

import { EventBus } from '@/services/eventbus'

import { provincieCodeByMunicipalityCode } from '@/services/municipalities'

/**
 * The land owned and maintained by the Rijkswaterstaat
 *  Mainly situated around highways
 */
export default {
  name: 'ConstructionSiteLayer',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      image: {
        name: 'construction-regular',
        path: '',
      },
      layerName: 'zh_nieuwbouwlocaties',
      type: 'symbol',
      minzoom: null,
      paint: {
        'icon-color': '#000000',
        'icon-opacity': [ // transition based on zoom
          'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.2,
            1,
        ],
      },
      layout: {
        'visibility': 'none',
        'symbol-placement': 'point',
        'symbol-z-order': 'source',
        'icon-allow-overlap': true,
        'icon-image': 'construction-regular',
        'icon-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0.01,
          18,
          0.3,
        ],
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser ||
        // this.hasAdminAccess({ code: this.getActiveMunicipality }) ||
        provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality }) === 28
    },
    source() {
      return {
        url: process.env.VUE_APP_CONSTRUCTION_LOCATIONS_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_CONSTRUCTION_LOCATIONS_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      if (! this.sourceName) return
      if (! this.hasAccess) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        if (! this.$store.map.hasImage(this.image.name)) {
          try {
            this.$store.map.loadImage(image({ name: `legend/${this.image.name}.png` }), (error, image) => {
              if (error) throw error

              if (! this.$store.map.hasImage(this.image.name)) {
                this.$store.map.addImage(this.image.name, image, { 'sdf': true })
              }

              this.addActualLayer()
            })
          } catch (e) {
            this.addActualLayer()
          }
        } else {
          this.addActualLayer()
        }
      })

    },
    addActualLayer() {
      this.$store.map.addSource(this.sourceName, {
        type: 'vector',
        url: this.source.url,
      })

      this.$store.map.addLayer({
        'id': this.layerName,
        'type': this.type,
        'source': this.sourceName,
        'source-layer': this.source.layer,
        'minzoom': this.minzoom || 0,
        'paint': this.paint,
        'layout': this.layout,
      }, this.labelLayer)

      EventBus.$emit('layers.national.loaded', {
        name: this.layerName,
      })
    },
  },
}
</script>
