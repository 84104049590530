<template>
  <tr :class="{ 'TileData__highlighted': highlight }">
    <td
      v-for="column in nrOfColumns"
      :key="column"
      :style="`width: ${column === 1 ? '70px' : '50px'}`"
      :class="[{'text-center': column > 1}, { 'd-flex': column === 1 }]"
    >
      <slot :name="`${columns[column]}-column`" />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    nrOfColumns: {
      type: Number,
      default: 3,
    },
    highlight: {
      type: Boolean,
      required: false,
    },
  },
  data () {
    return {
      columns: {
        '1': 'first',
        '2': 'second',
        '3': 'third',
        '4': 'fourth',
      },
    }
  },
}
</script>
