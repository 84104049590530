<template>
  <div
    v-if="loaded && accessible"
    class="Page--Export Export h-100 d-flex flex-column flex-grow-1"
  >
    <ImportNavBar />
    <div class="Export__Main d-flex flex-grow-1">
      <div class="Export__Content d-flex position-relative flex-grow-1 flex-shrink-0">
        <ExportNationalPanel />
      </div>
    </div>
    <Overlay />
  </div>
  <div
    v-else
    class="h-100 w-100 d-flex justify-content-center align-items-center"
  >
    <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
    <div class="d-flex align-items-center">
      <b-spinner
        class="mr-3"
        label="Loading..."
      />
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import ExportNationalPanel from '@/components/export/ExportNationalPanel'
import Overlay from '@/components/common/Overlay'

import { mapGetters, mapMutations } from 'vuex'
import ImportNavBar from '@/components/navbar/ImportNavBar.vue'

export default {
  name: 'Export',
  components: {
    ImportNavBar,
    ExportNationalPanel,
    Overlay,
  },
  /**
   * Handle a navigation between municipalities
   */
  beforeRouteUpdate (to, from, next) {
    // Redirect users without appropriate rights
    if (! this.superuser) {
      next({ name: 'Home' })
      return
    }

    next()
  },
  computed: {
    ...mapGetters('access', ['hasAccess']),
    ...mapGetters('config', ['loaded']),
    /**
     * Whether the Export portal of this municipality is accessible to the current user
     */
    accessible() {
      return this.superuser
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },

  /**
   * Navigate to the appropriate url upon a change in the active municipality
   *  The actual component & config changes are handled in the
   *  router guard upon successful navigation
   */
  watch: {
    loaded() {
      if (! this.accessible) {
        this.$router.push({ name: 'Home' })
      }
    },
  },

  /**
   * Load the municipality information
   */
  created() {
    // Redirect users without appropriate rights
    if (! this.superuser) {
      this.$router.push({ name: 'Home' })
    }
  },

  methods: {
    // todo:: should we set the page title?
    ...mapMutations(['setPageTitle']),
  },
}
</script>

<style lang="scss">
.Export {
  &__Content {
    width: 100%;
  }
  &__Main {
    max-height: calc(100% - 80px);
    // overflow-y: scroll;
    overflow-y: scroll;
  }
}

</style>
