<template>
  <div class="d-flex align-items-center add-parkinglot">
    <div
      v-if="coordinates"
      class="mb-3 w-100"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <strong>Laadpaal coördinaten</strong>
        <b-icon-pencil-square
          v-if="!isInputEnabled"
          class="ml-3 pointer"
          @click="isInputEnabled = true"
        />
        <div v-else>
          <b-icon-check
            class="TC-Comment__validation__icon pointer"
            @click="handleSaveCoordinates"
          />
          <b-icon-x
            class="TC-Comment__validation__icon pointer"
            @click="handleCancelCoordinates"
          />
        </div>
      </div>
      <div v-if="isInputEnabled">
        <FormField
          v-model="latLng[1]"
          :novalidate="true"
        />
        <FormField
          v-model="latLng[0]"
          :novalidate="true"
        />
      </div>
      <div v-else>
        {{ coordinates[1] }}, {{ coordinates[0] }}
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center w-100">
      <strong>Parkeervakken (Max. 8)</strong>
      <b-icon-plus
        v-if="canAddParkingLot"
        variant="dark"
        class="add-parkinglot__icon u-clickable"
        @click="handleAddParkinglot"
      />
    </div>
    <div
      v-for="(value, index) in ids"
      :key="index"
      class="d-flex justify-content-between align-items-center w-100"
    >
      <FormField
        v-model="ids[index]"
        type="number"
        :min="1"
        :novalidate="true"
      />
      <b-icon-trash
        v-if="index > 0"
        variant="dark"
        class="add-parkinglot__icon--trash u-clickable"
        @click="handleRemoveParkinglot({ id: value })"
      />
    </div>
    <b-alert
      :show="isDisabled"
      variant="danger"
      class="w-100 py-1"
    >
      Parkeervak nummer is verplicht!
    </b-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BIconPlus, BIconTrash, BIconPencilSquare, BIconCheck, BIconX } from 'bootstrap-vue'
import FormField from '@/components/form/FormField'

export default {
  name: 'ParkingLot',
  components: {
    BIconPlus,
    BIconTrash,
    FormField,
    BIconPencilSquare,
    BIconCheck,
    BIconX,
  },
  props: {
    parkinglotIds: {
      type: Array,
      default: () => [],
    },
    coordinates: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      ids: [],
      latLng: [],
      isInputEnabled: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['getSelectedParkingLotIds']),
    canAddParkingLot () {
      return this.ids.every(id => !!id) && this.ids.length < 8
    },
    isDisabled () {
      return !this.ids.some(v => !!v)
    },
  },
  watch: {
    ids () {
      this.$emit('parkinglotIds', this.ids)
    },
    getSelectedParkingLotIds: {
      immediate: true,
      handler () {
        this.ids = this.getSelectedParkingLotIds.length
          ? this.getSelectedParkingLotIds
          : [null]
      },
    },
  },
  created () {
    this.latLng = this.freeze(this.coordinates)
  },
  methods: {
    handleAddParkinglot () {
      this.ids.push('')
    },

    handleRemoveParkinglot ({ id }) {
      const index = this.ids.findIndex(lotId => lotId === id)
      this.ids.splice(index, 1)
    },

    handleSaveCoordinates () {
      const coordinates = [Number(this.latLng[0]), Number(this.latLng[1])]
      this.$emit('setCoordinates', coordinates)
      this.isInputEnabled = false
    },

    handleCancelCoordinates () {
      this.latLng = this.freeze(this.coordinates)
      this.isInputEnabled = false
    },
    freeze (item) {
      return JSON.parse(JSON.stringify(item))
    },
  },
}
</script>
<style lang="scss">
.add-parkinglot {
  flex-flow: row wrap;
  &__icon {
    font-size: 25px;
    &--trash {
      font-size: 15px;
      position: absolute;
      right: 3px;
      margin-bottom: 0.8em;
    }
  }
  .form-group {
    flex: 1 1 100%;
    margin-bottom: 0.5em;
  }
  .pointer {
    cursor: pointer;
  }
}
</style>
