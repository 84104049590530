<template>
  <Form @submit="() => null">
    <FormField
      v-model="model"
      :disabled="disabled"
      v-bind="config"
    />
  </Form>
</template>

<script>

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'

import { capitalizeFirstLetter } from '@/helpers/string'

/**
 * This scenario input is bound to the scenarios store module.
 */
export default {
  name: 'ScenarioInput',
  components: {
    Form, FormField,
  },
  props: {
    /**
     * The scenario attribute
     *  This should be an available state in the scenarios store module
     */
    scenario: {
      type: String,
      required: true,
    },
    /**
     * The available options. See default value for structure.
     */
    options: {
      type: Array,
      required: true,
      default: function() {
        return [{
          value: 'option-value',
          text: 'option label',
        }]
      },
    },
    /**
     * The field label. This is optional.
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * The input field configuration
     */
    config() {
      return {
        type: 'select',
        novalidate: true,
        label: this.label,
        options: this.options,
      }
    },
    /**
     * The input model is bound to the scenarios store module.
     */
    model: {
      get() {
        return this.$store.getters[`scenarios/${this.scenario}`]
      },
      set(value) {
        this.$store.commit(`scenarios/set${capitalizeFirstLetter(this.scenario)}`, {
          scenario: value,
        })
      },
    },
  },
}
</script>
