<script>
import { mapGetters } from 'vuex'
import MapLineWithDistance from '@/components/map/MapLineWithDistance'

export default {
  name: 'MapLinesGenerator',
  components: { MapLineWithDistance },
  computed: {
    ...mapGetters('planmode', [
      'getMapLines',
    ]),
  },
  render() {
    const h = this.$createElement

    return h('div', this.getMapLines.map(line => h(MapLineWithDistance, {
      props: {
          fromCoordinates: line.fromCoordinates,
          toPoints: line.toPoints,
          dashedLine: line.dashedLine,
        },
      })),
    )
  },
}
</script>
