<template>
  <div class="ActiveTab ValidationTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t('components.sidebar.Tabs.ParticipationTab') }}</h4>
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <ParticipationComments :chargingpoint="chargingpoint" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import ParticipationComments from '@/components/map/sidebar/ParticipationComments'

export default {
  components: { LocatieInformatieHeader, ParticipationComments },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
    }),
  },
}
</script>

<style lang="scss">
.ActiveTab__Content {
  p {
    margin-bottom: .5rem
  }
}
</style>
