<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BorderLayer',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
      'DeploymentCode',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    sourceName() {
      return this.DeploymentLayerName({ layer: 'gemeentegrenzen' })
    },
  },
  watch: {
    getActiveMunicipality(code) {
      // Update layer filter
      if (!this.loaded || ! this.$store.map.getLayer('border')) return

      this.$store.map.setPaintProperty(
        'border',
        'line-opacity',
        this.opacityByCode({ code }),
      )
    },
    loaded(loaded) {
      if (!loaded) return

      this.addBorder()
    },
  },
  created() {
    if (this.loaded) {
      this.addBorder()
    }
  },
  methods: {
    /**
     * Add the border layer
     */
    addBorder() {
      if (! this.sourceName) return

      if (this.$store.map.getLayer('border')) {
        this.$store.map.removeLayer('border')
      }
      if (this.$store.map.getSource('border')) {
        this.$store.map.removeSource('border')
      }

      this.$nextTick(() => {
        this.$store.map.addSource('border', {
          type: 'vector',
          url: `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        })

        this.$store.map.addLayer({
          'id': 'border',
          'type': 'line',
          'source': 'border',
          'source-layer': this.sourceName,
          'paint': {
            'line-color': 'hsla(23, 100%, 62%, 1)',
            'line-opacity': this.opacityByCode({ code: this.getActiveMunicipality }),
            'line-width': [ // transition based on zoom
              'interpolate',
              ['linear'],
              ['zoom'],
              10,
              0,
              10.2,
              2,
            ],
          },
        }, this.labelLayer)
      })
    },
    /**
     * Generate the fill-opacity style spec
     */
    opacityByCode({ code }) {

      // A regional mode, specifically for `Vlaams gewest`
      // TODO: Consider refactor
      // TODO: Fix zoom issue (layer disappears at minzoom 10 - before adding interpolation to line-width)
      // if (this.DeploymentCode === 'be' && code === '2000') {
      //   return 1
      // }

      return [
        'match',
        ['get', 'gemeentecode'],
        code, 1,
        0,
      ]
    },
  },
}
</script>
