<template>
  <div class="Factsheet__Bar">
    <div class="Bar__Icon">
      <img
        v-if="icon"
        :src="imageSrc"
        height="22"
      >
    </div>
    <div
      class="Bar__Title"
      v-text="title"
    />
    <div class="Bar__Icon">
      <img
        v-if="iconRight"
        :src="imageRightSrc"
        height="22"
      >
    </div>
    <div
      v-if="titleRight"
      class="Bar__End"
      v-text="titleRight"
    />
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    titleRight: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    imageSrc() {
      return image({ name: this.icon })
    },
    imageRightSrc() {
      return image({ name: this.iconRight })
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';


/**
 * Measurements are taken from a powerpoint "design", which has a different aspect ratio.
 * The factors below are used to help convert pp measurements into pixels
 */
$height: 1.96;
$width: 1.56;
$aspect: math.div($width + $height, 2);

.Factsheet__Bar {
  height: 22px * $height;
  background: var(--primary);
  color: white;
  padding: 0 10px;

  display: flex;
  align-items: center;

  z-index: 1;
  position: relative;

  .Bar__Icon {
    width: 35px * $width;
    margin-left: 4px * $width;
    display: flex;
    align-items: center;
    font-size: 14px * $aspect;

    .SvgIcon {
      fill: white;
    }
  }

  .Bar__Title {
    flex-grow: 1;
  }

  .Bar__Title,
  .Bar__End {
    margin: 0;
    font-size: 11px * $aspect;
  }
}
</style>
