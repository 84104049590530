<template>
  <a
    :href="`https://${url}`"
    v-text="url"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    switchCountry: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentCode',
    ]),
    url () {
      const countryCode = (this.DeploymentCode === 'nl') !== this.switchCountry ? 'nl' : 'be'
      return `evmaps.${countryCode}`
    },
  },
}
</script>
