<template>
  <NavBar class="PlainNavBar">
    <router-link
      v-if="superuser"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Dashboard' }"
    >
      <SvgIcon
        icon="cog-regular"
        :has-fill="true"
      />
    </router-link>
  </NavBar>
</template>

<script>
import NavBar from '@/components/common/NavBar'

import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'PlainNavBar',
  components: {
    NavBar, SvgIcon,
  },
  computed: {
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
}
</script>

<style lang="scss">


.ManualNavBar__Logout {
  color: white;
  cursor: pointer;

  &:hover {
    color: var(--cta);
  }
}
</style>
