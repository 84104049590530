<template>
  <nav class="NavBar d-flex flex-row">
    <div class="NavBar__logo">
      <router-link :to="{ name: 'Home'}">
        <Logo
          :organisation="getCurrentTheme.details.organization"
          :image="getCurrentTheme.details.logo"
        />
      </router-link>
    </div>
    <p class="NavBar__description align-self-center my-0 flex-grow-1">
      <span class="ml-2 d-flex">
        {{ pageTitle }}
      </span>
    </p>
    <div class="d-flex flex-row justify-content-end flex-grow-1 align-self-center align-items-center">
      <slot />

      <span
        class="NavBar__Management d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Management--visible': managementDashboardActive }"
        :value="managementDashboardActive"
        @click="showActiveItem('managementDashboardActive')"
      >
        <SvgIcon
          icon="info-circle-regular"
          :has-fill="true"
        />
      </span>

      <span
        v-if="getShowLocationCriteria"
        class="NavBar__Management d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Management--visible': locationCriteriaActive }"
        :value="locationCriteriaActive"
        @click="showActiveItem('locationCriteriaActive')"
      >
        <SvgIcon
          icon="card-list"
          :has-fill="true"
        />
      </span>

      <span
        class="NavBar__Profile d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Profile--visible': profileActive }"
        :value="profileActive"
        @click="showActiveItem('profileActive')"
      >
        <SvgIcon
          icon="user-regular"
          :has-fill="true"
        />
      </span>

      <span
        class="NavBar__ValidationNotification d-inline-flex align-center mr-3"
        :class="{ 'NavBar__ValidationNotification--visible': validationAlertsActive }"
        :value="validationAlertsActive"
        @click="showActiveItem('validationAlertsActive')"
      >
        <SvgIcon
          icon="bell"
          :has-fill="true"
        />
      </span>

      <span
        class="NavBar__Logout d-inline-flex align-center mr-3"
        :class="{ 'NavBar__Menu--visible': menuActive }"
        :value="menuActive"
        @click="showActiveItem('menuActive')"
      >
        <SvgIcon
          icon="bars-regular"
          :has-fill="true"
        />
      </span>
    </div>
    <ProfileCard
      v-if="profileActive"
      :style="{ right: profileCardOffset }"
    />
    <NavBarMenu
      v-if="menuActive"
      :style="{ right: profileCardOffset }"
    />
    <ValidationNotification
      v-if="validationAlertsActive"
      :style="{ right: profileCardOffset }"
    />
    <ManagementDashboard
      v-if="managementDashboardActive"
      :style="{ right: profileCardOffset }"
    />

    <LocationCriteria
      v-if="locationCriteriaActive"
      :style="{ right: profileCardOffset }"
    />
  </nav>
</template>

<script>
import Logo from '@/components/navbar/Logo'
import SvgIcon from '@/components/common/SvgIcon'

import ManagementDashboard from '@/components/navbar/ManagementDashboard'
import LocationCriteria from '@/components/navbar/LocationCriteria'
import ValidationNotification from '@/components/navbar/ValidationNotification'
import ProfileCard from '@/components/profile/ProfileCard'
import NavBarMenu from '@/components/common/NavBarMenu'

import { mapGetters, mapState } from 'vuex'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'NavBar',
  components: {
    Logo, SvgIcon, ProfileCard, NavBarMenu, ValidationNotification, ManagementDashboard, LocationCriteria,
  },
  data() {
    return {
      profileActive: false,
      menuActive: false,
      validationAlertsActive: false,
      managementDashboardActive: false,
      locationCriteriaActive: false,
    }
  },
  computed: {
    ...mapState(['pageTitle']),
    ...mapGetters('config', ['getCurrentTheme', 'getShowLocationCriteria']),
    /**
     * We need to avoid the profile card covering up the scrollbar of the main page content
     */
    profileCardOffset() {
      return '1.25em'
    },
    organization () {
      return this.getCurrentTheme.details.organization
    },
  },
  mounted() {
    EventBus.$on('overlay-hide', () => this.profileActive = this.menuActive = this.validationAlertsActive = this.managementDashboardActive = this.locationCriteriaActive =false)
  },
  beforeDestroy() {
    EventBus.$off('overlay-hide')
  },
  methods: {
    showActiveItem(item) {
      this[item] = true
      EventBus.$emit('overlay-show')
    },
  },
}
</script>

<style lang="scss">
.NavBar {
  position: relative;
  height: 80px;
  min-height: 80px;
  background: var(--primary);
  box-shadow: 0px -1px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;

  &__logo {
    width: 240px;
    height: 100%;
    padding: 7px;

    @media (max-width: 1050px) {
      width: 200px;
    }
  }
  &__description {
    height: 1.1rem;
    font-size: 1.1rem;
    color: var(--grey);

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }
  .form-group {
    margin-bottom: 0
  }
  .SvgIcon {
    font-size: 1.75rem;
    fill: white;
    cursor: pointer;

    &:hover {
      fill: darken(white, 20%);
    }
  }

  &__Profile--visible .SvgIcon {
    fill: var(--cta);
  }

  .ProfileCard, .NavBarMenu, .ManagementDashboard, .ValidationNotification {
    position: absolute;
    top: 68px;
    box-sizing: 0px 1px 3px 0px #dfe2e5;
  }
}
</style>
