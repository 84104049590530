/*
* helpers for mapbox
*/

const createMarker = async ({ coordinates, options, map }) => {
  const { default: mapboxgl } = await import('mapbox-gl') // Dynamically import mapbox-gl

  const defaults = {
    allowOverlap: false,
  }

  options = {
    ...defaults,
    ...options,
  }
  // Create a marker to display the distance
  return new mapboxgl.Marker(options)
    .setLngLat(coordinates)
    .addTo(map)
}

/**
 * StyleCondition
 * Class to create conditional styles in mapbox gl
 *
 * This is just to make conditions more readable
 */
class StyleConditions {
  conditions = []

  constructor({ method }) {
    this.conditions.push(method)
  }

  addCondition({ condition, output }) {
    this.conditions.push(condition, output)
  }

  addDefault({ value }) {
    this.conditions.push(value)
  }

  getConditions() {
    return this.conditions
  }
}

/**
 * Add a style condition to the map of conditions;
 *
 * This is just to make the code more readable
 */

module.exports = {
  createMarker,
  StyleConditions,
}
