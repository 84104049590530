<template>
  <div class="BarChart">
    <GChart
      type="BarChart"
      :settings="{ packages: ['corechart', 'table'], language: 'nl' }"
      :data="data"
      :options="options"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'BarChart',
  components: { GChart },
  props: {
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    settings() {
      return {
        packages: ['corechart'],
        language: 'nl',
      }
    },
    options() {
      return Object.assign(
        {
          chart: {
            title: this.title,
          },
          isStacked: true,
          legend: {
            position: 'right',
            textStyle: { color: labelColor, fontSize: 12 },
          },
          hAxis: {
            textPosition: 'out',
            textStyle: { color: labelColor },
            format: '#,###.##',
          },
          vAxis: {
            textStyle: { bold: true, color: labelColor },
            format: '#,###.##',
          },
          bar: { groupWidth: '70%' },
          colors: [
            getCssProperty('secondary'),
            '#E4EDF5',
            getCssProperty('primary'),
          ],
          height: 160,
          width: 480, // todo:: check how to make this smaller while still fitting the labels
          chartArea: { left: 50, right: 135 },
          focusTarget: 'category',
          animation: {
            duration: 500,
          },
          annotations: {
            alwaysOutside: true,
            highContrast: false,

            textStyle: {
              fontSize: 10,
              bold: true,
            },
          },
          series: {
            0: {
              annotations: {
                stem: {
                  length: 5,
                },
              },
            },
            1: {
              annotations: {
                stem: {
                  length: 22,
                },
                textStyle: {
                  color: '#93a1a4',
                },
              },
            },
            2: {
              annotations: {
                stem: {
                  length: 40,
                },
              },
            },
          },
        },
        this.chartOptions,
      )
    },
    data() {
      const chartSerieLabels = this.labels.reduce((acc, label, index) => {
        if (typeof label === 'string') {
          acc.push(
            this.$i18n.t(`components.factsheets.businessParks.BarChart.${label}`),
          )
        } else {
          acc.push(label)
          // Translate the role labels
        }

        // only add annotations for values, not the h-axis label
        if (index > 0) {
          acc.push({ role: 'annotation' })
        }

        return acc
      }, [])

      return Object.entries(this.chartData).reduce(
        (acc, [year, data]) => {
          acc.push([year, ...data])
          return acc
        },
        [chartSerieLabels],
      )
    },
  },
}
</script>
