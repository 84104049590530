<template>
  <div class="ProfileCard pt-4">
    <div>
      <p class="d-flex align-items-center justify-content-between px-4">
        <strong> {{ $t("navBarProfileMenu.userProfile") }} </strong>

        <span
          class="ProfileCard__logout d-inline-flex"
          @click="logout"
        >
          <span>{{ $t("logout") }}</span>
          <SvgIcon
            class="ml-2"
            icon="sign-out-regular"
          />
        </span>
      </p>
    </div>

    <div class="d-flex">
      <div class="user-details">
        <p class="d-flex align-items-center px-4">
          <SvgIcon
            icon="user-regular"
            :has-fill="false"
            class="mr-3"
          />
          <span class="d-flex flex-wrap flex-shrink-1">
            {{ currentUserName }}
          </span>
        </p>
        <p class="d-flex align-items-center px-4">
          <SvgIcon
            icon="envelope-regular"
            :has-fill="false"
            class="mr-3"
          />
          <span class="d-flex flex-wrap flex-shrink-1">
            {{ currentUserEmail }}
          </span>
        </p>
        <p class="d-flex align-items-center px-4">
          <SvgIcon
            icon="user-shield-regular"
            :has-fill="false"
            class="mr-2"
          />
          <span class="d-flex flex-wrap flex-shrink-1">
            {{ currentUserRoleLabel }}
          </span>
        </p>
      </div>

      <div id="locales">
        <div class="d-flex align-items-center px-4 pb-1">
          <SvgIcon
            icon="language"
            :has-fill="false"
            class="mr-3"
          />
          <span>{{ $t("language") }}</span>
        </div>

        <div class="radio-section px-5">
          <form>
            <input
              id="language-nl-radio"
              v-model="selectedLanguage"
              name="languageSelect"
              type="radio"
              value="nl"
              @click="selectLanguage('nl')"
            >
            <label
              for="language-nl-radio"
              class="ml-2 mb-0"
            >{{ $t("languages.nl") }}</label> <br>

            <input
              id="language-en-radio"
              v-model="selectedLanguage"
              name="languageSelect"
              type="radio"
              value="en"
              @click="selectLanguage('en')"
            >
            <label
              for="language-en-radio"
              class="ml-2 mb-0"
            >{{ $t("languages.en") }}</label>
          </form>
        </div>
      </div>
    </div>

    <PasswordBox @processing="handleProcessing" />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'
import PasswordBox from '@/components/profile/PasswordBox'

import { EventBus } from '@/services/eventbus'
import userMixin from '@/mixins/common/userMixin'

export default {
  name: 'ProfileCard',
  components: { PasswordBox, SvgIcon },
  mixins: [userMixin],
  data() {
    return {
      busy: false,
      selectedLanguage: localStorage.getItem('language'),
    }
  },
  created() {
    EventBus.$on('overlay-click', this.maybeClose)
  },
  beforeDestroy() {
    EventBus.$off('overlay-click', this.maybeClose)
  },
  methods: {
    maybeClose() {
      if (this.busy) return

      EventBus.$emit('overlay-hide')
    },
    handleProcessing({ busy }) {
      this.busy = busy
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    selectLanguage(language) {
      this.$i18n.locale = language
      // Store the selected language in local storage
      localStorage.setItem('language', language)
    },
  },
}
</script>

<style lang="scss">
// Styling radio button locales
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: 0.2s all linear;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #007bff;
}
.ProfileCard {
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 450px;
  max-width: 450px;

  p,
  .SvgIcon.SvgIcon {
    font-size: 1.15rem;
    cursor: auto;
  }
  &__logout,
  &__logout .SvgIcon {
    cursor: pointer !important;

    &:hover {
      color: #b04300;
    }
  }
}
</style>
