import { render, staticRenderFns } from "./FactsheetSection.vue?vue&type=template&id=7a05b156&scoped=true"
import script from "./FactsheetSection.vue?vue&type=script&lang=js"
export * from "./FactsheetSection.vue?vue&type=script&lang=js"
import style0 from "./FactsheetSection.vue?vue&type=style&index=0&id=7a05b156&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a05b156",
  null
  
)

export default component.exports