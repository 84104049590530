const uuid = require('uuid')

const messages = [
  {
    sentiment: 1,
    message: 'Er zijn veel te weinig laadpalen in onze wijk, dus deze paal zal de drukte rondom de laadpalen zeker verminderen.',
    status: 'ignore',
    hoursAgo: 108.58,
  },
  {
    sentiment: 1,
    message: 'Als kind droomde ik al van een laadpaal voor mijn huis, met deze locatie wordt deze droom eindelijk werkelijkheid.',
    status: 'todo',
    hoursAgo: 55.91,
  },
  {
    sentiment: -1,
    message: 'Deze locatie is recht voor ons keukenraam.',
    status: 'done',
    hoursAgo: 35.13,
  },
  {
    sentiment: 1,
    message: 'Absolute topplek!',
    status: 'new',
    hoursAgo: 12.87,
  },
]

module.exports = messages.map(({ hoursAgo, ...comment }) => {
  const creationDate = Date.now() - hoursAgo * 3600 * 1000

  return {
    data: {
      ...comment,
      uuid: uuid.v4(),
      created_at: creationDate,
      updated_at: creationDate,
    },
    ref: {
      '@ref': {
        id: Math.random().toString(10).slice(2),
      },
    },
  }
})
