<template>
  <div
    v-if="loaded && available && accessible"
    class="Page--Participation Participation h-100 d-flex flex-column flex-grow-1"
  >
    <!-- Not sure if we can use the FactSheetNavBar for the participation page, but I see no reason not to -->
    <ExportNavBar />
    <div class="Participation__Main d-flex flex-grow-1">
      <div class="Participation__Content d-flex position-relative flex-grow-1 flex-shrink-0">
        <Factsheet />
      </div>
    </div>
    <Overlay />
  </div>
  <div
    v-else
    class="h-100 w-100 d-flex justify-content-center align-items-center"
  >
    <!-- TODO: Make this looking nicer & offer a back button to Home / Contact address -->
    <div class="d-flex align-items-center">
      <b-spinner
        class="mr-3"
        label="Loading..."
      />
      <span>Loading...</span>
    </div>
  </div>
</template>

<script>
/**
 * This component mainly takes care of the (responsive) layout
 */
import ExportNavBar from '@/components/navbar/ExportNavBar'
import Factsheet from '@/components/factsheet/participation/Factsheet'
import Overlay from '@/components/common/Overlay'

import { mapGetters, mapMutations } from 'vuex'

import { municipalityBySlug, slugByCode, labelBySlug } from '@/services/municipalities'

export default {
  name: 'FactsheetParticipation',
  components: {
    ExportNavBar,
    Factsheet,
    Overlay,
  },
  /**
   * Handle a navigation between municipalities
   *  TODO: Figure out if there is anything else to do here
   */
  beforeRouteUpdate (to, from, next) {

    let slug = this.slug = to.params.municipality // slug

    // Redirect users without appropriate rights
    if (! this.superuser && ! this.hasAccess({ slug })) {
      next({ name: 'Home' })
      return
    }

    // Redirect users in case the municipality config is not loaded
    // Also redirect users if the prognose mode of the activated municipality is unavailable to them
    if (this.loaded && (!this.available || !this.accessible)) {
      next({ name: 'Home' })
      return
    }

    // NOTE: We're not setting the municipality code, because that was what triggered the navigation
    this.selectConfig({ slug })

    next()
  },
  data() {
    return {
      slug: '',
    }
  },
  computed: {
    ...mapGetters('access', [
      'hasAccess',
      'getActiveMunicipality',
      'canPreAccessPlanMode',
    ]),
    ...mapGetters('config', [
      'loaded', 'hasConfig', 'getConfigBySlug',
    ]),
    /**
     * Whether the requested municipality config is available at all
     *  Exit early if the configs have not yet been loaded
     */
    available() {
      return this.loaded && this.hasConfig({
        slug: this.slug,
      })
    },
    /**
     * Whether the Participation portal of this municipality is accessible to the current user
     */
    accessible() {
      if (!this.available) return false
      if (this.superuser) return true
      if (this.admin) return true

      let config = this.getConfigBySlug({ slug: this.slug })
      return !!(config.enabled && config.participation?.some(({ enabled }) => !!enabled))
    },
    superuser() {
      return this.$auth.user?.['https://evmaps.nl/superuser']
    },
    admin() {
      return this.canPreAccessPlanMode
    },
  },

  /**
   * Navigate to the appropriate url upon a change in the active municipality
   *  The actual component & config changes are handled in the
   *  router guard upon successful navigation
   */
  watch: {
    getActiveMunicipality(code) {
      let municipality = slugByCode({ code })
      // Avoid duplicate navigation
      if (this.slug !== municipality) {
        this.$router.push({
          name: 'Participation',
          params: {
            municipality,
          },
        })
        .catch(() => {
          // The router nags if we redirect the user in the beforeRouteUpdate navigation guard
        })
      }
    },
    /**
     * Verify after the configs have loaded that the municipality config is available & accessible
     *  It is possible that a user has rights to a municipality that has been disabled / removed.
     *  It is also possible that a municipality is available, but the prognose mode has been disabled.
     */
    loaded() {
      if (!this.available || !this.accessible) {
        this.$router.push({ name: 'Home' })
      }
    },
    slug() {
      this.setPageTitle({
        title: `EV Maps - Participatie - ${labelBySlug({ slug: this.slug })}`,
      })
    },
  },

  /**
   * Load the municipality information
   */
  created() {
    let slug = this.slug = this.$route.params.municipality

    // Redirect users without appropriate rights
    if (! this.superuser && ! this.hasAccess({ slug })) {
      this.$router.push({ name: 'Home' })
      return
    }

    // Redirect users in case the municipality config is not loaded
    // Also redirect users if the prognose mode of the activated municipality is unavailable to them
    if (this.loaded && (!this.available || !this.accessible)) {
      this.$router.push({ name: 'Home' })
      return
    }

    // Get all public municipality details
    let municipality = municipalityBySlug({ slug })

    this.setActiveMunicipality({
      code: municipality.code,
    })

    this.selectConfig({ slug })
  },

  methods: {
    ...mapMutations('config', [
      'selectConfig',
    ]),
    ...mapMutations('access', [
      'setActiveMunicipality',
    ]),
    ...mapMutations(['setPageTitle']),
  },
}
</script>

<style lang="scss">
.Participation {
  &__Content {
    width: 100%;
  }
  &__Main {
    max-height: calc(100% - 80px);
    // overflow-y: scroll;
    overflow-y: scroll;
  }
}

</style>
