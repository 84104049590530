import Vue from 'vue'
import VueI18n from 'vue-i18n'
import nlNL from '../assets/locales/nl-NL.json'
import enGB from '../assets/locales/en-GB.json'

Vue.use(VueI18n)

let storedLanguage = localStorage.getItem('language')

// Check if localStorageItem is valid
if (!['en', 'nl'].includes(storedLanguage)) {
  // Get browser language
  const browserLanguage = navigator.language.substring(0, 2)

  // Check if browser language is valid
  if (['en', 'nl'].includes(browserLanguage)) {
    localStorage.setItem('language', browserLanguage)
    storedLanguage = browserLanguage
  } else {
    localStorage.setItem('language', 'en')
    storedLanguage = 'en'
  }
}

const i18n = new VueI18n({
  locale: storedLanguage,
  messages: {
    'nl': nlNL,
    'en': enGB,
  },
})

// this can be imported into a store, for translations in vuex
export default i18n
