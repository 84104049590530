<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="['searchbox', { 'active': searchOpen }]">
    <img
      id="searchbox"
      :src="parkinglotImg"
      class="parkinglot"
      @click="handleOpen"
    >
    <!-- This LABEL property of vSelect is really a pain in the ass! If you have a different label value (e.g. id) than you use for seach (e.g straatnaam),
        you will get no options visible even if the OPTIONS property is filled with response!! No error, nothing!-->

    <vSelect
      ref="vSelect"
      v-model="selectedParkinglots"
      multiple
      :label="searchLabel"
      :no-drop="selectedParkinglots.length >= 8"
      :searchable="selectedParkinglots.length < 8"
      :loading="isSearching"
      :selectable="(option) => isSelectable(option)"
      :options="parkinglots"
      placeholder="Zoek op de parkeervak id of de straatnaam (Max. 8)"
      :autofocus="autoFocus"
      :class="['select-box', { 'expanded': searchOpen }]"
      @search="handleSearch"
      @open="showTotal = true"
      @close="showTotal = false"
    >
      <template #header>
        <span
          v-if="showTotal && parkinglots.length"
          class="total"
        >
          ({{ total }})
        </span>
      </template>
      <template #open-indicator="{ attributes }">
        <SvgIcon
          v-bind="attributes"
          icon="chevron-down-regular"
        />
      </template>
      <template #option="option">
        <div v-html="highlightSearchTerm(option.id)" />
        <em
          class="d-block"
          v-html="highlightSearchTerm(additionalValues({ option }))"
        />
      </template>
      <template #selected-option="option">
        <div>
          <strong>
            {{ option.id }}
          </strong>
          <em class="d-block">{{ additionalValues({ option }) }}</em>
        </div>
      </template>
      <template #no-options>
        {{ noOptionsMessage }}
      </template>
      <template
        v-if="noService"
        #footer
      >
        <b-alert
          variant="danger"
          :show="noService"
        >
          OpenAPI parkeervakken Amsterdam niet beschikbaar!
        </b-alert>
      </template>
    </vSelect>
    <b-tooltip target="searchbox">
      Zoek op de parkeervak id of de straatnaam
    </b-tooltip>
  </div>
</template>

<script>
import { image, icon } from '@/helpers/assets'
import proj4 from 'proj4'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { capitalizeFirstLetter } from '@/helpers/string'
import { mapGetters } from 'vuex'

export default {
  name: 'MapSearchParkinglot',
  components: {
    vSelect,
    SvgIcon,
  },
  data() {
    return {
      searchIcon: icon({ name: 'search-regular.svg' }),
      selectedParkinglots: [],
      searchOpen: false,
      parkinglots: [],
      debounceTimeout: null,
      isSearchEnabled: true,
      isSearching: false,
      autoFocus: true,
      additionalProperties: ['straatnaam', 'soort', 'eType', 'type', 'buurtcode'],
      noService: false,
      searchTerm: '',
      searchLabel: '',
      total: 0,
      showTotal: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['getSelectedParkingLotIds']),
    parkinglotImg () {
      return image({ name: 'legend/parkinglot.png' })
    },
    noOptionsMessage () {
      if (this.searchTerm.length >= 4) {
        return 'Geen parkeervakken gevonden'
      }
      return 'Zoek op de parkeervak id of de straatnaam'
    },
  },
  watch: {
    selectedParkinglots: {
      handler () {
        this.setParkinglotCoordinates()
      },
    },
  },
  methods: {
    isSelectable (option) {
      return !this.selectedParkinglots.length || !this.selectedParkinglots.some(lot => option.id === lot.id)
    },
    additionalValues ({ option }) {
      if (!option) {
        return
      }
      return this.additionalProperties.map(prop => option[prop]).filter(Boolean).join(', ')
    },

    handleSearch (term) {
      clearTimeout(this.debounceTimeout)

      if (term?.length < 4) {
        //this.searchTerm = ''
        //this.parkinglots = []
        this.isSearching = false
        return
      }

      this.searchTerm = term

      this.debounceTimeout = setTimeout(() => {
        this.isSearching = true
        this.handleSearchParkinglot(term)
      }, 500)
    },

    handleOpen () {
      this.searchOpen = !this.searchOpen
      this.noService = false
      //this.parkinglots = []

      if (this.searchOpen) {
        setTimeout(() => {
          this.$refs.vSelect.$refs.search.focus()
        }, 300)
      }
    },

    async handleSearchParkinglot (term) {
      this.searchLabel = Number(term) ? 'id' : 'straatnaam'

      // if (!this.isSearchEnabled || this.parkinglots.some(lot => lot[this.searchLabel].includes(term))) {
      //   this.isSearching = false
      //   return
      // }
      this.noService = false
      this.parkinglots = []

      term = this.searchLabel === 'straatnaam' ? term.split(' ').map(t => capitalizeFirstLetter(t)).join(' ') : term //.replace(/\s/g, '+')

      const url = `https://api.data.amsterdam.nl/v1/parkeervakken/parkeervakken/?${this.searchLabel}[like]=${term.trim()}*&_count=true&_pageSize=500`
      const parkingApi = await fetch(url, {
        method: 'GET',
        // Suddenly generates an preflight cors error on Dev and Prod, not localhost
        // headers: {
        //   'X-Api-Key': process.env.VUE_APP_AMS_OPENDATA_API,
        // },
      }).catch(() => this.isSearching = false)

      if (!parkingApi.ok) {
        this.isSearching = false
        this.noService = true
        return
      }
      const response = await parkingApi.json()

      if (response._embedded?.parkeervakken?.length) {
        this.parkinglots = response._embedded.parkeervakken
        this.total = response.page.totalElements
      }
      this.isSearching = false
    },

    setParkinglotCoordinates () {
      if (this.selectedParkinglots.length && !this.getSelectedParkingLotIds.length) {
        // World Geodetic System, in gebruik door Google Maps, Mapbox, CartoDB, e.a.
        const RD = '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs'
        const WGS84 = 'WGS84'
        const coordinates = [this.selectedParkinglots.at(-1)?.geometry.coordinates]
        const coords = coordinates.map(bound => bound[0].map(b => proj4(RD, WGS84, b)))
        this.$store.map.fitBounds(...coords, {
          maxZoom: 21,
        })
      }

      this.$store.dispatch('planmode/setHighlightedParkingLots', { ids: this.selectedParkinglots.map(p => p.id) })
    },

    highlightSearchTerm (data) {
      let search = this.searchTerm
      const match = (search && data) ? String(data).match(new RegExp(search, 'i')) : null

      if (match) {
          search = String(data).replace(match[0],
            `<span class="hl-search">${match[0]}</span>`,
          )
        return search
      } else {
        search = ''
      }
      return data
		},
  },
}
</script>
<style lang="scss" scoped>
.searchbox {
  position: absolute;
  top: 0.7em;
  left: 18.4em;
  z-index: 1;
  overflow: hidden;
  &.active {
    overflow: visible;
  }
  .select-box {
    transform: translateX(-500px);
    transition: 0.2s opacity 0.1s ease-in, transform 0.2s ease-out;
    box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
    width: 500px;
    opacity: 0;
    position: relative;
    top: -1px;

    &.expanded {
      transform: translateX(-1px);
      opacity: 1;
    }
  }
  .parkinglot {
    position: absolute;
    height: 37px;
    z-index: 1;
    border-radius: 4px;
    cursor: pointer;
  }
  .total {
    position: absolute;
    z-index: 1;
    right: 6em;
    top: 8px;
    font-size: 12px;
  }
}
::v-deep .v-select {
  margin: 0 !important;
  .vs__search {
    margin: 0;
    border-bottom: 1px solid black;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.67;
    color: #7F8FA4;
  }
  .vs__dropdown-toggle {
    padding-left: 3em;
    .vs__selected-options {
      min-height: 2.1em;
      .vs__selected {
        flex: 0 1 100%;
        display: flex;
        justify-content: space-between;
        background: none;
        border: none;
      }
    }
  }
  .vs__dropdown-option {
    .hl-search {
      padding: 0.2em 0 0.2em;
      background: rgba(255, 193, 7, 0.5);
    }
  }
  &.vs--unsearchable {
    .vs__actions, .vs__search {
      display: none;
    }
  }
  .vs__selected-options {
    flex: 0 1 90%;
  }
}
</style>
