<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'

/**
 * LS Kasten
 */
export default {
  name: 'LSKasten',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'ls_stations',
      type: 'circle',
      minzoom: 13,
      paint: {
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          1,
          22,
          16,
        ],
        'circle-color': 'hsl(93, 28%, 43%)',
        'circle-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          13,
          0,
          14,
          0.72,
        ],
        'circle-stroke-width': 0,
      },
      layout: {
        'visibility': 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser || this.hasAdminAccess({ code: this.getActiveMunicipality }) // || ['0335', '1783'].includes(this.getActiveMunicipality)
    },
    source() {
      return {
        url: process.env.VUE_APP_LSKASTEN_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_LSKASTEN_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      if (! this.sourceName) return
      // if (! this.hasAccess) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        this.$store.map.addSource(this.sourceName, {
          type: 'vector',
          url: this.source.url,
        })

        this.$store.map.addLayer({
          'id': this.layerName,
          'type': this.type,
          'source': this.sourceName,
          'source-layer': this.source.layer,
          'minzoom': this.minzoom,
          'paint': this.paint,
          'layout': this.layout,
        }, this.labelLayer)

        EventBus.$emit('layers.national.loaded', {
          name: this.layerName,
        })
      })
    },
  },
}
</script>
