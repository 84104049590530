import { roleSlugToLabel, USER_ROLE } from '../../shared/valueholders/userRoles'
import { getInstance } from '@/auth'
import { VERSION } from '@/components/TermsText.vue'

const state = {
  acceptedTerms: false,
}

const getters = {
  /*
  * AUTH SERVICE
  */
  authService() {
    return getInstance()
  },

  /*
  * USER INFORMATION
  */
  getCurrentUserEmail: (state, getters) => getters['authService'].user.email,
  getCurrentUserId: (state, getters) => getters['authService'].user.sub,
  getCurrentUserName: (state, getters) => getters['authService'].user.name,
  getCurrentUserRole: (state, getters, rootState, rootGetters) => {
    if (getters['isSuperuser']) {
      return USER_ROLE.SUPERUSER
    }

    const accessibleRoles = Object.values(rootGetters['access/getRoles']).filter(roles => roles.municipalities.includes(rootGetters['access/getActiveMunicipality']))
    if (accessibleRoles.length === 0) {
      return 'onbekend'
    }

    return accessibleRoles[0].role
  },
  getCurrentUserRoleLabel: (state, getters) => roleSlugToLabel({ role: getters['getCurrentUserRole'] }),
  hasAcceptedTerms: (state, getters) => {
    const acceptedTerms = getters['authService'].user['https://evmaps.nl/terms']

    let currentVersion = VERSION
    currentVersion = currentVersion.replace(/\./g, '_')

    const hadAlreadyAccepted = acceptedTerms && Object.keys(acceptedTerms).includes(currentVersion)

    return hadAlreadyAccepted || state.acceptedTerms
  },

  /*
  * PRIVILEGES
  */
  isSuperuser: (state, getters) => {
    return getters['authService'].user && getters['authService'].user['https://evmaps.nl/superuser']
  },
  isAdmin: (state, getters, rootState, rootGetters) => {
    return rootGetters['access/hasAdminAccess']({ code: rootGetters['access/getActiveMunicipality'] })
  },
  isEditor: (state, getters, rootState, rootGetters) => {
    return rootGetters['access/municipalityEditorCodeList'].includes(rootGetters['access/getActiveMunicipality'])
  },
}

const mutations = {
  setTermsAccepted() {
    state.acceptedTerms = true
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
