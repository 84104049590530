<template>
  <NavBar class="FactsheetNavBar">
    <!-- This may be not 100% accurate, due to feature restrictions.
         To avoid complexity the dropdown is then simply shown with 1 item. -->
    <MunicipalitySelection
      v-if="canAccessMultipleMunicipalities"
      filter="factsheet"
      class="mr-3"
    />

    <router-link
      v-if="superuser"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Dashboard', params: { municipality: activeMunicipalitySlug } }"
    >
      <SvgIcon
        icon="cog-regular"
        :has-fill="true"
      />
    </router-link>
    <!--
    <router-link
      v-if="isPrognoseEnabled || canPreAccessPrognose"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Viewer', params: { municipality: activeMunicipalitySlug } }">
      <SvgIcon icon="map-regular" :hasFill="true" />
    </router-link> -->
  </NavBar>
</template>

<script>
import NavBar from '@/components/common/NavBar'
import MunicipalitySelection from '@/components/navbar/MunicipalitySelection'
import SvgIcon from '@/components/common/SvgIcon'

import { codeToSlug } from '@/services/municipalities'

import { mapGetters } from 'vuex'

export default {
  name: 'FactsheetNavBar',
  components: {
    NavBar, MunicipalitySelection, SvgIcon,
  },
  computed: {
    ...mapGetters('config', [
      'isPrognoseEnabled',
      'hasLayers',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
      'canAccessMultipleMunicipalities',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    admin() {
      return this.hasAdminAccess({ code: this.getActiveMunicipality })
    },
    activeMunicipalitySlug() {
      return codeToSlug({ code: this.getActiveMunicipality })
    },
    canPreAccessPrognose() {
      return this.hasLayers({ code: this.getActiveMunicipality }) && ( this.superuser || this.admin )
    },
  },
}
</script>

<style lang="scss">


.FactsheetNavBar__Logout {
  color: white;
  cursor: pointer;

  &:hover {
    color: var(--cta);
  }
}
</style>
