<!-- Don't forget to change the version number at the bottom -->
<template>
  <div>
    <div class="text-muted mb-3">
      Laatst gewijzigd: 25 mei 2022
    </div>
    <ul>
      <li>Een gebruikersaccount voor de software van EVtools (EVConsult) is strikt persoonlijk en de inloggegevens mogen dan ook niet gedeeld worden met andere personen.</li>
      <li>De gebruiker is akkoord dat EVtools zijn/haar emailadres gebruikt om informatie te verstrekken over (updates van) het product. De gebruiker kan zich te allen tijde afmelden voor deze updates door op de uitschrijflink te klikken die onderaan de update wordt verstrekt. De gebruiker wordt niet automatisch uitgeschreven van deze lijst als het gebruikersaccount in EVtools is opgeheven.</li>
      <li>De gebruiker is akkoord dat er informatie wordt bijgehouden welke gebruiker wat en wanneer doet in de software. Deze informatie kan gebruikt worden in de software zelf om de samenwerking van verschillende stakeholders in de tool te vergemakkelijken. Tevens kan deze informatie gebruikt worden bij eventuele audits.</li>
      <li>Gebruiker maakt gebruik van een Software as a Service (SaaS) product. Het SaaS product wordt verder ontwikkeld op basis van input van de Klant en andere afnemers van het SaaS product. Gebruiker is akkoord dat er automatisch nieuwe versies van het product worden geïmplementeerd.</li>
      <li>Voor het gebruik van de EVtools software betreffende opslagruimte, hosting en rekenkracht geldt een fair use policy (een voorwaarde voor redelijk gebruik van de software).</li>
      <li>EVtools behoudt het recht om een account te blokkeren en/of verwijderen indien er onrechtmatig gebruikt wordt gemaakt van de software, of op verzoek van de Klant.</li>
    </ul>
  </div>
</template>

<script>
// Allowed characters: letters, numbers, spaces, dashes and dots
export const VERSION = '25-05-2022'

export default {
  name: 'TermsText',
}
</script>
