const uuid = require('uuid')
const { COUNTRIES } = require('../../src/data/countries')

const uuidValidateV4 = ({ id }) => {
  return uuid.validate(id) && uuid.version(id) === 4
}

const isNumeric = (string) => /^\d+$/.test(string)
const isNumber = (n) => Number(n)=== n
const isFloat = value => parseFloat(value) === value
const isFloatString = value => parseFloat(value)+'' === value
const isString = (string) => (typeof string === 'string' || string instanceof String)
const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item))
// Also allow space and dash
const isAlphanumericIsh = (string) => /^[a-z0-9 -]+$/i.test(string)
// Simple, based on known exceptions
const isDutchPostalcode = (string) => /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(string)
// Simple, based on generic number plate structure
const isDutchNumberplate = (string) => /^([a-z]{1,3}|[\d]{1,3})-?([a-z]{1,3}|[\d]{1,3})-?([a-z]{1,3}|[\d]{1,3})$/i.test(string)
// https://regexr.com/3aevr - slightly modified
const isDutchPhoneNumber = (string) => /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(string)
// https://emailregex.com - slightly modified
const isEmail = (string) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x5d-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x5d-\x7f])+)\])/.test(string)

const isLatitude = (string) => parseFloat(string) <= 90 && parseFloat(string) >= -90
const isLongitude = (string) => parseFloat(string) <= 180 && parseFloat(string) >= -180
const isValidCoordinateSet = (lat, lng) => isLatitude(lat) && isLongitude(lng)

const unicodeString = (string) => /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@€+!?&:;()#/" ])+$/.test(string)
const multilineUnicodeString = (string) => /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@€+!?&:;()#/" \r\n])+$/.test(string)
const getInvalidCharacters = (string) =>  {
  const uniques = new Set(string.replace(/[\u00c0-\u01ffa-zA-Z0-9'\-,._@€+!?&:;()#/" ]/g, '').split(''))
  return [...uniques].join('')
}

/**
 * Alphanumeric + underscore
 */
const isAplhanumericUnderscore = (string) => /^[a-z0-9_]+$/i.test(string)

/*
* EV-TOOLS - MUNICIPALITIES
*/
const validateCode = ({ code }) => {
  if (code.length > 2 && code.match(/^([a-zA-Z_-]+)$/)) return true // (e.g. gent)
  if ( (code.length === 4 || code.length === 5 ) && ! isNumeric(code)) return false
  if (code.length > 5 && !isNumeric(code.split('_').join(''))) return false
  return true
}

const validateCountries = ({ countries = [] }) => {
  return countries.every(country => COUNTRIES.includes(country.toLowerCase()))
}

module.exports = {
  uuidValidateV4,
  isNumeric,
  isNumber,
  isFloat,
  isFloatString,
  isString,
  isObject,
  isAlphanumericIsh,
  isDutchPostalcode,
  isDutchNumberplate,
  isDutchPhoneNumber,
  isEmail,
  isLatitude,
  isLongitude,
  isValidCoordinateSet,
  unicodeString,
  multilineUnicodeString,
  getInvalidCharacters,
  isAplhanumericUnderscore,
  validateCode,
  validateCountries,
}
