<template>
  <div
    class="TileData px-3 pb-4 pt-0"
    :class="{open: open}"
  >
    <div
      class="TileData__Collapse"
      @click="toggle"
    >
      <div
        class="TileData__Collapse__Icon"
        :class="{ rotated: open }"
      >
        <SvgIcon icon="chevron-left-solid" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon'

export default {
  components: { SvgIcon },
  data() {
    return {
      open: true,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
    },
  },
}
</script>

<style lang="scss">


$tile-background: var(--primary);

.TileData {
  position: relative;
  width: 400px;
  background: $tile-background; //white;
  color: white; // #7F8FA4;
  padding: 10px 15px 25px;
  transition: all .25s ease-out;
  transform: translateX(400px);
  max-height: 35px;
  z-index: 5;
  // box-shadow: 0px -1px 0px 0px rgba(223,226,229,1) inset;

  &__Collapse {
    position: absolute;
    left: -40px;
    bottom: 0;
    width: 40px;
    height: 35px;
    padding-top: 10px;
    padding-left: 12px;
    border-radius: 10px 0 0 10px;
    background: $tile-background;
    cursor: pointer;

    &__Icon {
      display: inline-block;
      width: 10px;
      height: 15px;
      transform: rotate(0);
      transition: transform .25s ease-out;
      margin-left: 2px;
      color: white;

      &.rotated {
        transform: rotate(180deg);
      }
    }
  }

  &.open {
    transform: translateX(0);
    max-height: 650px;
  }

  &__title {
    min-height: 60px;
  }

  &__highlighted {
    color: var(--secondary-text);
  }
  .fid {
    color: var(--grey-darker-10);
  }
}
</style>
