
// Find out which region, the url pathname (municipality), is part of and set the theme value accordingly, to be used in the app //
import { allRegions, municipalityBySlug } from '@/services/municipalities'
import themes from '@/data/themes'

export function currentTheme ({ path }) {
  const pathname = path.split(/\//g).filter(Boolean)
  let currentTheme = themes.find(theme => theme.type === 'default')

  pathname.map(slug => {
    const municipality = municipalityBySlug({ slug })
    const region = allRegions().find(region => region.proxy?.includes(municipality?.code))

    if (region) {
      currentTheme = themes.find(theme => region.slug === theme.geography?.slug)
    }
  })
  return currentTheme
}
