<template>
  <div class="d-flex">
    <ScenarioInput
      :disabled="isLimitedForecast"
      class="mr-3"
      scenario="model"
      :options="models"
    />
    <ScenarioInput
      class="mr-3"
      scenario="year"
      :options="availableYears"
    />
    <ScenarioInput
      :disabled="isLimitedForecast"
      class="mr-3"
      scenario="access"
      :options="access"
    />
    <ScenarioInput
      :key="forecastOptionsKey"
      class="mr-3"
      scenario="forecast"
      :options="availableForecastOptions"
    />
  </div>
</template>

<script>
import ScenarioInput from '@/components/navbar/ScenarioInput'
import { mapGetters, mapMutations } from 'vuex'
import userMixin from '@/mixins/common/userMixin'

export default {
  name: 'ScenarioInputs',
  components: {
    ScenarioInput,
  },
  mixins: [userMixin],
  data() {
    return {
      forecastOptionsKey: 0,
    }
  },
  computed: {
    /**
     * The scenario options for years & models are part of the municipality configuration
     */
    ...mapGetters('config', ['years', 'models', 'forecastOptions']),
    ...mapGetters('scenarios', ['year', 'model', 'forecast']),
    ...mapGetters('layers', {
      forecastLayers: 'getForecastLayerDetails',
    }),
    /**
     * The scenario options for access & forecast are fixed
     */
    access() {
      return [
        {
          value: 'public',
          text: this.$i18n.t('accessibility.public'),
        },
        {
          value: 'private',
          text: this.$i18n.t('accessibility.private'),
        },
      ]
    },

    // For these forecast variations have no distinction between public / private or data models
    isLimitedForecast() {
      const forecastLayer = this.forecastLayers.find(
        (layer) => layer.id === this.forecast,
      )
      return !!forecastLayer?.config?.isLimitedForecast
    },

    availableYears() {
      const forecastLayer = this.forecastLayers.find(layer => layer.id === this.forecast)

      let scenarioYears = this.years

      if (forecastLayer?.config?.availableYears) {
        scenarioYears = forecastLayer.config.availableYears
      } else if (this.isLimitedForecast) {
        scenarioYears = [2022, 2025, 2030].filter(year => this.years.includes(year))
      } else if (this.model === 'SparkCity') {
        scenarioYears = this.years.filter(year => year <= 2035)
      }

      return scenarioYears.sort((a, b) => a - b)
    },

    /**
     * Only show the 'gemeenteprognoses' option to superusers
     */
    availableForecastOptions() {
      if (this.superuser) {
        return this.forecastOptions.map((option) => ({
          ...option,
          text: this.$i18n.t(option.text),
        })).sort((a, b) => a.text.localeCompare(b.text))
      }

      return this.forecastOptions
        .filter((o) => o !== 'gemeenteprognoses')
        .map((option) => ({
          ...option,
          text: this.$i18n.t(option.text),
        })).sort((a, b) => a.text.localeCompare(b.text))

    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
  },
  watch: {
    /**
     * Track changes to the scenario options that can change per municipality
     *  If the active option is no longer available, select the first available option
     */
    availableYears() {
      this.checkYearOptions()
    },
    models() {
      this.checkModelOptions()
    },
    availableForecastOptions() {
      this.checkForecastOptions()
    },
  },
  created() {
    this.checkYearOptions()
    this.checkModelOptions()
    this.checkForecastOptions()
  },
  methods: {
    ...mapMutations('scenarios', ['setYear', 'setModel', 'setForecast']),

    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkYearOptions() {
      if (!this.availableYears.includes(this.year)) {
        this.setYear({
          scenario: this.availableYears[0] || null,
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkModelOptions() {
      if (!this.models.includes(this.model)) {
        this.setModel({
          scenario: this.models[0] || null,
        })
      }
    },
    /**
     * If the selected option is (no longer) available, select the first available option
     */
    checkForecastOptions() {
      if (!this.availableForecastOptions.includes(this.forecast)) {
        const stdForecast = this.availableForecastOptions.find(option => option.value === 'determinative').value

        this.setForecast({
          scenario: stdForecast || null,
        })
      }
    },
  },
}
</script>
