<template>
  <div class="mb-3">
    <Form>
      <FormField
        v-model="field.value"
        v-bind="field"
        :options="statusOptions"
        @input="setChargingPointCategory"
      />
    </Form>

    <ChargingpointsLayerGroup
      v-if="groupLayers.length > 1"
      :layer="layer"
      :group-layers="groupLayers"
      :selected-status="selectedStatus"
      :is-legend="false"
      @input="handleInput"
    />
  </div>
</template>

<script>
import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import ChargingpointsLayerGroup from '@/components/common/ChargingpointsLayerGroup'
import statusMixin from '@/mixins/common/statusMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'LocationStatusSelector',
  components: { ChargingpointsLayerGroup, Form, FormField },
  mixins: [statusMixin],
  props: {
    disabledStatuses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedStatus: 'chargingpoints-suggestion',
      field: {
        label: 'Status',
        value: 'suggestion',
        type: 'select',
      },
    }
  },
  computed: {
    ...mapGetters('layers', {
      getPlanmodeLayerDetails: 'getPlanmodeLayerDetails',
    }),
    groupLayers() {
      return this.getPlanmodeLayerDetails.filter((layer) =>
        this.field.value.includes(layer.config.statusGroup),
      )
    },
    layer() {
      return this.groupLayers.find((layer) =>
        layer.id.includes(this.field.value),
      )
    },
  },
  created() {
    // should there be statuses disabled, set the current value of dropdown field to the first available
    if (this.disabledStatuses.length > 0) {
      this.field.value = this.options[0]?.value
      this.selectedStatus = `chargingpoints-${this.field.value}`
    }
  },
  methods: {
    setChargingPointCategory(chargingPointCategory) {
      this.field.value = chargingPointCategory
      this.selectedStatus = this.layer.id
    },
    handleInput(chargingPoint) {
      this.selectedStatus = chargingPoint.layer.id

      this.$emit('select', {
        value: this.selectedStatus.replace('chargingpoints-', ''),
        label: chargingPoint.layer.label,
      })
    },
  },
}
</script>
