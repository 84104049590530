<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
    class="TileDataRegular"
  >
    <template #content-header>
      <TileDataContentHeader>
        <template #second-column>
          {{ $t('components.map.tiles.TileData.EV') }}
        </template>
        <template #third-column>
          {{ $t('components.map.tiles.TileData.chargingStations') }}
        </template>
      </TileDataContentHeader>
    </template>

    <template #content="{ showData }">
      <!-- Personenauto’s -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          {{ $t('components.map.tiles.RegularCharging.privateCars') }}
        </template>

        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('participants.inhabitants') }}
          </template>
          <template #second-column>
            {{ showData({field: `bewoners_ev_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `bewoners_ldpt_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('participants.visitors') }}
          </template>
          <template #second-column>
            {{ showData({field: `bezoekers_ev_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `bezoekers_ldpt_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('participants.commuters') }}
          </template>
          <template #second-column>
            {{ showData({field: `forenzen_ev_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `forenzen_ldpt_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>

      <!-- Overige mobiliteiten -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          {{ $t('components.map.tiles.RegularCharging.otherMobilities') }}
        </template>

        <TileDataContentRowThreeColumn v-if="!isProvince">
          <template #first-column>
            {{ $t('components.map.tiles.RegularCharging.sharedCars') }}
          </template>
          <template #second-column>
            {{ showData({field: `e-deelauto_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `e-deelauto_ldpt_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.RegularCharging.taxis') }}
          </template>
          <template #second-column>
            {{ showData({field: `e-taxi_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `e-taxi_ldpt_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            Bestelauto’s
            <SvgIcon
              id="sun"
              icon="sun"
            />
            <b-tooltip
              target="sun"
              triggers="hover"
            >
              {{ $t('components.map.tiles.RegularCharging.dayTooltip') }}
            </b-tooltip>
          </template>
          <template #second-column>
            {{ showData({field: `e-bestel_dag_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `e-bestel_ldpt_dag_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            Bestelauto’s
            <SvgIcon
              id="moon"
              icon="moon"
            />
            <b-tooltip
              target="moon"
              triggers="hover"
            >
              {{ $t('components.map.tiles.RegularCharging.nightTooltip') }}
            </b-tooltip>
          </template>
          <template #second-column>
            {{ showData({field: `e-bestel_nacht_pub_${year}`, decimals: 1}) }}
          </template>
          <template #third-column>
            {{ showData({field: `e-bestel_ldpt_nacht_pub_${year}`, decimals: 1}) }}
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>
      <TileDataContentRowThreeColumn :highlight="true">
        <template #first-column>
          {{ $t('max') }}
        </template>
        <template #third-column>
          {{ showData({field: `ldpt_tot_${year}`, decimals: 1}) }}
          <SvgIcon
            id="total-max"
            icon="info-circle-regular"
          />
          <b-tooltip
            target="total-max"
            triggers="hover"
          >
            {{ $t('components.map.tiles.RegularCharging.tooltip') }}
          </b-tooltip>
        </template>
      </TileDataContentRowThreeColumn>
    </template>

    <template #footer>
      ID: {{ fids[0] }}
    </template>
  </TileDataContent>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TileDataContentGroupThreeColumn from '@/components/map/tiles/TileDataContentGroupThreeColumn'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'TileDataRegularChargingNeighborhood',
  components: {
    TileDataContentGroupThreeColumn,
    TileDataContentRowThreeColumn,
    TileDataContentHeader,
    TileDataContent,
    SvgIcon,
   },
  mixins: [TiledataMixin],
}
</script>
<style lang="scss">
.TileDataRegular {
  .text-center {
  .svg-container {
    position: relative;
    top: 2px;
    cursor: pointer;
    color: white;
  }
}
td:not(.text-center) {
  position: relative;
    .svg-container {
      position: absolute;
      top: 4px;
      margin-left: 6px;
      svg {
        width: 17px;
        height: 17px;
      }
      &#moon {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
</style>
