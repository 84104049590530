import { render, staticRenderFns } from "./ParticipationCommentsItem.vue?vue&type=template&id=8ae43316&scoped=true"
import script from "./ParticipationCommentsItem.vue?vue&type=script&lang=js"
export * from "./ParticipationCommentsItem.vue?vue&type=script&lang=js"
import style0 from "./ParticipationCommentsItem.vue?vue&type=style&index=0&id=8ae43316&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ae43316",
  null
  
)

export default component.exports