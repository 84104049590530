import { mapActions, mapGetters, mapMutations } from 'vuex'
import { provincieCodeByMunicipalityCode } from '@/services/municipalities'
import userMixin from '@/mixins/common/userMixin'
import privilegesMixin from '@/mixins/common/privilegesMixin'

export default {
  mixins: [privilegesMixin, userMixin],
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('app', [
      'isMapReady',
    ]),
    ...mapGetters('config', [
      'isMonitoringEnabled',
      'isParticipationEnabled',
    ]),
    ...mapGetters('filters', [
      'getFilterById',
      'getFiltersByPrefix',
    ]),
  },
  watch: {
    isMapReady: async function() {
      this.resetFilters()
      this.filterRulesCallback()
    },
  },
  methods: {
    ...mapActions('filters', ['resetFilters']),
    ...mapMutations('filters', ['setFilterOptions']),

    /*
    * For business rules regarding filters
    * automatic callback after loading evmaps
    */
    filterRulesCallback() {
      this.showMonitoringFiltersIfFeatureIsActive()
      this.showParticipationFiltersIfFeatureIsActive()
    },

    /**
     * CODE EXAMPLE
     *
     * Automatically turn on priority filter for any municipalities in Limburg & Brabant provinces.
     * Automatically turn it off for the other municipalities
     */
    activateFilterForBrali() {
      let filter = this.getFilterById({ id: 'priority-fase-2' })

      let activate = ([31, 30].includes(provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality })))

      const excludedMunicipalities = [
        // s-hertogenbos (is missing prio data for the filter)
        '0796',
        // tilburg (don't want the filter automatically turned on anymore)
        '0855',
        // eindhoven (don't want the filter automatically turned on anymore)
        '0772',
      ]

      if (excludedMunicipalities.includes(this.getActiveMunicipality)) {
        activate = false
      }

      filter.active = activate

      this.setFilterOptions({
        id: filter.id,
        options: filter,
      })
    },

    /**
     * Show monitoring filters if the municipality has the feature activated
     */
    showMonitoringFiltersIfFeatureIsActive() {
      let filters = this.getFiltersByPrefix({ prefix: 'monitoring-' })

      if (! filters.length > 0) {
        return
      }

      filters.forEach(filter => {
        filter.visible = this.isMonitoringEnabled

        this.setFilterOptions({
          id: filter.id,
          options: filter,
        })
      })
    },

    /**
     * Show participation filters if the municipality has the feature activated
     */
    showParticipationFiltersIfFeatureIsActive() {
      let filters = this.getFiltersByPrefix({ prefix: 'participation-' })

      filters.forEach(filter => {
        filter.visible = this.isParticipationEnabled

        this.setFilterOptions({
          id: filter.id,
          options: filter,
        })
      })
    },
  },
}
