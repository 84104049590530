<template>
  <li class="d-flex my-3 align-items-center">
    <div
      v-if="layer.icon.svg"
      class="d-flex justify-content-center"
      :style="{ fill: layer.icon.color, fontSize: layer.icon.size, width: `${layer.icon.width}px` }"
    >
      <SvgIcon
        :icon="layer.icon.slug"
        :has-fill="true"
      />
    </div>
    <span v-else>
      <img
        :src="layer.icon.slug"
        :width="layer.icon.width"
      >
    </span>

    <span class="mx-2 flex-grow-1">{{ $t(layer.label) }}</span>
    <ToggleSwitch
      class="m-0"
      :value="value"
      @input="$emit('input', $event)"
    />
  </li>
</template>

<script>
import ToggleSwitch from '@/components/form/ToggleSwitch'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'LegendItem',
  components: {
    ToggleSwitch, SvgIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    layer: {
      type: Object,
      required: true,
    },
  },
}
</script>
