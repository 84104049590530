<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
  >
    <template #content-header>
      <TileDataContentHeaderTwoColumn>
        <template #second-column>
          {{ $t('components.map.tiles.OMCorridors.unit') }}
        </template>
      </TileDataContentHeaderTwoColumn>
    </template>

    <template #content="{ showData }">
      <TileDataContentRowTwoColumn>
        <template #first-column>
          50-150 kW <small>
            <SvgIcon
              id="50-150-kw-footnote"
              class="tooltip-icon"
              icon="info-circle-regular"
            />
          </small>
          <b-tooltip
            target="50-150-kw-footnote"
            triggers="hover"
          >
            <p class="mb-0">
              {{ $t('components.map.tiles.OMCorridors.tooltipOMNeighborhoods') }}
            </p>
          </b-tooltip>
        </template>
        <template #second-column>
          {{ showData({field: `kwh_kortp_${year}`, decimals: 0}) | numberWithDots }}
        </template>
      </TileDataContentRowTwoColumn>
      <TileDataContentRowTwoColumn>
        <template #first-column>
          > 150 kW <small>
            <SvgIcon
              id="150-kwh-neighborhoods-footnote"
              class="tooltip-icon"
              icon="info-circle-regular"
            />
          </small>
          <b-tooltip
            target="150-kwh-neighborhoods-footnote"
            triggers="hover"
          >
            <p class="mb-0">
              {{ $t('components.map.tiles.OMCorridors.tooltipUnit') }}
            </p>
          </b-tooltip>
        </template>
        <template #second-column>
          {{ showData({field: `kwh_bincor_${year}`, decimals: 0}) | numberWithDots }}
        </template>
      </TileDataContentRowTwoColumn>

      <TileDataContentRowTwoColumn>
        <template #second-column>
          {{ $t('components.map.tiles.TileData.chargingStations') }}
        </template>
      </TileDataContentRowTwoColumn>
      <TileDataContentRowTwoColumn>
        <template #first-column>
          > 50 kW
        </template>
        <template #second-column>
          {{ showData({field: `dc_pnt_${year}`, decimals: 1}) }}
        </template>
      </TileDataContentRowTwoColumn>
    </template>

    <template #footer>
      ID: {{ fids[0] }}
    </template>
  </TileDataContent>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TileDataContentRowTwoColumn from '@/components/map/tiles/TileDataContentRowTwoColumn'
import TileDataContentHeaderTwoColumn from '@/components/map/tiles/TileDataContentHeaderTwoColumn'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'TileDataOMNeighborhoods',
  components: { TileDataContentHeaderTwoColumn, SvgIcon, TileDataContentRowTwoColumn, TileDataContent },
  mixins: [TiledataMixin],
}
</script>
