<template>
  <div
    v-if="condition"
    class="PMS-Filter-Item__content"
  >
    <label
      :for="`${filter.id}-from`"
      class="label"
    >{{ $t('from') }}</label>
    <b-form-input
      :id="`${filter.id}-from`"
      type="number"
      :placeholder="filter.min && filter.min.toString()"
      :min="filter.min"
      :max="filter.max"
      :value="filter.from"
      :state="btnState"
      @blur="handleEvent({type:'blur', options: {id: filter.id, key: 'from', event: $event}})"
      @keyup.enter="handleEvent({type:'enter', options: {id: filter.id, key: 'from', event: $event}})"
    />
    <label
      :for="`${filter.id}-to`"
      class="ml-2 label"
    >{{ $t('to') }}</label>
    <b-form-input
      :id="`${filter.id}-to`"
      type="number"
      :placeholder="filter.max && filter.max.toString()"
      :min="filter.min"
      :max="filter.max"
      :value="filter.to"
      :state="btnState"
      @blur="handleEvent({type:'blur', options: {id: filter.id, key: 'to', event: $event}})"
      @keyup.enter="handleEvent({type:'enter', options: {id: filter.id, key: 'to', event: $event}})"
    />
    <span
      v-show="filter.unit"
      class="label"
    >{{ filter.unit }}</span>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  props: {
    filter: {
      type: Object,
      required: true,
    },
    condition: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  computed: {
    btnState() {
      if (! this.$v.$anyDirty) return null

      const isEmpty = ! this.filter.from && ! this.filter.to
      if (isEmpty) return null

      return this.$v.$anyDirty && ! this.$v.$anyError
    },
  },
  methods: {
    handleEvent({ type, options }) {
      this.$v.$touch()
      this.$emit(type, { ...options })
    },
  },
  validations: {
    filter: {
      from: { required, numeric },
      to: { required, numeric },
    },
  },
}
</script>

<style lang="scss">
.PMS-Filter-Item__content {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  input.form-control {
    background-image: none !important;
    padding-right: 0.75rem !important;
  }
}
</style>
