const checkIfPathExists = ({ object, path }) => {
  if (object === undefined) return false
  const levels = path.split('/')

  for (const level of levels) {
    if (object[level]) {
      continue
    }

    return false
  }

  return true
}

module.exports = {
  checkIfPathExists,
}
