<template>
  <Fragment>
    <tr :class="{ 'TileData__highlighted': highlight }">
      <td colspan="3">
        <strong>
          <slot name="header" />
        </strong>
      </td>
    </tr>

    <slot />
    <br>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
export default {
  components: { Fragment },
  props: {
    highlight: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
