const handleGuards = (guards, to, from, next) => {
  const [nextGuard, ...remaining] = guards

  if (nextGuard === undefined) {
    next()
  } else {
    nextGuard(to, from, (nextArg) => {
      if (nextArg === undefined) {
        handleGuards(remaining, to, from, next)
      } else (
        next(nextArg)
      )
    })
  }
}

// Supply multiple guard functions in an array
const multiguard = (guards) => {
  return (to, from, next) => {
    return handleGuards(guards, to, from, next)
  }
}

export default multiguard
