import getCssProperty from '@/helpers/cssProperty'

export const monochromeColors = [getCssProperty('rate-limit-low'), getCssProperty('primary')]

export const labelColor = '#7F7F7F'

export const animationDuration = 250

export const thresholdConfiguration = {
  color: getCssProperty('rate-limit-high'),
  areaOpacity: 0,
  enableInteractivity: true,
  lineDashStyle: [6, 6],
}

export const barThresholdConfiguration = {
  ...thresholdConfiguration,
  type: 'steppedArea',
}
