<template>
  <span class="InlineToggle">
    <template v-for="(option, index) in options">
      <span
        :key="option.value"
        :class="{ 'InlineToggle__active': index === selected }"
        @click="handleClick({ index })"
      >
        {{ option.label }}
      </span>
      <span
        :key="`${option.value}_bar`"
        class="mx-2"
      >|</span>
    </template>
  </span>
</template>

<script>
export default {
  name: 'InlineToggle',
  props: {
    options: {
      type: Array,
      default: null, // Label, Value
    },
  },
  data() {
    return {
      selected: 0,
    }
  },
  methods: {
    handleClick({ index }) {
      this.selected = index

      this.$emit('selected', { selected: this.options[this.selected].value, index })
    },
  },
}
</script>

<style lang="scss">


.InlineToggle {
  span:nth-child(2n+1) {
    cursor: pointer;
    &:hover {
      color: var(--cta);
    }
  }

  span:last-child{
    display: none;
  }

  &__active {
    color: var(--cta);
  }
}
</style>
