<template>
  <div class="TileData__Content">
    <div class="TileData__title">
      <div class="pt-3">
        {{ $t('components.map.tiles.TileDataContent.title') }}
        <span class="TileData__highlighted">{{ year }}</span> -
        <span class="TileData__highlighted">{{ $t(forecastLabel) }}</span>
      </div>

      <div
        v-if="hasFids"
        class="TileData__subline pb-2 d-flex"
      >
        <small>
          <slot name="subline" />
        </small>
      </div>

      <slot
        name="sub-title"
        :show-data="showData"
        :show-string-data="showStringData"
      />
      <slot
        name="sub-title2"
        :show-data="showData"
        :show-string-data="showStringData"
      />
    </div>

    <div v-if="hasFids">
      <table style="width: 100%">
        <tbody>
          <slot
            name="content-header"
            :show-data="showData"
            :show-string-data="showStringData"
          >
            <TileDataContentHeader>
              <template #second-column>
                {{ $t('components.map.tiles.TileData.EV') }}
              </template>
              <template #third-column>
                {{ $t('components.map.tiles.TileData.chargingStations') }}
              </template>
            </TileDataContentHeader>
          </slot>

          <slot
            name="content"
            :show-data="showData"
            :show-string-data="showStringData"
          >
            <TileDataContentRowThreeColumn
              v-for="key in dataset"
              :key="key"
            >
              <template #first-column>
                <strong style="text-transform: capitalize"> {{ key.replace('_', ' ') }} </strong>
              </template>
              <template #second-column>
                {{ showData({ field: `${key}_ev_${year}` }) }}
              </template>
              <template #third-column>
                {{ showData({ field: `${key}_laadpunt_${year}` }) }}
              </template>
            </TileDataContentRowThreeColumn>
          </slot>
        </tbody>
      </table>

      <div
        v-if="fids.length === 1"
        class="mt-3 text-muted"
      >
        <slot
          name="footer"
          :show-data="showData"
          :show-string-data="showStringData"
        >
          ID: {{ fids[0] }}
        </slot>
      </div>
      <div
        v-else
        class="mt-2 text-muted"
      >
        {{ $t('components.map.tiles.TileDataContent.multipleHexs') }}
      </div>
    </div>
    <div v-else>
      {{ $t('components.map.tiles.TileDataContent.description') }}
    </div>
  </div>
</template>

<script>
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'

export default {
  name: 'TileDataContent',
  components: { TileDataContentHeader, TileDataContentRowThreeColumn },
  mixins: [TiledataMixin],
  computed: {
    hasFids() {
      return this.fids.length !== 0
    },
  },
  methods: {
    showData({ field, decimals }) {
      decimals = ! decimals && decimals !== 0 ? 2 : decimals

      return this.fids.reduce((total, fid) => {
        return total + parseFloat(this.dataById[fid][field] || 0)
      }, 0).toFixed( decimals ).replace('.', ',')
    },
    showStringData({ field, max }) {
      if (Object.keys(this.fids).length > (max || 3)) return 'Meerdere'

      return this.fids.reduce((combined, fid) => {
        combined.push(this.dataById[fid][field])
        return combined
      }, []).join(', ')
    },
  },
}
</script>
