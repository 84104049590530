<template>
  <div
    v-if="validatorsCount"
    class="ValidationCounter d-flex align-items-center"
  >
    <div class="ValidationCounter__Count ValidationCounter__Count--approved mr-1">
      <b-icon-check class="ValidationCounter__Count__Icon" />
      {{ votes.Goedgekeurd }}
    </div>
    <div class="ValidationCounter__Count ValidationCounter__Count--rejected mr-1">
      <b-icon-x class="ValidationCounter__Count__Icon" />
      {{ votes.Afgekeurd }}
    </div>

    van  {{ validatorsCount }}
  </div>
</template>

<script>
import { BIconCheck, BIconX } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { VALIDATION_VOTE } from '@/../shared/valueholders/validation-vote'

export default {
  name: 'ValidatorCount',
  components: { BIconCheck, BIconX },
  data () {
    return {
      voteTypes: {
        [VALIDATION_VOTE.APPROVED]: 0,
        [VALIDATION_VOTE.REJECTED]: 0,
      },
    }
  },
  computed: {
    ...mapGetters('planmode', [
      'getCommentsBySelectedChargingpoint',
      'getSelectedChargingpoint',
    ]),
    validators () {
      return this.getSelectedChargingpoint.data.properties.validators
    },
    validatorsCount () {
      return this.validators?.length
    },
    votes () {
      const comments = this.getCommentsBySelectedChargingpoint
      Object.keys(this.voteTypes).forEach(vote => {
        this.voteTypes[vote] = comments.filter(comment => comment.data.validation === vote && this.isValidator(comment)).length
      })
      return this.voteTypes
    },
  },
  created () {
    if (this.validatorsCount && !this.getCommentsBySelectedChargingpoint.length) {
      const data = this.getSelectedChargingpoint.data
      this.fetchCommentsByChargingpointUuid({ chargingpointUuid: data.uuid })
    }
  },
  methods: {
    ...mapActions('planmode', ['fetchCommentsByChargingpointUuid']),
    isValidator (comment) {
      return (
        !comment.data.deleted_at &&
        this.validators?.some(validator => comment.data.UserId === validator.user_id)
      )
    },
  },
}
</script>

<style lang="scss">


.ValidationCounter {
  &__Count {
    border: 1px solid var(--border-color);
    border-radius: 3px;
    padding-right: 4px;

    &--approved {
      .b-icon.bi {
        color: #5d8c30 !important;
      }
    }
    &--rejected {
      .b-icon.bi {
        color: var(--danger);
      }
    }

    &__Icon {
      width: 20px;
      height: 20px;
      margin-right: -5px;
      margin-bottom: -4px;
    }
  }
}
</style>
