<template>
  <div>
    <div
      class="RulerWrapper"
      :class="{ hasLine }"
    >
      <b-button
        :variant="rulerEnabled ? 'cta' : 'primary'"
        @click="toggleRuler"
      >
        <div class="icon-text-wrapper">
          <SvgIcon icon="ruler-icon" />
        </div>
      </b-button>
      <SvgIcon
        id="rulerHelp"
        icon="question-circle-white"
        class="rulerHelpIcon"
      />
    </div>
    <div
      v-show="hasLine"
      class="distanceWrapper"
    >
      <span v-text="distanceText" />
    </div>
    <b-tooltip
      target="rulerHelp"
      triggers="hover"
      placement="rightbottom"
      class="customToolTip"
    >
      <ul class="outlined-text">
        <li>
          {{ $t('components.map.Ruler.tooltipLine1') }}
        </li>
        <li>
          {{ $t('components.map.Ruler.tooltipLine2') }}
        </li>
      </ul>
    </b-tooltip>
  </div>
</template>

<script>
import * as turf from '@turf/turf'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'Ruler',
  components: { SvgIcon },
  data() {
    return {
      geojson: {
        type: 'FeatureCollection',
        features: [],
      },
      linestring: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
      rulerEnabled: false,
    }
  },
  computed: {
    hasLine() {
      return this.geojson.features.some(
        (feature) => feature.geometry.type === 'LineString',
      )
    },
    distanceText() {
      const distance = Math.round(turf.length(this.linestring) * 1000)
      return this.$i18n.t('components.map.Ruler.distance', { distance: distance.toLocaleString() })
    },
  },
  methods: {
    handleRuler() {
      this.$store.map.addSource('geojson', {
        type: 'geojson',
        data: this.geojson,
      })

      this.$store.map.addLayer({
        id: 'measure-points',
        type: 'circle',
        source: 'geojson',
        paint: {
          'circle-radius': 5,
          'circle-color': '#000',
        },
        filter: ['in', '$type', 'Point'],
      })

      this.$store.map.addLayer({
        id: 'measure-lines',
        type: 'line',
        source: 'geojson',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#000',
          'line-width': 2.5,
        },
        filter: ['in', '$type', 'LineString'],
      })

      // Add click and mousemove event listeners
      this.$store.map.on('click', this.handleClick)
      this.$store.map.on('mousemove', this.handleMouseMove)
    },
    handleMouseMove(e) {
      if (!this.rulerEnabled) return

      const features = this.$store.map.queryRenderedFeatures(e.point, {
        layers: ['measure-points'],
      })

      this.$store.map.getCanvas().style.cursor = features.length
        ? 'pointer'
        : 'crosshair'
    },

    handleClick(e) {
      if (!this.rulerEnabled) return

      const features = this.$store.map.queryRenderedFeatures(e.point, {
        layers: ['measure-points'],
      })

      if (this.geojson.features.length > 1) this.geojson.features.pop()

      if (features.length) {
        // If a point is clicked, remove it
        const id = features[0].properties.id
        this.geojson.features = this.geojson.features.filter(
          (point) => point.properties.id !== id,
        )
      } else {
        // If no point is clicked, add a new point
        const point = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        }
        this.geojson.features.push(point)
      }

      if (this.geojson.features.length > 1) {
        this.linestring.geometry.coordinates = this.geojson.features.map(
          (point) => point.geometry.coordinates,
        )

        this.geojson.features.push(this.linestring)
      }
      // Update the data source for the line string
      this.$store.map.getSource('geojson').setData(this.geojson)
    },

    removeRuler() {
      // Remove points and lines
      this.geojson.features = []

      // Remove source and layers
      this.$store.map.removeLayer('measure-points')
      this.$store.map.removeLayer('measure-lines')
      this.$store.map.removeSource('geojson')

      // Remove event listeners
      this.$store.map.off('click', this.handleClick)
      this.$store.map.off('mousemove', this.handleMouseMove)
    },
    toggleRuler() {
      // eslint-disable-next-line vue/no-mutating-props
      this.rulerEnabled = !this.rulerEnabled

      if (this.rulerEnabled) {
        this.handleRuler()
      } else {
        this.removeRuler()
      }
    },
  },
}
</script>

<style lang="scss">
.RulerWrapper {
  position: absolute;
  bottom: 185px;
  left: 10px;
  padding-top: 0.7rem;

  .icon-text-wrapper {
    display: flex;
    align-items: center;
  }

  &.hasLine {
    bottom: 220px;
  }
}

.distanceWrapper {
  display: block;
  position: absolute;
  background-color: #041e42;
  bottom: 185px;
  left: 10px;
  color: white;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 3px;
}
.rulerHelpIcon {
  position: absolute;
  background-color: #041e42;
  padding: 5px 5px;
  margin-left: 5px;
  margin-top: 1px;
  display: inline-block;
  border-radius: 3px;
}

.outlined-text {
  list-style-type: circle;
  padding-left: 0;

  li {
    outline: 1px solid #000;
    padding: 5px;
    text-align: left;
  }
}
</style>
