<template>
  <b-icon-clipboard
    title="Kopieer adres naar klembord"
    class="cursor-copy"
    @click="copyAddressToClipboard"
  />
</template>

<script>
import { BIconClipboard } from 'bootstrap-vue'
import { Bugfender } from '@bugfender/sdk'

export default {
  components: { BIconClipboard },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyAddressToClipboard() {
      if (!navigator.clipboard) {
        Bugfender.error('clipboard api not found')
        return
      }

      navigator.clipboard.writeText(this.value)
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Adres gekopieerd!',
          })
        })
        .catch(err => {
          Bugfender.error('copying of address failed.', err)
        })
    },
  },
}
</script>

<style>
.cursor-copy {
  cursor: copy;
}
</style>
