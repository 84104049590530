import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Terms from '@/views/Terms.vue'
import Viewer from '@/views/Viewer.vue'
import Upload from '@/views/Upload.vue'
import Export from '@/views/Export.vue'
import ExportNational from '@/views/ExportNational.vue'
import Import from '@/views/Import.vue'
import FactsheetExport from '@/views/FactsheetExport.vue'
import NotFound from '@/views/NotFound.vue'

import FactsheetRegulierLaden from '@/views/FactsheetRegulierLaden.vue'
import FactsheetBedrijventerreinen from '@/views/FactsheetBedrijventerreinen.vue'
import FactsheetParticipation from '@/views/FactsheetParticipation.vue'

// Beheer
import Dashboard from '@/views/admin/Dashboard'

// Manuals
import Manuals from '@/views/Manuals.vue'
import ManualPage from '@/views/ManualPage.vue'

// Password Enforcer
import EnforcePassword from '@/views/EnforcePassword'

import { authGuard } from '@/auth/authGuard'
import termsGuard from '@/router/termsGuard'
import multiguard from '@/router/multiguard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/gebruikersvoorwaarden',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/wachtwoord',
    name: 'EnforcePassword',
    component: EnforcePassword,
  },
  {
    path: '/beheer/import',
    name: 'Import',
    component: Import,
  },
  {
    path: '/beheer/factsheet-export',
    name: 'FactsheetExport',
    component: FactsheetExport,
  },
  {
    path: '/beheer/:municipality?',
    name: 'Dashboard',
    component: Dashboard,
  },

  /**
   * Manual pages
   */
   {
    path: '/handleidingen',
    name: 'Manuals',
    component: Manuals,
    children: [
      {
        path: ':id',
        name: 'ManualPage',
        component: ManualPage,
      },
    ],
  },

  {
    path: '/:municipality/factsheet',
    name: 'Factsheet',
    component: FactsheetRegulierLaden,
  },
  {
    path: '/:municipality/participatie',
    name: 'Participation',
    component: FactsheetParticipation,
  },
  {
    path: '/:municipality/bedrijventerreinen',
    name: 'Bedrijventerreinen',
    component: FactsheetBedrijventerreinen,
  },
  {
    path: '/:municipality/upload',
    name: 'Upload',
    component: Upload,
  },
  {
    path: '/:municipality/export',
    name: 'Export',
    component: Export,
  },
  {
    path: '/export-national',
    name: 'ExportNational',
    component: ExportNational,
  },
  {
    path: '/:municipality',
    name: 'Viewer',
    component: Viewer,
  },
  {
    path: '/*',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(multiguard([authGuard, termsGuard]))

export default router
