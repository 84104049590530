<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :class="layerClass"
    @click="handleClick"
  >
    <div v-html="iconPath()" />
  </div>
</template>

<script>
export default {
  name: 'SvgLegendCategoryItem',
  props: {
    iconFolder: {
      type: String,
      default: '',
    },
    chargerType: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    layer: {
      type: Object,
      default: () => {},
    },
    isLegend: {
      type: Boolean,
      default: true,
    },
    selectedStatus: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconStateColor () {
      if (this.isLegend) {
        return this.layer?.visible ? this.layer.config.iconColor : '#bdbdbd'
      } else {
        return this.isSelectedLayer ? this.layer.config.iconColor : '#bdbdbd'
      }
    },
    layerClass () {
      return this.layer ? this.layer.id : 'no-layer'
    },
    isSelectedLayer () {
      return this.layer && !this.isLegend && this.layer?.id === this.selectedStatus
    },
  },
  watch: {
    'layer.visible': {
      handler () {
        if (this.layer && this.isLegend) {
          this.setIconColor()
        }
      },
    },
    selectedStatus: {
      handler () {
        this.setIconColor()
      },
    },
  },
  created () {
    if (this.isSelectedLayer && ! this.isLegend) {
      this.setIconColor()
    }
  },
  methods: {
    iconPath () {
      if (this.isLegend) {
        this.setIconColor()
      }
      const iconType = this.iconType ? 'point/' : ''
      const path = `${this.iconFolder}/${iconType}${this.chargerType}`
      return require(`!html-loader!@/assets/image/legend/chargingpoint-types/${path}.svg`)
    },
    setIconColor () {
      this.$nextTick(() => {
        const element = this.layer?.id || this.iconFolder
        const svg = this.$parent.$el.querySelector(`.${element} svg`)

        if (svg) {
          for (const path of svg.querySelectorAll('path')) {
            const fill = path.getAttribute('fill')
            const stroke = path.getAttribute('stroke')

            if (!['none', 'white'].includes(fill)) {
              path.setAttribute('fill', this.iconStateColor)
            } else if (stroke) {
              path.setAttribute('stroke', this.iconStateColor)
            }
          }
        }
      })
    },
    handleClick () {
      this.$emit('handleLayerVisibility', { layer: this.layer })
      if (!this.isLegend) {
        this.setIconColor()
      }
    },
  },
}
</script>
<style lang="scss">
.no-layer {
  pointer-events: none;
  opacity: 0.5;
}
</style>
