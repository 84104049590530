<template>
  <label
    class="ToggleSwitch"
    :class="{'ToggleSwitch--disabled': disabled}"
    @click.stop="stopTheEvent"
  >
    <input
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      @change="$emit('input', $event.target.checked)"
    >
    <span
      class="slider round success"
      :style="value && `background-color: ${bgColor}`"
    />
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: null,
    },
  },
  methods: {
    stopTheEvent(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style lang="scss">

/* The switch - the box around the slider */
.ToggleSwitch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-bottom: 0;

  /* Hide default HTML checkbox */
  input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:after {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 17px;

      &:after {
        border-radius: 50%;
      }
    }
  }

  input {
    &:checked + .slider {
      &.default {
        background-color: #444;
      }
      &.primary {
        background-color: #2196F3;
      }
      &.success {
        background-color: #8bc34a;
      }
      &.info {
        background-color: #3de0f5;
      }
      &.warning {
        background-color: #FFC107;
      }
      &.danger {
        background-color: #f44336;
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    &:checked + .slider:after {
      transform: translateX(13px);
    }
  }


  &--disabled {
    .slider {
      cursor: not-allowed;
    }

    input {
      &:checked + .slider {
        &:before {
          position: absolute;
          content: "";
          top:0;
          left:0;
          right:0;
          bottom:0;
          background-color: black;
          opacity: 0.4;
        }
      }
      &:checked + .slider.round {
        &:before {
          border-radius: 17px;
        }
      }
    }
  }
  &.layer-category {
    transform: scale(0.8) translateY(3px);
  }
}
</style>
