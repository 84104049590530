<template>
  <div>
    <CollapsableSegment :label="label">
      <template #header>
        <ToggleSwitch
          v-model="all"
          class="m-0"
        />
      </template>

      <ul class="m-0 p-0 mb-3 list-unstyled">
        <!-- The layer-id class is only required for the NewYearsTour -->
        <LegendItemCategory
          v-for="layer in planmodeLayerGroups"
          :key="layer.id"
          :layer="layer"
          :group-layers="chargingpointGroupLayers({ layer })"
          :class="`layer-${layer.id}`"
          :value="layer.visible"
          @input="handleInput"
          @layerVisibility="handleInput"
          @layerCategoryVisibility="handleLayerCategoryVisibility"
        />
      </ul>
    </CollapsableSegment>
    <LegendLocationSources />
  </div>
</template>

<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import LegendItemCategory from '@/components/sidebar/LegendItemCategory'
import LegendLocationSources from '@/components/sidebar/LegendLocationSources'
import { planmodeLayerGroups } from '@/data/planmodeLayerDetails'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LegendPlanmode',
  components: {
    CollapsableSegment,
    ToggleSwitch,
    LegendItemCategory,
    LegendLocationSources,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Make a copy of the dataset, 1 level deep
      layers: [],
    }
  },
  computed: {
    ...mapGetters('config', ['isPlanmodeEnabled']),
    ...mapGetters('app', ['isMapReady']),
    ...mapGetters('access', ['canPreAccessPlanMode']),
    ...mapGetters('currentUser', {
      superuser: 'isSuperuser',
    }),
    ...mapGetters('planmode', ['getPlanmodeLayers']),
    ...mapGetters('layers', ['getPlanmodeLayerDetails']),

    /**
     * Watch the all layers on / off toggle.
     */
    all: {
      get() {
        return !this.activeLayers.some((layer) => !layer.visible)
      },
      set(value) {
        this.activeLayers.forEach((layer) => (layer.visible = value))
      },
    },
    /**
     * Not all layers will be available for each municipality
     */
    activeLayers: {
      get() {
        return this.getPlanmodeLayers?.filter(
          (layer) => layer.status !== 'hidden',
        )
      },
      set(layers) {
        this.setPlanmodeLayers({ layers })
      },
    },
    planmodeLayerGroups() {
      return planmodeLayerGroups.map((layer) => ({
        ...layer,
        label: this.$t(layer.label),
      }))
    },
  },
  watch: {
    isPlanmodeEnabled: {
      immediate: true,
      handler() {
        this.initiateLayers()
      },
    },
  },
  methods: {
    ...mapActions('planmode', ['updatePlanmodeLayer']),
    ...mapMutations('planmode', ['setPlanmodeLayers']),
    initiateLayers() {
      this.setPlanmodeLayers({
        layers: this.getPlanmodeLayerDetails.map((layer) =>
          this.applyVisibilityRestrictions({ layer }),
        ),
      })
    },
    applyVisibilityRestrictions({ layer }) {
      // Only show realized locations if the planmode feature is disabled
      if (
        !this.isPlanmodeEnabled &&
        !this.superuser &&
        !this.canPreAccessPlanMode
      ) {
        layer.status = [
          'chargingpoints-realized',
          'chargingpoints-in-progress',
          'chargingpoints-realized-semipublic',
          'chargingpoints-fastcharger-realized',
        ].includes(layer.id)
          ? layer.status
          : 'hidden'
      }

      return layer
    },
    chargingpointGroupLayers({ layer }) {
      return this.getPlanmodeLayers.filter((planModeLayer) =>
        planModeLayer.config.status.includes(layer.id),
      )
    },
    handleInput({ value, layer }) {
      this.updatePlanmodeLayer({ value, layer })
    },
    handleLayerCategoryVisibility({ value, groupLayers }) {
      groupLayers.forEach((layer) => this.handleInput({ value, layer }))
    },
  },
}
</script>
