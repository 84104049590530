<template>
  <div class="CollapsableSubSegment">
    <h4
      class="d-flex justify-content-between"
      @click="togggleVisibility"
    >
      <span>
        <slot name="header" />
      </span>
      <SvgIcon
        class="ml-3"
        :icon="icon"
        :has-fill="true"
      />
    </h4>
    <b-collapse v-model="visibilityState">
      <div>
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'
import CollapsableSegment from '@/components/common/CollapsableSegment'

export default {
  name: 'CollapsableSubSegment',
  components: { SvgIcon },
  extends: CollapsableSegment,
}
</script>

<style lang="scss">
.CollapsableSubSegment {
  h4 {
    cursor: pointer;
    fill: #7F8FA4;
    position: relative;

    &:hover {
      color: darken(#7F8FA4, 20%);
      fill: darken(#7F8FA4, 20%);
    }
  }
}
</style>
