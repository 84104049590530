import { GEOPGRAPHY_TYPE } from '../../shared/valueholders/geography-type'
export default [
  {
    type: 'default',
    geography: {
      type: GEOPGRAPHY_TYPE.REGION,
      slug: 'evtools',
    },
    details: {
      cssFile: 'evtools-variables.css',
      logo: 'evtools.png',
      organization: 'EVtools',
    },
  },
  {
    type: 'custom',
    geography: {
      type: GEOPGRAPHY_TYPE.REGION,
      slug: 'mrae',
    },
    details: {
      cssFile: 'mrae-variables.css',
      logo: 'mrae.png',
      organization: 'MRA elektrisch',
    },
  },
]
