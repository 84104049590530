export const defaultLocationCriteria = [
    {
      label: 'Locatie in publieke ruimte',
      active: true,
      uuid: '57a1efcc-d965-469a-bdb0-15fdf6dc1836',
    },
    {
      label: 'Locatie 24/7 toegankelijk',
      active: true,
      uuid: '540c0f4c-17cb-456a-8b91-a82e6900fff4',
    },
    {
      label: 'Laadpaal met 2 punten en 2 vakken',
      active: true,
      uuid: '9df56d4e-d050-409d-ac8f-60f9288fa190',
    },
    {
      label: 'Laadpaal op gepland of beschikbaar parkeervak',
      active: true,
      uuid: '24d28e98-4c11-4ae5-be4c-6ff5b88dd534',
    },
    {
      label: 'Afstand van min. 1m tov voorzieningen',
      active: true,
      uuid: 'aec38124-a0f0-42f0-8f59-968198f45f97',
    },
    {
      label: 'Afstand min. 1m tov boom(kruin) of kruindoorsnede',
      active: true,
      uuid: 'd284f5a6-b597-4f2d-9f17-394d4a4d6348',
    },
    {
      label: 'Locatie binnen 25m van LS net',
      active: true,
      uuid: '67beae00-e9a5-4868-ba89-19016e714949',
    },
    {
      label: 'Trottoir doorgang van min. 90 cm (3 tegels) achter de laadpaal',
      active: true,
      uuid: 'e1288271-34ec-4c7b-a65a-34d3f751865b',
    },
    {
      label: 'Haaksparkeren afstand tot trottoirband min 30cm',
      active: true,
      uuid: 'fd3f41d9-c585-483b-a328-d1f9ad78b705',
    },
    {
      label: 'Langsparkeren afstand tot trottoirband min 30cm',
      active: true,
      uuid: '007c639e-bc5c-4b19-9d75-647587ca84d7',
    },
    {
      label:
        'Bij haaks- en langsparkeren laadpaal tussen 2 vakken. Bij harpparkeren tussen 4 vakken',
      active: true,
      uuid: 'b242da65-88ec-4727-98ed-2ba0e3575389',
    },
    {
      label: 'Locatie niet op asfalt',
      active: true,
      uuid: '6a6c5a65-9cd4-4ec0-b5d0-df207fa0054f',
    },
    {
      label:
        'Indien asfalt, laadpaal aan de kant van de weg waar LS kabel ligt',
      active: true,
      uuid: '8c245117-e6bb-4a54-a788-6a6d1327157c',
    },
    {
      label: 'Groenvoorziening zo veel mogelijk met rust laten',
      active: true,
      uuid: 'd22c7720-d1d9-4be5-a441-f85a0c4e0316',
    },
  ]
