<template>
  <div>
    <div v-if="simpleView">
      <TileDataContent
        :fids="fids"
        :forecast-label="forecastLabel"
        :dataset="dataset"
        :data-by-id="dataById"
      >
        <template #content-header>
          <TileDataContentHeader>
            <template #first-column>
              <b> {{ $t('components.map.tiles.ElaadForecast.expected') }} </b>
            </template>
            <template #second-column>
              <b> {{ $t('components.map.tiles.TileData.chargingStations') }} </b>
            </template>
            <template #third-column>
              <b> {{ $t('components.map.tiles.ElaadForecast.power') }} </b>
            </template>
          </TileDataContentHeader>
        </template>

        <template #sub-title>
          <p>
            <span class="currentView"> {{ $t('components.map.tiles.ElaadForecast.simpleView') }} </span> |
            <span
              class="otherView"
              @click="toggleView"
            > {{ $t('components.map.tiles.ElaadForecast.detailedView') }} </span>
          </p>
        </template>

        <template #sub-title2="{ showData }">
          <p>
            <b> {{ $t('components.map.tiles.ElaadForecast.totalEVs') }} </b>
            {{
              showData({
                field: generateFieldName("aantal_evs", year),
                decimals: 0,
              })
            }}
          </p>
        </template>

        <template #content="{ showData }">
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.semipublic') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("tot_semi_publiek", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.private') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("tot_privaat", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.fastChargers') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("snellaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              {{
                showData({
                  field: generateFieldName("vermogen_snellaadpunten", year),
                  decimals: 0,
                })
              }}
              kW
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              -----------------
            </template>
            <template #second-column>
              ----------------
            </template>
            <template #third-column>
              ------------------
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              <i> {{ $t('components.map.tiles.ElaadForecast.total') }} </i>
            </template>
            <template #second-column>
              <i>
                {{
                  showData({
                    field: generateFieldName("tot_overall", year),
                    decimals: 0,
                  })
                }}
              </i>
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
        </template>
        <template #footer>
          ID: {{ fids[0] }}
        </template>
      </TileDataContent>
    </div>

    <div v-else>
      <TileDataContent
        :fids="fids"
        :forecast-label="forecastLabel"
        :dataset="dataset"
        :data-by-id="dataById"
      >
        <template #content-header>
          <TileDataContentHeader>
            <template #first-column>
              <b> {{ $t('components.map.tiles.ElaadForecast.expected') }} </b>
            </template>
            <template #second-column>
              <b> {{ $t('components.map.tiles.TileData.chargingStations') }} </b>
            </template>
            <template #third-column>
              <b> {{ $t('components.map.tiles.ElaadForecast.power') }} </b>
            </template>
          </TileDataContentHeader>
        </template>

        <template #sub-title>
          <p>
            <span
              class="otherView"
              @click="toggleView"
            > {{ $t('components.map.tiles.ElaadForecast.simpleView') }} </span> |
            <span class="currentView"> {{ $t('components.map.tiles.ElaadForecast.detailedView') }} </span>
          </p>
        </template>

        <template #sub-title2="{ showData }">
          <p>
            <b> {{ $t('components.map.tiles.ElaadForecast.totalEVs') }} </b>
            {{
              showData({
                field: generateFieldName("aantal_evs", year),
                decimals: 0,
              })
            }}
          </p>
        </template>

        <template #content="{ showData }">
          <!-- COUNT -->
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.public') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("publiekelaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.destination') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("bestemmingslaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.garages') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("laadpunten_parkeergarages", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.lots') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("laadpunten_parkeerpleinen", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              ------------------------------
            </template>
            <template #second-column>
              ----------------
            </template>
            <template #third-column>
              ------------------
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              <i> {{ $t('components.map.tiles.ElaadForecast.totalSP') }} </i>
            </template>
            <template #second-column>
              <i>
                {{
                  showData({
                    field: generateFieldName("tot_semi_publiek", year),
                    decimals: 0,
                  })
                }}
              </i>
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <br>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.privateHome') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("thuislaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.privateOffice') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("werklaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              --------------------
            </template>
            <template #second-column>
              ----------------
            </template>
            <template #third-column>
              ------------------
            </template>
          </TileDataContentRowThreeColumn>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              <i> {{ $t('components.map.tiles.ElaadForecast.totalPrivate') }} </i>
            </template>
            <template #second-column>
              <i>
                {{
                  showData({
                    field: generateFieldName("tot_privaat", year),
                    decimals: 0,
                  })
                }}
              </i>
            </template>
            <template #third-column>
              -
            </template>
          </TileDataContentRowThreeColumn>
          <br>
          <TileDataContentRowThreeColumn>
            <template #first-column>
              {{ $t('components.map.tiles.ElaadForecast.fastChargers') }}
            </template>
            <template #second-column>
              {{
                showData({
                  field: generateFieldName("snellaadpunten", year),
                  decimals: 0,
                })
              }}
            </template>
            <template #third-column>
              {{
                showData({
                  field: generateFieldName("vermogen_snellaadpunten", year),
                  decimals: 0,
                })
              }}
              kW
            </template>
          </TileDataContentRowThreeColumn>
        </template>
        <template #footer>
          ID: {{ fids[0] }}
        </template>
      </TileDataContent>
    </div>
  </div>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'

export default {
  name: 'TileDataElaadForecast',
  components: {
    TileDataContentRowThreeColumn,
    TileDataContentHeader,
    TileDataContent,
  },
  mixins: [TiledataMixin],
  data() {
    return {
      simpleView: true,
    }
  },
  methods: {
    generateFieldName(baseName, year) {
      const suffix = this.forecast.endsWith('low')
        ? 'L'
        : this.forecast.endsWith('mid')
        ? 'M'
        : this.forecast.endsWith('high')
        ? 'H'
        : ''
      return `${baseName}_${suffix}_${year}`
    },
    toggleView() {
      this.simpleView = !this.simpleView
    },
  },
}
</script>

<style>
.currentView {
  color: var(--text-muted);
}

.otherView {
  cursor: pointer;

  &:hover {
    color: var(--cta);
  }
}
</style>
