<template>
  <div class="ColumnChart">
    <GChart
      type="ColumnChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { numberWithDots } from '@/helpers/number'
import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'ColumnChart',
  components: { GChart },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Laadpunt behoefte',
        },
        isStacked: true,
        legend: { position: 'right', textStyle: { color: labelColor } },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        vAxis: { format: '#.###' },
        bar: { groupWidth: '80%' },
        colors: [
          '#E4EDF5',
          getCssProperty('secondary'),
          getCssProperty('primary'),
        ],
        height: 237,
        width: 475,
        chartArea: { left: 50, right: 125 },
        focusTarget: 'category',
        animation: {
          duration: 500,
        },
      },
    }
  },
  computed: {
    chartData() {
      const chartSerieLabels = [
        'Jaar',
        'Bewoners', 'Bezoekers', 'Forenzen',
        { role: 'annotation' },
      ]
      const data = [chartSerieLabels]

      this.data.forEach(entry => {
        data.push([
          entry[0][0], entry[1][0], entry[1][1], entry[1][2], numberWithDots(entry[1][3]),
        ])
      })

      return data
    },
  },
}
</script>

<style>

</style>
