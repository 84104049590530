<template>
  <CollapsableSegment
    :label="groupLabel"
    :visible="collapseActive"
    :disabled="collapseDisabled"
    class="layer-category-segment"
  >
    <template #header>
      <ToggleSwitch
        class="m-0 layer-category"
        :value="groupLayersVisibility"
        :bg-color="layer.config.iconColor"
        @input="setLayerCategoryVisibility"
      />
    </template>
    <ChargingpointsLayerGroup
      :is-legend="true"
      :layer="layer"
      :group-layers="groupLayers"
      v-on="$listeners"
    />
  </CollapsableSegment>
</template>
<script>
import CollapsableSegment from '@/components/common/CollapsableSegment'
import ToggleSwitch from '@/components/form/ToggleSwitch'
import ChargingpointsLayerGroup from '@/components/common/ChargingpointsLayerGroup'

export default {
  name: 'LegendItemGroup',
  components: {
    ToggleSwitch, CollapsableSegment, ChargingpointsLayerGroup,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    layer: {
      type: Object,
      required: true,
    },
    groupLayers: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      collapseActive: false,
    }
  },
  computed: {
    groupLabel () {
      return this.layer.label
    },
    groupLayersVisibility () {
      return this.groupLayers.every(layer => layer.visible)
    },
    collapseDisabled () {
      // Disable collapse if only one group layer is present //
      return this.groupLayers.length === 1
    },
  },
  created () {
    this.collapseActive = this.layer.config.legendCollapseOpen
  },
  methods: {
    setLayerCategoryVisibility (value) {
      this.$emit('layerCategoryVisibility', { value, groupLayers: this.groupLayers })
    },
  },
}
</script>
