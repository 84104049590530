import { parse } from 'zipson/lib'

export const checkStatus = async (response) => {
  if (response.ok) {
    return Promise.resolve(response)
  } else {
    const body = await response.json()

    if (body.error_description) {
      return Promise.reject(new Error(body.error_description))
    }

    if (body.message) {
      return Promise.reject(new Error(body.message))
    }

    return Promise.reject(new Error('no error message'))
  }
}

export const returnJson = (response) => {
  return response.json()
}

export const fetchCompressedData = (url, options) => {
  return fetch(url, options)
    .then(checkStatus)
    .then(returnJson)
    .then(parseCompressedString)
}

const parseCompressedString = (data) => Object.keys(data).reduce((result, key) => {
  if (Object.prototype.hasOwnProperty.call(data, key)) {
    result[key] = parse(data[key]).map(chargingpoint => AddBackwardCompatibility(chargingpoint))
  }

  return result
}, {})

const AddBackwardCompatibility = data => ({
  ...data,
  // for backwards compatibility
  ref: {
    ...data.ref,
    ['@ref']: {
      id: data.ref.value.id,
      // collection is not added, because it is not used until now so probably better off without it
    },
  },
})
