<template>
  <div class="Chart Chart--column">
    <div class="Chart__Header">
      <div class="Chart__Header__Icon">
        <SvgIcon icon="battery-bolt-regular" />
      </div>
      {{ title }}
    </div>
    <GChart
      v-if="hasData"
      type="ComboChart"
      :data="chartData"
      :options="chartOptions"
    />
    <p
      v-else
      class="mb-4"
    >
      Geen data beschikbaar
    </p>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import SvgIcon from '@/components/common/SvgIcon'
import GoogleChartEntry from '@/components/map/sidebar/models/GoogleChartEntry'

import { animationDuration, labelColor, barThresholdConfiguration } from '@/helpers/charts'
import { round2Decimals, wholeNumber } from '@/helpers/number'

import chartMixins from '@/mixins/monitoring/chartMixins'
import { DEFAULT_THRESHOLD } from '@/../shared/valueholders/monitoring'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'ChargedVolumeColumnChart',
  components: { SvgIcon, GChart },
  mixins: [chartMixins],
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        thresholdEnabled: true,
        threshold: DEFAULT_THRESHOLD.CHARGED_VOLUME,
      }),
    },
  },
  computed: {
    chartOptions() {
      const viewWindow = this.$_chartMixin_calculateViewWindow(this.options.threshold, ...Object.values(this.data).map(entry => entry.volume))

      return {
        legend: { position: 'none' },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        vAxis: { format: 'decimal', textStyle: { bold: true, color: labelColor }, viewWindow: { max: viewWindow, min: 0 }, gridlines: { count: 4 } },
        colors: [getCssProperty('rate-limit-low')],
        bar: { groupWidth: '60%' },
        height: 140,
        width: 266,
        chartArea: { left: 30, top: 10, right: 20 },
        seriesType: 'bars',
        series: {
          1: barThresholdConfiguration,
        },
        animation: {
          duration: animationDuration,
        },
        tooltip: { isHtml: true },
      }
    },
    chartData() {
      let chartSerieLabels = [
        'Maand',
        this.title,
        { role: 'annotation' },
        { role: 'tooltip', p: { html: true } },
      ]

      if (this.options.thresholdEnabled) {
        chartSerieLabels = [
          ...chartSerieLabels,
          { role: 'style' },
          'Limiet',
          { role: 'tooltip', p: { html: true } },
        ]
      }

      const result = [
        chartSerieLabels,
      ]

        Object.values(this.data)
          // .reverse()
          .forEach((entry) => {
            const preppedEntry = entry.total_energy === null ?
              GoogleChartEntry.createEmpty() :
              GoogleChartEntry.create(
                entry.label,
                wholeNumber(entry?.total_energy),
                `${wholeNumber(entry?.total_energy)}`,
                `<strong>${entry.label}</strong> <br /> Geladen: <strong>${round2Decimals(entry?.total_energy)} kWh</strong>`,
              )
            if (this.options.thresholdEnabled) {
              preppedEntry.addLimit(this.options.threshold, `${this.options.threshold} kWh`)
              result.push(
                preppedEntry.toArrayWithLimit(),
              )
            } else {
              result.push(preppedEntry.toArray())
            }
          })

      return result
    },
    hasData(){
      return Object.keys(this.data).length > 0
    },
  },
}
</script>
