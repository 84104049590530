<template>
  <div class="ActiveTab InfoTab">
    <LocatieInformatieHeader :chargingpoint="chargingpoint">
      <h4>{{ $t('components.sidebar.Tabs.InfoTab') }}</h4>
    </LocatieInformatieHeader>

    <div class="ActiveTab__Content">
      <LocatieInformatieContent v-if="! isPlanModeActive" />
      <LocatieInformatieEdit v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocatieInformatieHeader from '@/components/map/sidebar/LocatieInformatieHeader'
import LocatieInformatieContent from '@/components/map/sidebar/LocatieInformatieContent'
import LocatieInformatieEdit from '@/components/map/sidebar/LocatieInformatieEdit'

export default {
  components: { LocatieInformatieEdit, LocatieInformatieContent, LocatieInformatieHeader },
  computed: {
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
      isPlanModeActive: 'isActive',
    }),
  },
}
</script>

<style lang="scss">


.LocatieInformatie {
  margin-bottom: 1rem;

  &__Attachments {
    &__Image {
      width: 49.5%;
      position: relative;

      &__Delete, &__Inline {
        z-index: 999;
        position: absolute;
        right: 0;
        top: 7px;
        width: 20px;
        height: 20px;

        .svg-class {
          transform: scale(1.5);

          path {
            fill: #ffffff;
          }
        }

        &:hover {
          cursor: pointer;

          .svg-class path {
            fill: var(--primary);
          }
        }
      }

      &__Inline {
        right: 7px;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }

  &__Content {
    color: #7F8FA4;
    //font-size: .9rem;

    strong {
      color: var(--primary-text)
    }

    p {
      margin-bottom: .5rem
    }
  }
}
</style>
