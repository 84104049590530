<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
  >
    <template #content-header>
      <TileDataContentHeader>
        <template #second-column>
          {{ $t('components.map.tiles.ElaadLogistics.eVans') }}
        </template>
        <template #third-column>
          {{ $t('components.map.tiles.ElaadLogistics.eTruck') }}
        </template>
      </TileDataContentHeader>
    </template>

    <template #content="{ showData }">
      <!-- COUNT -->
      <TileDataContentRowThreeColumn>
        <template #first-column>
          {{ $t('components.map.tiles.ElaadLogistics.amount') }}
        </template>
        <template #second-column>
          {{ showData({ field: `e-bestel_${year}`, decimals: 0 }) }}
        </template>
        <template #third-column>
          {{ showData({ field: `e-trucks_${year}`, decimals: 0 }) }}
        </template>
      </TileDataContentRowThreeColumn>

      <br>

      <!-- Publiek -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          {{ $t('accessibility.public') }}
        </template>

        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.TileData.chargingStations') }}
          </template>
          <template #second-column>
            {{ showData({ field: `ldpt_e-bestel_pub_${year}`, decimals: 1 }) }}
          </template>
          <template #third-column>
            -
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.ElaadLogistics.unit') }}
          </template>
          <template #second-column>
            {{ showData({ field: `mwj_e-bestel_pub_${year}`, decimals: 0 }) }}
          </template>
          <template #third-column>
            -
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            MW
          </template>
          <template #second-column>
            {{ showData({ field: `mw_e-bestel_pub_${year}`, decimals: 2 }) }}
          </template>
          <template #third-column>
            -
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>

      <!-- VZP -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          VZP
        </template>

        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.TileData.chargingStations') }}
          </template>
          <template #second-column>
            {{ showData({ field: `ldpt_e-bestel_vzp_${year}`, decimals: 1 }) }}
          </template>
          <template #third-column>
            {{ showData({ field: `ldpt_e-trucks_vzp_${year}`, decimals: 1 }) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.ElaadLogistics.unit') }}
          </template>
          <template #second-column>
            {{ showData({ field: `mwj_e-bestel_vzp_${year}`, decimals: 0 }) }}
          </template>
          <template #third-column>
            {{ showData({ field: `mwj_e-trucks_vzp_${year}`, decimals: 0 }) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            MW
          </template>
          <template #second-column>
            {{ showData({ field: `mw_e-bestel_vzp_${year}`, decimals: 2 }) }}
          </template>
          <template #third-column>
            {{ showData({ field: `mw_e-trucks_vzp_${year}`, decimals: 2 }) }}
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>

      <!-- Truckparkings -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          {{ $t('components.map.tiles.ElaadLogistics.truckParking') }}
        </template>

        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.TileData.chargingStations') }}
          </template>
          <template #second-column>
            -
          </template>
          <template #third-column>
            {{ showData({ field: `trucks_tp_overdag_${year}`, decimals: 1 }) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.ElaadLogistics.unit') }}
          </template>
          <template #second-column>
            -
          </template>
          <template #third-column>
            {{ showData({ field: `mwj_e-trucks_tp_${year}`, decimals: 0 }) }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            MW
          </template>
          <template #second-column>
            -
          </template>
          <template #third-column>
            {{ showData({ field: `mw_e-trucks_tp_${year}`, decimals: 2 }) }}
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>

      <!-- Depot -->
      <TileDataContentGroupThreeColumn>
        <template #header>
          {{ $t('components.map.tiles.ElaadLogistics.depot') }}
        </template>

        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.TileData.chargingStations') }}
          </template>
          <template #second-column>
            {{
              showData({
                field: `ldpt_e-bestel_depot_tot_${year}`,
                decimals: 1,
              })
            }}
          </template>
          <template #third-column>
            {{
              showData({
                field: `ldpt_e-trucks_depot_tot_${year}`,
                decimals: 1,
              })
            }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            {{ $t('components.map.tiles.ElaadLogistics.unit') }}
          </template>
          <template #second-column>
            {{
              showData({ field: `mwj_e-bestel_depot_tot_${year}`, decimals: 0 })
            }}
          </template>
          <template #third-column>
            {{
              showData({ field: `mwj_e-trucks_depot_tot_${year}`, decimals: 0 })
            }}
          </template>
        </TileDataContentRowThreeColumn>
        <TileDataContentRowThreeColumn>
          <template #first-column>
            MW
          </template>
          <template #second-column>
            {{
              showData({ field: `mw_e-bestel_depot_tot_${year}`, decimals: 2 })
            }}
          </template>
          <template #third-column>
            {{
              showData({ field: `mw_e-trucks_depot_tot_${year}`, decimals: 2 })
            }}
          </template>
        </TileDataContentRowThreeColumn>
      </TileDataContentGroupThreeColumn>
    </template>

    <template #footer>
      ID: {{ fids[0] }}
    </template>
  </TileDataContent>
</template>

<script>
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TiledataMixin from '@/mixins/map/tiledata-mixin'
import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'
import TileDataContentRowThreeColumn from '@/components/map/tiles/TileDataContentRowThreeColumn'
import TileDataContentGroupThreeColumn from '@/components/map/tiles/TileDataContentGroupThreeColumn'

export default {
  name: 'TileDataElaadLogistics',
  components: {
    TileDataContentGroupThreeColumn,
    TileDataContentRowThreeColumn,
    TileDataContentHeader,
    TileDataContent,
  },
  mixins: [TiledataMixin],
}
</script>
