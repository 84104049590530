<template>
  <li class="Comment">
    <div class="CommentHeader d-flex justify-content-between">
      <div class="CommentSentiment">
        <BIconHandThumbsUp
          v-if="comment.data.sentiment === 1"
          class="Positive mr-2"
        />
        <BIconHandThumbsDown
          v-if="comment.data.sentiment === -1"
          class="Negative mr-2"
        />
      </div>
      <span
        class="CommentDate"
        v-text="createdAt"
      />
    </div>
    <div>
      <p
        v-for="(line, index) in lines"
        :key="index"
        v-text="line"
      />
    </div>
    <ParticipationCommentStatus
      :is-demo="isDemo"
      :status="comment.data.status"
      @update-status="status => $emit('update-status', status)"
    />
  </li>
</template>

<script>
import { BIconHandThumbsUp, BIconHandThumbsDown } from 'bootstrap-vue'
import ParticipationCommentStatus from '@/components/map/sidebar/ParticipationCommentStatus'

export default {
  components: {
    ParticipationCommentStatus,
    BIconHandThumbsUp,
    BIconHandThumbsDown,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    isDemo: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    createdAt() {
      const format = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      return new Date(this.comment.data.created_at).toLocaleString('nl', format)
    },
    lines() {
      return (this.comment.data.message || '').match(/[^\r\n|\r|\n]+/g)
    },
  },
}
</script>

<style lang="scss" scoped>


.Comment {
  border-bottom: 1px solid var(--border-color);

  .CommentHeader {
    font-size: 0.85rem;
    padding: 0.6rem 0.2rem 0.1rem 0.2rem;

    .CommentSentiment {
      flex-shrink: 1;
      overflow-x: hidden;
      color: #888888;

      .Positive,
      .Negative {
        font-size: 1.1rem;
        line-height: 1.1rem;
      }

      .Positive {
        color: green;
      }

      .Negative {
        color: red;
      }
    }

    .CommentDate {
      color: #bbbbbb;
    }
  }

  .CommentStatus {
    display: flex;
    justify-content: flex-end;

    .CommentStatusButton {
      background: none;
      border: none;
      padding: 0;
      margin: 0 0.4rem;
      font-size: 1.2rem;
    }
  }
}
</style>
