const phaseToArray = (phase) => {
  if (!phase) {
    return []
  }
  if (Array.isArray(phase)) {
    return phase
  }
  return [phase]
}

const phaseLabelByUuid = (phases, uuid) => {
  if (!phases) {
    return uuid
  }
  const phase = phases.find(phase => phase.uuid === uuid)
  if (!phase) {
    return uuid
  }
  return phase.label
}

module.exports = {
  phaseToArray,
  phaseLabelByUuid,
}
