<template>
  <tr>
    <td style="width: 50px">
      <slot name="first-column" />
    </td>
    <td style="width: 50px">
      <slot name="second-column" />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TileDataContentHeaderTwoColumn',
}
</script>
