<template>
  <div style="display: none" />
</template>
<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'

export default {
  name: 'PdokCapacity',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
    layerVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
  return {
    layerName: 'capaciteit_afname',
    type: 'fill',
    minzoom: 3,
    paint: {
      'fill-color': // To set the colors beforehand.
      [
        'case',
        ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 0], 'hsl(0, 0%, 100%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 0], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 20]], 'hsl(284, 41%, 95%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 20], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 40]], 'hsl(287, 41%, 91%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 40], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 60]], 'hsl(283, 41%, 88%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 60], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 80]], 'hsl(281, 39%, 85%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 80], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 100]], 'hsl(274, 38%, 76%)',
        ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 100], 'hsl(266, 27%, 70%)',
        'hsl(0, 0%, 82%)',  // Default color if no conditions are met

      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        0,
        10.2,
        0.8,
      ],
      'fill-outline-color': '#ac48b7',
    },
    layout: {
      visibility: this.layerVisibility ? 'visible' : 'none',
    },
  }
},
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer', 'getHighlighted',
    ]),
    source() {
      return {
        url: process.env.VUE_APP_BL_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_BL_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded: {
      immediate: true,
      handler(loaded) {
        if (loaded) {
          this.addLayer()
        }
      },
    },
    layerVisibility: {
      handler(visible) {
        visible ? this.addLayer() : this.removeLayerAndSource()
      },
    },
    getHighlighted() {
      this.updatePrognose()
    },
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      this.removeLayerAndSource()

      if (!this.sourceName) return
      if (!this.source.url || !this.source.layer) return

      this.$nextTick(() => {
        if (this.$store.map.getSource(this.sourceName)) {
          this.$store.map.removeSource(this.sourceName)
        }

        this.$store.map.addSource(this.sourceName, {
          type: 'vector',
          url: this.source.url,
        })

        this.$store.map.addLayer({
          id: this.layerName,
          type: this.type,
          source: this.sourceName,
          'source-layer': this.source.layer,
          minzoom: this.minzoom || 0,
          paint: this.paint,
          layout: this.layout,
        }, this.labelLayer)

        EventBus.$emit('layers.national.loaded', {
          name: this.layerName,
        })
      })
    },
    updatePrognose() {
      if (this.$store.map.getLayer('capaciteit_afname')) {
        this.$store.map.setPaintProperty('capaciteit_afname', 'fill-color', this.fillHexagons())
      }
    },
    fillHexagons() {
      let styleCondition = [
        'case',
      ]
      // The selected tiles
      this.getHighlighted.forEach(fid => {
        styleCondition.push([
          '==',
          [
            'get',
            'fid',
          ],
          fid,
        ])
        styleCondition.push('hsl(23°, 69%, 54%)')
      })

      return styleCondition.concat([
        ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 0], 'hsl(0, 0%, 100%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 0], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 20]], 'hsl(284, 41%, 95%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 20], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 40]], 'hsl(287, 41%, 91%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 40], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 60]], 'hsl(283, 41%, 88%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 60], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 80]], 'hsl(281, 39%, 85%)',
        ['all', ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 80], ['<=', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 100]], 'hsl(274, 38%, 76%)',
        ['>', ['get', 'beschikbare_capaciteit_afname_huidig_mva_visualisatie'], 100], 'hsl(266, 27%, 70%)',
        'hsl(0, 0%, 82%)',  // Default color if no conditions are met
      ])
    },
    removeLayerAndSource() {
      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }
    },
  },
}
</script>
