<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { image } from '@/helpers/assets'

import { EventBus } from '@/services/eventbus'

/**
 *
 */
export default {
  name: 'TrafficFlowLayer',
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'verkeersstromen',
      type: 'circle',
      minzoom: null,
      paint: {
        'circle-color': [
          'case',

            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 7500], // larger than
            '#ff262e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 7000],
            '#ff332e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 6500],
            '#ff412e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 6000],
            '#fe4e2e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 5500],
            '#fe5c2e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 5000],
            '#fd692e',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 4500],
            '#fd772f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 4000],
            '#fc842f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 3500],
            '#fc922f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 3000],
            '#fb9f2f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 2500],
            '#fbac2f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 2000],
            '#fbba2f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 1500],
            '#fac72f',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 1000],
            '#fad530',
            ['>=', ['get', 'GEMIDDELDE_INTENSITEIT'], 500],
            '#aec322',

            '#00cf03', //
        ],
        'circle-opacity': [ // transition based on zoom
          'interpolate',
            ['linear'],
            ['zoom'],
            10,
            0,
            10.3,
            0.3,
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          8,
          3,
          18,
          15,
        ],
        'circle-blur': 0.5,
      },
      layout: {
        'visibility': 'none',
        // "symbol-placement": "point",
        // "symbol-z-order": "source",
        // "icon-allow-overlap": true,
        // "icon-image": "traffic-flow-icon",
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser || this.hasAdminAccess({ code: this.getActiveMunicipality }) || ['0335', '1783'].includes(this.getActiveMunicipality)
    },
    source() {
      return {
        url: process.env.VUE_APP_TRAFFICFLOW_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_TRAFFICFLOW_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      if (! this.sourceName) return
      // if (! this.hasAccess) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        if (! this.$store.map.hasImage('traffic-flow-icon')) {
          try {
            this.$store.map.loadImage(image({ name: 'legend/traffic-light-solid.png' }), (error, image) => {
              if (error) throw error

              if (! this.$store.map.hasImage('traffic-flow-icon')) {
                this.$store.map.addImage('traffic-flow-icon', image, { 'sdf': true })
              }

              this.addActualLayer()
            })
          } catch (e) {
            this.addActualLayer()
          }
        } else {
          this.addActualLayer()
        }
      })

    },
    addActualLayer() {
      this.$store.map.addSource(this.sourceName, {
        type: 'vector',
        url: this.source.url,
      })

      this.$store.map.addLayer({
        'id': this.layerName,
        'type': this.type,
        'source': this.sourceName,
        'source-layer': this.source.layer,
        'minzoom': this.minzoom || 0,
        'paint': this.paint,
        'layout': this.layout,
      }, this.labelLayer)

      EventBus.$emit('layers.national.loaded', {
        name: this.layerName,
      })
    },
  },
}
</script>
