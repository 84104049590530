<template>
  <div class="notFoundPage">
    <h1 class="notFoundCode">
      404
    </h1>
    <div class="notFoundMessage">
      Helaas, deze pagina bestaat niet (meer).
    </div>
    <b-button
      variant="primary"
      href="/"
    >
      Ga naar de thuis pagina
    </b-button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  created() {
    this.setPageTitle({ title: 'Niet gevonden (404)' })
  },
  methods: {
    ...mapMutations(['setPageTitle']),
  },
}
</script>

<style lang="scss">
.notFoundPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .notFoundCode {
    font-size: 3rem;
  }

  .notFoundMessage {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
}
</style>
