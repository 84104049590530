<template>
  <div class="SideBar d-flex flex-column flex-shrink-0">
    <div class="SideBar__main d-flex flex-column p-4 flex-grow-1">
      <slot />
    </div>

    <div
      v-if="version"
      class="SideBar__version p-4 d-flex align-items-center justify-content-between"
    >
      <span>
        Versie {{ version }}
      </span>
      <div
        v-if="getCurrentTheme.type === 'custom'"
        class="SideBar__logo"
      >
        <small>powered by </small><img :src="logo">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',
  computed: {
    ...mapGetters('version', [
      'version',
    ]),
    ...mapGetters('config', ['getCurrentTheme']),
    logo () {
      return require('@/assets/image/logos/logo_landscape_color.svg')
    },
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>

<style lang="scss">


.SideBar {
  position: relative;
  background: white;
  box-shadow: -1px 0px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;
  color: #7F8FA4;
  max-height: 100%;

  &__main {
    overflow-y: scroll;
    flex-shrink: 1;
  }

  p {
    font-size: 14px
  }

  &__version {
    background: var(--grey);
  }
  &__logout {
    cursor: pointer;

    &:hover {
      color: darken(#7F8FA4, 20%);
    }
  }
  // MRA-e logo
  &__logo {
    display: flex;
    height: 25px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      position: relative;
      height: 95px;
      top: -2px;
      margin: 0 !important;
    }
  }
}
</style>
