<template>
  <div style="display:none" />
</template>

<script>
import { turf } from '@/services/turf'
import { numberWithDots } from '@/helpers/number'
import { mapGetters } from 'vuex'

export default {
  name: 'MapLineWithDistance',
  props: {
    dashedLine: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    fromCoordinates: {
      required: false,
      type: Array,
      default: () => null,
    },
    toPoints: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('prognose', {
      loaded: 'ready',
    }),
    hasSufficientPoints() {
      return !!this.fromCoordinates && this.toPoints.length > 0 && this.toPoints.every(point => point || null)
    },
    geojson() {
      return {
        'type': 'geojson',
        'data': {
          type: 'FeatureCollection',
          features: this.toPoints.map(point => {
            const from = this.fromCoordinates
            const to = point

            return {
              'type': 'Feature',
              'properties': {
                'distance': `${numberWithDots(Math.ceil(turf.distance(from, to, { unit: 'kilometers' }) * 1000))} meter`,
              },
              'geometry': {
                'type': 'LineString',
                'coordinates': [
                  to,
                  from,
                ],
              },
            }
          }),
        },
      }
    },
  },
  watch: {
    toPoints() {
      this.drawLine()
    },
    fromCoordinates() {
      this.drawLine()
    },
    hasSufficientPoints() {
      this.drawLine()
    },
    loaded() {
      this.drawLine()
    },
    map() {
      this.drawLine()
    },
  },
  created() {
    this.drawLine()
  },
  destroyed() {
    this.reset()
  },
  methods: {
    reset() {
      // remove lines if no chargingpoints are selected
      if (this.$store.map.getSource(`line-with-distance-${this._uid}`)) {
        if (this.$store.map.getLayer(`line-with-distance-${this._uid}`)) {
          this.$store.map.removeLayer(`line-with-distance-${this._uid}`)
        }

        if (this.$store.map.getLayer(`line-with-distance-text-${this._uid}`)) {
          this.$store.map.removeLayer(`line-with-distance-text-${this._uid}`)
        }
      }
    },
    drawLine() {
      if (! this.loaded || ! this.$store.map) return
      if (! this.hasSufficientPoints) {
        this.reset()
        return
      }

      const source = this.$store.map.getSource(`line-with-distance-${this._uid}`)

      if (source) {
        source.setData(this.geojson.data)
      } else {
        this.$store.map.addSource(`line-with-distance-${this._uid}`, this.geojson)
      }

      if (! this.$store.map.getLayer(`line-with-distance-${this._uid}`)) {

        // find all currently activated/loaded layers under which this layer should be positioned below
        let currentLayers = this.$store.map.getStyle().layers.map(layer => layer.id)
        let positionBelow = ['chargingpoints']

        positionBelow = positionBelow.reduce((result, layer) => {
          return result ? result : (currentLayers.includes(layer) ? layer : null)
        }, null)

        this.$store.map.addLayer({
          'id': `line-with-distance-${this._uid}`,
          'type': 'line',
          'source': `line-with-distance-${this._uid}`,
          'minzoom': 10,
          'paint': {
            'line-color': 'rgb(55, 55, 00)',
            'line-width': 2,
            'line-dasharray': this.dashedLine ? [3, 7] : [],
          },
          'layout': {
            'line-join': 'round',
            'line-cap': 'round',
          },
        }, positionBelow)

        this.$store.map.addLayer({
          'id': `line-with-distance-text-${this._uid}`,
          'type': 'symbol',
          'source': `line-with-distance-${this._uid}`,
          'layout': {
            'symbol-placement': 'line',
            'text-font': ['Open Sans Regular'],
            'text-field': ['get', 'distance'],
            'text-offset': [0, -1],
            'text-size': 12,
          },
          'paint': {},
        }, positionBelow)
      }
    },
  },
}
</script>

<style>

</style>
