export const provincies = [
  { code: 22, label: 'Drenthe', country: 'nl' },
  { code: 27, label: 'Noord-Holland', country: 'nl' },
  { code: 25, label: 'Gelderland', country: 'nl' },
  { code: 21, label: 'Friesland', country: 'nl' },
  { code: 28, label: 'Zuid-Holland', country: 'nl' },
  { code: 23, label: 'Overijssel', country: 'nl' },
  { code: 24, label: 'Flevoland', country: 'nl' },
  { code: 30, label: 'Noord-Brabant', country: 'nl' },
  { code: 26, label: 'Utrecht', country: 'nl' },
  { code: 20, label: 'Groningen', country: 'nl' },
  { code: 31, label: 'Limburg', country: 'nl' },
  { code: 29, label: 'Zeeland', country: 'nl' },

  // Vlaanderen
  { code: 10000, label: 'Antwerpen', country: 'be' },
  { code: 70000, label: 'Limburg', country: 'be' },
  { code: 40000, label: 'Oost-Vlaanderen', country: 'be' },
  { code: 20001, label: 'Vlaams-Brabant', country: 'be' },
  { code: 30000, label: 'West-Vlaanderen', country: 'be' },
  { code: 50000, label: 'Henegouwen', country: 'be' },
  { code: 60000, label: 'Luik', country: 'be' },
  { code: 80000, label: 'Luxemburg', country: 'be' },
  { code: 90000, label: 'Namen', country: 'be' },
  { code: 20002, label: 'Waals-Brabant', country: 'be' },
]
