const turfCircle = require('@turf/circle')
const turfBooleanPointInPolygon = require('@turf/boolean-point-in-polygon')
const createCirclePolygon = turfCircle.default
const isPointInPolygon = turfBooleanPointInPolygon.default

const findSuspiciouslyCloseChargingpoints = ({ coordinates, chargingpoints, radiusToSearchWithin, observedStatuses }) => {
  // defaults
  radiusToSearchWithin = radiusToSearchWithin || 30

  // if it's a new point we have to check if the point maybe is already in our system
  let isPointInside
  const nearbyChargingpoints = []
  const searchWithin = createCirclePolygon(coordinates, radiusToSearchWithin, {
    steps: 8,
    units: 'meters',
  })

  // find nearby chargingpoints
  chargingpoints
    .filter(chargingpoint => chargingpoint.data.deleted_at === undefined)
    .forEach(chargingpoint => {
      isPointInside = isPointInPolygon(chargingpoint.data.coordinates, searchWithin)

      if (
        isPointInside &&
        observedStatuses.includes(chargingpoint.data.properties.status)
      ) {
        nearbyChargingpoints.push(chargingpoint)
      }
    })

  return nearbyChargingpoints
}

/**
 distance = sqrt(dx * dx + dy * dy)

 dx = 111.3 * cos(lat) * (lon1 - lon2)
 lat = (lat1 + lat2) / 2 * 0.01745
 dy = 111.3 * (lat1 - lat2)
 */
const distanceBetweenTwoPoints = (pointA, pointB) => {
  if (Array.isArray(pointA)) {
    pointA = {
      lng: parseFloat(pointA[0]),
      lat: parseFloat(pointA[1]),
    }
  }

  if (Array.isArray(pointB)) {
    pointB = {
      lng: parseFloat(pointB[0]),
      lat: parseFloat(pointB[1]),
    }
  }

  const oneDegreeLongitudeInKm = 111.3
  const radian = (Math.PI / 180)

  const latMidpoint = (pointA.lat + pointB.lat) / 2
  const lat = latMidpoint * radian // lat in rad

  const dx = oneDegreeLongitudeInKm * Math.cos(lat) * ( pointA.lng - pointB.lng)
  const dy = oneDegreeLongitudeInKm * ( pointA.lat - pointB.lat)

  const distance = Math.sqrt(dx * dx + dy * dy) // in km

  return distance * 1000 // in m
}

module.exports = {
  findSuspiciouslyCloseChargingpoints,
  distanceBetweenTwoPoints,
}
