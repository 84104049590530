<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '@/services/eventbus'
import { image } from '@/helpers/assets'

export default {
  name: 'Onderstations',
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'onderstations',
      type: 'symbol',
      icons: [
        'pdok-onderstation',
        'pdok-onderstation-ip',
      ],
      sdf: false,
      minzoom: 10,
      paint: {
        'icon-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          10.2,
          0.8,
        ],
      },
      layout: {
        visibility: 'none',
        'symbol-placement': 'point',
        'symbol-z-order': 'source',
        'icon-allow-overlap': true,
        'icon-image': [
          'case',
          ['==', ['get', 'status'], 'Bestaand'], 'pdok-onderstation',
          'pdok-onderstation-ip',
        ],
        'icon-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          0.05,
          18,
          0.2,
        ],
      },
    }
  },
  computed: {
    ...mapGetters('deployment', ['MapboxAccount', 'DeploymentLayerName']),
    ...mapGetters('prognose', ['labelLayer']),
    source() {
      return {
        url: process.env.VUE_APP_LS_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_LS_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded(loaded) {
      if (loaded) {
        this.addLayer()
      }
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    addLayer() {
      if (!this.sourceName) return

      if (!this.source.url || !this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        this.loadIconsAndAddLayer()
      })
    },
    loadIconsAndAddLayer() {
      this.icons.forEach(icon => {
        if (! this.$store.map.hasImage(icon)) {
          try {
            this.$store.map.loadImage(image({ name: `legend/${icon}.png` }), (error, image) => {
              if (error) throw error

              if (! this.$store.map.hasImage(icon)) {
                this.$store.map.addImage(icon, image, { 'sdf': this.sdf || false })
              }

              this.addActualLayer()
            })
          } catch (e) {
            this.addActualLayer()
          }
        } else {
          this.addActualLayer()
        }
      })
    },
    addActualLayer() {
      this.$store.map.addSource(this.sourceName, {
        type: 'vector',
        url: this.source.url,
      })

      this.$store.map.addLayer({
        'id': this.layerName,
        'type': this.type,
        'source': this.sourceName,
        'source-layer': this.source.layer,
        'minzoom': this.minzoom || 0,
        'paint': this.paint,
        'layout': this.layout,
      }, this.labelLayer)

      EventBus.$emit('layers.national.loaded', {
        name: this.layerName,
      })
    },
    removeLayerAndSource() {
      this.$store.map.removeLayer(this.layerName)
      this.$store.map.removeSource(this.sourceName)
    },
  },
}
</script>
