<template>
  <transition
    name="sidebar"
    mode="out-in"
  >
    <ActionSidebarSearch v-if="isChargingpointSelected && isPlanningAlternativeLocation" />
    <!-- insert here more specific sidebars, if necessary -->
    <ActionSidebar v-else-if="isChargingpointSelected && ! isPlanningAlternativeLocation" />
  </transition>
</template>

<script>
import ActionSidebar from '@/components/map/sidebar/ActionSidebar'
import ActionSidebarSearch from '@/components/map/sidebar/ActionSidebarSearch'
import { mapGetters } from 'vuex'

export default {
  name: 'MapSidebar',
  components: { ActionSidebar, ActionSidebarSearch },
  data() {
    return {
      open: true,
      hasScrollbar: false,
    }
  },
  computed: {
    ...mapGetters('planmode', ['isChargingpointSelected', 'isPlanningAlternativeLocation']),
  },
}
</script>
