import { mapGetters } from 'vuex'
import { statusGroupLabels, statusSlugToLabel } from '@/../shared/services/statusTranslations'

export default {
  computed: {
    ...mapGetters('access', [
      'canAccessLayer',
    ]),
    statusOptions() {
      return Object.entries(statusGroupLabels)
      .filter(([status]) => this.canAccessLayer({ id: `chargingpoints-${status}` }))
      .map(([status]) => ({
        value: status,
        text: statusSlugToLabel({
          status,
          language: localStorage.getItem('language'),
        }),
      }))

    },
  },
}
