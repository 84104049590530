<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/services/eventbus'

import privilegesMixin from '@/mixins/common/privilegesMixin'
import userMixin from '@/mixins/common/userMixin'

export default {
  mixins: [privilegesMixin, userMixin],
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'grondeigendom_provincie',
      type: 'fill',
      minzoom: 10,
      paint: {
        'fill-color': 'hsla(0,100%,50%,0.2)',
        'fill-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          10.2,
          1,
        ],
      },
      layout: {
        'visibility': 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
      'canAccessLayer',
    ]),
    ...mapGetters('layers', [
      'getLayerDetails',
    ]),
    grondeigendomProvincieLayers() {
      return this.getLayerDetails
        .filter(layer => layer.id.startsWith('p_') && layer.id.includes('_grondeigendom'))
    },
  },
  watch: {
    loaded(loaded) {
      if (!loaded) return

      this.addLayer()
    },
  },
  created() {
    if (this.loaded) {
      this.addLayer()
    }
  },
  methods: {
    getSource({ sourceName }) {
      return {
        url: `mapbox://${this.MapboxAccount}.${sourceName}`,
        layer: sourceName,
      }
    },
    /**
     * Add the layer(s)
     */
    addLayer() {
      if (! this.canAccessLayer({ id: this.layerName })) {
        return false
      }

      this.grondeigendomProvincieLayers.forEach(layer => {
        const layerName = layer.id
        const sourceName = this.DeploymentLayerName({ layer: layerName })

        if (!sourceName) {
          return
        }

        const source = this.getSource({ sourceName })

        if (!source.url || !source.layer) return

        if (this.$store.map.getLayer(layerName)) {
          this.$store.map.removeLayer(layerName)
        }
        if (this.$store.map.getSource(sourceName)) {
          this.$store.map.removeSource(sourceName)
        }

        this.$nextTick(() => {
          this.$store.map.addSource(sourceName, {
            type: 'vector',
            url: source.url,
          })

          this.$store.map.addLayer({
            'id': layerName,
            'type': this.type,
            'source': sourceName,
            'source-layer': source.layer,
            'minzoom': this.minzoom || 0,
            'paint': this.paint,
            'layout': this.layout,
          }, this.labelLayer)

          this.$store.map.moveLayer(layerName)

          EventBus.$emit('layers.national.loaded', {
            name: layerName,
          })
        })
      })
    },
  },
}
</script>
