<template>
  <div class="d-flex justify-content-between">
    <span
      v-if="filter.subline"
      class="mr-3 mt-2"
    >
      <span
        v-if="filter.hasTooltip"
        class="d-flex align-items-center info"
      >
        <SvgIcon
          v-if="filter.hasTooltip"
          :id="filter.id"
          icon="info-circle-regular"
        />
        <b-tooltip
          :target="filter.id"
          triggers="hover"
        >
          {{ $t('components.sidebar.PlanModeSearch.cpoTooltip') }}
        </b-tooltip>
      </span>

      {{ $t(filter.subline) }}
    </span>
    <vSelect
      v-model="itemValues"
      :options="filterOptions"
      class="filter-select"
      multiple
    />
  </div>
</template>

<script>
import vSelect from '@/components/form/vSelect'
import { mapGetters, mapActions } from 'vuex'
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  components: { vSelect, SvgIcon },
  mixins: [chargingpointCpoMixin],
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      filterOptions: [],
      items: [],
      itemValues: [],
    }
  },
  computed: {
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('filters', ['getFilterById', 'getActiveFilters']),
    ...mapGetters('config', ['getPhases']),
    filteredFilterOptions() {
      if (this.filterOptions.length && this.filterOptions.length === this.itemValues.length) {
        return []
      }

      return this.items.length
        ? this.activeFilterOptions.filter(option => this.itemValues.some(item => item.id !== option.id))
        : this.activeFilterOptions
    },
    activeFilterOptions() {
      return this.filterOptions.filter(option => !option.created || this.itemValues.some(item => item.uuid === option.id && item.active))
    },
    isCpoFilter () {
      return this.filter.id === 'cpo-list'
    },
  },
  watch: {
    getActiveMunicipality() {
      this.init()
    },
    itemValues() {
      this.handleSelect()
    },
    // watch filter and update phases with active values.
    // This is so that it also updates when filter option is deactivated via Tag
    filter: {
      deep: true,
      handler() {
        this.itemValues = this.filter.values
      },
    },
  },
  created() {
    this.items = this.isCpoFilter ? this.cposByDeployment : this.getPhases

    this.init()
  },
  methods: {
    ...mapActions('filters', ['resetFilter']),
    init() {
      this.filterOptions = []

      this.filter.options.forEach(filter => this.filterOptions.push(filter))
      this.items.forEach(item => {
        const itemFilter = {
          id: item.uuid,
          type: null,
          label: item.label || item.name,
          tag: item.label || item.name,
          active: false,
          visible: false,
          created: true,
          filterFnName: this.filter.filterFnName,
        }

        this.filterOptions.push(itemFilter)
      })
    },
    handleSelect() {
      this.$emit('input', {
        selectedFilter: {
          ...this.filter,
          values: this.itemValues,
        },
      })
    },
  },
}
</script>
<style lang="scss">
.filter-select {
  max-width: 190px;
}
.info {
  position: absolute;
  margin-left: -1.2em;
  margin-top: 0.2em;
}
</style>
