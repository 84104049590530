<template>
  <div :class="['LocatieInformatie__Content', { isLockedForEditing }]">
    <b-alert
      :show="isLockedForEditing"
      variant="warning"
    >
      {{ $t(isLockedForEditingMessage, { case: processCaseRef }) }}
    </b-alert>
    <div v-if="!isLoading">
      <b-alert
        :show="error !== false"
        variant="danger"
      >
        {{ error }}
      </b-alert>
      <div class="LocatieInformatie__Attachments mb-3">
        <strong>{{ $t('components.sidebar.LocatieInformatieContent.attachments') }}</strong>
        <template v-if="isAllowedToUploadAttachments">
          <div
            class="LocatieInformatie__Attachments d-flex justify-content-between"
            :class="{ 'is-dragging': isDragging }"
          >
            <LocatieInformatieUploadAttachment
              :attachment="attachments.top"
              @remove="$_chargingpointAttachmentsMixin_handleFileRemove"
              @change="$_chargingpointAttachmentsMixin_handleFileChange"
              @dragenter="isDragging = true"
              @dragover="() => {}"
              @dragleave="isDragging = false"
              @drop="$_chargingpointAttachmentsMixin_handleDrop"
              @on-edit="hasBeenEdited => attachments.top.edited = hasBeenEdited"
            />

            <LocatieInformatieUploadAttachment
              :attachment="attachments.side"
              @remove="$_chargingpointAttachmentsMixin_handleFileRemove"
              @change="$_chargingpointAttachmentsMixin_handleFileChange"
              @dragenter="isDragging = true"
              @dragover="() => {}"
              @dragleave="isDragging = false"
              @drop="$_chargingpointAttachmentsMixin_handleDrop"
              @on-edit="hasBeenEdited => attachments.side.edited = hasBeenEdited"
            />
          </div>
        </template>
        <p v-else>
          Alleen gebruikers met de rol 'Beheerder', 'Medewerker' of 'CPO' mogen bijlagen uploaden.
        </p>
      </div>

      <HintAddressIncomplete />

      <Form
        :class="{ isLockedForEditing }"
        @submit="handleSubmitUpdate"
      >
        <div>
          <FormField
            v-model="fields.remark.value"
            v-bind="fields.remark"
          />
          <!-- start address -->
          <div v-if="isAddressEditable">
            <strong> {{ $t('address.title') }} </strong><br>
            <FormField
              v-model.trim="fields.address.street.value"
              v-bind="fields.address.street"
              @input="fields.address.edited = true"
            />
            <div class="row">
              <div class="col-6">
                <FormField
                  v-model.trim="fields.address.number.value"
                  v-bind="fields.address.number"
                  @input="fields.address.edited = true"
                />
              </div>
              <div class="col-6">
                <FormField
                  v-model.trim="fields.address.postalcode.value"
                  v-bind="fields.address.postalcode"
                  @input="fields.address.edited = true"
                />
              </div>
            </div>
            <FormField
              v-model.trim="fields.address.city.value"
              v-bind="fields.address.city"
              @input="fields.address.edited = true"
            />
          </div>

          <template v-else>
            <p>
              <strong>
                {{ $t('address.title') }} <SvgIcon
                  id="ecomovement-hint"
                  icon="info-circle-regular"
                />
              </strong> <br>
              {{ address.simple_address }} <br>
              {{ address.postalcode }} {{ address.city }}
            </p>
            <b-tooltip
              target="ecomovement-hint"
              triggers="hover"
            >
              EcoMovement data kan niet worden aangepast.
            </b-tooltip>
          </template>

          <p v-if="address && address.source">
            <strong>{{ $t('components.sidebar.LocatieInformatieContent.addressSource') }}</strong> <br>
            {{ addressSource }}
          </p>
          <!-- end address -->

          <p v-if="evse">
            <strong>{{ $t('evse') }}</strong><br>
            {{ evse }}
          </p>

          <p v-if="evse_ids.length">
            <strong>{{ $t('evse_ids') }}</strong><br>
            {{ evse_ids.join(', ') }}
          </p>

          <p v-if="coordinates.lat && coordinates.lng">
            <strong>
              {{ $t('address.coordinates') }} <SvgIcon
                v-if="importedWithoutCoordinates"
                id="no-coordinates-hint"
                icon="info-circle-regular"
              />
            </strong> <br>
            {{ coordinates.lat }}, <br>
            {{ coordinates.lng }}
          </p>
          <b-tooltip
            v-if="importedWithoutCoordinates"
            target="no-coordinates-hint"
            triggers="hover"
          >
            Deze locatie werd door Vattenfall zonder coördinaten aangeleverd.
          </b-tooltip>

          <p v-if="introducedBy">
            <strong>{{ $t('introducedBy') }}</strong><br>
            {{ introducedBy }}
          </p>

          <p v-if="parkinglotIds">
            <strong>{{ $t('mapLayersPanel.municipality.parkingSpacesAmsterdam.title') }}</strong><br>
            {{ parkinglotIds }}
          </p>

          <div v-if="prio && prio.order">
            <strong>{{ $t('components.sidebar.PlanModeSearch.priority.label') }}</strong>
            <p>{{ prio.order }}</p>
          </div>

          <FormField
            v-model="fields.fase.value"
            v-bind="fields.fase"
            :options="faseOptions"
            style="pointer-events: auto"
          />

          <!-- <FormField
            v-model="fields.power.value"
            v-bind="fields.power"
            :step="fields.power.step"
            :placeholder="fields.power.placeholder"
            append="kW"
            disabled
            readonly
          /> -->
          <p v-if="power">
            <strong>{{ $t('components.sidebar.PlanModeSearch.power') }}</strong><br>
            {{ power | formatTokW }}
          </p>

          <p v-if="nroutlets">
            <strong>Aantal laadpunten</strong><br>
            {{ nroutlets }}
          </p>

          <p v-if="types">
            <strong>{{ $t('components.sidebar.LocatieInformatieContent.connectorTypes') }}</strong><br>
            <span
              v-for="type of types"
              :key="type"
            >{{ type }}<br></span>
          </p>

          <FormField
            v-model="chargingPointCategory"
            v-bind="fields.status"
            :disabled="! isAllowedToEditStatus"
            :options="statusOptions"
            :info="statusInfo"
            @input="setChargingPointCategory"
          />
          <div v-if="groupLayers.length > 1">
            <strong
              class="d-flex justify-content-center"
              :style="chargingPointLabelStyle"
            >
              {{ chargingPointLabel }}
            </strong>
            <ChargingpointsLayerGroup
              :layer="layer"
              :group-layers="groupLayers"
              :selected-status="selectedStatus"
              :is-legend="false"
              @input="handleInput"
            />
          </div>
          <FormField
            v-model="fields.cpo.value"
            v-bind="fields.cpo"
            :options="cpoSelectOptions"
            :disabled="isCpoFieldDisabled"
          />

          <p v-if="closingDates">
            <strong>{{ $t('components.sidebar.LocatieInformatieContent.closingDateParticipation') }}</strong><br>
            {{ fields.participation.closingDate }} <br>
          </p>
          <div class="my-4">
            <strong>{{ $t('components.sidebar.LocatieInformatieContent.trafficDecision') }}</strong>
            <div
              v-for="(date, key) in fields.trafficDecision"
              :key="key"
            >
              <label
                :for="key"
                class="mb-0"
              >{{ $t(labels[key]) }}</label>
              <b-form-datepicker
                :id="key"
                v-model="fields.trafficDecision[key]"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :dropup="true"
                :min="(!isPublicationDate(key) && publicationDate) || ''"
                :max="(isPublicationDate(key) && definitiveDate) || ''"
                :locale="$i18n.locale"
                :placeholder="$t('selectedDate')"
                reset-button
                :disabled="isLockedForEditing || (!isPublicationDate(key) && !publicationDate)"
                class="mb-2 datepicker"
                @input="event => handleDatePickerInput(event, key)"
              />
            </div>
          </div>
          <AlternativeLocation
            :predecessor="fields.predecessor.value"
            :disabled="isLockedForEditing"
            @select="(value) => (fields.predecessor.value = value)"
          />

          <!-- start locatie beheer -->
          <p>
            <strong>{{ $t('components.sidebar.LocatieInformatieContent.locationManagement') }}</strong>
          </p>
          <template v-if="superuser">
            <FormField
              v-model="fields.beheerder.value"
              v-bind="fields.beheerder"
              :options="beheerderOptions"
            />
            <div class="d-flex align-items-center add-validator">
              <FormField
                v-model="fields.validatie.value"
                v-bind="fields.validatie"
                :options="validatorOptions"
              />
              <b-icon-plus
                variant="dark"
                class="add-validator__icon u-clickable ml-2 mt-1"
                @click="$_chargingpointValidatorsMixin_addValidator"
              />
            </div>
            <div class="the-validators">
              <ul v-if="newValidators.length > 0">
                <li
                  v-for="(validator, index) in newValidators"
                  :key="validator.user_id"
                  class="mb-1 d-flex"
                >
                  {{ validator.email }}
                  <b-icon-trash
                    variant="dark"
                    class="the-validators__validator__icon u-clickable pull-right"
                    @click="$_chargingpointValidatorsMixin_removeValidator(index)"
                  />
                </li>
              </ul>
              <p
                v-else
                class="text-muted"
              >
                Geen validatoren geselecteerd
              </p>
            </div>
          </template>
          <template v-else>
            <p>
              <strong>{{ $t('administrator') }}</strong><br>
              {{ beheerder ? beheerder.name : $t('none') }}
            </p>
            <div class="the-validators">
              <p class="mb-0">
                <strong>{{ $t('validators') }}</strong>
              </p>
              <ul v-if="hasValidators">
                <li
                  v-for="validator in validators"
                  :key="validator.user_id"
                >
                  {{ validator.name }}
                </li>
              </ul>
              <span v-else>
                {{ $t('none') }}
              </span>
            </div>
          </template>
          <!-- end locatie beheer -->
        </div>

        <div class="sticky-bottom">
          <div class="mt-2 d-flex justify-content-end">
            <b-button
              v-if="hasLockedFase && locked"
              id="unlock-hint"
              type="submit"
              size="sm"
              variant="primary"
              class="mt-2 ml-auto"
              @click.prevent="unlock"
            >
              <SvgIcon
                class="unlock-hint-icon--white mr-1 mt-"
                icon="unlock-keyhole-solid"
              /> Ontgrendelen om te bewerken

              <b-tooltip
                target="unlock-hint"
                triggers="hover"
              >
                Deze laadpaal bevat een fase prioritering. Het is niet de bedoeling dat laadpalen met een fase bewerkt worden.
              </b-tooltip>
            </b-button>
            <div v-else>
              <b-button
                v-if="!isLockedForEditing && canRemove"
                :disabled="isLockedForEditing"
                class="mr-1"
                size="sm"
                variant="danger"
                @click.prevent="handleChargingpointDelete"
              >
                Verwijder locatie
              </b-button>
              <b-button
                v-if="faseHasChanged || !isLockedForEditing"
                type="submit"
                size="sm"
                variant="primary"
                style="pointer-events: auto"
              >
                Opslaan
              </b-button>
            </div>
          </div>
        </div>
      </Form>
    </div>
    <p
      v-else
      class="text-muted"
    >
      Loading...
    </p>
  </div>
</template>

<script>
import { validate } from 'uuid'
import { reverseDate } from '@/helpers/date'
import { phaseToArray } from '@/helpers/phases'
import { mapActions, mapGetters } from 'vuex'
import { BIconPlus, BIconTrash } from 'bootstrap-vue'

import Form from '@/components/form/Form'
import FormField from '@/components/form/FormField'
import SvgIcon from '@/components/common/SvgIcon'
import LocatieInformatieUploadAttachment from '@/components/map/sidebar/LocatieInformatieUploadAttachment'
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'
import chargingpointEditMixin from '@/mixins/chargingpoint/chargingpointEditMixin'
import chargingpointCpoMixin from '@/mixins/chargingpoint/chargingpointCpoMixin'
import chargingpointValidatorsMixin from '@/mixins/chargingpoint/chargingpointValidatorsMixin'
import chargingpointAttachmentsMixin from '@/mixins/chargingpoint/chargingpointAttachmentsMixin'
import userMixin from '@/mixins/common/userMixin'
import { SOURCE } from '@/services/sourceTranslation'
import {
  EcoMovementLockedForEditingMessage,
  RHDHVBedrijventerreinenLockedForEditingMessage,
  RHDHVLockedForEditingMessage,
} from '@/services/commonMessages'
import HintAddressIncomplete from '@/components/map/sidebar/HintAddressIncomplete'
import AlternativeLocation from '@/components/map/sidebar/AlternativeLocation'
import { formatFormattedAddress, formatSimpleAddress } from '@/../shared/helpers/address'
import { USER_ROLE } from '../../../../shared/valueholders/userRoles'
import ChargingpointsLayerGroup from '@/components/common/ChargingpointsLayerGroup.vue'

export default {
  components: {
    AlternativeLocation,
    HintAddressIncomplete,
    LocatieInformatieUploadAttachment,
    Form,
    FormField,
    SvgIcon,
    BIconPlus,
    BIconTrash,
    ChargingpointsLayerGroup,
  },
  mixins: [
    chargingpointDataMixin,
    chargingpointEditMixin,
    chargingpointCpoMixin,
    chargingpointValidatorsMixin,
    chargingpointAttachmentsMixin,
    userMixin,
  ],
  data() {
    return {
      translationsPath: 'components.sidebar.LocatieInformatieContent',
      error: false,
      fields: {
        address: {
          edited: false,
          street: {
            label: this.$i18n.t('address.street'),
            value: null,
          },
          number: {
            label: this.$i18n.t('address.houseNumber'),
            value: null,
          },
          postalcode: {
            label: this.$i18n.t('address.zipCode'),
            value: null,
          },
          city: {
            label: this.$i18n.t('address.city'),
            value: null,
          },
        },
        power: {
          type: 'number',
          label: this.$i18n.t('components.sidebar.PlanModeSearch.power'),
          value: this.power,
          step: '0.01',
          placeholder: 'e.g. 11.7',
        },
        remark: {
          label: this.$i18n.t('remark'),
          value: this.remark,
          type: 'textarea',
        },
        status: {
          label: this.$i18n.t('mapLayersPanel.status.title'),
          value: 'suggestion',
          type: 'select',
        },
        fase: {
          label: this.$i18n.t('phase'),
          value: [],
          type: 'v-select',
        },
        predecessor: {
          value: null,
        },
        participation: {
          closingDate: {
            label: this.$i18n.t('components.sidebar.LocatieInformatieContent.closingDateParticipation'),
            value: null,
          },
        },
        trafficDecision: {
          publicationDate: '',
          definitiveDate: '',
        },
      },
      isDragging: false,
      locked: true,
      selectedStatus: 'chargingpoints-suggestion',
      chargingPointLabel: '',
      chargingPointCategory: 'suggestion',
      faseHasChanged: false,
      labels: {
        publicationDate: '',
        definitiveDate: '',
      },
    }
  },
  computed: {
    beheerderOptions() {
      const deleteBeheerder = { value: null, text: this.$t('validationPanel.noAdmin') }
      return [deleteBeheerder, ...this.usersByCodeAsOptions]
    },
    ...mapGetters('planmode', {
      chargingpoint: 'getSelectedChargingpoint',
      getChargingPointByUuid: 'getChargingPointByUuid',
      isPlanModeActive: 'isActive',
      getRejectedLocationUuid: 'getRejectedLocationUuid',
      getPlanmodeLayers: 'getPlanmodeLayers',
      getLockedPhases: 'getLockedPhases',
    }),
    ...mapGetters('access', ['getActiveMunicipality']),
    ...mapGetters('currentUser', {
      currentUserRole: 'getCurrentUserRole',
    }),
    isBoundToProcess () {
    return !!this.chargingpoint.data.workflowCaseRef ||
           !!this.chargingpoint.data.workflowUuid ||
           !!this.chargingpoint.data.externalProcessId
    },
    isLockedForEditing() {
      return this.isBoundToProcess || this.chargingpoint.data.isLockedForEditing
    },
    isLockedForEditingMessage () {
      const isEcoMovementLocation = this.chargingpoint.data.address?.source === SOURCE.ECO_MOVEMENT || this.chargingpoint.data.meta?.source === SOURCE.ECO_MOVEMENT || false
      if (isEcoMovementLocation) {
        return EcoMovementLockedForEditingMessage
      }

      if (this.isBoundToProcess) {
        return 'components.sidebar.Tabs.CommentsTab.editNotAllowed'
      }

      // TOOLS-3285: Vastzetten palen met fase "Bedrijventerreinen GORAL"
      if (this.customPhase === 'Bedrijventerreinen GORAL') {
        return RHDHVBedrijventerreinenLockedForEditingMessage
      }

      return RHDHVLockedForEditingMessage
    },
    processCaseRef() {
      return this.chargingpoint.data?.workflowCaseRef || this.chargingpoint.data?.externalProcessId
    },
    hasLockedFase() {
      return phaseToArray(this.chargingpoint.data.prio?.fase).some(r => this.getLockedPhases.includes(r))
    },
    canRemove() {
      // return this.getEditMode && (isPlanModeAdmin() || (isAnalist() && (this.userName === this.currentUserName) && (this.status.value !== 'definitive')))
      return true // TODO: Add permission check - Role can differ per municipality!
    },
    isAllowedToUploadAttachments() {
      return this.superuser || this.admin || this.isEditor || ([USER_ROLE.CPO, USER_ROLE.MUNICIPALITY].includes(this.currentUserRole))
    },
    isAllowedToEditStatus() {
      // superuser always are allowed to change the status
      if (this.superuser) {
        return true
      }

      // if there is no beheerder, everyone may change the status
      if (! this.chargingpoint.data.properties.beheerder) {
        return true
      }

      // is user the beheerder?
      return this.currentUserId === this.chargingpoint.data.properties.beheerder?.user_id
    },
    isUserCpo() {
      return this.currentUserRole === 'cpo'
    },
    isLoading() {
      return this.isLoadingFauna || this.isLoadingAws
    },
    statusInfo() {
      if (this.isAllowedToEditStatus) {
        return null
      }

      const beheerderName = this.chargingpoint.data.properties?.beheerder?.name
      return `De status kan alleen aangepast worden door de beheerder van deze locatie (${beheerderName}).`
    },
    groupLayers () {
      return this.getPlanmodeLayers?.filter(layer =>
              layer.status !== 'hidden' &&
              this.fields.status.value.includes(layer.config.statusGroup))
    },
    layer () {
      const status = this.chargingpoint.data.properties.status
      const initialSelectedLayer = this.groupLayers.find(layer => layer.id.includes(status))

      return initialSelectedLayer || this.groupLayers.find(layer => layer.id.includes(this.fields.status.value))
    },
    chargingPointLabelStyle () {
      return `border-bottom: 1px solid ${this.layer.config.iconColor}`
    },
    parkinglotIds () {
      return this.chargingpoint.data.properties.parkinglotIds?.join(', ')
    },
    isCpoFieldDisabled () {
      if (!this.currentCpo) return false
      return this.isUserCpo && this.identity.category === 'cpo' && (this.identity.uuid !== this.currentCpo?.uuid)
    },
    publicationDate () {
      return this.fields.trafficDecision.publicationDate
    },
    definitiveDate () {
      return this.fields.trafficDecision.definitiveDate
    },
  },
  watch: {
    // re-init when selected chargingpoint changes
    chargingpoint(next, previous) {
      if (!next || next.data.uuid === previous.data.uuid) {
        return
      }
      //Object.keys(this.fields.trafficDecision).map(date => this.fields.trafficDecision[date] = null)
      this.init()
    },
    'fields.fase.value': {
      handler(newValue, oldValue) {
        this.faseHasChanged = false
        if (oldValue !== undefined && newValue !== oldValue) {
          this.faseHasChanged = true
        }
      },
      immediate: true,
    },
  },
  async created() {
    /* Set the translation labels */
    Object.keys(this.labels).forEach(label => this.labels[label] = `${this.translationsPath}.${label}`)
    this.init()

    /* connect to pusher */
    await this.$_chargingpointEditMixin_connectToPusher()
  },
  beforeDestroy () {
    this.fields.status.class = ''
  },
  methods: {
    ...mapActions('planmode', ['clearAlternativeLocationForUuid']),
    init() {
      this.$_chargingpointAttachmentsMixin_cleanFetchAttachments()
      this.setInitialValues()
    },


    /* fill form with data */
    setInitialValues() {
      let point = this.chargingpoint

      this.fields.address.street.value = point.data.address?.street || null
      this.fields.address.number.value = point.data.address?.number || null
      this.fields.address.postalcode.value = point.data.address?.postalcode || null
      this.fields.address.city.value = point.data.address?.city || null
      this.fields.status.value = point.data.properties.status || 'suggestion'
      this.fields.fase.value = [...phaseToArray(point.data.prio?.fase), ...phaseToArray(point.data.prio?.customPhase)] || []
      this.fields.cpo.value = this.currentCpo ? this.currentCpo.uuid : ''
      this.fields.beheerder.value = point.data.properties.beheerder?.user_id || null
      this.fields.power.value = this.power / 1000 || null
      this.fields.remark.value = point.data.properties.remark || ''
      this.newValidators = point.data.properties.validators || []
      if (point.data.participation?.closingDates) {
        this.fields.participation.closingDate = reverseDate(point.data.participation.closingDates?.at(-1))
      }
      this.fields.trafficDecision = point.data.trafficDecision || { publicationDate: '', definitiveDate: '' }

      if (this.layer) {
        this.selectedStatus = this.layer.id
        this.chargingPointLabel = this.layer.label
        this.chargingPointCategory = this.layer.config.statusGroup
      }
      this.fields.predecessor.value = this.getRejectedLocationUuid ? { uuid: this.getRejectedLocationUuid } : (point.data.properties.predecessor || null)
      // reset, otherwise the rejected location is also selected for a new location
      this.clearAlternativeLocationForUuid()
      this.faseHasChanged = false
    },
    setChargingPointCategory(chargingPointCategory) {
      this.fields.status.value = chargingPointCategory
      this.selectedStatus = this.layer.id
      this.chargingPointLabel = this.layer.label
    },

    isPublicationDate (dateField) {
      return dateField === 'publicationDate'
    },

    handleInput(chargingPoint) {
      this.selectedStatus = chargingPoint.layer.id
      this.fields.status.value = this.selectedStatus.replace('chargingpoints-', '')
      this.chargingPointLabel = chargingPoint.layer.label
    },

    handleDatePickerInput (event, dateField) {
      if (dateField === 'publicationDate' && !event) {
        this.fields.trafficDecision.definitiveDate = ''
      }
    },

    /**
     * Update the marker
     */
    async handleSubmitUpdate() {
      let point = this.chargingpoint

      await this.$_chargingpointAttachmentsMixin_deleteAttachments()

      // always compute address, and let fauna change detection decide if the address was updated
      const address = this.computeAddress()

      // if there was a cpo already assigned, filter it out and add the (if) selected one
      const stakeholders = this.$_chargingpointCpoMixin_removeOrUpdateCpoFromStakeholders()

      const beheerder = this.$_chargingpointValidatorsMixin_removeOrUpdateBeheerder()

      // quick n dirty fix for: https://ev-it.atlassian.net/browse/TOOLS-2478
      // todo: refactor this
      // we need to not mix the priority-fase with the custom tag phases

      const fase = []
      const customPhase = []
      this.fields.fase.value.forEach(phase => {
        if (validate(phase)) {
          customPhase.push(phase)
        } else {
          fase.push(phase)
        }
      })

      const phase = {
        fase,
        customPhase,
      }

      try {
        await this.$_chargingpointEditMixin_save({
          data: {
            code: this.getActiveMunicipality,
            ref: point.ref, // full ref
            id: point.data.properties.id,
            stakeholders,
            status: this.fields.status.value,
            user: {
              name: point.data.properties.user_name || point.data.properties.user?.name,
            },
            coordinates: point.data.coordinates,
            power: Math.floor(this.fields.power.value * 1000),
            remark: this.fields.remark.value,
            predecessor: this.fields.predecessor.value,
            validators: this.newValidators,
            beheerder,
            address,
            prio: {
              ...point.data.prio,
              ...phase,
            },
            participation: {
              closingDates: point.closingDates,
            },
            trafficDecision: Object.values(this.fields.trafficDecision).some(val => val.length)
              ? this.fields.trafficDecision
              : null,
          },
        })

        // re-init with updated chargingpoint data
        this.setInitialValues()
      } catch (error) {
        this.error = error.message
        this.isLoadingFauna = false
      }
    },

    computeAddress() {
      const address = {}
      Object.entries(this.fields.address).forEach(field => {
        const [key, value] = field
        address[key] = value.value
      })

      if (this.fields.address.edited) {
        address.source = SOURCE.MANUAL
      }

      if (! this.fields.address.simple_address) {
        address.simple_address = formatSimpleAddress({ address })
        address.formatted_address = formatFormattedAddress({ address })
      }

      return address
    },

    /*
    * Confirm that this chargingpoint is to be edited, while it already is assigned a realisation fase
    */
    unlock() {
      this.$bvModal.msgBoxConfirm('Weet u zeker dat u deze laadpaal wilt ontgrendelen?', {
        title: 'Deze laadpaal bevat een fase prioritering',
        okVariant: 'danger',
        okTitle: 'Ontgrendelen',
        cancelTitle: 'Annuleren',
      })
        .then(async (decision) => {
          if (! decision) {
            return
          }

          this.locked = false
        })
    },

    /**
     * Delete the marker from the data set
     */
    handleChargingpointDelete() {
      this.$bvModal.msgBoxConfirm(
        'Bevestig het verwijderen van dit punt.', {
          okVariant: 'danger',
          okTitle: 'Bevestigen',
          cancelTitle: 'Annuleren',
        })
        .then(confirmed => {
          if (confirmed) {
            /* $_chargingpointEditMixin_delete broadcasts to close the popup/sidepanel */
            this.$_chargingpointEditMixin_delete({
              code: this.getActiveMunicipality,
              ref: this.chargingpoint.ref, // full ref
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.unlock-hint-icon--white {
  fill: white;
}

.sticky-bottom {
  width: calc(100% - 40px - 28px);
  position: absolute;
  bottom: 0;
  background: white;
  padding: 10px;
}

.hidden {
  display: none !important;
}

.FormField {
  input {
    color: var(--text-muted);
  }

  label, legend {
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    color: var(--primary-text)
  }
}

.ActionSidebar {
  .add-validator {
    .custom-select {
      width: 225px;
    }
  }
}

.add-validator {
  width: calc(100% - 26px) !important;

  &__icon {
    font-size: 25px;
  }
}

.the-validators {
  ul {
    list-style: none;
    padding: 0;
  }
}

.ratio {
  overflow: hidden;
}

.is-dragging {
  .LocatieInformatie__Attachments__Image {
    animation-duration: .3s;
    animation-name: wobbel;
    animation-iteration-count: infinite;
    transition-timing-function: ease-out;
  }
}
.isLockedForEditing {
  pointer-events: none;
}

.datepicker {
  .b-calendar .b-calendar-inner {
    min-width: auto !important;
    width: auto !important;
    .b-calendar-grid-help {
      display: none;
    }
  }
}

@keyframes wobbel {
  0% { transform: rotate(0); }

  25% { transform: rotate(-2deg); }

  50% { transform: rotate(0); }

  75% { transform: rotate(2deg); }

  100% { transform: rotate(0); }
}
</style>
