<template>
  <div
    id="silentbox-gallery"
    class="d-flex justify-content-between"
  >
    <div
      v-for="(previewItem, index) in previewGallery"
      :key="previewItem.src"
      class="LocatieInformatie__Attachments__Image cursor-pointer"
      @click.prevent="openOverlay(previewItem, index)"
    >
      <slot
        name="silentbox-item"
        :item="previewItem"
      >
        <img
          :src="previewItem.thumbnail"
          :alt="previewItem.alt"
          :width="previewItem.thumbnailWidth"
          :height="previewItem.thumbnailHeight"
        >
      </slot>
    </div>

    <LightboxOverlay
      v-if="overlay.visible"
      :overlay-item="overlay.item"
      :visible="overlay.visible"
      :total-items="totalItems"
      @closeSilentboxOverlay="hideOverlay"
      @requestNextSilentBoxItem="showNextItem"
      @requestPreviousSilentBoxItem="showPreviousItem"
    />
  </div>
</template>

<script>
import LightboxOverlay from '@/components/common/lightbox/LightboxOverlay.vue'

export default {
  name: 'Lightbox',
  components: { LightboxOverlay },
  props: {
    previewCount: {
      type: Number,
      default: null,
    },
    gallery: {
      type: Array,
      default: () => {
        return []
      },
    },
    image: {
      type: Object,
      default: () => {
        return {
          src: '',
          alt: '',
          thumbnailWidth: 'auto',
          thumbnailHeight: 'auto',
          thumbnail: '',
          autoplay: false,
          controls: true,
          description: '',
          filename: '',
        }
      },
    },
  },
  data () {
    return {
      overlay: {
        item: {
          src: '',
          alt: '',
          thumbnailWidth: 'auto',
          thumbnailHeight: 'auto',
          thumbnail: '',
          autoplay: false,
          controls: true,
          description: '',
          filename: '',
        },
        visible: false,
        currentItem: 0,
      },
    }
  },
  computed: {
    totalItems () {
      return this.gallery.length || 1
    },
    previewGallery () {
      if (Number.isInteger(this.previewCount)) {
        return this.gallery.slice(0, this.previewCount).map(item => {
          return {
            ...this.overlay.item,
            ...item,
            thumbnail: this.setThumbnail(item),
            autoplay: this.setAutoplay(item),
          }
        })
      }
      return this.galleryItems
    },
    galleryItems () {
      if (this.gallery.length > 0) {
        return this.gallery.map(item => {
          return {
            ...this.overlay.item,
            ...item,
            thumbnail: this.setThumbnail(item),
            autoplay: this.setAutoplay(item),
          }
        })
      }
      return [{
        ...this.overlay.item,
        ...this.image,
        thumbnail: this.setThumbnail(this.image),
      }]
    },
  },
  methods: {
    openOverlay (image, index = 0) {
      this.overlay.visible = true
      this.overlay.item = image
      this.overlay.currentItem = index
      this.$emit('silentbox-overlay-opened', { item: image })
    },
    hideOverlay () {
      this.overlay.visible = false
      this.$emit('silentbox-overlay-hidden', { item: this.overlay.item })
    },
    showNextItem () {
      let newItemIndex = this.overlay.currentItem + 1
      newItemIndex = newItemIndex <= this.galleryItems.length - 1
          ? newItemIndex : 0

      this.overlay.item = this.galleryItems[newItemIndex]
      this.overlay.currentItem = newItemIndex
      this.$emit('silentbox-overlay-next-item-displayed', { item: this.overlay.item })
    },
    showPreviousItem () {
      let newItemIndex = this.overlay.currentItem - 1
      newItemIndex = newItemIndex > -1
          ? newItemIndex : this.galleryItems.length - 1

      this.overlay.item = this.galleryItems[newItemIndex]
      this.overlay.currentItem = newItemIndex
      this.$emit('silentbox-overlay-previous-item-displayed', { item: this.overlay.item })
    },
    setAutoplay (item) {
      return item.autoplay ? 'autoplay' : ''
    },
    setThumbnail (item) {
      return item.thumbnail || item.src
    },
  },
}
</script>
