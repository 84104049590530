import { DEFAULT_TAB } from '@/../shared/valueholders/action-sidebar-tabs'


/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */

const state = {
  selectedActionSidebarTab: DEFAULT_TAB,
  appReady: false,
  mapReady: false,
}

const getters = {
  getSelectedActionSidebarTab: state => state.selectedActionSidebarTab,
  // todo:: is both necessary?
  isAppReady: state => state.appReady,
  isMapReady: state => state.mapReady,
}

const actions = {
  selectTab({ commit }, { tabName }) {
    commit('setSelectedActionSidebarTab', { tabName })
  },
}

const mutations = {
  setSelectedActionSidebarTab(state, { tabName }) {
    state.selectedActionSidebarTab = tabName ? tabName : DEFAULT_TAB
  },
  setAppReady(state, { value }) {
    state.appReady = value
  },
  setMapReady(state, { value }) {
    state.mapReady = value
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
