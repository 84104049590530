const indexToYears = (result, item, index) => {
  result[index+2020] = (item)
  return result
}

export const decielen = {
  SparkCity: {
    D1: {
      '2020': 0.0213606327593112,
      '2021': 0.0219079906268307,
      '2022': 0.0253671203800475,
      '2023': 0.0281448747195213,
      '2024': 0.04175859578138174,
      '2025': 0.047859214587978365,
      '2026': 0.0642481459121986,
      '2027': 0.08581224622944664,
      '2028': 0.11400726098818294,
      '2029': 0.15077004087554224,
      '2030': 0.19869122472940065,
      '2031': 0.23068951396437729,
      '2032': 0.26252087116846373,
      '2033': 0.2873942021834476,
      '2034': 0.3056918938423797,
      '2035': 0.3238355839301341,
    },
    D2: {
      '2020': 0.0226171405686824,
      '2021': 0.0242140949033392,
      '2022': 0.0299793240855107,
      '2023': 0.0333568885564697,
      '2024': 0.0739066130309745,
      '2025': 0.09186261240830815,
      '2026': 0.11477739294909026,
      '2027': 0.14355525750666825,
      '2028': 0.17953493915888857,
      '2029': 0.2245157104032994,
      '2030': 0.28089671015629086,
      '2031': 0.3261338070083311,
      '2032': 0.37113490622957207,
      '2033': 0.4062992012921891,
      '2034': 0.4321672857908786,
      '2035': 0.45781765290037973,
    },
    D3: {
      '2020': 0.0301561874249099,
      '2021': 0.0392037727006444,
      '2022': 0.0472750879809976,
      '2023': 0.0562897494390426,
      '2024': 0.09853457192249976,
      '2025': 0.12601210986472003,
      '2026': 0.1553152497059769,
      '2027': 0.1916461236338174,
      '2028': 0.23647821290498997,
      '2029': 0.2918013158073519,
      '2030': 0.3602647624738195,
      '2031': 0.4182837116574451,
      '2032': 0.47599998150261563,
      '2033': 0.5211000341206912,
      '2034': 0.5542772091484364,
      '2035': 0.5871751502063044,
    },
    D4: {
      '2020': 0.0326692030436524,
      '2021': 0.0426629291154072,
      '2022': 0.0518872916864608,
      '2023': 0.0625441660433807,
      '2024': 0.1123207922525377,
      '2025': 0.14201236333897724,
      '2026': 0.17410239631035543,
      '2027': 0.2136675575690614,
      '2028': 0.2622093173669794,
      '2029': 0.3217604188911469,
      '2030': 0.39502527538235405,
      '2031': 0.4586422420301039,
      '2032': 0.5219273250148369,
      '2033': 0.5713789021906884,
      '2034': 0.6077572107761583,
      '2035': 0.6438293432174796,
    },
    D5: {
      '2020': 0.0439777733279936,
      '2021': 0.0588056590509666,
      '2022': 0.0749483102137767,
      '2023': 0.0833922213911743,
      '2024': 0.1421272940200692,
      '2025': 0.17700792542995258,
      '2026': 0.21274060868914502,
      '2027': 0.25575827289848146,
      '2028': 0.30720570447998224,
      '2029': 0.3686587616749602,
      '2030': 0.44220344479226176,
      '2031': 0.5134182342045093,
      '2032': 0.5842615028351704,
      '2033': 0.6396191195254083,
      '2034': 0.6803421172035696,
      '2035': 0.7207223718875654,
    },
  },
  // The NAL Model
  NAL: {
    D1: {
      '2020': 0.017,
      '2021': 0.019,
      '2022': 0.022,
      '2023': 0.0244090501121915,
      '2024': 0.02844249518487545,
      '2025': 0.032770407183403336,
      '2026': 0.043854201260628056,
      '2027': 0.05799515486016174,
      '2028': 0.07586775916905854,
      '2029': 0.09835621755295383,
      '2030': 0.12661448468312686,
      '2031': 0.152704594212271,
      '2032': 0.18384421927210645,
      '2033': 0.22013899479084675,
      '2034': 0.2564017426342635,
      '2035': 0.2925164083343823,
      '2036': 0.3286570753408864,
      '2037': 0.36467008814096413,
      '2038': 0.40055684150019005,
      '2039': 0.4363186505853113,
      '2040': 0.4722007641395081,
      '2041': 0.5082384009627042,
      '2042': 0.5439070749629051,
      '2043': 0.5797683423132374,
      '2044': 0.6224000455608418,
      '2045': 0.6223999426263646,
      '2046': 0.6223999070471049,
      '2047': 0.6223998090503382,
      '2048': 0.622399780128732,
      '2049': 0.6223997553979633,
      '2050': 0.6223996582524148,
    },
    D2: {
      '2020': 0.018,
      '2021': 0.021,
      '2022': 0.026,
      '2023': 0.0289292445774121,
      '2024': 0.05033906063961018,
      '2025': 0.06290063970894302,
      '2026': 0.07834422019646027,
      '2027': 0.09702006130720195,
      '2028': 0.11947408795260828,
      '2029': 0.14646488074319478,
      '2030': 0.1789993103825371,
      '2031': 0.2158838076437262,
      '2032': 0.25990698102101456,
      '2033': 0.3112181702945218,
      '2034': 0.36248408092703926,
      '2035': 0.4135406426718948,
      '2036': 0.46463396336991164,
      '2037': 0.5155468148666761,
      '2038': 0.5662811689908981,
      '2039': 0.6168388850396506,
      '2040': 0.6675666797097738,
      '2041': 0.7185143430463373,
      '2042': 0.7689403907791394,
      '2043': 0.8196387144442255,
      '2044': 0.8799086393335626,
      '2045': 0.879908493811504,
      '2046': 0.8799084435118646,
      '2047': 0.8799083049704196,
      '2048': 0.8799082640829369,
      '2049': 0.8799082291201872,
      '2050': 0.879908091782139,
    },
    D3: {
      '2020': 0.024,
      '2021': 0.034,
      '2022': 0.041,
      '2024': 0.06711358547882497,
      '2025': 0.08628365897471084,
      '2026': 0.10601436232508286,
      '2027': 0.12952168375565684,
      '2028': 0.15736780227765276,
      '2029': 0.19035926191382924,
      '2030': 0.22957600322930655,
      '2031': 0.27688230538348624,
      '2032': 0.3333443340462216,
      '2033': 0.3991536253176783,
      '2034': 0.46490484435741586,
      '2035': 0.5303875624693721,
      '2036': 0.5959174258665902,
      '2037': 0.6612158280484498,
      '2038': 0.726285297988684,
      '2039': 0.7911282203333005,
      '2040': 0.856189277429641,
      '2041': 0.9215323276217585,
      '2042': 0.9862063784457907,
      '2043': 1.0,
      '2044': 1.0,
      '2045': 1.0,
      '2046': 1.0,
      '2047': 1.0,
      '2048': 1.0,
      '2049': 1.0,
      '2050': 1.0,
    },
    D4: {
      '2020': 0.026,
      '2021': 0.037,
      '2022': 0.045,
      '2023': 0.0542423335826477,
      '2024': 0.0765036163938387,
      '2025': 0.09723943469947126,
      '2026': 0.1188380056630132,
      '2027': 0.14440459997605806,
      '2028': 0.17449093302875857,
      '2029': 0.20990335730233903,
      '2030': 0.2517268779608368,
      '2031': 0.3035975769086244,
      '2032': 0.3655074019716965,
      '2033': 0.43766636980612045,
      '2034': 0.5097616622503415,
      '2035': 0.5815625471810189,
      '2036': 0.6534151262578729,
      '2037': 0.7250139113480224,
      '2038': 0.7963616754358098,
      '2039': 0.867461033252244,
      '2040': 0.9387995727743174,
      '2041': 1.0,
      '2042': 1.0,
      '2043': 1.0,
      '2044': 1.0,
      '2045': 1.0,
      '2046': 1.0,
      '2047': 1.0,
      '2048': 1.0,
      '2049': 1.0,
      '2050': 1.0,
    },
    D5: {
      '2020': 0.035,
      '2021': 0.051,
      '2022': 0.065,
      '2023': 0.0723231114435303,
      '2024': 0.09680533552824931,
      '2025': 0.12120177568660066,
      '2026': 0.1452114973482979,
      '2027': 0.1728510940484513,
      '2028': 0.20443442111344182,
      '2029': 0.2404979209101414,
      '2030': 0.2817908106597469,
      '2031': 0.33985646667705455,
      '2032': 0.40916022928535406,
      '2033': 0.48993719758985277,
      '2034': 0.5706428857038143,
      '2035': 0.6510190049907288,
      '2036': 0.7314529922950577,
      '2037': 0.8116028748036118,
      '2038': 0.8914717566803653,
      '2039': 0.9710625649356419,
      '2040': 1.0,
      '2041': 1.0,
      '2042': 1.0,
      '2043': 1.0,
      '2044': 1.0,
      '2045': 1.0,
      '2046': 1.0,
      '2047': 1.0,
      '2048': 1.0,
      '2049': 1.0,
      '2050': 1.0,
    },
  },
  GENT: {
    D1: [0, 0.0187761607431245, 0.0212464855072692, 0.0229341115781085, 0.0250874071668978, 0.0279421484198498, 0.0381366422647652, 0.0487891763901015, 0.0622486691642012, 0.0802481685195191, 0.103151208316974].reduce(indexToYears, {}),
    D2: [0, 0.020752598716085, 0.0251094828722273, 0.0283137179976648, 0.0321432404325878, 0.0369557446843174, 0.0485375447006103, 0.0575983332383143, 0.0748308469739866, 0.0906027709091343, 0.120343076369803].reduce(indexToYears, {}),
    D3: [0, 0.0335994455403281, 0.0395957229908199, 0.045868223156217, 0.0509587958077612, 0.0631478462992574, 0.0762732845295306, 0.0901244508317155, 0.106617401440813, 0.133962668415649, 0.169935003445271].reduce(indexToYears, {}),
    D4: [0, 0.0365641024997688, 0.0434587203557779, 0.0509646923957966, 0.0572306475994856, 0.0695335835654745, 0.0797402520081455, 0.0982559802300655, 0.119199579250598, 0.142375782857211, 0.181175840249043].reduce(indexToYears, {}),
    D5: [0, 0.0503991683104922, 0.0627737071805681, 0.0679529231943955, 0.0736942585527623, 0.0815955317349955, 0.100542056879836, 0.121972940975254, 0.148337254178522, 0.184441355065023, 0.218865704826399].reduce(indexToYears, {}),
  },
  VL: {
    D1: [0, 0.0281642411146870, 0.0329320525362670, 0.0366945785249740, 0.0413942218253810, 0.0475016523137450, 0.0640695590048060, 0.0809900328075690, 0.1020878174292900, 0.1300020330016210, 0.1650419333071580].reduce(indexToYears, {}),
    D2: [0, 0.0311288980741280, 0.0389196984519520, 0.0453019487962640, 0.0530363467137700, 0.0628247659633400, 0.0815430750970250, 0.0956132331756020, 0.1227225890373380, 0.1467764888727980, 0.1925489221916850].reduce(indexToYears, {}),
    D3: [0, 0.0503991683104920, 0.0613733706357710, 0.0733891570499470, 0.0840820130828060, 0.1073513387087380, 0.1281391180096110, 0.1496065883806480, 0.1748525383629330, 0.2170195228333510, 0.2718960055124340].reduce(indexToYears, {}),
    D4: [0, 0.0548461537496530, 0.0673610165514560, 0.0815435078332750, 0.0944305685391510, 0.1182070920613070, 0.1339636233736840, 0.1631049271819090, 0.1954873099709810, 0.2306487682286820, 0.2898813443984690].reduce(indexToYears, {}),
    D5: [0, 0.0755987524657380, 0.0972992461298810, 0.1087246771110330, 0.1215955266120580, 0.1387124039494920, 0.1689106555581240, 0.2024750820189220, 0.2432730968527760, 0.2987949952053370, 0.3501851277222380].reduce(indexToYears, {}),
  },
}
