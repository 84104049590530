<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="LayerConfigPanel">
    <div class="d-flex">
      <aside
        class="LayerConfigPanel__Aside d-flex flex-column flex-shrink-0 mr-3"
      >
        <h3 class="LayerConfigPanel__Title">
          {{ $t('mapLayersPanel.title') }}
        </h3>
        <div
          class="w-75 mt-3 flex-grow-1 d-flex flex-column justify-content-between"
        >
          <div>
            <p>
              {{ $t('mapLayersPanel.tip1') }}
            </p>
            <p>{{ $t('mapLayersPanel.tip2') }}</p>

            <b-button
              class="mt-3"
              @click="handleAutoMatch"
            >
              {{ $t('mapLayersPanel.match') }}
            </b-button>
            <p class="mt-3">
              {{ $t('mapLayersPanel.matchDescription') }}
            </p>
          </div>

          <div>
            <p class="mt-5 font-weight-bold">
              {{ $t('mapLayersPanel.statusDefinition') }}
            </p>
            <CollapsableSegment
              class="mt-3"
              :label="$t('mapLayersPanel.status.primary.title')"
              :visible="false"
            >
              {{ $t('mapLayersPanel.status.primary.description') }}
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              :label="$t('mapLayersPanel.status.secondary.title')"
              :visible="false"
            >
              {{ $t('mapLayersPanel.status.secondary.description') }}
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              :label="$t('mapLayersPanel.status.hidden.title')"
              :visible="false"
            >
              {{ $t('mapLayersPanel.status.hidden.description') }}
            </CollapsableSegment>
            <CollapsableSegment
              class="mt-3"
              :label="$t('mapLayersPanel.status.promo.title')"
              :visible="false"
            >
              {{ $t('mapLayersPanel.status.promo.description') }}
            </CollapsableSegment>
          </div>
        </div>
      </aside>

      <div class="LayerConfigPanel__Main flex-grow-1 flex-shrink-1">
        <!-- Prognose laag -->

        <div>
          <h3>{{ $t("mapLayersPanel.forecast.title") }}</h3>
          <p>
            {{ $t("mapLayersPanel.forecast.description") }}
          </p>
        </div>

        <hr>

        <div class="d-flex align-items-center">
          <span class="PrognosePanel__Label mr-3" />
          <div class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            {{ $t("mapLayersPanel.status.title") }}
          </div>
          <div class="flex-grow-1 flex-shrink-0 mr-3">
            {{ $t("mapLayersPanel.mbReference") }}
          </div>
        </div>

        <div
          v-for="layer in resultsLayers"
          :key="layer.id"
          class="d-flex align-items-center mt-4 flex-shrink-1"
        >
          <span class="LayerConfigPanel__Label mr-3">
            {{ $t(layer.label) }}
          </span>
          <span class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            <form @submit="() => null">
              <SimpleSelect
                :disabled="
                  layer.url === '' ||
                    !layer.url ||
                    (layer.required && layer.status !== 'hidden')
                "
                :value="layer.status"
                v-bind="statusfieldconfig"
                @input="(status) => updateStatus(layer, status)"
              />
            </form>
          </span>
          <vSelect
            class="vSelect flex-shrink-0 mr-3"
            :options="mapboxOptions"
            :clearable="!layer.required"
            :value="layer.source"
            @input="(source) => updateSource(layer, source)"
          />

          <template v-if="layer.tooltip">
            <SvgIcon
              :id="layer.id"
              class="SvgIcon__Info"
              icon="info-circle-regular"
            />
            <b-tooltip
              :target="layer.id"
              triggers="hover"
            >
              {{ $t(layer.tooltip) }}
            </b-tooltip>
          </template>
        </div>

        <!-- Gemeentelijke lagen -->

        <div class="mt-5">
          <h3>{{ $t('mapLayersPanel.municipality.title') }}</h3>
          <p>
            {{ $t('mapLayersPanel.municipality.description') }}
          </p>
        </div>

        <hr>

        <div class="d-flex align-items-center">
          <span class="PrognosePanel__Label mr-3" />
          <div class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            {{ $t("mapLayersPanel.status.title") }}
          </div>
          <div class="flex-grow-1 flex-shrink-0 mr-3">
            {{ $t("mapLayersPanel.mbReference") }}
          </div>
        </div>

        <div
          v-for="layer in layers"
          :key="layer.id"
          class="d-flex align-items-center mt-4 flex-shrink-1"
        >
          <span class="LayerConfigPanel__Label mr-3">
            {{ $t(layer.label) }}
          </span>
          <span class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            <form @submit="() => null">
              <SimpleSelect
                :disabled="
                  layer.url === '' ||
                    !layer.url ||
                    (layer.required && layer.status !== 'hidden')
                "
                :value="layer.status"
                v-bind="statusfieldconfig"
                @input="(status) => updateStatus(layer, status)"
              />
            </form>
          </span>
          <vSelect
            class="vSelect flex-shrink-0 mr-3"
            :options="mapboxOptions"
            :clearable="!layer.required"
            :value="layer.source"
            @input="(source) => updateSource(layer, source)"
          />

          <template v-if="layer.tooltip">
            <SvgIcon
              :id="layer.id"
              class="SvgIcon__Info"
              icon="info-circle-regular"
            />
            <b-tooltip
              :target="layer.id"
              triggers="hover"
            >
              {{ $t(layer.tooltip) }}
            </b-tooltip>
          </template>
        </div>

        <!-- Landelijke lagen -->

        <div class="mt-5">
          <h3>{{ $t('mapLayersPanel.national.title') }}</h3>
          <p>
            {{ $t('mapLayersPanel.national.description') }}
          </p>
        </div>

        <hr>

        <div class="d-flex align-items-center">
          <span class="PrognosePanel__Label mr-3" />
          <div class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            Status
          </div>
        </div>

        <div
          v-for="layer in nationalLayers"
          :key="layer.id"
          class="d-flex align-items-center mt-4 flex-shrink-1"
        >
          <span class="LayerConfigPanel__Label mr-3">
            {{ $t(layer.label) }}
          </span>
          <span class="LayerConfigPanel__Status flex-shrink-0 mr-3">
            <form @submit="() => null">
              <SimpleSelect
                :value="layer.status"
                v-bind="statusfieldconfig"
                @input="(status) => updateStatus(layer, status)"
              />
            </form>
          </span>

          <span class="flex-shrink-0 flex-grow-1 mr-3" />

          <template v-if="layer.tooltip">
            <SvgIcon
              :id="layer.id"
              class="SvgIcon__Info"
              icon="info-circle-regular"
            />
            <b-tooltip
              :target="layer.id"
              triggers="hover"
            >
              <span
                v-html="$t(layer.tooltip, {
                  pdokLink: '<a style=&quot;color: lightblue;&quot; href=&quot;https://www.pdok.nl/introductie/-/article/beschikbare-capaciteit-elektriciteitsnet-1&quot;>' + $t('mapLayersPanel.national.capacity.thisLink') + '</a>',
                  pdokWMS: '<a style=&quot;color: lightblue;&quot; href=&quot;https://www.google.com&quot;>' + $t('mapLayersPanel.national.capacity.thisLink') + '</a>'
                })"
              />
            </b-tooltip>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from '@/components/form/vSelect'
import SimpleSelect from '@/components/form/SimpleSelect'
import SvgIcon from '@/components/common/SvgIcon'
import CollapsableSegment from '@/components/common/CollapsableSegment'

import { layers as layerDetails } from '@/data/layerDetails'
import { configLayers, simplifyTilesets } from '@/services/configGenerator'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: 'LayerConfigPanel',
  components: {
    vSelect,
    SimpleSelect,
    SvgIcon,
    CollapsableSegment,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusfieldconfig: {
        options: [
          {
            text: this.$i18n.t('mapLayersPanel.status.primary.title'),
            value: 'primary',
          },
          {
            text: this.$i18n.t('mapLayersPanel.status.secondary.title'),
            value: 'secondary',
          },
          {
            text: this.$i18n.t('mapLayersPanel.status.hidden.title'),
            value: 'hidden',
          },
          {
            text: this.$i18n.t('mapLayersPanel.status.promo.title'),
            value: 'promo',
            disabled: true,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentCode']),
    ...mapGetters('tilesets', ['tilesets']),
    ...mapGetters('access', ['getActiveMunicipality']),
    mapboxOptions() {
      return this.tilesets.map((tileset) => tileset.name)
    },
    resultsLayers() {
      /**
       * Go over the list of layers specified in the source code
       *  If layer config data exists, we load the layer config data
       *  If not, we add default values
       */
      return layerDetails
        .slice()
        .filter((layer) => layer.type === 'results')
        .map((layer) => {
          const layerData = this.value[layer.id]
            ? this.value[layer.id]
            : {
                source: '',
                url: '',
                status: 'hidden',
              }
          return Object.assign({}, layer, layerData)
        })
    },
    layers() {
      /**
       * Go over the list of layers specified in the source code
       *  If layer config data exists, we load the layer config data
       *  If not, we add default values
       */
      return layerDetails
        .slice()
        .filter(
          (layer) =>
            // only if not ignored
            !layer.ignore &&
            // and meets requirements
            (layer.type === 'context' ||
              (layer.type === 'forecast' && layer.config.isConfigurable)),
        )
        .map((layer) => {
          const layerData = this.value[layer.id]
            ? this.value[layer.id]
            : {
                source: '',
                url: '',
                status: 'hidden',
              }
          return Object.assign({}, layer, layerData)
        })
    },
    nationalLayers() {
      /**
       * Go over the list of layers specified in the source code
       *  If layer config data exists, we load the layer config data
       *  If not, we add default values
       */
      return layerDetails
        .slice()
        .filter(
          (layer) =>
            // only if not ignored
            !layer.ignore &&
            // and meets requirements
            layer.type === 'national',
        )
        .map((layer) => {
          const layerData = this.value[layer.id]
            ? this.value[layer.id]
            : {
                source: '',
                url: '',
                status: layer.status ?? 'hidden',
              }
          return Object.assign({}, layer, layerData)
        })
    },
  },
  methods: {
    /**
     * Get the label of a layer based on the layer id (slug)
     */
    idToLabel({ id }) {
      let layer = layerDetails.find((layer) => layer.id === id)
      return layer ? layer.label : 'Onbekende kaartlaag'
    },
    /**
     * Handle a change in the MapBox reference field of a specific layer
     */
    updateSource(layer, source) {
      let layers = Object.assign(this.value)

      // Source connection was deleted
      if (!source) {
        layers[layer.id] = Object.assign(layers[layer.id], {
          source: '',
          url: '',
          status: 'hidden',
        })

        // A source connection was set
      } else {
        let tileset = this.tilesets.find((tileset) => tileset.name === source)
        let details = layerDetails.find((details) => details.id === layer.id)

        if (tileset) {
          if (layers[layer.id]) {
            Vue.set(
              layers,
              layer.id,
              Object.assign(layers[layer.id], {
                source: tileset.name,
                url: `mapbox://${tileset.id}`,
                status: details ? details.status : layers[layer.id].status,
              }),
            )
          } else {
            Vue.set(layers, layer.id, {
              source: tileset.name,
              url: `mapbox://${tileset.id}`,
              status: details ? details.status : 'secondary',
            })
          }
        }
      }

      this.$emit('input', layers)
    },

    /**
     * Update the status of a single layer
     */
    updateStatus(layer, status) {
      let layers = Object.assign(this.value)

      // use defaults if the layer is not yet in the list
      let layerDetails = layers[layer.id] ?? {
        source: '',
        url: '',
        status: 'hidden',
      }

      // update the layers' status
      layerDetails = Object.assign(layerDetails, {
        status,
      })

      // update the list
      layers[layer.id] = layerDetails

      this.$emit('input', layers)
    },

    /**
     * Try to automatically match any empty layers with the available tilesets
     */
    handleAutoMatch() {
      let autoLayers = configLayers({
        deployment: this.DeploymentCode,
        code: this.getActiveMunicipality,
        tilesets: simplifyTilesets({ tilesets: this.tilesets }),
      })

      let layers = Object.keys(this.value).reduce((result, key) => {
        result[key] =
          this.value[key].source !== ''
            ? this.value[key]
            : autoLayers[key] || {
                source: '',
                url: '',
                status: 'hidden',
              }
        return result
      }, {})

      this.$emit('input', layers)
    },
  },
}
</script>
<style lang="scss">
.LayerConfigPanel {
  &__Aside {
    width: 200px;

    @media (min-width: 1280px) {
      width: 275px;
    }
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }
  &__Label {
    flex-shrink: 0;
    width: 125px;

    @media (min-width: 1440px) {
      width: 175px;
    }
  }
  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;

    &__Info {
      display: none;
      @media (min-width: 1280px) {
        display: inline-block;
      }
    }
  }
  &__Status {
    min-width: 100px;
  }

  .vSelect.vSelect {
    min-width: 350px;
    flex-grow: 1;
  }

  &__Info {
    strong {
      display: inline-block;
      min-width: 120px;
    }
  }
}
</style>
