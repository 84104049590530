import { mapGetters } from 'vuex'

// todo:: import getter in components instead and stop using mixin
export default {
  computed: {
    ...mapGetters('currentUser', {
      currentUserEmail: 'getCurrentUserEmail',
      currentUserId: 'getCurrentUserId',
      currentUserName: 'getCurrentUserName',
      currentUserRole: 'getCurrentUserRole',
      currentUserRoleLabel: 'getCurrentUserRoleLabel',
    }),
  },
}
