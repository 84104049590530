<template>
  <div class="ValidationNotification pt-4">
    <p v-if="toValidate !== 0">
      {{
        $t("components.navbar.validationNotification", {
          alreadyValidated: alreadyValidated,
          total: total,
          toValidate: toValidate,
          timeExpected: toValidate * 5,
        })
      }}
    </p>
    <p v-else>
      {{ $t("components.navbar.noValidations") }}
    </p>
  </div>
</template>

<script>
import { EventBus } from '@/services/eventbus'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ValidationNotification',
  data() {
    return {
      busy: false,
      filters: [],
      toValidate: 0,
      alreadyValidated: 0,
      total: 0,
      chargingPoints: [],
    }
  },
  computed: {
    ...mapGetters('filters', ['getFilters']),
    ...mapGetters('planmode', ['getChargingPointsByValidator']),
  },
  watch: {
    chargingPoints: {
      handler(newVal) {
        this.setupFilters(newVal)
        this.total = newVal.length
        this.alreadyValidated = this.total - this.toValidate
      },
      immediate: true,
    },
  },
  created() {
    EventBus.$on('overlay-click', this.maybeClose)
    this.fetchChargingPoints()
  },
  beforeDestroy() {
    EventBus.$off('overlay-click', this.maybeClose)
  },
  methods: {
    ...mapActions('planmode', ['fetchChargingPointsByValidator']),

    async fetchChargingPoints() {
      const response = await this.fetchChargingPointsByValidator({
        user_id: this.$auth.user.sub,
      })

      this.chargingPoints = response
      this.total = this.chargingPoints.length
    },
    setupFilters(chargingPoints) {
      const toValidateFilter = Object.values(this.getFilters).filter(
        (filter) => filter.visible && filter.id === 'to-validate',
      )

      const toValidate = toValidateFilter[0].filterFn

      this.toValidate = this.calculateValidation(
        toValidate,
        chargingPoints,
        toValidateFilter,
      )
    },

    calculateValidation(validationFn, chargingPoints, filter) {
      const result = validationFn({
        chargingpoints: chargingPoints,
        filter: filter,
      })

      return result.length
    },
    maybeClose() {
      if (this.busy) return

      EventBus.$emit('overlay-hide')
    },
  },
}
</script>

<style lang="scss">
.ValidationNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: white;
  z-index: 999999;
  opacity: 0.999;
  min-width: 24rem;
  max-width: 24rem;

  p {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.15rem;
    cursor: auto;
    max-width: 100%;
  }
}
</style>
