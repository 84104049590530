<template>
  <TileDataContent
    :fids="fids"
    :forecast-label="accessLabel"
    :data-by-id="dataById"
  >
    <template #content-header>
      <TileDataContentHeader>
        <template #second-column>
          MVa
        </template>
      </TileDataContentHeader>
    </template>

    <template #content="{ showData }">
      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.TileDataPdok.currentCapacity') }}
        </template>
        <template #second-column>
          {{ formatData(showData({ field: 'beschikbare_capaciteit_afname_huidig_mva_visualisatie', decimals: 0 })) }}
        </template>
      </TileDataContentRowTwoColumn>

      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.TileDataPdok.capacity3Years') }}
        </template>
        <template #second-column>
          {{ formatData(showData({ field: 'beschikbare_capaciteit_afname_3_jaar_mva_visualisatie', decimals: 0 })) }}
        </template>
      </TileDataContentRowTwoColumn>

      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.TileDataPdok.capacity5Years') }}
        </template>
        <template #second-column>
          {{ formatData(showData({ field: 'beschikbare_capaciteit_afname_5_jaar_mva_visualisatie', decimals: 0 })) }}
        </template>
      </TileDataContentRowTwoColumn>

      <TileDataContentRowTwoColumn>
        <template #first-column>
          {{ $t('components.map.tiles.TileDataPdok.capacity10Years') }}
        </template>
        <template #second-column>
          {{ formatData(showData({ field: 'beschikbare_capaciteit_afname_10_jaar_mva_visualisatie', decimals: 0 })) }}
        </template>
      </TileDataContentRowTwoColumn>
    </template>
  </TileDataContent>
</template>


  <script>
  import { mapGetters, mapMutations } from 'vuex'
  import TileDataContent from '@/components/map/tiles/TileDataContent'
  import TileDataContentRowTwoColumn from '@/components/map/tiles/TileDataContentRowTwoColumn'
  import TileDataContentHeader from '@/components/map/tiles/TileDataContentHeader'

  export default {
    components: { TileDataContentHeader, TileDataContentRowTwoColumn, TileDataContent },
    props: {
      loaded: {
        type: Boolean,
        value: false,
      },
    },
    data() {
      return {
        // This data is obtained from the prognose layer on click
        dataById: {},
        fids: [],
      }
    },
    computed: {
      ...mapGetters('scenarios', [
        'access', 'forecast',
      ]),
      ...mapGetters('planmode', {
        isPlanModeActive: 'isActive',
      }),
      ...mapGetters('deployment', [
        'DeploymentCode',
      ]),
      accessLabel() {
        return 'components.map.tiles.TileDataPdok.title'
      },
    },
    watch: {
      loaded() {
        this.activate()
      },
      getActiveMunicipality() {
        this.fids = []
      },
      fids() {
        this.setHighlighted({ fids: this.fids })
      },
    },
    created() {
      if (this.loaded) {
        this.activate()
      }
    },
    beforeDestroy() {
      if (this.loaded) {
        this.$store.map.off('click', 'capaciteit_afname', this.handleClick)
        this.$store.map.off('contextmenu', 'capaciteit_afname', this.handleContext)
        this.$store.map.off('mouseenter', 'capaciteit_afname', this.mouseEnter)
        this.$store.map.off('mouseleave', 'capaciteit_afname', this.mouseLeave)
      }
    },
    methods: {
      ...mapMutations('prognose', [
      'setHighlighted',
    ]),
      activate() {
        this.$store.map.on('click', 'capaciteit_afname', this.handleClick)

        // TODO: Figure out a fix to avoid this
        if (navigator.appVersion.indexOf('Win') === -1) { // OSX
          this.$store.map.on('contextmenu', 'capaciteit_afname', this.handleContext)
        }

        // Cursor on hover
        this.$store.map.on('mouseenter', 'capaciteit_afname', this.mouseEnter)
        this.$store.map.on('mouseleave', 'capaciteit_afname', this.mouseLeave)
      },
      handleClick(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return
      }

      if (
        (e.originalEvent.ctrlKey || e.originalEvent.button == 2) &&
        navigator.appVersion.indexOf('Win') === -1 // Continue on Windows OS
      ) {
        return
      }

      this.selectTile(e)
    },
      handleContext(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return
      }

      if ( ! (e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        return
      }

      this.selectTile(e)
    },
      selectTile(e) {
        let props = e.features[0].properties
        // Obtain the data from the prognose layer
        let data = {};
        [
          'beschikbare_capaciteit_afname_huidig_mva_visualisatie',
          'beschikbare_capaciteit_afname_3_jaar_mva_visualisatie',
          'beschikbare_capaciteit_afname_5_jaar_mva_visualisatie',
          'beschikbare_capaciteit_afname_10_jaar_mva_visualisatie',
          'fid',
        ].forEach((key) => {
        data[key] = props[key] || 0
      })

        if (! data.fid) return

        // Holding CTRL
        if ((e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        if (! this.fids.includes(data.fid)) {
          this.dataById[data.fid] = data
          this.fids.push(data.fid)

        } else {
          let index = this.fids.indexOf(data.fid)
          this.fids.splice(index, 1)
          delete this.dataById[data.fid]
        }
        } else {
        if (! this.fids.includes(data.fid) || this.fids.length > 1) {
          // Not emptying data object, as that would lead to computation errors
          this.dataById[data.fid] = data
          this.fids = [data.fid]
        } else {
          this.fids = []
          this.dataById = {}
        }
      }
      },
      mouseEnter() {
        if (this.isPlanModeActive) return

        this.$store.map.getCanvas().style.cursor = 'pointer'
      },
      mouseLeave() {
        if (this.isPlanModeActive) return

        this.$store.map.getCanvas().style.cursor = ''
      },
      formatData(data) {
      if (data === undefined || Number(data) < 0) {
        return '0'
      }
      return data
    },
    },
  }
  </script>
