<template>
  <div class="Manuals">
    <PlainNavBar />
    <ParentPage
      v-if="menu.length"
      class="Manuals"
      :menu="menu"
      :root-route-name="rootRouteName"
    />
    <Overlay />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ParentPage from '@/components/manual/ParentPage.vue'
import PlainNavBar from '@/components/navbar/PlainNavBar.vue'
import Overlay from '@/components/common/Overlay'

export default {
  name: 'Manuals',
  components: {
    ParentPage,
    PlainNavBar,
    Overlay,
  },
  data() {
    return {
      manuals: [],
      rootRouteName: 'Manuals',
    }
  },
  computed: {
    ...mapGetters('currentUser', ['isSuperuser']),
    ...mapGetters('access', ['municipalityPlanModeAccessList']),
    canAccessManuals() {
      return (
        this.isSuperuser || this.municipalityPlanModeAccessList.length !== 0
      )
    },
    menu() {
      const manualsList = []
      this.manuals?.map((manual) => {
        const data = manual.data['nl-NL']
        const item = {
          type: 'link',
          label: data.title,
          to: {
            name: 'ManualPage',
            params: { id: manual.id },
          },
          securityCheck: 'manuals',
        }
        if (data.category) {
          const found = manualsList.find((m) => m.label === data.category)
          if (!found) {
            manualsList.push({
              type: 'segment',
              label: data.category,
              securityCheck: 'manuals',
              items: [item],
            })
          } else {
            manualsList[manualsList.indexOf(found)].items.push(item)
          }
        } else {
          manualsList.push(item)
        }
      })
      return manualsList
    },
  },
  async created() {
    // Access check
    if (!this.canAccessManuals) {
      this.$router.push({
        name: 'Dashboard',
      })
    }
    try {
      const token = await this.$auth.getTokenSilently()
      const response = await fetch('/api/get-manuals', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (response.status === 200) {
        this.manuals = await response.json()
      }
      if (response.status === 404) {
        this.manuals = 'Handleidingen niet gevonden'
      }
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style>
.Manuals {
  height: 100%;
}
</style>
