<template>
  <div class="ColumnChart">
    <GChart
      type="ColumnChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { numberWithDots } from '@/helpers/number'
import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'ColumnChart',
  components: { GChart },
  props: {
    data: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    annotation: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: { title: this.title },
        legend: { position: 'left' },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        bar: { groupWidth: '80%' },
        colors: [
        getCssProperty('primary'),
        ],
        height: 180,
        width: 300,
        chartArea: { left: 25, right: 25 },
        focusTarget: 'category',
        animation: {
          duration: 500,
        },
      }
    },
    chartData() {
      const chartSerieLabels = [
        'Jaar',
        this.annotation, { role: 'annotation' },
      ]
      const data = [chartSerieLabels]

      this.data.forEach(entry => {
        data.push([
          entry[0],
          entry[1],
          entry[1] === 0 ? null : numberWithDots(entry[1]),
        ])
      })

      return data
    },
  },
}
</script>
