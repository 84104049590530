<template>
  <div
    v-if="! isEmpty"
    class="MenuGeneratorSegment"
  >
    <h3>{{ item.label }}</h3>

    <template v-for="(subItem, index) in subItems">
      <MenuGeneratorLink
        v-if="subItem.type === 'link'"
        :key="`link_${index}`"
        :item="subItem"
      />
      <MenuGeneratorSegment
        v-else-if="subItem.type === 'segment'"
        :key="`segment_${index}`"
        :item="subItem"
        :depth="depth + 1"
      />
    </template>
  </div>
</template>

<script>
import MenuGeneratorLink from '@/components/manual/MenuGeneratorLink.vue'

export default {
  name: 'MenuGeneratorSegment',
  components: {
    MenuGeneratorLink,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
  },
  computed: {
    subItems() {
      return this.item.items || []
    },
    isEmpty() {
      return this.subItems.length === 0
    },
  },
}
</script>

<style lang="scss">
.MenuGeneratorSegment {
  padding-top: 24px;
  padding-bottom: 12px;

  &:first-child {
    padding-top: 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>
