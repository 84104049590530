const USER_ROLE = {
  SUPERUSER: 'superuser',
  GUEST: 'guest',
  EDITOR: 'editor',
  CPO: 'cpo',
  MUNICIPALITY: 'municipality',
  ADMIN: 'admin',
}

const USER_ROLE_LABEL = {
  SUPERUSER: 'Superuser',
  GUEST: 'Gast',
  EDITOR: 'Medewerker',
  CPO: 'CPO',
  MUNICIPALITY: 'Gemeente',
  ADMIN: 'Beheerder',
}

const userRoles = Object.values(USER_ROLE).filter(role => role !== USER_ROLE.SUPERUSER)

const userRoleLabels = Object.values(USER_ROLE_LABEL).filter(label => label !== USER_ROLE_LABEL.SUPERUSER)

const userRoleOptions = userRoles.map(role => ({
  label: USER_ROLE_LABEL[role.toUpperCase()],
  value: USER_ROLE[role.toUpperCase()],
}))

const roleSlugToLabel = ({ role }) => {
  return USER_ROLE_LABEL[role.toUpperCase()] || 'Onbekend'
}

const roleLabelToSlug = ({ role }) => {
  return USER_ROLE[role.toUpperCase()] || 'Onbekend'
}

const AUTHORIZED_ROLES = [
  USER_ROLE.CPO,
  USER_ROLE.EDITOR,
  USER_ROLE.MUNICIPALITY,
  USER_ROLE.ADMIN,
]

module.exports = {
  USER_ROLE,
  USER_ROLE_LABEL,
  roles: userRoles,
  userRoleLabels,
  userRoleOptions,
  roleSlugToLabel,
  roleLabelToSlug,
  AUTHORIZED_ROLES,
}
