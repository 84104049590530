<template>
  <div>
    <FactsheetBar
      :title="title"
      :icon="icon"
      :title-right="titleRight"
      :icon-right="iconRight"
    />

    <div class="Main__Content d-flex justify-content-between">
      <slot />
    </div>
  </div>
</template>

<script>
import FactsheetBar from '@/components/factsheet/FactsheetBar'

export default {
  components: {
    FactsheetBar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleRight: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

/**
 * Measurements are taken from a powerpoint "design", which has a different aspect ratio.
 * The factors below are used to help convert pp measurements into pixels
 */
$height: 1.96;
$width: 1.56;
$aspect: math.div($width + $height, 2);

.Main__Content {
  padding: 0 7px * $width;
}
</style>
