<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/services/eventbus'

export default {
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
    layerVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layerName: 'bl_bedrijventerrein',
      type: 'fill',
      icon: 'building-regular',
      sdf: false,
      minzoom: null,
      paint: {
        'fill-color': 'hsla(163,100%,21%,0.5)',
        'fill-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          10.2,
          0.8,
        ],
      },
      layout: {
        'visibility': this.layerVisibility ? 'visible' : 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    source() {
      return {
        url: process.env.VUE_APP_BL_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_BL_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded: {
      immediate: true,
      handler(loaded) {
        if (!loaded) return

        this.addLayer()
      },
    },
    layerVisibility: {
      handler(visible) {
        !visible ? this.removeLayerAndSource() : this.addLayer()
      },
    },
  },
  methods: {
    /**
     * Add the layer
     */
    addLayer() {
      this.removeLayerAndSource()

      if (! this.sourceName) return

      if (! this.source.url || ! this.source.layer) return

      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
      }
      if (this.$store.map.getSource(this.sourceName)) {
        this.$store.map.removeSource(this.sourceName)
      }

      this.$nextTick(() => {
        this.$store.map.addSource(this.sourceName, {
          type: 'vector',
          url: this.source.url,
        })

        this.$store.map.addLayer({
          'id': this.layerName,
          'type': this.type,
          'source': this.sourceName,
          'source-layer': this.source.layer,
          'minzoom': this.minzoom || 0,
          'paint': this.paint,
          'layout': this.layout,
        }, this.labelLayer)

        EventBus.$emit('layers.national.loaded', {
          name: this.layerName,
        })
      })
    },
    removeLayerAndSource () {
      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
        this.$store.map.removeSource(this.sourceName)
      }
    },
  },
}
</script>
