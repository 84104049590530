<template>
  <MapBoxPopup
    v-if="coordinates"
    :coordinates="coordinates"
    :show="show"
    @close="handleClose"
  >
    <div>
      <div class="d-flex flex-row">
        Gem. voertuigen spits: {{ averageIntensity }}

        <SvgIcon
          id="traffic-flow-popup-info"
          class="SvgIcon__Info ml-1"
          icon="info-circle-regular"
        />
        <b-tooltip
          target="traffic-flow-popup-info"
          triggers="hover"
        >
          Bron: Nationaal Dataportaal Wegverkeer (NDW)
        </b-tooltip>
      </div>
    </div>
  </MapBoxPopup>
</template>

<script>

import MapBoxPopup from '@/components/common/MapBoxPopup'

import { mapGetters } from 'vuex'

import { provincieCodeByMunicipalityCode } from '@/services/municipalities'
import SvgIcon from '@/components/common/SvgIcon'

export default {
  name: 'TrafficFlowPopup',
  components: { SvgIcon, MapBoxPopup },
  data() {
    return {
      layerName: 'verkeersstromen',

      show: false,

      coordinates: null,

      // Properties
      data: {},
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.superuser ||
        this.hasAdminAccess({ code: this.getActiveMunicipality }) ||
        provincieCodeByMunicipalityCode({ code: this.getActiveMunicipality }) === 28
    },
    averageIntensity() {
      return Math.round(this.data['GEMIDDELDE_INTENSITEIT'])
    },
  },
  watch: {
    /**
     * Hide the popup whenever the municiaplity changes
     */
    getActiveMunicipality() {
      this.show = false
    },
    hasAccess: {
      immediate: true,
      handler (value) {
        value ? this.enable() : this.disable()
      },
    },
  },
  /**
   * Disconnect the event handlers from Mapbox
   */
  beforeDestroy() {
    this.disable()
  },
  methods: {
    enable() {
      this.$store.map.on('click', this.layerName, this.handleClickMarkerEvent)

      // Cursor
      this.$store.map.on('mouseenter', this.layerName, this.showPointer)
      this.$store.map.on('mouseleave', this.layerName, this.hidePointer)
    },
    disable() {
      this.$store.map.off('click', this.layerName, this.handleClickMarkerEvent)

      // Cursor
      this.$store.map.off('mouseenter', this.layerName, this.showPointer)
      this.$store.map.off('mouseleave', this.layerName, this.hidePointer)
    },
    /**
     * Mouse hover effects
     */
    showPointer() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.$store.map.getCanvas().style.cursor = ''
    },
    /**
     * Load the clicked point's properties
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      let feature = e.features[0]
      if (feature) {

        this.show = false

        this.$nextTick(() => {
          this.coordinates = feature.geometry.coordinates
          this.data = feature.properties

          this.show = true
        })
      }
    },
    /**
     * Upon the close event of the popup
     */
    handleClose() {
      this.show = false
    },
  },
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 20px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>
