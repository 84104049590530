<template>
  <div v-if="visible">
    <TruckParkingsLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
    />
    <TruckParkingsSecuredLayer
      :loaded="loaded"
      :layer-visibility="layerVisibility"
    />
  </div>
</template>

<script>
import TruckParkingsSecuredLayer from '@/components/map/national/TruckParkingsSecuredLayer'
import TruckParkingsLayer from '@/components/map/national/TruckParkingsLayer'

import userMixin from '@/mixins/common/userMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'TruckParkingsBundle',
  components: {
    TruckParkingsSecuredLayer, TruckParkingsLayer,
  },
  mixins: [userMixin],
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layerName: 'truckparkings_bundle',
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'DeploymentLayerName',
    ]),
    ...mapGetters('access', [
      'canAccessLayer',
    ]),
    ...mapGetters('layers', [
      'getActiveLayers',
    ]),
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
    visible() {
      if (! this.sourceName) return false
      if (! this.canAccessLayer({ id: this.layerName })) {
        return false
      }

      return true
    },
    bundleLayer() {
      return this.getActiveLayers.find(layer => layer.id === this.layerName)
    },
    isPrimaryBundleLayer () {
      return this.bundleLayer?.status === 'primary'
    },
    layerVisibility () {
      return this.isPrimaryBundleLayer && this.bundleLayer?.visible
    },
  },
}
</script>

