<template>
  <tr :class="{ 'TileData__highlighted': highlight }">
    <td>
      <slot name="first-column" />
    </td>
    <td class="text-center">
      <slot name="second-column" />
    </td>
    <td class="text-center">
      <slot name="third-column" />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    highlight: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
