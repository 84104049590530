<template>
  <div
    class="d-inline-flex ml-1 cursor-pointer"
    title="Vlieg naar dit adres"
  >
    <SvgIcon
      icon="crosshairs-solid"
      @click.native="flyTo"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon.vue'

export default {
  components: { SvgIcon },
  props: {
    coordinates: {
      type: Array,
      required: true,
    },
  },
  methods: {
    flyTo() {
      this.$store.map.flyTo({
        center: this.coordinates,
        zoom: 16,
      })
    },
  },
}
</script>

<style>
.cursor-pointer {
    cursor: pointer;
}
</style>
