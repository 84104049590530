import store from '@/store'

const termsGuard = (to, from, next) => {
  const hasAccepted = store.getters['currentUser/hasAcceptedTerms']
  if (hasAccepted || to.name === 'Terms') {
    next()
  } else {
    next({ name: 'Terms', query: { redirect: to.fullPath } })
  }
}

export default termsGuard
