<template>
  <div class="MenuGenerator">
    <template v-for="(item,index) in _menu">
      <MenuGeneratorLink
        v-if="item.type === 'link'"
        :key="`link_${index}`"
        :item="item"
      />
      <MenuGeneratorSegment
        v-else-if="item.type === 'segment'"
        :key="`segment_${index}`"
        :item="item"
        :depth="1"
      />
    </template>
  </div>
</template>

<script>

import MenuGeneratorLink from '@/components/manual/MenuGeneratorLink.vue'
import MenuGeneratorSegment from '@/components/manual/MenuGeneratorSegment.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'MenuGenerator',
  components: {
    MenuGeneratorLink,
    MenuGeneratorSegment,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // ...mapGetters('user', [
    //   'securityCheck'
    // ]),
    ...mapGetters('currentUser', [
      'isEditor',
      'isAdmin',
      'isSuperuser',
    ]),
    _menu() {
      return this.menu.reduce(this.checkAccess, [])
    },
  },
  methods: {
    securityCheck({ attribute }) {
      return attribute === 'manuals' && true
    },
    /**
     * Check access to menu items.
     */
    checkAccess(menu, menuitem) {

      // Ignore item if outside of access
      if (
        menuitem.securityCheck
        && ! this.securityCheck({ attribute: menuitem.securityCheck })
      ) {
        return menu
      }

      if (menuitem.type === 'segment') {
        menuitem.items = (menuitem.items || []).reduce(this.checkAccess, [])

        // Ignore empty segments
        if (menuitem.items.length === 0) {
          return menu
        }
      }

      menu.push(menuitem)

      return menu
    },
  },
}
</script>

<style>

</style>
