<template>
  <div
    class="LocatieInformatie__Attachments__Image"
    @dragenter.stop.prevent="$emit('dragenter')"
    @dragover.stop.prevent="() => {}"
    @dragleave.stop.prevent="$emit('dragleave')"
    @drop.stop.prevent="$emit('drop', $event, attachment.id)"
  >
    {{ $t(description) }}
    <div class="ratio ratio-16x9">
      <div
        class="inner"
        title="Klik om een afbeelding te kiezen"
      >
        <div
          v-if="attachment.url"
          class="LocatieInformatie__Attachments__Image__Delete"
          title="Verwijder afbeelding"
          @click.stop="$emit('remove', attachment.id)"
        >
          <SvgIcon icon="times-circle-solid" />
        </div>

        <label :for="`${attachment.id}-view-image`">
          <img :src="thumbnail">
        </label>
      </div>
      <input
        :id="`${attachment.id}-view-image`"
        type="file"
        hidden
        @change="$emit('change', $event, attachment.id)"
      >
    </div>
    <b-form-checkbox
      v-if="attachment.changed & !! attachment.file"
      :id="`${attachment.id}-view-image-edited`"
      v-model="hasBeenEdited"
      :name="`${attachment.id}-view-image-edited`"
    >
      ingetekend
    </b-form-checkbox>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'
import SvgIcon from '@/components/common/SvgIcon'
import { attachmentIDToDescription } from '@/../shared/valueholders/attachments'

export default {
  components: { SvgIcon },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description() {
      return attachmentIDToDescription({ id: this.attachment.id })
    },
    placeholderImage() {
      return image({ name: 'no-image-placeholder.png' })
    },
    thumbnail() {
      if (this.attachment.url) {
        return this.attachment.url
      }

      return this.placeholderImage
    },
    hasBeenEdited: {
      get() {
        return this.attachment.edited
      },
      set(hasBeenEdited) {
        this.$emit('on-edit', hasBeenEdited)
      },
    },
  },
}
</script>
