<template>
  <div class="LeftSideBar d-flex flex-column flex-shrink-0 h-100">
    <div class="LeftSideBar__main d-flex flex-column p-4 flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftSideBar',
}
</script>

<style lang="scss">
.LeftSideBar {
  position: relative;
  background: white;
  box-shadow: -1px 0px 0px 0px rgba(223,226,229,1) inset;
  user-select: none;
  color: #7F8FA4;
  max-height: 100%;

  &__main {
    // overflow-y: scroll;
    flex-shrink: 1;
  }

  p {
    font-size: 14px
  }
}
</style>
