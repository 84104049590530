<template>
  <div class="ColumnChart">
    <GChart
      type="ColumnChart"
      :settings="{ packages: ['corechart', 'table'], language: 'nl' }"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { numberWithDots } from '@/helpers/number'
import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'ColumnChart',
  components: { GChart },
  props: {
    data: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Laadpunt behoefte',
        },
        legend: { position: 'right', textStyle: { color: labelColor } },
        hAxis: { textStyle: { bold: true, color: labelColor } },
        vAxis: { format: '#.###' },
        bar: { groupWidth: '80%' },
        colors: [getCssProperty('primary')],
        height: 237,
        width: 475,
        chartArea: { left: 50, right: 125 },
        focusTarget: 'category',
        animation: {
          duration: 500,
        },
      },
    }
  },
  computed: {
    chartData() {
      const chartSerieLabels = [
        'year',
        'semiDemand',
        { role: 'annotation' },
        // 'Private laadpunten', { role: 'annotation' }
      ]
      const translatedLabels = chartSerieLabels.map((label, idx) => {
        if (idx <= 1) {
          return this.$i18n.t(
            `components.factsheets.evmaps.ColumnChart.${label}`,
          )
        }
        return label
      })

      const data = [translatedLabels]

      this.data.forEach((entry) => {
        data.push([
          entry[0],
          entry[1],
          entry[1] === 0 ? null : numberWithDots(entry[1]),
          // entry[2],
          // entry[2] === 0 ? null : numberWithDots(entry[2])
        ])
      })

      return data
    },
  },
}
</script>

<style></style>
