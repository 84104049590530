<template>
  <div class="PlanModeSearch d-flex flex-column flex-grow-1 flex-shrink-1">
    <PlanModeFilterOverlay :show.sync="show" />

    <div class="PlanModeSearch__header p-4">
      <Fuse
        :list="list"
        :options="fuseOptions"
        :placeholder="$t('components.sidebar.PlanModeSearch.filterSearch')"
        @resultsChanged="handleResultsChanged"
        @enterButton="selectFirstChargingpoint"
      />

      <span
        class="PlanModeSearch__filter"
        @click="showFilter"
      >
        <SvgIcon icon="filter-regular" />
      </span>

      <PlanModeFilterTags />
    </div>

    <b-button-group
      v-if="isParticipationEnabled"
      size="sm"
      class="mb-2 mx-4"
    >
      <b-button
        :pressed="!showParticipationComments"
        variant="outline-primary"
        class="w-50"
        @click="showParticipationComments = !showParticipationComments"
      >
        <BIconHouseFill class="mr-1" />
        {{ $t('address.title') }}
      </b-button>
      <b-button
        :pressed.sync="showParticipationComments"
        class="w-50"
        variant="outline-primary"
      >
        <BIconPeopleFill class="mr-1" />
        {{ $t('components.sidebar.participationComments') }}
      </b-button>
    </b-button-group>

    <div class="border-top px-3 py-1 m-0 pl-4 ">
      <SvgIcon
        icon="eye-solid"
        class="d-inline-block mr-1 mt-1 color-primary"
      /> {{ results.length }} {{ $t('of') }} {{ charginpointsTotalCount }}
    </div>

    <ul
      v-if="results.length"
      class="SearchResults py-3"
    >
      <PlanModeSearchItem
        v-for="(chargingpoint, index) in results"
        :key="`${index}-${chargingpoint.refId}`"
        :chargingpoint="chargingpoint"
        :show-participation-comments="showParticipationComments"
        @select-chargingpoint="handleSelectChargingpoint"
      />
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { BIconHouseFill, BIconPeopleFill } from 'bootstrap-vue'

import Fuse from '@/components/common/Fuse'
import SvgIcon from '@/components/common/SvgIcon'

import userMixin from '@/mixins/common/userMixin'
import privilegesMixin from '@/mixins/common/privilegesMixin'
import planmodeFilterMixin from '@/mixins/planmode/filterMixin'

import PlanModeFilterOverlay from '@/components/sidebar/PlanModeFilterOverlay'
import PlanModeFilterTags from '@/components/sidebar/PlanModeFilterTags'
import PlanModeSearchItem from '@/components/sidebar/PlanModeSearchItem'
import { ACTION_SIDEBAR_TAB } from '@/../shared/valueholders/action-sidebar-tabs'
import { EventBus } from '@/services/eventbus'
import { formatSimpleAddress } from '@/../shared/helpers/address'

export default {
  name: 'PlanModeSearch',
  components: {
    BIconHouseFill,
    BIconPeopleFill,
    PlanModeFilterTags,
    PlanModeFilterOverlay,
    PlanModeSearchItem,
    Fuse,
    SvgIcon,
  },
  mixins: [userMixin, privilegesMixin, planmodeFilterMixin],
  data() {
    return {
      /**
       * Fuse.js options
       */
      fuseOptions: {
        threshold: 0.2,
        keys: ['id', 'remark', 'statusLabel', 'address', 'evse', 'uuid'],
      },
      /**
       * Generated by Fuse
       */
      results: [],
      /**
       * Priority filter
       */
      show: false,
      showParticipationComments: false,
    }
  },
  computed: {
    ...mapGetters('access', [
      'getActiveMunicipality',
    ]),
    ...mapGetters('config', ['isParticipationEnabled']),
    ...mapGetters('planmode', [
      'getChargingPoints',
      'getFilteredChargingpoints',
    ]),
    /**
     * Map chargingpoint objects to searchable items
     */
    list() {
      return this.getFilteredChargingpoints
        .map((chargingpoint) => {
          const address = chargingpoint.data.address

          return {
            id: chargingpoint.data.properties.id,
            uuid: chargingpoint.data.uuid,
            refId: chargingpoint.ref['@ref'].id,
            status: chargingpoint.data.properties.status,
            statusLabel: this.$i18n.t(`participationPanel.options.statusStations.regularLabels.${chargingpoint.data.properties.status}`),
            remark: chargingpoint.data.properties.remark,
            coordinates: chargingpoint.data.coordinates,
            evse: chargingpoint.data.properties.evse,
            address: chargingpoint.data.address
                ? chargingpoint.data.address.simple_address || formatSimpleAddress({ address })
                : '',
          }
        })
        .sort((a, b) => (a.id < b.id ? -1 : 1))
    },
    charginpointsTotalCount() {
      return this.getChargingPoints.length
    },
  },
  watch: {
    getActiveMunicipality() {
      this.show = false
    },
    showParticipationComments(showParticipationComments) {
      if (showParticipationComments) {
        this.enableParticipationCommentsFilter()
      } else {
        this.disableParticipationCommentsFilter()
      }
    },
  },
  methods: {
    ...mapMutations('filters', ['enableParticipationCommentsFilter', 'disableParticipationCommentsFilter']),
    ...mapActions('planmode', ['selectChargingPoint']),
    ...mapActions('app', ['selectTab']),

    showFilter() {
      this.show = !this.show
    },

    /**
     * Handle results from Fuse.js
     */
    handleResultsChanged({ results }) {
      this.results = results.map((result) => result.item)
    },

    /**
     * if enter button is pressed, select first result
     */
    handleSelectChargingpoint({ chargingpoint }) {
      if (this.$store.map) {
        this.$store.map.flyTo({
          center: chargingpoint.coordinates,
          zoom: 16,
        })
      }

      if (this.showParticipationComments) {
        this.selectTab({ tabName: ACTION_SIDEBAR_TAB.PARTICIPATION })
      }

      EventBus.$emit('select-chargingpoint', chargingpoint.refId)
    },
    selectFirstChargingpoint() {
      if (! this.results[0]) {
        return
      }

      this.handleSelectChargingpoint({ chargingpoint: this.results[0] })
    },
  },
}
</script>

<style lang="scss">


$hover-color: #4f5c6e;

.color-primary {
  color: var(--primary)
}

.PlanModeSearch {
  max-height: calc(100% - 49px);
  overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }

  &__filter {
    font-size: 1.3rem;
    line-height: 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 0 0.4em;
    display: flex;
    align-items: center;

    &:hover {
      color: $hover-color;
      border: 1px solid black;
      box-shadow: inset 0 0 0 1px black;
    }
  }
  .SearchResults {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    max-height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
    border-top: 1px solid var(--grey-darker-10);
  }
}
</style>
