import { mapGetters } from 'vuex'

// todo:: import getter in components instead and stop using mixin
export default {
  computed: {
    ...mapGetters('currentUser', {
      superuser: 'isSuperuser',
      admin: 'isAdmin',
      isEditor: 'isEditor',
    }),
  },
}
