import { numberWithDots } from '@/helpers/number'
import { mapGetters } from 'vuex'

export default  {
  filters: {
    numberWithDots,
  },
  props: {
    fids: {
      type: Array,
      required: true,
    },
    dataById: {
      type: Object,
      required: true,
    },
    forecastLabel: {
      type: String,
      required: false,
      default: () => '',
    },
    dataset: {
      type: Array,
      required: false,
      default: () => [],
    },
    isProvince: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('scenarios', [
      'year',
      'forecast',
    ]),
  },
}
