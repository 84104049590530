<template>
  <div class="Factsheet__Controls d-flex align-items-bottom">
    <slot />
    <div class="d-flex ml-auto">
      <slot name="right" />
      <b-button
        :disabled="downloadDisabled"
        variant="cta"
        class="PrintBtn m-3 ml-auto"
        @click="handlePrint"
      >
        Opslaan als PDF
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pdfTitle: {
      type: String,
      required: true,
    },
    downloadDisabled: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async handlePrint() {
      // Dynamically import jspdf and html2canvas
      const { default: jsPDF } = await import('jspdf')
      const { default: html2canvas } = await import('html2canvas')

      const factsheet = this.$parent.$refs.factsheet
      if (!factsheet) {
        return
      }

      html2canvas(factsheet.$el, { scale: 5 }).then(canvas => {
        const context = canvas.getContext('2d')
        context['imageSmoothingEnabled'] = false /* standard */
        context['mozImageSmoothingEnabled'] = false /* Firefox */
        context['oImageSmoothingEnabled'] = false /* Opera */
        context['webkitImageSmoothingEnabled'] = false /* Safari */
        context['msImageSmoothingEnabled'] = false /* IE */

        const doc = new jsPDF({ orientation: 'landscape' })
        const width = Math.floor(doc.internal.pageSize.getWidth())
        const height = Math.floor(doc.internal.pageSize.getHeight())
        doc.addImage(canvas, 'JPEG', 0, 0, width, height)
        doc.save(this.pdfTitle)
      })
    },
  },
}
</script>

<style lang="scss">
.Factsheet__Controls {
  height: 70px;
  width: 29.7cm;
  margin: auto;

  .PrintBtn {
    top: 1rem;
    right: 1rem;
  }
}
</style>
