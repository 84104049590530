
/**
 * Export format to support Node.js scripts
 */
module.exports = {
  deprecatedCodesByDeployment: {
    nl: [
      '0756',
      '1702',
      '1685',
      '0856',
      '0398',
      '0416',
      '0370',
      '9000',
    ],
    be: [],
  },
  gemeentenByDeployment: {
    nl: [
      { code: '1680', label: 'Aa en Hunze', provincie: 22, slug: 'aa-en-hunze' },
      { code: '0358', label: 'Aalsmeer', provincie: 27, slug: 'aalsmeer' },
      { code: '0197', label: 'Aalten', provincie: 25, slug: 'aalten' },
      { code: '0059', label: 'Achtkarspelen', provincie: 21, slug: 'achtkarspelen' },
      { code: '0482', label: 'Alblasserdam', provincie: 28, slug: 'alblasserdam' },
      { code: '0613', label: 'Albrandswaard', provincie: 28, slug: 'albrandswaard' },
      { code: '0361', label: 'Alkmaar', provincie: 27, slug: 'alkmaar' },
      { code: '0141', label: 'Almelo', provincie: 23, slug: 'almelo' },
      { code: '0034', label: 'Almere', provincie: 24, slug: 'almere' },
      { code: '0484', label: 'Alphen aan den Rijn', provincie: 28, slug: 'alphen-aan-den-rijn' },
      { code: '1723', label: 'Alphen-Chaam', provincie: 30, slug: 'alphen-chaam' },
      { code: '1959', label: 'Altena', provincie: 30, slug: 'altena' },
      { code: '0060', label: 'Ameland', provincie: 21, slug: 'ameland' },
      { code: '0307', label: 'Amersfoort', provincie: 26, slug: 'amersfoort' },
      { code: '0362', label: 'Amstelveen', provincie: 27, slug: 'amstelveen' },
      { code: '0363', label: 'Amsterdam', provincie: 27, slug: 'amsterdam' },
      { code: '0200', label: 'Apeldoorn', provincie: 25, slug: 'apeldoorn' },
      { code: '0202', label: 'Arnhem', provincie: 25, slug: 'arnhem' },
      { code: '0106', label: 'Assen', provincie: 22, slug: 'assen' },
      { code: '0743', label: 'Asten', provincie: 30, slug: 'asten' },
      { code: '0744', label: 'Baarle-Nassau', provincie: 30, slug: 'baarle-nassau' },
      { code: '0308', label: 'Baarn', provincie: 26, slug: 'baarn' },
      { code: '0489', label: 'Barendrecht', provincie: 28, slug: 'barendrecht' },
      { code: '0203', label: 'Barneveld', provincie: 25, slug: 'barneveld' },
      { code: '0888', label: 'Beek', provincie: 31, slug: 'beek' },
      { code: '1954', label: 'Beekdaelen', provincie: 31, slug: 'beekdaelen' },
      { code: '0889', label: 'Beesel', provincie: 31, slug: 'beesel' },
      { code: '1945', label: 'Berg en Dal', provincie: 25, slug: 'berg-en-dal' },
      { code: '1724', label: 'Bergeijk', provincie: 30, slug: 'bergeijk' },
      { code: '0893', label: 'Bergen (L.)', provincie: 31, slug: 'bergen-limburg' },
      { code: '0373', label: 'Bergen (NH.)', provincie: 27, slug: 'bergen-noord-holland' },
      { code: '0748', label: 'Bergen op Zoom', provincie: 30, slug: 'bergen-op-zoom' },
      { code: '1859', label: 'Berkelland', provincie: 25, slug: 'berkelland' },
      { code: '1721', label: 'Bernheze', provincie: 30, slug: 'bernheze' },
      { code: '0753', label: 'Best', provincie: 30, slug: 'best' },
      { code: '0209', label: 'Beuningen', provincie: 25, slug: 'beuningen' },
      { code: '0375', label: 'Beverwijk', provincie: 27, slug: 'beverwijk' },
      { code: '0310', label: 'De Bilt', provincie: 26, slug: 'de-bilt' },
      { code: '1728', label: 'Bladel', provincie: 30, slug: 'bladel' },
      { code: '0376', label: 'Blaricum', provincie: 27, slug: 'blaricum' },
      { code: '0377', label: 'Bloemendaal', provincie: 27, slug: 'bloemendaal' },
      { code: '1901', label: 'Bodegraven-Reeuwijk', provincie: 28, slug: 'bodegraven-reeuwijk' },
      { code: '0755', label: 'Boekel', provincie: 30, slug: 'boekel' },
      { code: '1681', label: 'Borger-Odoorn', provincie: 22, slug: 'borger-odoorn' },
      { code: '0147', label: 'Borne', provincie: 23, slug: 'borne' },
      { code: '0654', label: 'Borsele', provincie: 29, slug: 'borsele' },
      { code: '0757', label: 'Boxtel', provincie: 30, slug: 'boxtel' },
      { code: '0758', label: 'Breda', provincie: 30, slug: 'breda' },
      { code: '1876', label: 'Bronckhorst', provincie: 25, slug: 'bronckhorst' },
      { code: '0213', label: 'Brummen', provincie: 25, slug: 'brummen' },
      { code: '0899', label: 'Brunssum', provincie: 31, slug: 'brunssum' },
      { code: '0312', label: 'Bunnik', provincie: 26, slug: 'bunnik' },
      { code: '0313', label: 'Bunschoten', provincie: 26, slug: 'bunschoten' },
      { code: '0214', label: 'Buren', provincie: 25, slug: 'buren' },
      { code: '0502', label: 'Capelle aan den IJssel', provincie: 28, slug: 'capelle-aan-den-ijssel' },
      { code: '0383', label: 'Castricum', provincie: 27, slug: 'castricum' },
      { code: '0109', label: 'Coevorden', provincie: 22, slug: 'coevorden' },
      { code: '1706', label: 'Cranendonck', provincie: 30, slug: 'cranendonck' },
      { code: '0216', label: 'Culemborg', provincie: 25, slug: 'culemborg' },
      { code: '0148', label: 'Dalfsen', provincie: 23, slug: 'dalfsen' },
      { code: '1891', label: 'Dantumadiel', provincie: 21, slug: 'dantumadiel' },
      { code: '0503', label: 'Delft', provincie: 28, slug: 'delft' },
      { code: '0762', label: 'Deurne', provincie: 30, slug: 'deurne' },
      { code: '0150', label: 'Deventer', provincie: 23, slug: 'deventer' },
      { code: '0384', label: 'Diemen', provincie: 27, slug: 'diemen' },
      { code: '1774', label: 'Dinkelland', provincie: 23, slug: 'dinkelland' },
      { code: '0221', label: 'Doesburg', provincie: 25, slug: 'doesburg' },
      { code: '0222', label: 'Doetinchem', provincie: 25, slug: 'doetinchem' },
      { code: '0766', label: 'Dongen', provincie: 30, slug: 'dongen' },
      { code: '0505', label: 'Dordrecht', provincie: 28, slug: 'dordrecht' },
      { code: '0498', label: 'Drechterland', provincie: 27, slug: 'drechterland' },
      { code: '1719', label: 'Drimmelen', provincie: 30, slug: 'drimmelen' },
      { code: '0303', label: 'Dronten', provincie: 24, slug: 'dronten' },
      { code: '0225', label: 'Druten', provincie: 25, slug: 'druten' },
      { code: '0226', label: 'Duiven', provincie: 25, slug: 'duiven' },
      { code: '1711', label: 'Echt-Susteren', provincie: 31, slug: 'echt-susteren' },
      { code: '0385', label: 'Edam-Volendam', provincie: 27, slug: 'edam-volendam' },
      { code: '0228', label: 'Ede', provincie: 25, slug: 'ede' },
      { code: '0317', label: 'Eemnes', provincie: 26, slug: 'eemnes' },
      { code: '1979', label: 'Eemsdelta', provincie: 20, slug: 'eemsdelta' },
      { code: '0770', label: 'Eersel', provincie: 30, slug: 'eersel' },
      { code: '1903', label: 'Eijsden-Margraten', provincie: 31, slug: 'eijsden-margraten' },
      { code: '0772', label: 'Eindhoven', provincie: 30, slug: 'eindhoven' },
      { code: '0230', label: 'Elburg', provincie: 25, slug: 'elburg' },
      { code: '0114', label: 'Emmen', provincie: 22, slug: 'emmen' },
      { code: '0388', label: 'Enkhuizen', provincie: 27, slug: 'enkhuizen' },
      { code: '0153', label: 'Enschede', provincie: 23, slug: 'enschede' },
      { code: '0232', label: 'Epe', provincie: 25, slug: 'epe' },
      { code: '0233', label: 'Ermelo', provincie: 25, slug: 'ermelo' },
      { code: '0777', label: 'Etten-Leur', provincie: 30, slug: 'etten-leur' },
      { code: '1940', label: 'De Fryske Marren', provincie: 21, slug: 'de-fryske-marren' },
      { code: '0779', label: 'Geertruidenberg', provincie: 30, slug: 'geertruidenberg' },
      { code: '1771', label: 'Geldrop-Mierlo', provincie: 30, slug: 'geldrop-mierlo' },
      { code: '1652', label: 'Gemert-Bakel', provincie: 30, slug: 'gemert-bakel' },
      { code: '0907', label: 'Gennep', provincie: 31, slug: 'gennep' },
      { code: '0784', label: 'Gilze en Rijen', provincie: 30, slug: 'gilze-en-rijen' },
      { code: '1924', label: 'Goeree-Overflakkee', provincie: 28, slug: 'goeree-overflakkee' },
      { code: '0664', label: 'Goes', provincie: 29, slug: 'goes' },
      { code: '0785', label: 'Goirle', provincie: 30, slug: 'goirle' },
      { code: '1942', label: 'Gooise Meren', provincie: 27, slug: 'gooise-meren' },
      { code: '0512', label: 'Gorinchem', provincie: 28, slug: 'gorinchem' },
      { code: '0513', label: 'Gouda', provincie: 28, slug: 'gouda' },
      { code: '0518', label: '\'s-Gravenhage', provincie: 28, slug: 's-gravenhage' },
      { code: '0014', label: 'Groningen', provincie: 20, slug: 'groningen' },
      { code: '1729', label: 'Gulpen-Wittem', provincie: 31, slug: 'gulpen-wittem' },
      { code: '0158', label: 'Haaksbergen', provincie: 23, slug: 'haaksbergen' },
      { code: '0788', label: 'Haaren', provincie: 30, slug: 'haaren' },
      { code: '0392', label: 'Haarlem', provincie: 27, slug: 'haarlem' },
      { code: '0394', label: 'Haarlemmermeer', provincie: 27, slug: 'haarlemmermeer' },
      { code: '1655', label: 'Halderberge', provincie: 30, slug: 'halderberge' },
      { code: '0160', label: 'Hardenberg', provincie: 23, slug: 'hardenberg' },
      { code: '0243', label: 'Harderwijk', provincie: 25, slug: 'harderwijk' },
      { code: '0523', label: 'Hardinxveld-Giessendam', provincie: 28, slug: 'hardinxveld-giessendam' },
      { code: '0072', label: 'Harlingen', provincie: 21, slug: 'harlingen' },
      { code: '0244', label: 'Hattem', provincie: 25, slug: 'hattem' },
      { code: '0396', label: 'Heemskerk', provincie: 27, slug: 'heemskerk' },
      { code: '0397', label: 'Heemstede', provincie: 27, slug: 'heemstede' },
      { code: '0246', label: 'Heerde', provincie: 25, slug: 'heerde' },
      { code: '0074', label: 'Heerenveen', provincie: 21, slug: 'heerenveen' },
      { code: '0917', label: 'Heerlen', provincie: 31, slug: 'heerlen' },
      { code: '1658', label: 'Heeze-Leende', provincie: 30, slug: 'heeze-leende' },
      { code: '0399', label: 'Heiloo', provincie: 27, slug: 'heiloo' },
      { code: '0400', label: 'Den Helder', provincie: 27, slug: 'den-helder' },
      { code: '0163', label: 'Hellendoorn', provincie: 23, slug: 'hellendoorn' },
      { code: '0794', label: 'Helmond', provincie: 30, slug: 'helmond' },
      { code: '0531', label: 'Hendrik-Ido-Ambacht', provincie: 28, slug: 'hendrik-ido-ambacht' },
      { code: '0164', label: 'Hengelo', provincie: 23, slug: 'hengelo' },
      { code: '0796', label: '\'s-Hertogenbosch', provincie: 30, slug: 's-hertogenbosch' },
      { code: '0252', label: 'Heumen', provincie: 25, slug: 'heumen' },
      { code: '0797', label: 'Heusden', provincie: 30, slug: 'heusden' },
      { code: '0534', label: 'Hillegom', provincie: 28, slug: 'hillegom' },
      { code: '0798', label: 'Hilvarenbeek', provincie: 30, slug: 'hilvarenbeek' },
      { code: '0402', label: 'Hilversum', provincie: 27, slug: 'hilversum' },
      { code: '1963', label: 'Hoeksche Waard', provincie: 28, slug: 'hoeksche-waard' },
      { code: '1735', label: 'Hof van Twente', provincie: 23, slug: 'hof-van-twente' },
      { code: '1966', label: 'Het Hogeland', provincie: 20, slug: 'het-hogeland' },
      { code: '1911', label: 'Hollands Kroon', provincie: 27, slug: 'hollands-kroon' },
      { code: '0118', label: 'Hoogeveen', provincie: 22, slug: 'hoogeveen' },
      { code: '0405', label: 'Hoorn', provincie: 27, slug: 'hoorn' },
      { code: '1507', label: 'Horst aan de Maas', provincie: 31, slug: 'horst-aan-de-maas' },
      { code: '0321', label: 'Houten', provincie: 26, slug: 'houten' },
      { code: '0406', label: 'Huizen', provincie: 27, slug: 'huizen' },
      { code: '0677', label: 'Hulst', provincie: 29, slug: 'hulst' },
      { code: '0353', label: 'IJsselstein', provincie: 26, slug: 'ijsselstein' },
      { code: '1884', label: 'Kaag en Braassem', provincie: 28, slug: 'kaag-en-braassem' },
      { code: '0166', label: 'Kampen', provincie: 23, slug: 'kampen' },
      { code: '0678', label: 'Kapelle', provincie: 29, slug: 'kapelle' },
      { code: '0537', label: 'Katwijk', provincie: 28, slug: 'katwijk' },
      { code: '0928', label: 'Kerkrade', provincie: 31, slug: 'kerkrade' },
      { code: '1598', label: 'Koggenland', provincie: 27, slug: 'koggenland' },
      { code: '0542', label: 'Krimpen aan den IJssel', provincie: 28, slug: 'krimpen-aan-den-ijssel' },
      { code: '1931', label: 'Krimpenerwaard', provincie: 28, slug: 'krimpenerwaard' },
      { code: '1659', label: 'Laarbeek', provincie: 30, slug: 'laarbeek' },
      { code: '0882', label: 'Landgraaf', provincie: 31, slug: 'landgraaf' },
      { code: '0415', label: 'Landsmeer', provincie: 27, slug: 'landsmeer' },
      { code: '1980', label: 'Dijk en Waard', provincie: 27, slug: 'dijk-en-waard' },
      { code: '1621', label: 'Lansingerland', provincie: 28, slug: 'lansingerland' },
      { code: '0417', label: 'Laren', provincie: 27, slug: 'laren' },
      { code: '0080', label: 'Leeuwarden', provincie: 21, slug: 'leeuwarden' },
      { code: '0546', label: 'Leiden', provincie: 28, slug: 'leiden' },
      { code: '0547', label: 'Leiderdorp', provincie: 28, slug: 'leiderdorp' },
      { code: '1916', label: 'Leidschendam-Voorburg', provincie: 28, slug: 'leidschendam-voorburg' },
      { code: '0995', label: 'Lelystad', provincie: 24, slug: 'lelystad' },
      { code: '1640', label: 'Leudal', provincie: 31, slug: 'leudal' },
      { code: '0327', label: 'Leusden', provincie: 26, slug: 'leusden' },
      { code: '1705', label: 'Lingewaard', provincie: 25, slug: 'lingewaard' },
      { code: '0553', label: 'Lisse', provincie: 28, slug: 'lisse' },
      { code: '0262', label: 'Lochem', provincie: 25, slug: 'lochem' },
      { code: '0809', label: 'Loon op Zand', provincie: 30, slug: 'loon-op-zand' },
      { code: '0331', label: 'Lopik', provincie: 26, slug: 'lopik' },
      { code: '0168', label: 'Losser', provincie: 23, slug: 'losser' },
      { code: '0263', label: 'Maasdriel', provincie: 25, slug: 'maasdriel' },
      { code: '1641', label: 'Maasgouw', provincie: 31, slug: 'maasgouw' },
      { code: '0556', label: 'Maassluis', provincie: 28, slug: 'maassluis' },
      { code: '0935', label: 'Maastricht', provincie: 31, slug: 'maastricht' },
      { code: '0420', label: 'Medemblik', provincie: 27, slug: 'medemblik' },
      { code: '0938', label: 'Meerssen', provincie: 31, slug: 'meerssen' },
      { code: '1948', label: 'Meierijstad', provincie: 30, slug: 'meierijstad' },
      { code: '0119', label: 'Meppel', provincie: 22, slug: 'meppel' },
      { code: '0687', label: 'Middelburg', provincie: 29, slug: 'middelburg' },
      { code: '1842', label: 'Midden-Delfland', provincie: 28, slug: 'midden-delfland' },
      { code: '1731', label: 'Midden-Drenthe', provincie: 22, slug: 'midden-drenthe' },
      { code: '1952', label: 'Midden-Groningen', provincie: 20, slug: 'midden-groningen' },
      { code: '1709', label: 'Moerdijk', provincie: 30, slug: 'moerdijk' },
      { code: '1978', label: 'Molenlanden', provincie: 28, slug: 'molenlanden' },
      { code: '1955', label: 'Montferland', provincie: 25, slug: 'montferland' },
      { code: '0335', label: 'Montfoort', provincie: 26, slug: 'montfoort' },
      { code: '0944', label: 'Mook en Middelaar', provincie: 31, slug: 'mook-en-middelaar' },
      { code: '1740', label: 'Neder-Betuwe', provincie: 25, slug: 'neder-betuwe' },
      { code: '0946', label: 'Nederweert', provincie: 31, slug: 'nederweert' },
      { code: '0356', label: 'Nieuwegein', provincie: 26, slug: 'nieuwegein' },
      { code: '0569', label: 'Nieuwkoop', provincie: 28, slug: 'nieuwkoop' },
      { code: '0267', label: 'Nijkerk', provincie: 25, slug: 'nijkerk' },
      { code: '0268', label: 'Nijmegen', provincie: 25, slug: 'nijmegen' },
      { code: '1930', label: 'Nissewaard', provincie: 28, slug: 'nissewaard' },
      { code: '1970', label: 'Noardeast-Fryslân', provincie: 21, slug: 'noardeast-fryslan' },
      { code: '1695', label: 'Noord-Beveland', provincie: 29, slug: 'noord-beveland' },
      { code: '1699', label: 'Noordenveld', provincie: 22, slug: 'noordenveld' },
      { code: '0171', label: 'Noordoostpolder', provincie: 24, slug: 'noordoostpolder' },
      { code: '0575', label: 'Noordwijk', provincie: 28, slug: 'noordwijk' },
      { code: '0820', label: 'Nuenen, Gerwen en Nederwetten', provincie: 30, slug: 'nuenen-gerwen-en-nederwetten' },
      { code: '0302', label: 'Nunspeet', provincie: 25, slug: 'nunspeet' },
      { code: '0579', label: 'Oegstgeest', provincie: 28, slug: 'oegstgeest' },
      { code: '0823', label: 'Oirschot', provincie: 30, slug: 'oirschot' },
      { code: '0824', label: 'Oisterwijk', provincie: 30, slug: 'oisterwijk' },
      { code: '1895', label: 'Oldambt', provincie: 20, slug: 'oldambt' },
      { code: '0269', label: 'Oldebroek', provincie: 25, slug: 'oldebroek' },
      { code: '0173', label: 'Oldenzaal', provincie: 23, slug: 'oldenzaal' },
      { code: '1773', label: 'Olst-Wijhe', provincie: 23, slug: 'olst-wijhe' },
      { code: '0175', label: 'Ommen', provincie: 23, slug: 'ommen' },
      { code: '1586', label: 'Oost Gelre', provincie: 25, slug: 'oost-gelre' },
      { code: '0826', label: 'Oosterhout', provincie: 30, slug: 'oosterhout' },
      { code: '0085', label: 'Ooststellingwerf', provincie: 21, slug: 'ooststellingwerf' },
      { code: '0431', label: 'Oostzaan', provincie: 27, slug: 'oostzaan' },
      { code: '0432', label: 'Opmeer', provincie: 27, slug: 'opmeer' },
      { code: '0086', label: 'Opsterland', provincie: 21, slug: 'opsterland' },
      { code: '0828', label: 'Oss', provincie: 30, slug: 'oss' },
      { code: '1509', label: 'Oude IJsselstreek', provincie: 25, slug: 'oude-ijsselstreek' },
      { code: '0437', label: 'Ouder-Amstel', provincie: 27, slug: 'ouder-amstel' },
      { code: '0589', label: 'Oudewater', provincie: 26, slug: 'oudewater' },
      { code: '1734', label: 'Overbetuwe', provincie: 25, slug: 'overbetuwe' },
      { code: '0590', label: 'Papendrecht', provincie: 28, slug: 'papendrecht' },
      { code: '1894', label: 'Peel en Maas', provincie: 31, slug: 'peel-en-maas' },
      { code: '0765', label: 'Pekela', provincie: 20, slug: 'pekela' },
      { code: '1926', label: 'Pijnacker-Nootdorp', provincie: 28, slug: 'pijnacker-nootdorp' },
      { code: '0439', label: 'Purmerend', provincie: 27, slug: 'purmerend' },
      { code: '0273', label: 'Putten', provincie: 25, slug: 'putten' },
      { code: '0177', label: 'Raalte', provincie: 23, slug: 'raalte' },
      { code: '0703', label: 'Reimerswaal', provincie: 29, slug: 'reimerswaal' },
      { code: '0274', label: 'Renkum', provincie: 25, slug: 'renkum' },
      { code: '0339', label: 'Renswoude', provincie: 26, slug: 'renswoude' },
      { code: '1667', label: 'Reusel-De Mierden', provincie: 30, slug: 'reusel-de-mierden' },
      { code: '0275', label: 'Rheden', provincie: 25, slug: 'rheden' },
      { code: '0340', label: 'Rhenen', provincie: 26, slug: 'rhenen' },
      { code: '0597', label: 'Ridderkerk', provincie: 28, slug: 'ridderkerk' },
      { code: '1742', label: 'Rijssen-Holten', provincie: 23, slug: 'rijssen-holten' },
      { code: '0603', label: 'Rijswijk', provincie: 28, slug: 'rijswijk' },
      { code: '1669', label: 'Roerdalen', provincie: 31, slug: 'roerdalen' },
      { code: '0957', label: 'Roermond', provincie: 31, slug: 'roermond' },
      { code: '0736', label: 'De Ronde Venen', provincie: 26, slug: 'de-ronde-venen' },
      { code: '1674', label: 'Roosendaal', provincie: 30, slug: 'roosendaal' },
      { code: '0599', label: 'Rotterdam', provincie: 28, slug: 'rotterdam' },
      { code: '0277', label: 'Rozendaal', provincie: 25, slug: 'rozendaal' },
      { code: '0840', label: 'Rucphen', provincie: 30, slug: 'rucphen' },
      { code: '0441', label: 'Schagen', provincie: 27, slug: 'schagen' },
      { code: '0279', label: 'Scherpenzeel', provincie: 25, slug: 'scherpenzeel' },
      { code: '0606', label: 'Schiedam', provincie: 28, slug: 'schiedam' },
      { code: '0088', label: 'Schiermonnikoog', provincie: 21, slug: 'schiermonnikoog' },
      { code: '1676', label: 'Schouwen-Duiveland', provincie: 29, slug: 'schouwen-duiveland' },
      { code: '0965', label: 'Simpelveld', provincie: 31, slug: 'simpelveld' },
      { code: '0845', label: 'Sint-Michielsgestel', provincie: 30, slug: 'sint-michielsgestel' },
      { code: '1883', label: 'Sittard-Geleen', provincie: 31, slug: 'sittard-geleen' },
      { code: '0610', label: 'Sliedrecht', provincie: 28, slug: 'sliedrecht' },
      { code: '1714', label: 'Sluis', provincie: 29, slug: 'sluis' },
      { code: '0090', label: 'Smallingerland', provincie: 21, slug: 'smallingerland' },
      { code: '0342', label: 'Soest', provincie: 26, slug: 'soest' },
      { code: '0847', label: 'Someren', provincie: 30, slug: 'someren' },
      { code: '0848', label: 'Son en Breugel', provincie: 30, slug: 'son-en-breugel' },
      { code: '0037', label: 'Stadskanaal', provincie: 20, slug: 'stadskanaal' },
      { code: '0180', label: 'Staphorst', provincie: 23, slug: 'staphorst' },
      { code: '0532', label: 'Stede Broec', provincie: 27, slug: 'stede-broec' },
      { code: '0851', label: 'Steenbergen', provincie: 30, slug: 'steenbergen' },
      { code: '1708', label: 'Steenwijkerland', provincie: 23, slug: 'steenwijkerland' },
      { code: '0971', label: 'Stein', provincie: 31, slug: 'stein' },
      { code: '1904', label: 'Stichtse Vecht', provincie: 26, slug: 'stichtse-vecht' },
      { code: '1900', label: 'Súdwest-Fryslân', provincie: 21, slug: 'sudwest-fryslan' },
      { code: '0715', label: 'Terneuzen', provincie: 29, slug: 'terneuzen' },
      { code: '0093', label: 'Terschelling', provincie: 21, slug: 'terschelling' },
      { code: '0448', label: 'Texel', provincie: 27, slug: 'texel' },
      { code: '1525', label: 'Teylingen', provincie: 28, slug: 'teylingen' },
      { code: '0716', label: 'Tholen', provincie: 29, slug: 'tholen' },
      { code: '0281', label: 'Tiel', provincie: 25, slug: 'tiel' },
      { code: '0855', label: 'Tilburg', provincie: 30, slug: 'tilburg' },
      { code: '0183', label: 'Tubbergen', provincie: 23, slug: 'tubbergen' },
      { code: '1700', label: 'Twenterand', provincie: 23, slug: 'twenterand' },
      { code: '1730', label: 'Tynaarlo', provincie: 22, slug: 'tynaarlo' },
      { code: '0737', label: 'Tytsjerksteradiel', provincie: 21, slug: 'tytsjerksteradiel' },
      { code: '0450', label: 'Uitgeest', provincie: 27, slug: 'uitgeest' },
      { code: '0451', label: 'Uithoorn', provincie: 27, slug: 'uithoorn' },
      { code: '0184', label: 'Urk', provincie: 24, slug: 'urk' },
      { code: '0344', label: 'Utrecht', provincie: 26, slug: 'utrecht' },
      { code: '1581', label: 'Utrechtse Heuvelrug', provincie: 26, slug: 'utrechtse-heuvelrug' },
      { code: '0981', label: 'Vaals', provincie: 31, slug: 'vaals' },
      { code: '0994', label: 'Valkenburg aan de Geul', provincie: 31, slug: 'valkenburg-aan-de-geul' },
      { code: '0858', label: 'Valkenswaard', provincie: 30, slug: 'valkenswaard' },
      { code: '0047', label: 'Veendam', provincie: 20, slug: 'veendam' },
      { code: '0345', label: 'Veenendaal', provincie: 26, slug: 'veenendaal' },
      { code: '0717', label: 'Veere', provincie: 29, slug: 'veere' },
      { code: '0861', label: 'Veldhoven', provincie: 30, slug: 'veldhoven' },
      { code: '0453', label: 'Velsen', provincie: 27, slug: 'velsen' },
      { code: '0983', label: 'Venlo', provincie: 31, slug: 'venlo' },
      { code: '0984', label: 'Venray', provincie: 31, slug: 'venray' },
      { code: '1961', label: 'Vijfheerenlanden', provincie: 26, slug: 'vijfheerenlanden' },
      { code: '0622', label: 'Vlaardingen', provincie: 28, slug: 'vlaardingen' },
      { code: '0096', label: 'Vlieland', provincie: 21, slug: 'vlieland' },
      { code: '0718', label: 'Vlissingen', provincie: 29, slug: 'vlissingen' },
      { code: '0986', label: 'Voerendaal', provincie: 31, slug: 'voerendaal' },
      { code: '1992', label: 'Voorne aan Zee', provincie: 28, slug: 'voorne-aan-zee' },
      { code: '0626', label: 'Voorschoten', provincie: 28, slug: 'voorschoten' },
      { code: '0285', label: 'Voorst', provincie: 25, slug: 'voorst' },
      { code: '0865', label: 'Vught', provincie: 30, slug: 'vught' },
      { code: '1949', label: 'Waadhoeke', provincie: 21, slug: 'waadhoeke' },
      { code: '0866', label: 'Waalre', provincie: 30, slug: 'waalre' },
      { code: '0867', label: 'Waalwijk', provincie: 30, slug: 'waalwijk' },
      { code: '0627', label: 'Waddinxveen', provincie: 28, slug: 'waddinxveen' },
      { code: '0289', label: 'Wageningen', provincie: 25, slug: 'wageningen' },
      { code: '0629', label: 'Wassenaar', provincie: 28, slug: 'wassenaar' },
      { code: '0852', label: 'Waterland', provincie: 27, slug: 'waterland' },
      { code: '0988', label: 'Weert', provincie: 31, slug: 'weert' },
      { code: '0457', label: 'Weesp', provincie: 27, slug: 'weesp' },
      { code: '1960', label: 'West Betuwe', provincie: 25, slug: 'west-betuwe' },
      { code: '0668', label: 'West Maas en Waal', provincie: 25, slug: 'west-maas-en-waal' },
      { code: '1969', label: 'Westerkwartier', provincie: 20, slug: 'westerkwartier' },
      { code: '1701', label: 'Westerveld', provincie: 22, slug: 'westerveld' },
      { code: '0293', label: 'Westervoort', provincie: 25, slug: 'westervoort' },
      { code: '1950', label: 'Westerwolde', provincie: 20, slug: 'westerwolde' },
      { code: '1783', label: 'Westland', provincie: 28, slug: 'westland' },
      { code: '0098', label: 'Weststellingwerf', provincie: 21, slug: 'weststellingwerf' },
      { code: '0189', label: 'Wierden', provincie: 23, slug: 'wierden' },
      { code: '0296', label: 'Wijchen', provincie: 25, slug: 'wijchen' },
      { code: '1696', label: 'Wijdemeren', provincie: 27, slug: 'wijdemeren' },
      { code: '0352', label: 'Wijk bij Duurstede', provincie: 26, slug: 'wijk-bij-duurstede' },
      { code: '0294', label: 'Winterswijk', provincie: 25, slug: 'winterswijk' },
      { code: '0873', label: 'Woensdrecht', provincie: 30, slug: 'woensdrecht' },
      { code: '0632', label: 'Woerden', provincie: 26, slug: 'woerden' },
      { code: '1690', label: 'De Wolden', provincie: 22, slug: 'de-wolden' },
      { code: '0880', label: 'Wormerland', provincie: 27, slug: 'wormerland' },
      { code: '0351', label: 'Woudenberg', provincie: 26, slug: 'woudenberg' },
      { code: '0479', label: 'Zaanstad', provincie: 27, slug: 'zaanstad' },
      { code: '0297', label: 'Zaltbommel', provincie: 25, slug: 'zaltbommel' },
      { code: '0473', label: 'Zandvoort', provincie: 27, slug: 'zandvoort' },
      { code: '0050', label: 'Zeewolde', provincie: 24, slug: 'zeewolde' },
      { code: '0355', label: 'Zeist', provincie: 26, slug: 'zeist' },
      { code: '0299', label: 'Zevenaar', provincie: 25, slug: 'zevenaar' },
      { code: '0637', label: 'Zoetermeer', provincie: 28, slug: 'zoetermeer' },
      { code: '0638', label: 'Zoeterwoude', provincie: 28, slug: 'zoeterwoude' },
      { code: '1892', label: 'Zuidplas', provincie: 28, slug: 'zuidplas' },
      { code: '0879', label: 'Zundert', provincie: 30, slug: 'zundert' },
      { code: '0301', label: 'Zutphen', provincie: 25, slug: 'zutphen' },
      { code: '1896', label: 'Zwartewaterland', provincie: 23, slug: 'zwartewaterland' },
      { code: '0642', label: 'Zwijndrecht', provincie: 28, slug: 'zwijndrecht' },
      { code: '0193', label: 'Zwolle', provincie: 23, slug: 'zwolle' },
      { code: '1982', label: 'Land van Cuijk', provincie: 30, slug: 'land-van-cuijk', proxy: [ '1982', '1702', '0756' ] },
      { code: '1991', label: 'Maashorst', provincie: 30, slug: 'maashorst' },

      // Gecombineerde gemeenten
      { code: '9001', label: 'Friese Waddeneilanden', provincie: 21, slug: 'friese-waddeneilanden', codeLabel: '9001 (0096, 0093, 0060, 0088)' },
      { code: '9002', label: 'ABG', provincie: 30, slug: 'abg', codeLabel: '9002 (1723, 0744, 0784)' },
      { code: '9003', label: 'kempen-gemeenten', provincie: 30, slug: 'kempen-gemeenten', codeLabel: '9003 (1724, 1728, 0770, 0823, 1667)' },
      { code: '9004', label: 'Boxtel en Sint-Michielsgestel', provincie: 30, slug: 'boxtel-en-sint-michielsgestel', codeLabel: '9004 (0757, 0845)' },
      // 9005 - 9016 = provincies
      { code: '9017', label: 'BAR gemeenten', provincie: 28, slug: 'bar-gemeenten', codeLabel: '9017 (0613, 0489, 0597)' },

      // Provincies - NOTE: Proxy access also has to be defined in functions/chargingpointsload
      { code: '9005', label: 'Provincie Flevoland', provincie: 24, slug: 'flevoland' },
      {
        code: '9006', label: 'Provincie Limburg', provincie: 31, slug: 'limburg',
        proxy: [
          '0888', '1954', '0889', '0893', '0899', '1711', '1903', '0907', '1729', '0917', '1507', '0928', '0882', '1640', '1641', '0935', '0938', '0944', '0946', '1894', '1669', '0957', '0965', '1883', '0971', '0981', '0994', '0983', '0984', '0986', '0988', '9006',
        ],
      },
      {
        code: '9007', label: 'Provincie Noord-Brabant', provincie: 30, slug: 'noord-brabant',
        proxy: [
          '1723', '1959', '0743', '0744', '1724', '0748', '1721', '0753', '1728', '0755', '0757', '0758', '1706', '0762', '0766', '1719', '0770', '0772', '0777', '0779', '1771', '1652', '0784', '0785', '0788', '1655', '1658', '0794', '0796', '0797', '0798', '1659', '0809', '1948', '1709', '0820', '0823', '0824', '0826', '0828', '1667', '1674', '0840', '0845', '0847', '0848', '0851', '0855', '0858', '0861', '0865', '0866', '0867', '0873', '0879', '1982', '1991', '9002', '9003', '9004', '9007',
        ],
      },
      { code: '9008', label: 'Provincie Drenthe', provincie: 22, slug: 'drenthe' },
      { code: '9009', label: 'Provincie Gelderland', provincie: 25, slug: 'gelderland' },
      { code: '9010', label: 'Provincie Noord-Holland', provincie: 27, slug: 'noord-holland' },
      { code: '9011', label: 'Provincie Utrecht', provincie: 26, slug: 'provincie-utrecht' },
      { code: '9012', label: 'Provincie Overijssel', provincie: 23, slug: 'overijssel' },
      { code: '9013', label: 'Provincie Zeeland', provincie: 29, slug: 'zeeland' },
      { code: '9014', label: 'Provincie Zuid-Holland', provincie: 28, slug: 'zuid-holland' },
      { code: '9015', label: 'Provincie Friesland', provincie: 21, slug: 'friesland' },
      { code: '9016', label: 'Provincie Groningen', provincie: 20, slug: 'groningen' },

      // Reserved codes for Workflow
      // { code: "9018", label: 'Intern - EVnetNL', provincie: 0, slug: 'intern-evnetnl' }, // Reserved for PARKnCHARGE

      // Deprecated
      { code: '0756', label: 'Boxmeer', slug: 'boxmeer', provincie: 0, deprecated: true },
      { code: '1702', label: 'Sint Anthonis', slug: 'sint-anthonis', provincie: 0, deprecated: true },
      { code: '1685', label: 'Landerd', slug: 'landerd', provincie: 0, deprecated: true },
      { code: '0856', label: 'Uden', slug: 'uden', provincie: 0, deprecated: true },
      { code: '0398', label: 'Heerhugowaard', slug: 'heerhugowaard', provincie: 0, deprecated: true },
      { code: '0416', label: 'Langedijk', slug: 'langedijk', provincie: 0, deprecated: true },
      { code: '0370', label: 'Beemster', slug: 'beemster', provincie: 0, deprecated: true },
      { code: '9000', label: 'CMG Gemeenten', slug: 'cmg-gemeenten', provincie: 0, deprecated: true },
    ],
    be: [
      { code: '83034', label: 'Marche-en-Famenne', provincie: 0, slug: 'marche-en-famenne' },
      { code: '23027', label: 'Halle', provincie: 0, slug: 'halle' },
      { code: '91103', label: 'Onhaye', provincie: 0, slug: 'onhaye' },
      { code: '91034', label: 'Dinant', provincie: 0, slug: 'dinant' },
      { code: '82037', label: 'Gouvy', provincie: 0, slug: 'gouvy' },
      { code: '91072', label: 'Houyet', provincie: 0, slug: 'houyet' },
      { code: '91142', label: 'Hastière', provincie: 0, slug: 'hastiere' },
      { code: '82014', label: 'Houffalize', provincie: 0, slug: 'houffalize' },
      { code: '56029', label: 'Froidchapelle', provincie: 0, slug: 'froidchapelle' },
      { code: '91114', label: 'Rochefort', provincie: 0, slug: 'rochefort' },
      { code: '93010', label: 'Cerfontaine', provincie: 0, slug: 'cerfontaine' },
      { code: '83031', label: 'La Roche-en-Ardenne', provincie: 0, slug: 'la-roche-en-ardenne' },
      { code: '56088', label: 'Sivry-Rance', provincie: 0, slug: 'sivry-rance' },
      { code: '93018', label: 'Doische', provincie: 0, slug: 'doische' },
      { code: '91013', label: 'Beauraing', provincie: 0, slug: 'beauraing' },
      { code: '93014', label: 'Couvin', provincie: 0, slug: 'couvin' },
      { code: '56016', label: 'Chimay', provincie: 0, slug: 'chimay' },
      { code: '84075', label: 'Wellin', provincie: 0, slug: 'wellin' },
      { code: '93090', label: 'Viroinval', provincie: 0, slug: 'viroinval' },
      { code: '84059', label: 'Saint-Hubert', provincie: 0, slug: 'saint-hubert' },
      { code: '91054', label: 'Gedinne', provincie: 0, slug: 'gedinne' },
      { code: '84077', label: 'Libramont-Chevigny', provincie: 0, slug: 'libramont-chevigny' },
      { code: '82036', label: 'Vaux-sur-Sûre', provincie: 0, slug: 'vaux-sur-sure' },
      { code: '82009', label: 'Fauvillers', provincie: 0, slug: 'fauvillers' },
      { code: '84009', label: 'Bertrix', provincie: 0, slug: 'bertrix' },
      { code: '91143', label: 'Vresse-sur-Semois', provincie: 0, slug: 'vresse-sur-semois' },
      { code: '84043', label: 'Neufchâteau', provincie: 0, slug: 'neufchateau' },
      { code: '84033', label: 'Léglise', provincie: 0, slug: 'leglise' },
      { code: '84010', label: 'Bouillon', provincie: 0, slug: 'bouillon' },
      { code: '81013', label: 'Martelange', provincie: 0, slug: 'martelange' },
      { code: '84029', label: 'Herbeumont', provincie: 0, slug: 'herbeumont' },
      { code: '81003', label: 'Attert', provincie: 0, slug: 'attert' },
      { code: '85007', label: 'Chiny', provincie: 0, slug: 'chiny' },
      { code: '85046', label: 'Habay', provincie: 0, slug: 'habay' },
      { code: '85039', label: 'Tintigny', provincie: 0, slug: 'tintigny' },
      { code: '81001', label: 'Arlon', provincie: 0, slug: 'arlon' },
      { code: '85034', label: 'Saint-Léger', provincie: 0, slug: 'saint-leger' },
      { code: '85045', label: 'Virton', provincie: 0, slug: 'virton' },
      { code: '85047', label: 'Rouvroy', provincie: 0, slug: 'rouvroy' },
      { code: '21009', label: 'Elsene', provincie: 0, slug: 'elsene' },
      { code: '21014', label: 'Sint-Joost-ten-Node', provincie: 0, slug: 'sint-joost-ten-node' },
      { code: '21017', label: 'Watermaal-Bosvoorde', provincie: 0, slug: 'watermaal-bosvoorde' },
      { code: '21012', label: 'Sint-Jans-Molenbeek', provincie: 0, slug: 'sint-jans-molenbeek' },
      { code: '21013', label: 'Sint-Gillis', provincie: 0, slug: 'sint-gillis' },
      { code: '21019', label: 'Sint-Pieters-Woluwe', provincie: 0, slug: 'sint-pieters-woluwe' },
      { code: '21018', label: 'Sint-Lambrechts-Woluwe', provincie: 0, slug: 'sint-lambrechts-woluwe' },
      { code: '41034', label: 'Lede', provincie: 0, slug: 'lede' },
      { code: '71024', label: 'Herk-de-Stad', provincie: 0, slug: 'herk-de-stad' },
      { code: '84050', label: 'Paliseul', provincie: 0, slug: 'paliseul' },
      { code: '46021', label: 'Sint-Niklaas', provincie: 0, slug: 'sint-niklaas' },
      { code: '72030', label: 'Peer', provincie: 0, slug: 'peer' },
      { code: '12007', label: 'Bornem', provincie: 0, slug: 'bornem' },
      { code: '42008', label: 'Hamme', provincie: 0, slug: 'hamme' },
      { code: '36008', label: 'Izegem', provincie: 0, slug: 'izegem' },
      { code: '36007', label: 'Ingelmunster', provincie: 0, slug: 'ingelmunster' },
      { code: '23047', label: 'Machelen', provincie: 0, slug: 'machelen' },
      { code: '23094', label: 'Zaventem', provincie: 0, slug: 'zaventem' },
      { code: '71017', label: 'Gingelom', provincie: 0, slug: 'gingelom' },
      { code: '57018', label: 'Celles', provincie: 0, slug: 'celles' },
      { code: '63003', label: 'Aubel', provincie: 0, slug: 'aubel' },
      { code: '64029', label: 'Geer', provincie: 0, slug: 'geer' },
      { code: '62118', label: 'Grâce-Hollogne', provincie: 0, slug: 'grace-hollogne' },
      { code: '63012', label: 'Bullange', provincie: 0, slug: 'bullange' },
      { code: '13014', label: 'Hoogstraten', provincie: 0, slug: 'hoogstraten' },
      { code: '13035', label: 'Ravels', provincie: 0, slug: 'ravels' },
      { code: '11016', label: 'Essen', provincie: 0, slug: 'essen' },
      { code: '13002', label: 'Baarle-Hertog', provincie: 0, slug: 'baarle-hertog' },
      { code: '11053', label: 'Wuustwezel', provincie: 0, slug: 'wuustwezel' },
      { code: '13023', label: 'Merksplas', provincie: 0, slug: 'merksplas' },
      { code: '13037', label: 'Rijkevorsel', provincie: 0, slug: 'rijkevorsel' },
      { code: '11002', label: 'Antwerpen', provincie: 0, slug: 'antwerpen' },
      { code: '31043', label: 'Knokke-Heist', provincie: 0, slug: 'knokke-heist' },
      { code: '13031', label: 'Oud-Turnhout', provincie: 0, slug: 'oud-turnhout' },
      { code: '46003', label: 'Beveren', provincie: 0, slug: 'beveren' },
      { code: '31004', label: 'Blankenberge', provincie: 0, slug: 'blankenberge' },
      { code: '11057', label: 'Malle', provincie: 0, slug: 'malle' },
      { code: '13025', label: 'Mol', provincie: 0, slug: 'mol' },
      { code: '31006', label: 'Damme', provincie: 0, slug: 'damme' },
      { code: '72037', label: 'Hamont-Achel', provincie: 0, slug: 'hamont-achel' },
      { code: '11040', label: 'Schoten', provincie: 0, slug: 'schoten' },
      { code: '13036', label: 'Retie', provincie: 0, slug: 'retie' },
      { code: '13017', label: 'Kasterlee', provincie: 0, slug: 'kasterlee' },
      { code: '61041', label: 'Modave', provincie: 0, slug: 'modave' },
      { code: '52011', label: 'Charleroi', provincie: 0, slug: 'charleroi' },
      { code: '72020', label: 'Lommel', provincie: 0, slug: 'lommel' },
      { code: '13006', label: 'Dessel', provincie: 0, slug: 'dessel' },
      { code: '35002', label: 'Bredene', provincie: 0, slug: 'bredene' },
      { code: '11056', label: 'Zwijndrecht', provincie: 0, slug: 'zwijndrecht' },
      { code: '13044', label: 'Vorselaar', provincie: 0, slug: 'vorselaar' },
      { code: '46024', label: 'Stekene', provincie: 0, slug: 'stekene' },
      { code: '13003', label: 'Balen', provincie: 0, slug: 'balen' },
      { code: '35005', label: 'Gistel', provincie: 0, slug: 'gistel' },
      { code: '13001', label: 'Arendonk', provincie: 0, slug: 'arendonk' },
      { code: '43010', label: 'Maldegem', provincie: 0, slug: 'maldegem' },
      { code: '31012', label: 'Jabbeke', provincie: 0, slug: 'jabbeke' },
      { code: '11050', label: 'Wijnegem', provincie: 0, slug: 'wijnegem' },
      { code: '11035', label: 'Ranst', provincie: 0, slug: 'ranst' },
      { code: '35014', label: 'Oudenburg', provincie: 0, slug: 'oudenburg' },
      { code: '72003', label: 'Bocholt', provincie: 0, slug: 'bocholt' },
      { code: '44045', label: 'Moerbeke', provincie: 0, slug: 'moerbeke' },
      { code: '43005', label: 'Eeklo', provincie: 0, slug: 'eeklo' },
      { code: '43018', label: 'Zelzate', provincie: 0, slug: 'zelzate' },
      { code: '44019', label: 'Evergem', provincie: 0, slug: 'evergem' },
      { code: '46013', label: 'Kruibeke', provincie: 0, slug: 'kruibeke' },
      { code: '11004', label: 'Boechout', provincie: 0, slug: 'boechout' },
      { code: '11029', label: 'Mortsel', provincie: 0, slug: 'mortsel' },
      { code: '72038', label: 'Hechtel-Eksel', provincie: 0, slug: 'hechtel-eksel' },
      { code: '46025', label: 'Temse', provincie: 0, slug: 'temse' },
      { code: '38016', label: 'Nieuwpoort', provincie: 0, slug: 'nieuwpoort' },
      { code: '13012', label: 'Herenthout', provincie: 0, slug: 'herenthout' },
      { code: '11018', label: 'Hemiksem', provincie: 0, slug: 'hemiksem' },
      { code: '38014', label: 'Koksijde', provincie: 0, slug: 'koksijde' },
      { code: '71034', label: 'Leopoldsburg', provincie: 0, slug: 'leopoldsburg' },
      { code: '71069', label: 'Ham', provincie: 0, slug: 'ham' },
      { code: '32010', label: 'Koekelare', provincie: 0, slug: 'koekelare' },
      { code: '11038', label: 'Schelle', provincie: 0, slug: 'schelle' },
      { code: '32003', label: 'Diksmuide', provincie: 0, slug: 'diksmuide' },
      { code: '11037', label: 'Rumst', provincie: 0, slug: 'rumst' },
      { code: '85011', label: 'Florenville', provincie: 0, slug: 'florenville' },
      { code: '85009', label: 'Étalle', provincie: 0, slug: 'etalle' },
      { code: '85024', label: 'Meix-devant-Virton', provincie: 0, slug: 'meix-devant-virton' },
      { code: '21015', label: 'Schaarbeek', provincie: 0, slug: 'schaarbeek' },
      { code: '21016', label: 'Ukkel', provincie: 0, slug: 'ukkel' },
      { code: '21007', label: 'Vorst', provincie: 0, slug: 'vorst' },
      { code: '12009', label: 'Duffel', provincie: 0, slug: 'duffel' },
      { code: '71004', label: 'Beringen', provincie: 0, slug: 'beringen' },
      { code: '72039', label: 'Houthalen-Helchteren', provincie: 0, slug: 'houthalen-helchteren' },
      { code: '37012', label: 'Ruiselede', provincie: 0, slug: 'ruiselede' },
      { code: '13013', label: 'Herselt', provincie: 0, slug: 'herselt' },
      { code: '12035', label: 'Sint-Katelijne-Waver', provincie: 0, slug: 'sint-katelijne-waver' },
      { code: '12029', label: 'Putte', provincie: 0, slug: 'putte' },
      { code: '42010', label: 'Laarne', provincie: 0, slug: 'laarne' },
      { code: '36011', label: 'Lichtervelde', provincie: 0, slug: 'lichtervelde' },
      { code: '42006', label: 'Dendermonde', provincie: 0, slug: 'dendermonde' },
      { code: '38002', label: 'Alveringem', provincie: 0, slug: 'alveringem' },
      { code: '71037', label: 'Lummen', provincie: 0, slug: 'lummen' },
      { code: '21003', label: 'Sint-Agatha-Berchem', provincie: 0, slug: 'sint-agatha-berchem' },
      { code: '35006', label: 'Ichtegem', provincie: 0, slug: 'ichtegem' },
      { code: '11021', label: 'Hove', provincie: 0, slug: 'hove' },
      { code: '13021', label: 'Meerhout', provincie: 0, slug: 'meerhout' },
      { code: '11001', label: 'Aartselaar', provincie: 0, slug: 'aartselaar' },
      { code: '44034', label: 'Lochristi', provincie: 0, slug: 'lochristi' },
      { code: '41027', label: 'Herzele', provincie: 0, slug: 'herzele' },
      { code: '41024', label: 'Haaltert', provincie: 0, slug: 'haaltert' },
      { code: '73001', label: 'Alken', provincie: 0, slug: 'alken' },
      { code: '34025', label: 'Lendelede', provincie: 0, slug: 'lendelede' },
      { code: '24137', label: 'Glabbeek', provincie: 0, slug: 'glabbeek' },
      { code: '73032', label: 'Hoeselt', provincie: 0, slug: 'hoeselt' },
      { code: '23016', label: 'Dilbeek', provincie: 0, slug: 'dilbeek' },
      { code: '45061', label: 'Wortegem-Petegem', provincie: 0, slug: 'wortegem-petegem' },
      { code: '24130', label: 'Zoutleeuw', provincie: 0, slug: 'zoutleeuw' },
      { code: '21008', label: 'Ganshoren', provincie: 0, slug: 'ganshoren' },
      { code: '34022', label: 'Kortrijk', provincie: 0, slug: 'kortrijk' },
      { code: '24016', label: 'Boutersem', provincie: 0, slug: 'boutersem' },
      { code: '41048', label: 'Ninove', provincie: 0, slug: 'ninove' },
      { code: '34002', label: 'Anzegem', provincie: 0, slug: 'anzegem' },
      { code: '13049', label: 'Westerlo', provincie: 0, slug: 'westerlo' },
      { code: '12002', label: 'Berlaar', provincie: 0, slug: 'berlaar' },
      { code: '12014', label: 'Heist-op-den-Berg', provincie: 0, slug: 'heist-op-den-berg' },
      { code: '38008', label: 'De Panne', provincie: 0, slug: 'de-panne' },
      { code: '13053', label: 'Laakdal', provincie: 0, slug: 'laakdal' },
      { code: '31033', label: 'Torhout', provincie: 0, slug: 'torhout' },
      { code: '24107', label: 'Tienen', provincie: 0, slug: 'tienen' },
      { code: '33029', label: 'Wervik', provincie: 0, slug: 'wervik' },
      { code: '21001', label: 'Anderlecht', provincie: 0, slug: 'anderlecht' },
      { code: '73066', label: 'Riemst', provincie: 0, slug: 'riemst' },
      { code: '21005', label: 'Etterbeek', provincie: 0, slug: 'etterbeek' },
      { code: '23104', label: 'Lennik', provincie: 0, slug: 'lennik' },
      { code: '34027', label: 'Menen', provincie: 0, slug: 'menen' },
      { code: '41018', label: 'Geraardsbergen', provincie: 0, slug: 'geraardsbergen' },
      { code: '23077', label: 'Sint-Pieters-Leeuw', provincie: 0, slug: 'sint-pieters-leeuw' },
      { code: '34003', label: 'Avelgem', provincie: 0, slug: 'avelgem' },
      { code: '62011', label: 'Bassenge', provincie: 0, slug: 'bassenge' },
      { code: '45060', label: 'Kluisbergen', provincie: 0, slug: 'kluisbergen' },
      { code: '23098', label: 'Drogenbos', provincie: 0, slug: 'drogenbos' },
      { code: '25005', label: 'Beauvechain', provincie: 0, slug: 'beauvechain' },
      { code: '24059', label: 'Landen', provincie: 0, slug: 'landen' },
      { code: '31005', label: 'Brugge', provincie: 0, slug: 'brugge' },
      { code: '11008', label: 'Brasschaat', provincie: 0, slug: 'brasschaat' },
      { code: '13046', label: 'Vosselaar', provincie: 0, slug: 'vosselaar' },
      { code: '13019', label: 'Lille', provincie: 0, slug: 'lille' },
      { code: '31042', label: 'Zuienkerke', provincie: 0, slug: 'zuienkerke' },
      { code: '11054', label: 'Zandhoven', provincie: 0, slug: 'zandhoven' },
      { code: '11024', label: 'Kontich', provincie: 0, slug: 'kontich' },
      { code: '35029', label: 'De Haan', provincie: 0, slug: 'de-haan' },
      { code: '43014', label: 'Sint-Laureins', provincie: 0, slug: 'sint-laureins' },
      { code: '45041', label: 'Ronse', provincie: 0, slug: 'ronse' },
      { code: '23033', label: 'Hoeilaart', provincie: 0, slug: 'hoeilaart' },
      { code: '23003', label: 'Beersel', provincie: 0, slug: 'beersel' },
      { code: '51017', label: 'Ellezelles', provincie: 0, slug: 'ellezelles' },
      { code: '23101', label: 'Sint-Genesius-Rode', provincie: 0, slug: 'sint-genesius-rode' },
      { code: '25118', label: 'Hélécine', provincie: 0, slug: 'helecine' },
      { code: '63040', label: 'La Calamine', provincie: 0, slug: 'la-calamine' },
      { code: '62027', label: 'Dalhem', provincie: 0, slug: 'dalhem' },
      { code: '23032', label: 'Herne', provincie: 0, slug: 'herne' },
      { code: '62060', label: 'Juprelle', provincie: 0, slug: 'juprelle' },
      { code: '64047', label: 'Lincent', provincie: 0, slug: 'lincent' },
      { code: '64056', label: 'Oreye', provincie: 0, slug: 'oreye' },
      { code: '62006', label: 'Awans', provincie: 0, slug: 'awans' },
      { code: '51065', label: 'Frasnes-lez-Anvaing', provincie: 0, slug: 'frasnes-lez-anvaing' },
      { code: '25014', label: 'Braine-l\'Alleud', provincie: 0, slug: 'braine-lalleud' },
      { code: '43002', label: 'Assenede', provincie: 0, slug: 'assenede' },
      { code: '44073', label: 'Wachtebeke', provincie: 0, slug: 'wachtebeke' },
      { code: '35011', label: 'Middelkerke', provincie: 0, slug: 'middelkerke' },
      { code: '64074', label: 'Waremme', provincie: 0, slug: 'waremme' },
      { code: '57062', label: 'Pecq', provincie: 0, slug: 'pecq' },
      { code: '25119', label: 'Lasne', provincie: 0, slug: 'lasne' },
      { code: '64034', label: 'Hannut', provincie: 0, slug: 'hannut' },
      { code: '63084', label: 'Welkenraedt', provincie: 0, slug: 'welkenraedt' },
      { code: '62051', label: 'Herstal', provincie: 0, slug: 'herstal' },
      { code: '64063', label: 'Remicourt', provincie: 0, slug: 'remicourt' },
      { code: '64025', label: 'Fexhe-le-Haut-Clocher', provincie: 0, slug: 'fexhe-le-haut-clocher' },
      { code: '63023', label: 'Eupen', provincie: 0, slug: 'eupen' },
      { code: '25084', label: 'Perwez', provincie: 0, slug: 'perwez' },
      { code: '62099', label: 'Soumagne', provincie: 0, slug: 'soumagne' },
      { code: '24134', label: 'Scherpenheuvel-Zichem', provincie: 0, slug: 'scherpenheuvel-zichem' },
      { code: '21011', label: 'Koekelberg', provincie: 0, slug: 'koekelberg' },
      { code: '21002', label: 'Oudergem', provincie: 0, slug: 'oudergem' },
      { code: '13010', label: 'Grobbendonk', provincie: 0, slug: 'grobbendonk' },
      { code: '25124', label: 'Walhain', provincie: 0, slug: 'walhain' },
      { code: '25068', label: 'Mont-Saint-Guibert', provincie: 0, slug: 'mont-saint-guibert' },
      { code: '11007', label: 'Borsbeek', provincie: 0, slug: 'borsbeek' },
      { code: '13029', label: 'Olen', provincie: 0, slug: 'olen' },
      { code: '44084', label: 'Aalter', provincie: 0, slug: 'aalter' },
      { code: '12041', label: 'Puurs-Sint-Amands', provincie: 0, slug: 'puurs-sint-amands' },
      { code: '51067', label: 'Edingen', provincie: 0, slug: 'edingen' },
      { code: '51068', label: 'Silly', provincie: 0, slug: 'silly' },
      { code: '72018', label: 'Kinrooi', provincie: 0, slug: 'kinrooi' },
      { code: '44021', label: 'Gent', provincie: 0, slug: 'gent' },
      { code: '62038', label: 'Fléron', provincie: 0, slug: 'fleron' },
      { code: '44085', label: 'Lievegem', provincie: 0, slug: 'lievegem' },
      { code: '72042', label: 'Oudsbergen', provincie: 0, slug: 'oudsbergen' },
      { code: '72043', label: 'Pelt', provincie: 0, slug: 'pelt' },
      { code: '12026', label: 'Nijlen', provincie: 0, slug: 'nijlen' },
      { code: '31040', label: 'Zedelgem', provincie: 0, slug: 'zedelgem' },
      { code: '42004', label: 'Buggenhout', provincie: 0, slug: 'buggenhout' },
      { code: '37011', label: 'Pittem', provincie: 0, slug: 'pittem' },
      { code: '56005', label: 'Beaumont', provincie: 0, slug: 'beaumont' },
      { code: '24001', label: 'Aarschot', provincie: 0, slug: 'aarschot' },
      { code: '23039', label: 'Kapelle-op-den-Bos', provincie: 0, slug: 'kapelle-op-den-bos' },
      { code: '44043', label: 'Merelbeke', provincie: 0, slug: 'merelbeke' },
      { code: '32030', label: 'Lo-Reninge', provincie: 0, slug: 'lo-reninge' },
      { code: '71016', label: 'Genk', provincie: 0, slug: 'genk' },
      { code: '24048', label: 'Keerbergen', provincie: 0, slug: 'keerbergen' },
      { code: '73107', label: 'Maasmechelen', provincie: 0, slug: 'maasmechelen' },
      { code: '23096', label: 'Zemst', provincie: 0, slug: 'zemst' },
      { code: '23050', label: 'Meise', provincie: 0, slug: 'meise' },
      { code: '24014', label: 'Boortmeerbeek', provincie: 0, slug: 'boortmeerbeek' },
      { code: '36015', label: 'Roeselare', provincie: 0, slug: 'roeselare' },
      { code: '24033', label: 'Haacht', provincie: 0, slug: 'haacht' },
      { code: '23060', label: 'Opwijk', provincie: 0, slug: 'opwijk' },
      { code: '83040', label: 'Nassogne', provincie: 0, slug: 'nassogne' },
      { code: '83049', label: 'Tenneville', provincie: 0, slug: 'tenneville' },
      { code: '82003', label: 'Bastogne', provincie: 0, slug: 'bastogne' },
      { code: '82005', label: 'Bertogne', provincie: 0, slug: 'bertogne' },
      { code: '84068', label: 'Tellin', provincie: 0, slug: 'tellin' },
      { code: '82038', label: 'Sainte-Ode', provincie: 0, slug: 'sainte-ode' },
      { code: '37002', label: 'Dentergem', provincie: 0, slug: 'dentergem' },
      { code: '37007', label: 'Meulebeke', provincie: 0, slug: 'meulebeke' },
      { code: '24008', label: 'Bekkevoort', provincie: 0, slug: 'bekkevoort' },
      { code: '37010', label: 'Oostrozebeke', provincie: 0, slug: 'oostrozebeke' },
      { code: '33040', label: 'Langemark-Poelkapelle', provincie: 0, slug: 'langemark-poelkapelle' },
      { code: '24135', label: 'Tielt-Winge', provincie: 0, slug: 'tielt-winge' },
      { code: '23081', label: 'Steenokkerzeel', provincie: 0, slug: 'steenokkerzeel' },
      { code: '41082', label: 'Erpe-Mere', provincie: 0, slug: 'erpe-mere' },
      { code: '73042', label: 'Lanaken', provincie: 0, slug: 'lanaken' },
      { code: '33021', label: 'Poperinge', provincie: 0, slug: 'poperinge' },
      { code: '23002', label: 'Asse', provincie: 0, slug: 'asse' },
      { code: '24062', label: 'Leuven', provincie: 0, slug: 'leuven' },
      { code: '36012', label: 'Moorslede', provincie: 0, slug: 'moorslede' },
      { code: '23105', label: 'Affligem', provincie: 0, slug: 'affligem' },
      { code: '24028', label: 'Geetbets', provincie: 0, slug: 'geetbets' },
      { code: '84016', label: 'Daverdisse', provincie: 0, slug: 'daverdisse' },
      { code: '71045', label: 'Nieuwerkerken', provincie: 0, slug: 'nieuwerkerken' },
      { code: '23044', label: 'Liedekerke', provincie: 0, slug: 'liedekerke' },
      { code: '34043', label: 'Spiere-Helkijn', provincie: 0, slug: 'spiere-helkijn' },
      { code: '62119', label: 'Blegny', provincie: 0, slug: 'blegny' },
      { code: '57081', label: 'Tournai', provincie: 0, slug: 'tournai' },
      { code: '61081', label: 'Tinlot', provincie: 0, slug: 'tinlot' },
      { code: '52010', label: 'Chapelle-lez-Herlaimont', provincie: 0, slug: 'chapelle-lez-herlaimont' },
      { code: '92097', label: 'Ohey', provincie: 0, slug: 'ohey' },
      { code: '61024', label: 'Hamoir', provincie: 0, slug: 'hamoir' },
      { code: '61012', label: 'Clavier', provincie: 0, slug: 'clavier' },
      { code: '53065', label: 'Quaregnon', provincie: 0, slug: 'quaregnon' },
      { code: '52018', label: 'Farciennes', provincie: 0, slug: 'farciennes' },
      { code: '52012', label: 'Châtelet', provincie: 0, slug: 'chatelet' },
      { code: '83012', label: 'Durbuy', provincie: 0, slug: 'durbuy' },
      { code: '92048', label: 'Fosses-la-Ville', provincie: 0, slug: 'fosses-la-ville' },
      { code: '53020', label: 'Dour', provincie: 0, slug: 'dour' },
      { code: '91064', label: 'Havelange', provincie: 0, slug: 'havelange' },
      { code: '63001', label: 'Amblève', provincie: 0, slug: 'ambleve' },
      { code: '53084', label: 'Quévy', provincie: 0, slug: 'quevy' },
      { code: '53083', label: 'Honnelles', provincie: 0, slug: 'honnelles' },
      { code: '63088', label: 'Plombières', provincie: 0, slug: 'plombieres' },
      { code: '25112', label: 'Wavre', provincie: 0, slug: 'wavre' },
      { code: '62079', label: 'Oupeye', provincie: 0, slug: 'oupeye' },
      { code: '25050', label: 'La Hulpe', provincie: 0, slug: 'la-hulpe' },
      { code: '51069', label: 'Lessines', provincie: 0, slug: 'lessines' },
      { code: '25091', label: 'Rixensart', provincie: 0, slug: 'rixensart' },
      { code: '92087', label: 'Mettet', provincie: 0, slug: 'mettet' },
      { code: '52025', label: 'Gerpinnes', provincie: 0, slug: 'gerpinnes' },
      { code: '56078', label: 'Thuin', provincie: 0, slug: 'thuin' },
      { code: '91141', label: 'Yvoir', provincie: 0, slug: 'yvoir' },
      { code: '63067', label: 'Saint-Vith', provincie: 0, slug: 'saint-vith' },
      { code: '91005', label: 'Anhée', provincie: 0, slug: 'anhee' },
      { code: '82032', label: 'Vielsalm', provincie: 0, slug: 'vielsalm' },
      { code: '56022', label: 'Erquelinnes', provincie: 0, slug: 'erquelinnes' },
      { code: '83013', label: 'Érezée', provincie: 0, slug: 'erezee' },
      { code: '93088', label: 'Walcourt', provincie: 0, slug: 'walcourt' },
      { code: '83028', label: 'Hotton', provincie: 0, slug: 'hotton' },
      { code: '93022', label: 'Florennes', provincie: 0, slug: 'florennes' },
      { code: '61019', label: 'Ferrières', provincie: 0, slug: 'ferrieres' },
      { code: '53028', label: 'Frameries', provincie: 0, slug: 'frameries' },
      { code: '83055', label: 'Manhay', provincie: 0, slug: 'manhay' },
      { code: '11044', label: 'Stabroek', provincie: 0, slug: 'stabroek' },
      { code: '35013', label: 'Oostende', provincie: 0, slug: 'oostende' },
      { code: '72021', label: 'Maaseik', provincie: 0, slug: 'maaseik' },
      { code: '44083', label: 'Deinze', provincie: 0, slug: 'deinze' },
      { code: '24055', label: 'Kortenberg', provincie: 0, slug: 'kortenberg' },
      { code: '34041', label: 'Wevelgem', provincie: 0, slug: 'wevelgem' },
      { code: '25120', label: 'Orp-Jauche', provincie: 0, slug: 'orp-jauche' },
      { code: '73083', label: 'Tongeren', provincie: 0, slug: 'tongeren' },
      { code: '73111', label: 'Tongeren-Borgloon', provincie: 0, slug: 'tongeren-borgloon' },
      { code: '57096', label: 'Moeskroen', provincie: 0, slug: 'moeskroen' },
      { code: '64008', label: 'Berloz', provincie: 0, slug: 'berloz' },
      { code: '63004', label: 'Baelen', provincie: 0, slug: 'baelen' },
      { code: '64015', label: 'Braives', provincie: 0, slug: 'braives' },
      { code: '61003', label: 'Amay', provincie: 0, slug: 'amay' },
      { code: '61079', label: 'Anthisnes', provincie: 0, slug: 'anthisnes' },
      { code: '53082', label: 'Colfontaine', provincie: 0, slug: 'colfontaine' },
      { code: '91030', label: 'Ciney', provincie: 0, slug: 'ciney' },
      { code: '21004', label: 'Brussel', provincie: 0, slug: 'brussel' },
      { code: '12021', label: 'Lier', provincie: 0, slug: 'lier' },
      { code: '63061', label: 'Raeren', provincie: 0, slug: 'raeren' },
      { code: '11022', label: 'Kalmthout', provincie: 0, slug: 'kalmthout' },
      { code: '13004', label: 'Beerse', provincie: 0, slug: 'beerse' },
      { code: '13008', label: 'Geel', provincie: 0, slug: 'geel' },
      { code: '37018', label: 'Wingene', provincie: 0, slug: 'wingene' },
      { code: '42003', label: 'Berlare', provincie: 0, slug: 'berlare' },
      { code: '71066', label: 'Zonhoven', provincie: 0, slug: 'zonhoven' },
      { code: '45068', label: 'Kruisem', provincie: 0, slug: 'kruisem' },
      { code: '21010', label: 'Jette', provincie: 0, slug: 'jette' },
      { code: '34042', label: 'Zwevegem', provincie: 0, slug: 'zwevegem' },
      { code: '73022', label: 'Heers', provincie: 0, slug: 'heers' },
      { code: '73028', label: 'Herstappe', provincie: 0, slug: 'herstappe' },
      { code: '64076', label: 'Faimes', provincie: 0, slug: 'faimes' },
      { code: '55085', label: 'Seneffe', provincie: 0, slug: 'seneffe' },
      { code: '52015', label: 'Courcelles', provincie: 0, slug: 'courcelles' },
      { code: '56001', label: 'Anderlues', provincie: 0, slug: 'anderlues' },
      { code: '63045', label: 'Lierneux', provincie: 0, slug: 'lierneux' },
      { code: '85026', label: 'Musson', provincie: 0, slug: 'musson' },
      { code: '11009', label: 'Brecht', provincie: 0, slug: 'brecht' },
      { code: '11052', label: 'Wommelgem', provincie: 0, slug: 'wommelgem' },
      { code: '44013', label: 'Destelbergen', provincie: 0, slug: 'destelbergen' },
      { code: '41002', label: 'Aalst', provincie: 0, slug: 'aalst' },
      { code: '24066', label: 'Lubbeek', provincie: 0, slug: 'lubbeek' },
      { code: '45064', label: 'Maarkedal', provincie: 0, slug: 'maarkedal' },
      { code: '25110', label: 'Waterloo', provincie: 0, slug: 'waterloo' },
      { code: '23009', label: 'Bever', provincie: 0, slug: 'bever' },
      { code: '62003', label: 'Ans', provincie: 0, slug: 'ans' },
      { code: '57027', label: 'Estaimpuis', provincie: 0, slug: 'estaimpuis' },
      { code: '25018', label: 'Chaumont-Gistoux', provincie: 0, slug: 'chaumont-gistoux' },
      { code: '25123', label: 'Rebecq', provincie: 0, slug: 'rebecq' },
      { code: '64021', label: 'Crisnée', provincie: 0, slug: 'crisnee' },
      { code: '63020', label: 'Dison', provincie: 0, slug: 'dison' },
      { code: '61031', label: 'Huy', provincie: 0, slug: 'huy' },
      { code: '91120', label: 'Somme-Leuze', provincie: 0, slug: 'somme-leuze' },
      { code: '56051', label: 'Momignies', provincie: 0, slug: 'momignies' },
      { code: '11025', label: 'Lint', provincie: 0, slug: 'lint' },
      { code: '13040', label: 'Turnhout', provincie: 0, slug: 'turnhout' },
      { code: '13011', label: 'Herentals', provincie: 0, slug: 'herentals' },
      { code: '11030', label: 'Niel', provincie: 0, slug: 'niel' },
      { code: '44040', label: 'Melle', provincie: 0, slug: 'melle' },
      { code: '24038', label: 'Herent', provincie: 0, slug: 'herent' },
      { code: '24009', label: 'Bertem', provincie: 0, slug: 'bertem' },
      { code: '62108', label: 'Visé', provincie: 0, slug: 'vise' },
      { code: '25105', label: 'Tubize', provincie: 0, slug: 'tubize' },
      { code: '57094', label: 'Leuze-en-Hainaut', provincie: 0, slug: 'leuze-en-hainaut' },
      { code: '63035', label: 'Herve', provincie: 0, slug: 'herve' },
      { code: '63089', label: 'Thimister-Clermont', provincie: 0, slug: 'thimister-clermont' },
      { code: '25122', label: 'Ramillies', provincie: 0, slug: 'ramillies' },
      { code: '25121', label: 'Ottignies-Louvain-la-Neuve', provincie: 0, slug: 'ottignies-louvain-la-neuve' },
      { code: '62063', label: 'Liège', provincie: 0, slug: 'liege' },
      { code: '64023', label: 'Donceel', provincie: 0, slug: 'donceel' },
      { code: '25023', label: 'Court-Saint-Étienne', provincie: 0, slug: 'court-saint-etienne' },
      { code: '62093', label: 'Saint-Nicolas', provincie: 0, slug: 'saint-nicolas' },
      { code: '92035', label: 'Éghezée', provincie: 0, slug: 'eghezee' },
      { code: '61063', label: 'Verlaine', provincie: 0, slug: 'verlaine' },
      { code: '63079', label: 'Verviers', provincie: 0, slug: 'verviers' },
      { code: '25072', label: 'Nivelles', provincie: 0, slug: 'nivelles' },
      { code: '62120', label: 'Flémalle', provincie: 0, slug: 'flemalle' },
      { code: '61068', label: 'Villers-le-Bouillet', provincie: 0, slug: 'villers-le-bouillet' },
      { code: '92138', label: 'Fernelmont', provincie: 0, slug: 'fernelmont' },
      { code: '53046', label: 'Lens', provincie: 0, slug: 'lens' },
      { code: '63058', label: 'Pepinster', provincie: 0, slug: 'pepinster' },
      { code: '92142', label: 'Gembloux', provincie: 0, slug: 'gembloux' },
      { code: '63038', label: 'Jalhay', provincie: 0, slug: 'jalhay' },
      { code: '25107', label: 'Villers-la-Ville', provincie: 0, slug: 'villers-la-ville' },
      { code: '62032', label: 'Esneux', provincie: 0, slug: 'esneux' },
      { code: '55004', label: 'Braine-le-Comte', provincie: 0, slug: 'braine-le-comte' },
      { code: '63046', label: 'Limbourg', provincie: 0, slug: 'limbourg' },
      { code: '25031', label: 'Genappe', provincie: 0, slug: 'genappe' },
      { code: '23045', label: 'Londerzeel', provincie: 0, slug: 'londerzeel' },
      { code: '24007', label: 'Begijnendijk', provincie: 0, slug: 'begijnendijk' },
      { code: '37020', label: 'Ardooie', provincie: 0, slug: 'ardooie' },
      { code: '63080', label: 'Waimes', provincie: 0, slug: 'waimes' },
      { code: '57064', label: 'Péruwelz', provincie: 0, slug: 'peruwelz' },
      { code: '63076', label: 'Theux', provincie: 0, slug: 'theux' },
      { code: '62121', label: 'Neupré', provincie: 0, slug: 'neupre' },
      { code: '57093', label: 'Brunehaut', provincie: 0, slug: 'brunehaut' },
      { code: '52055', label: 'Pont-à-Celles', provincie: 0, slug: 'pont-a-celles' },
      { code: '53044', label: 'Jurbise', provincie: 0, slug: 'jurbise' },
      { code: '55035', label: 'Le Rœulx', provincie: 0, slug: 'le-roeulx' },
      { code: '52021', label: 'Fleurus', provincie: 0, slug: 'fleurus' },
      { code: '92003', label: 'Andenne', provincie: 0, slug: 'andenne' },
      { code: '62009', label: 'Aywaille', provincie: 0, slug: 'aywaille' },
      { code: '58001', label: 'La Louvière', provincie: 0, slug: 'la-louviere' },
      { code: '63072', label: 'Spa', provincie: 0, slug: 'spa' },
      { code: '51009', label: 'Bernissart', provincie: 0, slug: 'bernissart' },
      { code: '92140', label: 'Jemeppe-sur-Sambre', provincie: 0, slug: 'jemeppe-sur-sambre' },
      { code: '42025', label: 'Wetteren', provincie: 0, slug: 'wetteren' },
      { code: '24109', label: 'Tremelo', provincie: 0, slug: 'tremelo' },
      { code: '36006', label: 'Hooglede', provincie: 0, slug: 'hooglede' },
      { code: '42011', label: 'Lebbeke', provincie: 0, slug: 'lebbeke' },
      { code: '32006', label: 'Houthulst', provincie: 0, slug: 'houthulst' },
      { code: '36019', label: 'Staden', provincie: 0, slug: 'staden' },
      { code: '63049', label: 'Malmedy', provincie: 0, slug: 'malmedy' },
      { code: '53014', label: 'Boussu', provincie: 0, slug: 'boussu' },
      { code: '83044', label: 'Rendeux', provincie: 0, slug: 'rendeux' },
      { code: '81004', label: 'Aubange', provincie: 0, slug: 'aubange' },
      { code: '11023', label: 'Kapellen', provincie: 0, slug: 'kapellen' },
      { code: '43007', label: 'Kaprijke', provincie: 0, slug: 'kaprijke' },
      { code: '44012', label: 'De Pinte', provincie: 0, slug: 'de-pinte' },
      { code: '34013', label: 'Harelbeke', provincie: 0, slug: 'harelbeke' },
      { code: '73109', label: 'Voeren', provincie: 0, slug: 'voeren' },
      { code: '25043', label: 'Incourt', provincie: 0, slug: 'incourt' },
      { code: '62022', label: 'Chaudfontaine', provincie: 0, slug: 'chaudfontaine' },
      { code: '92137', label: 'Sambreville', provincie: 0, slug: 'sambreville' },
      { code: '46020', label: 'Sint-Gillis-Waas', provincie: 0, slug: 'sint-gillis-waas' },
      { code: '71020', label: 'Halen', provincie: 0, slug: 'halen' },
      { code: '23052', label: 'Merchtem', provincie: 0, slug: 'merchtem' },
      { code: '23025', label: 'Grimbergen', provincie: 0, slug: 'grimbergen' },
      { code: '44081', label: 'Zulte', provincie: 0, slug: 'zulte' },
      { code: '71067', label: 'Zutendaal', provincie: 0, slug: 'zutendaal' },
      { code: '44052', label: 'Oosterzele', provincie: 0, slug: 'oosterzele' },
      { code: '23038', label: 'Kampenhout', provincie: 0, slug: 'kampenhout' },
      { code: '33041', label: 'Vleteren', provincie: 0, slug: 'vleteren' },
      { code: '24043', label: 'Holsbeek', provincie: 0, slug: 'holsbeek' },
      { code: '44020', label: 'Gavere', provincie: 0, slug: 'gavere' },
      { code: '71011', label: 'Diepenbeek', provincie: 0, slug: 'diepenbeek' },
      { code: '37017', label: 'Wielsbeke', provincie: 0, slug: 'wielsbeke' },
      { code: '33011', label: 'Ieper', provincie: 0, slug: 'ieper' },
      { code: '33037', label: 'Zonnebeke', provincie: 0, slug: 'zonnebeke' },
      { code: '24054', label: 'Kortenaken', provincie: 0, slug: 'kortenaken' },
      { code: '23102', label: 'Wemmel', provincie: 0, slug: 'wemmel' },
      { code: '41081', label: 'Zottegem', provincie: 0, slug: 'zottegem' },
      { code: '63073', label: 'Stavelot', provincie: 0, slug: 'stavelot' },
      { code: '61048', label: 'Ouffet', provincie: 0, slug: 'ouffet' },
      { code: '63075', label: 'Stoumont', provincie: 0, slug: 'stoumont' },
      { code: '53039', label: 'Hensies', provincie: 0, slug: 'hensies' },
      { code: '92054', label: 'Gesves', provincie: 0, slug: 'gesves' },
      { code: '52022', label: 'Fontaine-l\'Évêque', provincie: 0, slug: 'fontaine-leveque' },
      { code: '52074', label: 'Aiseau-Presles', provincie: 0, slug: 'aiseau-presles' },
      { code: '58003', label: 'Estinnes', provincie: 0, slug: 'estinnes' },
      { code: '53068', label: 'Quiévrain', provincie: 0, slug: 'quievrain' },
      { code: '92006', label: 'Assesse', provincie: 0, slug: 'assesse' },
      { code: '92101', label: 'Profondeville', provincie: 0, slug: 'profondeville' },
      { code: '63086', label: 'Trois-Ponts', provincie: 0, slug: 'trois-ponts' },
      { code: '91059', label: 'Hamois', provincie: 0, slug: 'hamois' },
      { code: '58002', label: 'Binche', provincie: 0, slug: 'binche' },
      { code: '52048', label: 'Montigny-le-Tilleul', provincie: 0, slug: 'montigny-le-tilleul' },
      { code: '56086', label: 'Ham-sur-Heure-Nalinnes', provincie: 0, slug: 'ham-sur-heure-nalinnes' },
      { code: '31022', label: 'Oostkamp', provincie: 0, slug: 'oostkamp' },
      { code: '13016', label: 'Hulshout', provincie: 0, slug: 'hulshout' },
      { code: '24094', label: 'Rotselaar', provincie: 0, slug: 'rotselaar' },
      { code: '34023', label: 'Kuurne', provincie: 0, slug: 'kuurne' },
      { code: '31003', label: 'Beernem', provincie: 0, slug: 'beernem' },
      { code: '42023', label: 'Waasmunster', provincie: 0, slug: 'waasmunster' },
      { code: '44064', label: 'Sint-Martens-Latem', provincie: 0, slug: 'sint-martens-latem' },
      { code: '71022', label: 'Hasselt', provincie: 0, slug: 'hasselt' },
      { code: '73006', label: 'Bilzen', provincie: 0, slug: 'bilzen' },
      { code: '23103', label: 'Wezembeek-Oppem', provincie: 0, slug: 'wezembeek-oppem' },
      { code: '33016', label: 'Mesen', provincie: 0, slug: 'mesen' },
      { code: '63048', label: 'Lontzen', provincie: 0, slug: 'lontzen' },
      { code: '55040', label: 'Soignies', provincie: 0, slug: 'soignies' },
      { code: '52075', label: 'Les Bons Villers', provincie: 0, slug: 'les-bons-villers' },
      { code: '58004', label: 'Morlanwelz', provincie: 0, slug: 'morlanwelz' },
      { code: '93056', label: 'Philippeville', provincie: 0, slug: 'philippeville' },
      { code: '11055', label: 'Zoersel', provincie: 0, slug: 'zoersel' },
      { code: '11013', label: 'Edegem', provincie: 0, slug: 'edegem' },
      { code: '42026', label: 'Wichelen', provincie: 0, slug: 'wichelen' },
      { code: '41063', label: 'Sint-Lievens-Houtem', provincie: 0, slug: 'sint-lievens-houtem' },
      { code: '24133', label: 'Linter', provincie: 0, slug: 'linter' },
      { code: '25015', label: 'Braine-le-Château', provincie: 0, slug: 'braine-le-chateau' },
      { code: '55050', label: 'Écaussinnes', provincie: 0, slug: 'ecaussinnes' },
      { code: '92045', label: 'Floreffe', provincie: 0, slug: 'floreffe' },
      { code: '63087', label: 'Burg-Reuland', provincie: 0, slug: 'burg-reuland' },
      { code: '81015', label: 'Messancy', provincie: 0, slug: 'messancy' },
      { code: '11039', label: 'Schilde', provincie: 0, slug: 'schilde' },
      { code: '46014', label: 'Lokeren', provincie: 0, slug: 'lokeren' },
      { code: '12040', label: 'Willebroek', provincie: 0, slug: 'willebroek' },
      { code: '44048', label: 'Nazareth', provincie: 0, slug: 'nazareth' },
      { code: '34009', label: 'Deerlijk', provincie: 0, slug: 'deerlijk' },
      { code: '25048', label: 'Jodoigne', provincie: 0, slug: 'jodoigne' },
      { code: '25044', label: 'Ittre', provincie: 0, slug: 'ittre' },
      { code: '57003', label: 'Antoing', provincie: 0, slug: 'antoing' },
      { code: '62015', label: 'Beyne-Heusay', provincie: 0, slug: 'beyne-heusay' },
      { code: '64075', label: 'Wasseiges', provincie: 0, slug: 'wasseiges' },
      { code: '64065', label: 'Saint-Georges-sur-Meuse', provincie: 0, slug: 'saint-georges-sur-meuse' },
      { code: '51012', label: 'Brugelette', provincie: 0, slug: 'brugelette' },
      { code: '62096', label: 'Seraing', provincie: 0, slug: 'seraing' },
      { code: '25117', label: 'Chastre', provincie: 0, slug: 'chastre' },
      { code: '63057', label: 'Olne', provincie: 0, slug: 'olne' },
      { code: '51014', label: 'Chièvres', provincie: 0, slug: 'chievres' },
      { code: '61010', label: 'Burdinne', provincie: 0, slug: 'burdinne' },
      { code: '62122', label: 'Trooz', provincie: 0, slug: 'trooz' },
      { code: '61080', label: 'Engis', provincie: 0, slug: 'engis' },
      { code: '61072', label: 'Wanze', provincie: 0, slug: 'wanze' },
      { code: '51008', label: 'Belœil', provincie: 0, slug: 'beloeil' },
      { code: '61028', label: 'Héron', provincie: 0, slug: 'heron' },
      { code: '57072', label: 'Rumes', provincie: 0, slug: 'rumes' },
      { code: '92141', label: 'La Bruyère', provincie: 0, slug: 'la-bruyere' },
      { code: '62100', label: 'Sprimont', provincie: 0, slug: 'sprimont' },
      { code: '61043', label: 'Nandrin', provincie: 0, slug: 'nandrin' },
      { code: '53070', label: 'Saint-Ghislain', provincie: 0, slug: 'saint-ghislain' },
      { code: '63013', label: 'Butgenbach', provincie: 0, slug: 'butgenbach' },
      { code: '92094', label: 'Namur', provincie: 0, slug: 'namur' },
      { code: '55086', label: 'Manage', provincie: 0, slug: 'manage' },
      { code: '62026', label: 'Comblain-au-Pont', provincie: 0, slug: 'comblain-au-pont' },
      { code: '53053', label: 'Mons', provincie: 0, slug: 'mons' },
      { code: '61039', label: 'Marchin', provincie: 0, slug: 'marchin' },
      { code: '56049', label: 'Merbes-le-Château', provincie: 0, slug: 'merbes-le-chateau' },
      { code: '84035', label: 'Libin', provincie: 0, slug: 'libin' },
      { code: '37015', label: 'Tielt', provincie: 0, slug: 'tielt' },
      { code: '23088', label: 'Vilvoorde', provincie: 0, slug: 'vilvoorde' },
      { code: '24086', label: 'Oud-Heverlee', provincie: 0, slug: 'oud-heverlee' },
      { code: '51004', label: 'Ath', provincie: 0, slug: 'ath' },
      { code: '92114', label: 'Sombreffe', provincie: 0, slug: 'sombreffe' },
      { code: '56044', label: 'Lobbes', provincie: 0, slug: 'lobbes' },
      { code: '91015', label: 'Bièvre', provincie: 0, slug: 'bievre' },
      { code: '38025', label: 'Veurne', provincie: 0, slug: 'veurne' },
      { code: '11005', label: 'Boom', provincie: 0, slug: 'boom' },
      { code: '42028', label: 'Zele', provincie: 0, slug: 'zele' },
      { code: '72041', label: 'Dilsen-Stokkem', provincie: 0, slug: 'dilsen-stokkem' },
      { code: '72004', label: 'Bree', provincie: 0, slug: 'bree' },
      { code: '71057', label: 'Tessenderlo', provincie: 0, slug: 'tessenderlo' },
      { code: '12025', label: 'Mechelen', provincie: 0, slug: 'mechelen' },
      { code: '32011', label: 'Kortemark', provincie: 0, slug: 'kortemark' },
      { code: '71070', label: 'Heusden-Zolder', provincie: 0, slug: 'heusden-zolder' },
      { code: '24020', label: 'Diest', provincie: 0, slug: 'diest' },
      { code: '12005', label: 'Bonheiden', provincie: 0, slug: 'bonheiden' },
      { code: '71002', label: 'As', provincie: 0, slug: 'as' },
      { code: '34040', label: 'Waregem', provincie: 0, slug: 'waregem' },
      { code: '45065', label: 'Zwalm', provincie: 0, slug: 'zwalm' },
      { code: '41011', label: 'Denderleeuw', provincie: 0, slug: 'denderleeuw' },
      { code: '45035', label: 'Oudenaarde', provincie: 0, slug: 'oudenaarde' },
      { code: '36010', label: 'Ledegem', provincie: 0, slug: 'ledegem' },
      { code: '23086', label: 'Ternat', provincie: 0, slug: 'ternat' },
      { code: '73040', label: 'Kortessem', provincie: 0, slug: 'kortessem' },
      { code: '21006', label: 'Evere', provincie: 0, slug: 'evere' },
      { code: '71053', label: 'Sint-Truiden', provincie: 0, slug: 'sint-truiden' },
      { code: '24011', label: 'Bierbeek', provincie: 0, slug: 'bierbeek' },
      { code: '73098', label: 'Wellen', provincie: 0, slug: 'wellen' },
      { code: '24104', label: 'Tervuren', provincie: 0, slug: 'tervuren' },
      { code: '23099', label: 'Kraainem', provincie: 0, slug: 'kraainem' },
      { code: '45062', label: 'Horebeke', provincie: 0, slug: 'horebeke' },
      { code: '23097', label: 'Roosdaal', provincie: 0, slug: 'roosdaal' },
      { code: '73009', label: 'Borgloon', provincie: 0, slug: 'borgloon' },
      { code: '45059', label: 'Brakel', provincie: 0, slug: 'brakel' },
      { code: '45063', label: 'Lierde', provincie: 0, slug: 'lierde' },
      { code: '33039', label: 'Heuvelland', provincie: 0, slug: 'heuvelland' },
      { code: '24045', label: 'Huldenberg', provincie: 0, slug: 'huldenberg' },
      { code: '57097', label: 'Komen-Waasten', provincie: 0, slug: 'komen-waasten' },
      { code: '23024', label: 'Gooik', provincie: 0, slug: 'gooik' },
      { code: '24041', label: 'Hoegaarden', provincie: 0, slug: 'hoegaarden' },
      { code: '23062', label: 'Overijse', provincie: 0, slug: 'overijse' },
      { code: '25037', label: 'Grez-Doiceau', provincie: 0, slug: 'grez-doiceau' },
      { code: '23064', label: 'Pepingen', provincie: 0, slug: 'pepingen' },
      { code: '51019', label: 'Vloesberg', provincie: 0, slug: 'vloesberg' },
      { code: '23023', label: 'Galmaarden', provincie: 0, slug: 'galmaarden' },
      { code: '23100', label: 'Linkebeek', provincie: 0, slug: 'linkebeek' },
      { code: '57095', label: 'Mont-de-l\'Enclus', provincie: 0, slug: 'mont-de-lenclus' },
      { code: '2000', label: 'Vlaams Gewest', provincie: 0, slug: 'vlaams-gewest' },
    ],
  },
}
