<template>
  <img
    class="d-inline mr-1"
    width="15"
    :src="icon"
  >
</template>

<script>
import { image } from '@/helpers/assets'

export default {
  name: 'InlineChargingpointIcon',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return image({ name: `chargingpoint/png/${this.status}.png` })
    },
  },
}
</script>
