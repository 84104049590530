/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  loaded: false,
  highlighted: [],
  labelLayer: null,
  visibility: true,
}

const getters = {
  /**
   * Whether MapBox has loaded
   */
  ready: state => state.loaded,

  /**
   * Get the id of the highlighted hexagon
   */
  getHighlighted: state => state.highlighted,
  /**
   * The first label layer. We want to add our layers below this one
   */
  labelLayer: state => state.labelLayer,

  /**
   * Whether the Hexagon layer is visible
   */
  visibility: state => !! state.visibility,

  /*
  * layers // todo:: add prognose layers here
  */
}
const actions = {

}
const mutations = {
  setLoaded(state, { loaded }) {
    state.loaded = loaded
  },
  setHighlighted(state, { fids }) {
    state.highlighted = fids
  },
  setLabelLayer(state, { id }) {
    state.labelLayer = id
  },
  setVisibility(state, { visibility }) {
    state.visibility = visibility
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
