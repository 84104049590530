<template>
  <NavBar class="ImportNavBar">
    <router-link
      v-if="superuser"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Dashboard', params: { municipality: activeMunicipalitySlug } }"
    >
      <SvgIcon
        icon="cog-regular"
        :has-fill="true"
      />
    </router-link>
  </NavBar>
</template>

<script>
import NavBar from '@/components/common/NavBar'
import SvgIcon from '@/components/common/SvgIcon'

import { codeToSlug } from '@/services/municipalities'

import privilegesMixin from '@/mixins/common/privilegesMixin'

import { mapGetters } from 'vuex'

export default {
  name: 'ImportNavBar',
  components: {
    NavBar, SvgIcon,
  },
  mixins: [privilegesMixin],
  computed: {
    ...mapGetters('config', [
      'hasLayers',
    ]),
    ...mapGetters('access', [
      'municipalityCodeList',
      'getActiveMunicipality',
      'hasAdminAccess',
    ]),
    activeMunicipalitySlug() {
      return codeToSlug({ code: this.getActiveMunicipality })
    },
    canPreAccessPrognose() {
      return this.hasLayers({ code: this.getActiveMunicipality }) && ( this.superuser || this.admin )
    },
  },
}
</script>

<style lang="scss">


.ExportNavBar__Logout {
  color: white;
  cursor: pointer;

  &:hover {
    color: var(--cta);
  }
}
</style>
