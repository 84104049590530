<template>
  <div class="BarChart">
    <GChart
      type="BarChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { numberWithDots } from '@/helpers/number'
import { labelColor } from '@/helpers/charts'
import getCssProperty from '@/helpers/cssProperty'

export default {
  name: 'BarChart',
  components: { GChart },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          title: 'Toename elektrische auto\'s',
        },
        isStacked: true,
        legend: { position: 'right', textStyle: { color: labelColor } },
        hAxis: { textPosition: 'out', textStyle: { color: labelColor } },
        vAxis: { textStyle: { bold: true, color: labelColor } },
        bar: { groupWidth: '70%' },
        colors: [
          '#E4EDF5',
          getCssProperty('secondary'),
          getCssProperty('primary'),
        ],
        height: 122,
        width: 475,
        chartArea: { left: 50, right: 135 },
        focusTarget: 'category',
        animation: {
          duration: 500,
        },
      },
    }
  },
  computed: {
    chartData() {
      const chartSerieLabels = ['Jaar', 'Bewoners', 'Bezoekers', 'Forenzen', { role: 'annotation' }]
      const data = [chartSerieLabels]

      Object.entries(this.data).forEach(entry => {
        data.push([entry[0], entry[1][0], entry[1][1], entry[1][2], numberWithDots(entry[1][3])])
      })

      return data
    },
  },
}
</script>

<style>

</style>
