/*global google */

const fetch = require('node-fetch')
const { Loader } = require('@googlemaps/js-api-loader')
const { formatPostalCode } = require('../../src/helpers/string')

let loaded = false

const initGoogleAPILoader = () => {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY,
    version: 'weekly',
  })
  loader.load().then(() => {
    loaded = true
  })
}

/**
 * PUBLIC
 */
const getAddressByReverseGeocoding = async ({ coordinates }) => {
  let lng = coordinates[0]
  let lat = coordinates[1]
  let endpoint = 'https://maps.googleapis.com/maps/api/geocode/json'
  let address = await fetch(
    `${endpoint}?latlng=${lat},${lng}&key=${process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY}&language=nl&result_type=street_address|route`,
  )
  address = await address.json()

  // The status is ok if there are results
  if (address.status === 'OK') {

    // See: https://developers.google.com/maps/documentation/geocoding/overview#ReverseGeocoding
    address = address.results[0]

    let components = address.address_components.reduce(componentsToAddressObject, {})

    return enrichAddress({ components, address })
  }

  return false
}

/**
 * May be triggered in realtime based on user interaction
 *  https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
 */
const dynamicReverseGeocode = async ({ lat, lng }) => {
  const latlng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }

  const validTypes = ['street_address', 'premise', 'subpremise']

  return new Promise((resolve, reject) => {
    if (! loaded) {
      reject('Geocoder is not (yet) available')
    } else {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK' && results.length !== 0) {
          results = results.filter(result => validTypes.some(type => result.types.includes(type)))

          if (results.length !== 0) {
            const bestAddress = results[0]
            const components = bestAddress.address_components.reduce(componentsToAddressObject, {})

            resolve(enrichAddress({ components, address: bestAddress }))
          } else {
            reject('not found')
          }
        } else {
          reject(status)
        }
      })
    }
  })
}

/**
 * PRIVATE
 */

const componentsToAddressObject = (result, component) => {

  if (component.types.includes('administrative_area_level_2')) {
    result.municipality = component.long_name
  }
  else if (component.types.includes('administrative_area_level_1')) {
    result.province = component.long_name
  }
  else if (component.types.includes('country')) {
    result.country = component.long_name
  }
  else if (component.types.includes('postal_code')) {
    result.postalCode = formatPostalCode(component.long_name)
  }
  else if (component.types.includes('route')) {
    result.street = component.long_name
  }
  // This consists of number + suffix...
  else if (component.types.includes('street_number')) {
    result.street_number = component.long_name
  }
  else if (component.types.includes('locality')) {
    result.city = component.long_name
  }

  return result
}

const enrichAddress = ({ components, address }) => ({
  source: 'Google',
  runCount: 1, // This is the geocoder version.

  postalcode: components.postalCode ?? null,
  street: components.street ?? null,
  number: components.street_number ?? null,
  municipality: components.municipality ?? null,
  city: components.city ?? null,
  country: components.country ?? null,
  province: components.province ?? null,

  simple_address: `${components.street ?? ''} ${components.street_number ?? ''}`.trim(), // e.g. 'Helmkruid 33'
  formatted_address: address.formatted_address, // e.g. 'Helmkruid 33, 5684 HP Best, Nederland'
  coordinates: address.geometry.location, // e.g. {"lat": 51.4957034,"lng": 5.3889716} // the geolocation lat lng
})

module.exports = {
  initGoogleAPILoader,
  getAddressByReverseGeocoding,
  dynamicReverseGeocode,
}
