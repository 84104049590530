<template>
  <div class="DonutChart">
    <GChart
      type="PieChart"
      :settings="{packages: ['corechart', 'table'], language: 'nl'}"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

export default {
  name: 'DonutChart',
  components: { GChart },
  props: {
    data: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: { title: this.title },
        legend: { position: 'none' },
        pieHole: 0.55,
        colors: this.data.map(({ color }) => color),
        height: 190,
        width: 190,
        chartArea: { top: 0, bottom: 0, left: 20, right: 20 },
        pieStartAngle: 270,
        pieSliceText: 'value',
        slices: {
          [this.data.length]: {
            color: 'transparent',
          },
        },
        theme: 'maximized',
        animation: {
          duration: 500,
        },
      }
    },
    chartData() {
      const chartSerieLabels = ['reactie', 'aandeel', { role: 'tooltip' }]
      const data = [chartSerieLabels]

      this.data.forEach(({ key, value, label }) => data.push([key, { v: value / 2, f: `${Math.round(value * 100)}%` }, label]))

      return [...data, [null, 0.5, null]]
    },
  },
}
</script>

<style>
.DonutChart {
  height: calc(190px / 2);
  overflow: hidden;
}
</style>
