/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  version: '2.9.1',
}

const getters = {
  version: state => {
    return state.version
  },
}
const actions = {

}
const mutations = {

}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
