<template>
  <div v-if="condition">
    <div class="d-flex pt-2 justify-content-between align-items-center">
      <span class="mr-3">{{ $t(filter.subline) }}</span>
      <Dropdown
        :value="filter.active"
        @input="handleDropdownInput"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/form/Dropdown'
export default {
  components: { Dropdown },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    condition: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    handleDropdownInput(newValue) {
      this.$emit('input', { selectedFilter: { ...this.filter, selectedDate: newValue } })
    },
  },
}
</script>
