<template>
  <tr>
    <td style="width: 50px">
      <slot name="first-column" />
    </td>
    <td
      style="width: 50px"
      class="text-center"
    >
      <slot name="second-column" />
    </td>
    <td
      style="width: 50px"
      class="text-center"
    >
      <slot name="third-column" />
    </td>
    <td
      v-if="nrOfColumns === 4"
      style="width: 50px"
      class="text-center"
    >
      <slot name="fourth-column" />
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TileDataContentHeader',
  props: {
    nrOfColumns: {
      type: Number,
      default: 3,
    },
  },
}
</script>
