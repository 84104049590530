<template>
  <component
    :is="forecastDataTemplateComponent"
    :fids="fids"
    :layer-name="layerName"
    :forecast-label="forecastLabel"
    :dataset="dataset"
    :data-by-id="dataById"
    :is-province="isProvince"
  />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import userMixin from '@/mixins/common/userMixin'
import TileDataElaadForecast from '@/components/map/tiles/TileDataElaadForecast'
import TileDataContent from '@/components/map/tiles/TileDataContent'
import TileDataOMCorridors from '@/components/map/tiles/TileDataOMCorridors'
import TileDataOMNeighborhoods from '@/components/map/tiles/TileDataOMNeighborhoods'
import TileDataGemeenteprognose from '@/components/map/tiles/TileDataGemeenteprognose'
import TileDataElaadFastcharge from '@/components/map/tiles/TileDataElaadFastcharge'
import TileDataElaadLogistics from '@/components/map/tiles/TileDataElaadLogistics'
import TileDataGoralLogistics from '@/components/map/tiles/TileDataGoralLogistics'
import TileDataOMLogistics from '@/components/map/tiles/TileDataOMLogistics'
import TileDataRegularChargingNeighborhood from '@/components/map/tiles/TileDataRegularChargingNeighborhood'

export default {
  name: 'NeighborhoodData',
  components: {
    TileDataElaadForecast,
    TileDataElaadFastcharge,
    TileDataElaadLogistics,
    TileDataGoralLogistics,
    TileDataOMLogistics,
    TileDataGemeenteprognose,
    TileDataOMNeighborhoods,
    TileDataOMCorridors,
    TileDataContent,
    TileDataRegularChargingNeighborhood,
  },
  mixins: [userMixin],
  props: {
    loaded: {
      type: Boolean,
      value: false,
    },
  },
  data() {
    return {
      // This data is obtained from the prognose layer on click

      Bezoekers: null,
      Forenzen: null,
      Pubparkeer: null,

      fids: [],

      dataById: {},
    }
  },
  computed: {
    ...mapGetters('prognose', [
      'getHighlighted',
    ]),
    ...mapGetters('config', {
      configLimit: 'limit',
    }),
    ...mapGetters('scenarios', [
      'year', 'forecast',
    ]),
    ...mapGetters('planmode', {
      isPlanModeActive: 'isActive',
    }),
    ...mapGetters('access', [
      'getActiveMunicipality',
      'hasAdminAccess',
      'canAccessLayer',
    ]),
    ...mapGetters('layers', {
      forecastLayers: 'getForecastLayerDetails',
    }),
    limit() {
      return parseInt(this.configLimit, 10) * this.fids.length
    },
    superuser() {
      return this.$auth.user && this.$auth.user['https://evmaps.nl/superuser']
    },
    hasAccess() {
      return this.canAccessLayer({ id: this.layer?.id })
    },
    layer() {
      return this.forecastLayers.find(forecast => forecast.id === this.forecast)
    },
    forecastDataTemplateComponent() {
      return this.layer.config.forecastDataTemplateComponent ?? 'TileDataContent'
    },
    forecastLabel() {
      return this.layer?.label ?? ''
    },
    isProvince () {
      return this.layer.config.province
    },
    dataset() {
      return this.layer?.config?.dataset ?? []
    },

    idProp() {
      return this.layer?.config?.propertyWithTileID ?? 'buurtcode'
    },

    layerName() {
      return this.forecast
    },
    layerNames() {
      return this.forecastLayers.map(layer => layer.id)
    },
  },
  watch: {
    loaded() {
      if (this.layerName) {
        this.activate({ layerName: this.layerName })
      }
    },
    hasAccess() {
      if (this.hasAccess) {
        if (this.layerName) {
          this.activate({ layerName: this.layerName })
        }
      } else {
        this.disable()
      }
    },
    /**
     * Clear the selected tile when the municipality changes
     */
    getActiveMunicipality() {
      this.fids = []
    },
    layerName() {
      this.fids = []

      if (this.layerName) {
        this.activate({ layerName: this.layerName })
      }
    },
    fids() {
      this.setHighlighted({ fids: this.fids })
    },
  },
  created() {
    if (this.layerName) {
      this.activate({ layerName: this.layerName })
    }
  },
  beforeDestroy() {
    this.disable()
  },
  methods: {
    ...mapMutations('prognose', [
      'setHighlighted',
    ]),
    disable() {
      if (! this.$store.map) return

      const toBeDeactivated = new Set(this.layerNames)
      this.forecastLayers.forEach(layer => toBeDeactivated.add(layer.id))

      toBeDeactivated.forEach(layerName => {
        this.$store.map.off('click', layerName, this.handleClick)
        this.$store.map.off('contextmenu', layerName, this.handleContext)
        this.$store.map.off('mouseenter', layerName, this.mouseEnter)
        this.$store.map.off('mouseleave', layerName, this.mouseLeave)
      })
    },
    activate({ layerName }) {
      this.disable()

      if (!this.loaded) return

      this.$store.map.on('click', layerName, this.handleClick)
      // TODO: Figure out a fix to avoid this
      if (navigator.appVersion.indexOf('Win') === -1) { // OSX
        this.$store.map.on('contextmenu', layerName, this.handleContext)
      }

      // Cursor on hover
      this.$store.map.on('mouseenter', layerName, this.mouseEnter)
      this.$store.map.on('mouseleave', layerName, this.mouseLeave)
    },
    oneDecimal(num) {
      return (Math.round((num * 10)) / 10).toFixed(1)
    },
    atLimit(num) {
      return num === this.limit
    },
    applyLimit(num) {
      return num > this.limit ? this.limit : num
    },
    handleClick(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e.originalEvent.defaultPrevented) {
        return
      }

      if (
        (e.originalEvent.ctrlKey || e.originalEvent.button == 2) &&
        navigator.appVersion.indexOf('Win') === -1 // Continue on Windows OS
      ) {
        return
      }

      this.selectTile(e)
    },
    handleContext(e) {
      // Ignore prognose interaction if the planmode is active
      if (e.features.length === 0 || this.isPlanModeActive || e._defaultPrevented) {
        return
      }

      if ( ! (e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        return
      }

      this.selectTile(e)
    },
    selectTile(e) {
      let props = e.features[0].properties

      let idProp = this.idProp

      if (! props[idProp]) return

      // Holding CTRL
      if ((e.originalEvent.ctrlKey || e.originalEvent.button == 2)) {
        // Obtain the data from the prognose layer
        if (! this.fids.includes(props[idProp])) {
          this.dataById[props[idProp]] = props
          this.fids.push(props[idProp])

        } else {
          let index = this.fids.indexOf(props[idProp])
          this.fids.splice(index, 1)
          delete this.dataById[props[idProp]]
        }
      } else {
        if (! this.fids.includes(props[idProp]) || this.fids.length > 1) {
          // Not emptying data object, as that would lead to computation errors
          this.dataById[props[idProp]] = props
          this.fids = [props[idProp]]
        } else {
          this.fids = []
          this.dataById = {}
        }
      }
    },
    mouseEnter() {
      this.$store.map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeave() {
      this.$store.map.getCanvas().style.cursor = ''
    },
  },
}
</script>

<style>
.tooltip-inner {
  max-width: 250px !important;
}

.tooltip-icon {
  cursor: pointer;
}
</style>
