<template>
  <div class="ManagementDashboard">
    <p v-if="!isElaadForecast">
      {{ $t("components.navbar.managementDashboard.noData") }}
    </p>
    <div v-else-if="!isLoading && isElaadForecast && numPrognose !== 0">
      <div class="data-container">
        <div class="column">
          <div class="header" />
          <div>
            {{ $t("components.navbar.managementDashboard.forecast") }}
            {{ year }}
          </div>
          <div>{{ $t("groupLabels.realized") }}</div>
          <div>{{ $t("groupLabels.in-progress") }}</div>
          <div>{{ $t("groupLabels.suggestion") }}</div>
          <div>{{ $t("groupLabels.definitive") }}</div>
          <div class="border-top">
            <i>{{ $t("components.navbar.managementDashboard.toSuggest") }}</i>
          </div>
        </div>
        <div class="column">
          <div class="header">
            <b>{{ $t("components.navbar.managementDashboard.numSemiPublic") }}</b>
          </div>
          <div class="text-right">
            {{ numPrognose }}
          </div>
          <div class="text-right">
            {{ numRealized }}
          </div>
          <div class="text-right">
            {{ numInProgress }}
          </div>
          <div class="text-right">
            {{ numSuggested }}
          </div>
          <div class="text-right">
            {{ numValidated }}
          </div>
          <div class="border-top text-right">
            <i>{{ numToSuggest }}</i>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <span>{{ $t("loading") }}</span>
      <b-spinner
        small
        variant="primary"
        class="flex justify-center"
      />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/services/eventbus'
import { mapGetters } from 'vuex'
import chargingpointsLoadMixin from '@/mixins/chargingpoint/chargingpointsLoadMixin'

export default {
  name: 'ManagementDashboard',
  mixins: [chargingpointsLoadMixin],
  data() {
    return {
      isLoading: false,
      chargingPoints: [],
      numPrognose: 0,
    }
  },
  computed: {
    ...mapGetters('scenarios', ['year', 'forecast']),
    ...mapGetters('access', ['getActiveMunicipality']),
    numRealized() {
      return this.fetchChargingPointsByStatus('realized')
    },
    numInProgress() {
      return this.fetchChargingPointsByStatus('in-progress')
    },
    numSuggested() {
      return this.fetchChargingPointsByStatus('suggestion')
    },
    numValidated() {
      return this.fetchChargingPointsByStatus('definitive')
    },
    numToSuggest() {
      return (
        this.numPrognose -
        this.numRealized -
        this.numValidated -
        this.numInProgress -
        this.numSuggested
      )
    },
    isElaadForecast() {
      return [
        'elaad_buurt_low',
        'elaad_buurt_mid',
        'elaad_buurt_high',
      ].includes(this.forecast)
    },
    source() {
      return this.$store.map.getSource(this.forecast)
    },
  },
  watch: {
    getActiveMunicipality() {
      if (this.isElaadForecast) {
        this.activate()
        this.fetchAllChargingPoints()
      }
    },
    forecast() {
      if (this.isElaadForecast) {
        this.activate()
        this.fetchAllChargingPoints()
      }
    },
    isElaadForecast(newValue) {
      if (newValue && this.getActiveMunicipality) {
        this.activate()
        this.fetchAllChargingPoints()
      }
    },
  },
  async created() {
    this.activate()

    if (!this.isElaadForecast) return

    this.getForecastValue()
    this.fetchAllChargingPoints()
  },
  beforeDestroy() {
    this.disable()
  },
  methods: {
    activate() {
      this.disable()
      EventBus.$on('overlay-click', this.maybeClose)
    },
    disable() {
      EventBus.$off('overlay-click', this.maybeClose)
    },
    async getElaadData({ layer }) {
      try {
        const token = await this.$auth.getTokenSilently()

        const apiCall = await fetch('/api/get-maps-data', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ layer }),
        })

        const response = await apiCall.json()

        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async getForecastValue() {
      this.isLoading = true

      const mapsData = await this.getElaadData({ layer: 'elaadBuurt2024' })
      const propertyName = this.generateFieldName(
        this.getActiveMunicipality,
        this.year,
      )
      const numStations = mapsData.data.features.find(
        (obj) => obj.properties.key === propertyName,
      )

      this.numPrognose = Math.round(numStations.properties.value)
      this.isLoading = false
    },
    async fetchAllChargingPoints() {
      try {
        this.isLoading = true

        this.chargingPoints =
          await this.$_chargingpointsLoadMixin_loadChargingPoints({
            code: this.getActiveMunicipality,
          })

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error('Error fetching charging points:', error)
      }
    },
    fetchChargingPointsByStatus(status) {
      return this.chargingPoints
        .filter((chargingpoint) => {
          return (
            chargingpoint.data.properties.status.includes(status) &&
            !chargingpoint.data.properties.status.includes('private')
          )
        })
        .reduce(
          (sum, chargingpoint) =>
            sum + (chargingpoint.data.properties.nroutlets || 2),
          0,
        )
    },
    generateFieldName(munCode, year) {
      const suffix = this.forecast.endsWith('low')
        ? 'L'
        : this.forecast.endsWith('mid')
        ? 'M'
        : this.forecast.endsWith('high')
        ? 'H'
        : ''

      return `tot_semi_publiek_${munCode}_${year}_semi_publiekelaadpunten_${suffix}`
    },
    maybeClose() {
      EventBus.$emit('overlay-hide')
    },
  },
}
</script>

<style lang="scss">
.ManagementDashboard {
  position: relative;
  background: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  opacity: 0.999;
  padding: 1rem;
  min-width: 20rem;
  min-height: 5rem;

  p {
    align-self: center;
    margin: 1rem;
    max-width: 100%;
  }

  .data-container {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .header {
    font-weight: bold;
    padding-bottom: 0.5rem;
    height: 24px;
  }
}
</style>
