/**
 * Import Dependency
 */
import { slugToCode, labelByCode } from "@/services/municipalities";
import { configGenerator } from "@/services/configGenerator";
import { checkStatus, returnJson } from "@/helpers/api";
import { defaultLocationCriteria } from "@/data/defaultLocationCriteria";
/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  /**
   * Configs of all municipalities that the user has access to
   */
  configs: [],

  active: null,

  loaded: false,

  // Generic config values
  accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
  mapStyle: process.env.VUE_APP_MAPBOX_STYLE,
  mapStyleSat: process.env.VUE_APP_MAPBOX_STYLE_SAT,
  // Current Css theme //
  currentTheme: null,
};

const getters = {
  loaded: (state) => state.loaded,
  accessToken: (state) => state.accessToken,
  mapStyle: (state) => state.mapStyle,
  mapStyleSat: (state) => state.mapStyleSat,

  /**************************************************************************************
   * GETTERS about all configs
   */

  /**
   * Get a list with codes of the loaded configs
   */
  getAvailableConfigCodes: (state) =>
    state.configs.map((config) => config.code),

  /**************************************************************************************
   * GETTERS for information by CODE
   */

  /**
   * Check the availability of a specific config by code or slug
   */
  hasConfig:
    (state, getters) =>
    ({ code, slug }) => {
      if (slug) {
        code = slugToCode({ slug });
      }
      return !!getters.getConfigByCode({ code });
    },
  hasLayers:
    (state, getters) =>
    ({ code, slug }) => {
      if (slug) {
        code = slugToCode({ slug });
      }

      let config = getters.getConfigByCode({ code });

      if (!config) return false;
      let layers = Object.values(config.layers);

      return (
        layers.length !== 0 && layers.some((layer) => layer.status !== "hidden")
      );
    },

  /**
   * Get the details of a specific config by code or slug
   */
  getConfigByCode:
    (state) =>
    ({ code }) => {
      return state.configs.find((config) => config.code === code);
    },
  getConfigBySlug:
    (state, getters) =>
    ({ slug }) => {
      return getters.getConfigByCode({ code: slugToCode({ slug }) });
    },

  /**
   * Check whether the `stored` flag is set. This indicates whether the record came from storage
   *  The flag is set by the lambda endpoints on all configs that originate from storage
   */
  isStoredByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config ? !!config.stored : false;
    },

  /**
   * Get enabled states for specific municipalities by code
   */
  isEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config ? config.enabled : false;
    },
  isPrognoseEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config && config.prognose ? config.prognose.enabled : false;
    },
  isPlanmodeEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config ? config.planmode : false;
    },
  isUploadEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config ? config.upload : false;
    },
  isFactsheetEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config ? config.factsheet : false;
    },
  isExportEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config && config.export ? config.export.enabled : false;
    },
  isMonitoringEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config && config.monitoring ? config.monitoring.enabled : false;
    },
  isParticipationEnabledByCode:
    (state, getters) =>
    ({ code }) => {
      let config = getters.getConfigByCode({ code });
      return config.participation?.some(({ enabled }) => !!enabled);
    },

  /**************************************************************************************
   * GETTERS for Active Config
   */

  /**
   * The complete config of the active municipality
   */
  config: (state) => {
    return state.configs.find((config) => config.code === state.active) || {};
  },
  hasSelectedConfig: (state, getters) => getters.config !== {},

  /**
   * Check whether the `stored` flag is set. This indicates whether the record came from storage
   *  The flag is set by the lambda endpoints on all configs that originate from storage
   */
  isStored: (state, getters) => !!getters.config.stored,

  /**
   * Whether the municipality / ev maps features are enabled
   */
  isEnabled: (state, getters) => getters.config.enabled,
  isPrognoseEnabled: (state, getters) =>
    !!getters.config.prognose?.enabled || false,
  isPlanmodeEnabled: (state, getters) => !!getters.config.planmode,
  isUploadEnabled: (state, getters) => !!getters.config.upload,
  isFactsheetEnabled: (state, getters) => !!getters.config.factsheet,
  isBedrijventerreinenEnabled: (state, getters) =>
    !!getters.config.bedrijventerreinen,
  isExportEnabled: (state, getters) =>
    !!getters.config.export?.enabled || false,
  isMonitoringEnabled: (state, getters) =>
    !!getters.config.monitoring?.enabled || false,
  isParticipationEnabled: (state, getters) => {
    if (Array.isArray(getters.config.participation)) {
      return getters.config.participation?.some((config) => config.enabled);
    }

    return !!getters.config.participation?.enabled;
  },

  /**
   * Get the layer data
   */
  layers: (state, getters) => getters.config.layers,
  /**
   * Filter the layer configs. Only pass on the context type layers
   */
  contextLayers: (state, getters, rootState, rootGetters) => {
    const configContextLayers = {};
    const ids = rootGetters["layers/getContextLayerDetails"].map(
      (layer) => layer.id
    );

    // filter only context layers
    Object.entries(getters.layers).forEach((entry) => {
      if (ids.includes(entry[0])) {
        configContextLayers[entry[0]] = entry[1];
      }
    });

    return configContextLayers;
  },

  forecastOptions: (state, getters, rootState, rootGetters) => {
    let code = getters.config.code;
    let deployment = rootGetters["deployment/DeploymentCode"]; // be / nl / etc

    // A special mode for 'Vlaams Gewest'
    if (deployment === "be" && code === "2000") {
      return [
        {
          value: "gemeenteprognoses",
          text: "Gemeenten (bewoners)",
        },
      ];
    }

    // add options per forecast layer, if available and accessible
    return rootGetters["layers/getForecastLayerDetails"].reduce(
      (options, layer) => {
        // if the layer is configurable and the source is not configured it, don't show it
        if (
          layer.config?.isConfigurable &&
          !rootGetters["config/layers"][layer.id]?.source
        ) {
          return options;
        }

        // if a forecast layer is not properly configured, don't show it
        if (
          (layer.config.isNeighborhoodForecast &&
            !layer.config.isConfigurable &&
            !rootGetters["deployment/DeploymentHasLayer"]({
              layer: layer.id,
            })) ||
          !rootGetters["access/canAccessLayer"]({ id: layer.id })
        ) {
          return options;
        }

        // otherwise add to list
        return options.concat({
          value: layer.id,
          text: layer.label,
        });
      },
      []
    );
  },

  /**
   * The map attributes
   */
  mapOptions: (state, getters) => getters.config?.map ?? {},
  center: (state, getters) =>
    getters.config?.map?.center?.map((num) => parseFloat(num, 10)),
  zoom: (state, getters) => parseFloat(getters.config?.map?.zoom, 10),

  /**
   * The planmode attributes
   */
  planmode: (state, getters) => getters.config.planmode,

  // Set the participation configs to an empty array if its not an array, to fix problems that could arise due to the old object notation
  participationConfigs: (state, getters) =>
    Array.isArray(getters.config.participation)
      ? getters.config.participation
      : [],
  getAllParticipationConfigs: (state) => {
    return state.configs.reduce((total, { participation }) => {
      if (!Array.isArray(participation)) {
        return total;
      }

      return [...total, ...participation];
    }, []);
  },

  /**
   * Prognose attributes
   */
  models: (state, getters) => getters.config.prognose.models,
  years: (state, getters) =>
    getters.config.prognose.years.map((num) => parseInt(num)),
  defaultYear: (state, getters) => getters.config.prognose.defaultYear,
  limit: (state, getters) => parseInt(getters.config.prognose.limit),
  breakpoints: (state, getters) => {
    // TODO: Feature: allow more breakpoints to be set (requires either adding color, or GeoChart gradient solution)
    let breakpoints = getters.config.prognose.breakpoints;
    return breakpoints && breakpoints.length === 3
      ? breakpoints.map((num) => parseInt(num))
      : [6, 12, 24];
  },

  /**
   * Monitoring attributes
   */
  chargePointIdType: (state, getters) =>
    getters.config.monitoring?.chargePointIdType ?? "evse_id",
  lastMonth: (state, getters) => getters.config.monitoring?.lastMonth ?? "",
  cpos: (state, getters) => getters.config.monitoring?.cpos ?? [],
  notificationEmailAddresses: (state, getters) =>
    getters.config.monitoring?.notificationEmailAddresses ?? "",
  thresholds: (state, getters) =>
    getters.config.monitoring?.thresholds ?? {
      occupancy: {
        enabled: true,
        value: "",
      },
      chargedVolume: {
        enabled: true,
        value: "",
      },
      session: {
        enabled: true,
        value: "",
      },
    },

  getValidationSettingProfile:
    (state, getters) =>
    ({ profile }) =>
      getters.config.validationSettingProfiles?.[profile] || {},
  getDefaultValidationSettingProfile: (state, getters) =>
    getters.config.validationSettingProfiles?.[
      getters.config.validationSettingDefaultProfileId
    ] || {},
  getValidationSettings: (state, getters) =>
    Object.values(getters.config.validationSettingProfiles || {}),
  hasValidationSettings: (state, getters) =>
    getters["getValidationSettings"].length > 0,
  getCurrentTheme: (state) => state.currentTheme,
  getPhases: (_, getters) => getters["config"]?.phases ?? [],
  getLocationCriteria: (_, getters) =>
    getters["config"]?.locationCriteria?.length ? getters["config"]?.locationCriteria : defaultLocationCriteria,
  getDefaultLocationCriteria: () => defaultLocationCriteria,
  getShowLocationCriteria: (_, getters) => getters["config"]?.showLocationCriteria ?? false,
};
const actions = {
  async saveConfig({ commit }, { data }) {
    const token = await this.$auth.getTokenSilently();
    const { config } = await fetch("/api/configsave", {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    })
      .then(await checkStatus)
      .then(returnJson);

    if (config) {
      commit("updateOrAddConfig", { config });
    }
  },
  createConfig({ commit, getters, rootGetters }, { code }) {
    let config = configGenerator({
      deployment: rootGetters["deployment/DeploymentCode"],
      code,
      tilesets: rootGetters["tilesets/tilesets"],
      models: getters["models"],
    });

    commit("updateOrAddConfig", { config });
    commit("selectConfig", { code });
  },
  saveCurrentTheme({ commit }, { theme }) {
    commit("setCurrentTheme", { theme });
  },
  setToast(_, { message, error, variant, delay = 1000 }) {
    setTimeout(() => {
        this.$bvToast.toast(message, {
        title: variant ? 'Waarschuwing' : error ? 'Fout!' : 'Succes!',
        variant: variant || (error ? 'danger' : 'success'),
        toaster: 'b-toaster-top-center',
        solid: true,
        delay,
      })
    }, delay)
  },
}
const mutations = {
  selectConfig(state, { code, slug }) {
    if (slug) {
      code = slugToCode({ slug });
    }
    state.active = code;
  },
  loadConfigs(state, { configs }) {
    configs = configs.filter((config) => !!labelByCode({ code: config.code }));

    state.configs = configs;
    state.loaded = true;
  },
  updateOrAddConfig(state, { config }) {
    let index = state.configs.findIndex((item) => item.code === config.code);

    if (index !== -1) {
      state.configs.splice(index, 1, config);
    } else {
      state.configs.push(config);
    }
  },
  setCurrentTheme(state, { theme }) {
    state.currentTheme = theme;
  },
};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
