/**
 * Import Dependency
 */

/**
 * Import API
 */

import {
  CHARGINGPOINT_CATEGORY,
  CHARGINGPOINT_TYPE,
} from '@/../shared/valueholders/chargingpoint-statuses'
import { FILTER_TYPE } from '@/../shared/valueholders/filter-types'
import { checkIfPathExists } from '@/helpers/object'
import { getInstance } from '@/auth'
import { validate } from 'uuid'
import { phaseToArray } from '@/helpers/phases'
/**
 * Declare Variable
 */

const state = {
  filters: {
    'priority': {
      id: 'priority',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'components.sidebar.PlanModeSearch.priority.label',
      tag: 'components.sidebar.planmodeFilterTags.priority',
      icon: 'chart-bar-regular',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: true,
      filterFnName: 'priorityFilter',
    },

    'priority-fases': {
      id: 'priority-fases',
      type: FILTER_TYPE.SELECT_FILTER,
      label: null,
      subline: 'components.sidebar.PlanModeSearch.priority.subline',
      active: false,
      visible: true,
      filterFnName: 'priorityFaseFilter',
      // default filter values, that should always be available for all municipalities
      options: [{
        id: 'priority-fase-1',
        label: 'Fase 1',
        tag: 'components.sidebar.planmodeFilterTags.phase1',
        from: 1,
        to: 1,
        visible: false,
      }, {
        id: 'priority-fase-2',
        label: 'Fase 2',
        tag: 'components.sidebar.planmodeFilterTags.phase2',
        from: 2,
        to: 2,
        visible: false,
      }],
      // active values to filter on; todo:: maybe better name would be selection?
      values: [],
    },

    'cpo-list': {
      id: 'cpo-list',
      type: FILTER_TYPE.SELECT_FILTER,
      label: null,
      subline: 'participationPanel.options.cpos.title',
      active: false,
      visible: true,
      filterFnName: 'cpoFilter',
      options: [],
      values: [],
      hasTooltip: true,
    },

    'power': {
      id: 'power',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'components.sidebar.PlanModeSearch.power',
      tag: 'components.sidebar.planmodeFilterTags.power ',
      icon: 'plug-solid',
      unit: 'kW',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: true,
      filterFnName: 'powerFilter',
    },
    'to-validate': {
      id: 'to-validate',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'components.sidebar.PlanModeSearch.toValidate.label',
      subline: 'components.sidebar.PlanModeSearch.toValidate.subline',
      tag: 'components.sidebar.planmodeFilterTags.toValidate',
      icon: 'user-check-solid',
      active: false,
      visible: true,
      filterFnName: 'toValidateFilter',
    },
    'already-validated': {
      id: 'already-validated',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'components.sidebar.PlanModeSearch.validated.label',
      subline: 'components.sidebar.PlanModeSearch.validated.subline',
      tag: 'components.sidebar.planmodeFilterTags.validated',
      icon: 'check-double-solid',
      active: false,
      visible: true,
      filterFnName: 'alreadyValidatedFilter',
    },
    'to-decide': {
      id: 'to-decide',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'components.sidebar.PlanModeSearch.toDecide.label',
      subline: 'components.sidebar.PlanModeSearch.toDecide.subline',
      tag: 'components.sidebar.planmodeFilterTags.toDecide',
      icon: 'exchange-alt-solid',
      active: false,
      visible: true,
      filterFnName: 'toDecideFilter',
    },
    'monitoring-occupancy-rate': {
      id: 'monitoring-occupancy-rate',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Bezettingsgraad',
      tag: 'components.sidebar.planmodeFilterTags.occupancyRate',
      icon: 'chart-bar-regular',
      unit: '%',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'occupancyRateFilter',
      // prefilter
      statuses: CHARGINGPOINT_TYPE.REALIZED,
    },
    'monitoring-unique-users': {
      id: 'monitoring-unique-users',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Unieke gebruikers',
      tag: 'components.sidebar.planmodeFilterTags.uniqueUsers',
      icon: 'users-regular',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'uniqueUsersFilter',
    },
    'monitoring-session-count': {
      id: 'monitoring-session-count',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Aantal sessies',
      tag: 'components.sidebar.planmodeFilterTags.sessionsCount',
      icon: 'plug-solid',
      unit: '',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'sessionFilter',
    },
    'monitoring-charged': {
      id: 'monitoring-charged',
      type: FILTER_TYPE.RANGE_FILTER,
      label: 'Geladen kWh',
      tag: 'components.sidebar.planmodeFilterTags.chargedKWH',
      icon: 'users-regular',
      unit: 'kWh',
      min: null,
      max: null,
      from: null,
      to: null,
      active: false,
      visible: false,
      filterFnName: 'chargedFilter',
    },
    'participation-has-comments': {
      id: 'participation-has-comments',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'components.sidebar.PlanModeSearch.participation.label',
      subline: 'components.sidebar.PlanModeSearch.participation.sublines.hasComments',
      tag: 'components.sidebar.planmodeFilterTags.hasParticipationComments',
      icon: 'users-regular',
      active: false,
      visible: true,
      filterFnName: 'hasParticipationCommentsFilter',
    },
    'participation-has-unfinished-comments': {
      id: 'participation-has-unfinished-comments',
      type: FILTER_TYPE.TOGGLE_FILTER,
      subline: 'components.sidebar.PlanModeSearch.participation.sublines.hasUnfinishedComments',
      tag: 'components.sidebar.planmodeFilterTags.hasUnfinishedComments',
      active: false,
      visible: true,
      filterFnName: 'hasUnfinishedParticipationCommentsFilter',
    },
    'participation-by-date': {
      id: 'participation-by-date',
      type: FILTER_TYPE.DROPDOWN_FILTER,
      subline: 'Per datum',
      tag: 'components.sidebar.planmodeFilterTags.byClosingDate',
      icon: 'calendar-check',
      date: '',
      active: false,
      visible: true,
      filterFnName: 'filterStationsByClosingDate',
    },
    'participation-has-closingDate': {
      id: 'participation-has-closingDate',
      type: FILTER_TYPE.BUTTON_FILTER,
      subline: 'Is geparticipeerd',
      tag: 'components.sidebar.planmodeFilterTags.participated',
      icon: 'calendar',
      state: '',
      active: false,
      visible: true,
      filterFnName: 'filterStationsWithClosingDate',
    },
    'stations-with-caseRef': {
      id: 'stations-with-caseRef',
      type: FILTER_TYPE.BUTTON_FILTER,
      label: 'components.sidebar.PlanModeSearch.processInfo.label',
      subline: 'components.sidebar.PlanModeSearch.processInfo.sublines.inProcess',
      tag: 'components.sidebar.planmodeFilterTags.inProcess',
      icon: 'users-regular',
      state: '',
      active: false,
      visible: true,
      filterFnName: 'filterStationsInProcess',
    },
    'duplicates': {
      id: 'duplicates',
      type: FILTER_TYPE.TOGGLE_FILTER,
      label: 'components.sidebar.PlanModeSearch.duplicates.label',
      subline: 'components.sidebar.PlanModeSearch.duplicates.subline',
      tag: 'components.sidebar.planmodeFilterTags.hasDuplicates',
      icon: 'merge-regular',
      active: false,
      visible: true,
      filterFnName: 'hasPossibleDuplicates',
    },
  },
}

const getters = {
  getFilters (state, getters) {
    return Object.values(state.filters).map(filter => ({
      ...filter,
      filterFn: filter.filterFnName ? getters['getFilterFns'][filter.filterFnName] : null,
    }))
  },
  getFilterById: state => ({ id }) => state.filters[id],
  getFiltersByPrefix: state => ({ prefix }) => Object.values(state.filters).filter(filter => filter.id.startsWith(prefix)),
  getActiveFilters: (_, getters) => getters['getFilters'].filter(filter => filter.active),
  isAnyFilterActive: (_, getters) => getters['getActiveFilters'].length > 0,
  getFilterFns: (_, getters, rootState, rootGetters) => ({
    rangeFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        if (! checkIfPathExists({ object: chargingpoint, path: filter.path })) {
          return false
        }

        let key = chargingpoint.data[filter.path] // todo:: make this work getNestedDataByPath

        return (key >= parseInt(filter.from, 10) && key <= parseInt(filter.to, 10))
      })
    },

    priorityFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.prio || ! chargingpoint.data.prio.order) {
          return false
        }

        // Check the position in the prioritized planning
        let order = chargingpoint.data.prio.order

        return (order >= parseInt(filter.from, 10) && order <= parseInt(filter.to, 10))
      })
    },

    powerFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.properties.power) {
          return false
        }

        const power = chargingpoint.data.properties.power

        return (power >= parseInt(filter.from * 1000, 10) && power <= parseInt(filter.to * 1000, 10))
      })
    },

    priorityFaseFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Required data
        if (! chargingpoint.data.prio) {
          return false
        }

        return filter.values.some(phase => {
          const isCustomPhase = validate(phase.id)


          // if custom phase check if uuid equals to that of the chargingpoint
          if (isCustomPhase) {
            return phaseToArray(chargingpoint.data?.prio?.customPhase).includes(phase.id)
          }

          // if not custom phase, check if phase is in between
          else if (phase.from && phase.to) {
            return phaseToArray(chargingpoint.data?.prio?.fase).some((fase) => {
              return (phase.from >= fase && phase.to <= fase)
            })
          }

          return false
        })
      })
    },

    cpoFilter({ chargingpoints, filter }) {
      return chargingpoints.filter(chargingpoint => {
        // Filtering is done only on stakeholders field
        // Imported Eco-movement chargingpoints are mapped to a stakeholder
        if (chargingpoint.data.properties.stakeholders?.length) {
          return filter.values.some(cpo => chargingpoint.data.properties.stakeholders.some(stakeholder => stakeholder.uuid === cpo.id))
        } else if (filter.values.some(cpo => !cpo.id)) {
          // If No CPO search or no filter is selected
          return !chargingpoint.data.properties.stakeholders?.length
        }

      })
    },

    toValidateFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (! CHARGINGPOINT_TYPE.SUGGESTION.includes(chargingpoint.data.properties.status)) {
          return false
        }

        if (! (chargingpoint.data.properties.validators?.length > 0)) {
          return false
        }

        const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === authService.user.sub) !== -1
        if (!isValidator) {
          return false
        }

        const hasVoted = rootGetters['planmode/hasUserAlreadyVoted']({
          chargingpointUuid: chargingpoint.data.uuid,
          userId: authService.user.sub,
        })
        const needsToBeVotedByValidator = hasVoted === false

        return needsToBeVotedByValidator
      })
    },

    hasParticipationCommentsFilter({ chargingpoints }) {
      return chargingpoints.filter(chargingpoint => {
        const getParticipationCommentsByChargingpointUuid = rootGetters['planmode/getParticipationCommentsByChargingpointUuid']
        const participationComments = getParticipationCommentsByChargingpointUuid({ chargingpointUuid: chargingpoint.data.uuid })
        return participationComments.length
      })
    },

    hasUnfinishedParticipationCommentsFilter({ chargingpoints }) {
      return chargingpoints.filter(chargingpoint => {
        const getParticipationCommentsByChargingpointUuid = rootGetters['planmode/getParticipationCommentsByChargingpointUuid']
        const comments = getParticipationCommentsByChargingpointUuid({ chargingpointUuid: chargingpoint.data.uuid })
        const unfinishedComments = comments.filter(({ data }) => ['new', 'todo'].includes(data.status))
        return unfinishedComments.length
      })
    },

    alreadyValidatedFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (!CHARGINGPOINT_CATEGORY.VALIDATIONS.includes(chargingpoint.data.properties.status)) {
          return false
        }

        if (!(chargingpoint.data.properties.validators?.length > 0)) {
          return false
        }

        const isValidator = chargingpoint.data.properties.validators?.findIndex(validator => validator.user_id === authService.user.sub) !== -1
        if (!isValidator) {
          return false
        }

        const hasVoted = rootGetters['planmode/hasUserAlreadyVoted']({
          chargingpointUuid: chargingpoint.data.uuid,
          userId: authService.user.sub,
        })
        const hasAlreadyValidated = hasVoted === true

        return hasAlreadyValidated
      })
    },

    toDecideFilter({ chargingpoints }) {
      const authService = getInstance()

      return chargingpoints.filter(chargingpoint => {
        if (chargingpoint.data.properties.status !== 'suggestion') {
          return false
        }

        if (!chargingpoint.data.properties.beheerder) {
          return false
        }

        const isBeheerder = chargingpoint.data.properties.beheerder.user_id === authService.user.sub
        if (!isBeheerder) {
          return false
        }

        if (!chargingpoint.data.properties.isValidated) {
          return false
        }

        return true
      })
    },

    occupancyRateFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'occupancy_rate',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => chargingpoint.data.evse_ids?.some(evse_id => validLocationsUuids.includes(evse_id)) ?? false)
    },
    uniqueUsersFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'unique_users',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },
    sessionFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'session_count',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },
    chargedFilter({ chargingpoints, filter }) {
      const validLocationsUuids = rootGetters['monitoring/getFilteredLocationUuids']({
        key: 'volume',
        from: filter.from,
        to: filter.to,
        unit: filter.unit,
      })

      return chargingpoints.filter(chargingpoint => validLocationsUuids.includes(chargingpoint.data.uuid))
    },

    filterStationsByClosingDate({ chargingpoints, filter }){
      const participatedStations = chargingpoints.filter(chargingpoint => chargingpoint.data.participation != null &&
        chargingpoint.data.participation.closingDates != null &&
        chargingpoint.data.participation.closingDates.includes(filter.date))

      return participatedStations
    },

    filterStationsWithClosingDate ({ chargingpoints, filter }){
      let stations = []

      if (filter.state === 'with'){
        stations = chargingpoints.filter(chargingpoint => chargingpoint.data.participation &&
          chargingpoint.data.participation.closingDates)
      } else if (filter.state === 'without'){
        stations = chargingpoints.filter(chargingpoint => !chargingpoint.data.participation ||
          !chargingpoint.data.participation.closingDates)
      } else {
        stations = chargingpoints
      }

      return stations
    },

    filterStationsInProcess ({ chargingpoints, filter }){
      let stations = []

      if (filter.state === 'with'){
        stations = chargingpoints.filter(chargingpoint => chargingpoint.data?.workflowCaseRef ||
          chargingpoint.data?.externalProcessId)
      } else if (filter.state === 'without'){
        stations = chargingpoints.filter(chargingpoint => !chargingpoint.data.workflowCaseRef &&
          !chargingpoint.data.externalProcessId)
      } else {
        stations = chargingpoints
      }

      return stations
    },
    hasPossibleDuplicates({ chargingpoints }) {
      // a list of uuids that are loaded by the application. If a duplicates' uuid is not in this list, it's probably deleted
      const validChargingpointUuids = rootGetters['planmode/getChargingPoints'].map(chargingpoint => chargingpoint.data.uuid)

      // create a list of valid uuids (chargingpoints and their duplicates). If a duplicate is not visible, it should not be shown
      const uuids = chargingpoints.reduce((uuids, chargingpoint) => {
        if (! chargingpoint.data.possibleDuplicates?.length) {
          return uuids
        }

        const visibleDuplicates = chargingpoint.data.possibleDuplicates.filter(uuid => validChargingpointUuids.includes(uuid))
        if (! visibleDuplicates.length) {
          return uuids
        }

        return [...uuids, chargingpoint.data.uuid, ...visibleDuplicates]
      }, [])

      return chargingpoints.filter(chargingpoint => uuids.includes(chargingpoint.data.uuid))
    },
  }),
}
const actions = {
  resetFilters({ dispatch, getters }) {
    getters['getActiveFilters'].forEach(filter => dispatch('resetFilter', { id: filter.id, resetAll: true }))
  },
  resetFilter({ getters, commit }, { id, optionID, resetAll }) {
    const options =  {
      active: false,
    }

    const filter = getters['getFilterById']({ id })
    if (filter.type === FILTER_TYPE.RANGE_FILTER) {
      options.from = null
      options.to = null
    }

    if (filter.type === FILTER_TYPE.SELECT_FILTER) {
      options.values = filter.values.filter(value => value.id !== optionID)
      options.active = options.values.length > 0
    }

    commit('setFilterOptions', { id, options, resetAll })
  },
  toggleFilter({ commit, getters }, { id }) {
    const options = { active: ! getters.getFilters[id].active }
    commit('setFilterOptions', { id, options })
  },
}
const mutations = {
  setFilterOptions(state, { id, options, resetAll }) {
    if (resetAll) {
      return Object.keys(state.filters).forEach(filter => {
        state.filters[filter].active = false
        state.filters[filter].values = []
      })
    }

    Object.entries(options).forEach(([key, value]) => {
      state.filters[id][key] = value
    })
  },
  enableParticipationCommentsFilter() {
    state.filters['participation-has-comments'].active = true
  },
  disableParticipationCommentsFilter() {
    state.filters['participation-has-comments'].active = false
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
