<template>
  <div style="display: none" />
</template>

<script>
import { mapGetters } from 'vuex'

import { EventBus } from '@/services/eventbus'

export default {
  props: {
    /**
     * Indicates whether the MapBox instance is ready
     */
    loaded: {
      type: Boolean,
      required: true,
    },
    layerVisibility: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layerName: 'bl_restaurants_langs_weg',
      type: 'circle',
      minzoom: null,
      paint: {
        'circle-color': '#ED971C',
        'circle-radius': 4,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff',
        'circle-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          10.2,
          0.8,
        ],
      },
      layout: {
        'visibility': this.layerVisibility ? 'visible' : 'none',
      },
    }
  },
  computed: {
    ...mapGetters('deployment', [
      'MapboxAccount',
      'DeploymentLayerName',
    ]),
    ...mapGetters('prognose', [
      'labelLayer',
    ]),
    source() {
      return {
        url: process.env.VUE_APP_BL_URL || `mapbox://${this.MapboxAccount}.${this.sourceName}`,
        layer: process.env.VUE_APP_BL_SOURCE || this.sourceName,
      }
    },
    sourceName() {
      return this.DeploymentLayerName({ layer: this.layerName })
    },
  },
  watch: {
    loaded: {
      immediate: true,
      handler(loaded) {
        if (!loaded) return

        this.addLayer()
      },
    },
    layerVisibility: {
      handler(visible) {
        !visible ? this.removeLayerAndSource() : this.addLayer()
      },
    },
  },
  methods: {
    addLayer() {
      this.removeLayerAndSource()

      this.$store.map.addSource(this.sourceName, {
        type: 'vector',
        url: this.source.url,
      })

      this.$store.map.addLayer({
        'id': this.layerName,
        'type': this.type,
        'source': this.sourceName,
        'source-layer': this.source.layer,
        'minzoom': this.minzoom || 0,
        'paint': this.paint,
        'layout': this.layout,
      }, this.labelLayer)

      EventBus.$emit('layers.national.loaded', {
        name: this.layerName,
      })
    },
    removeLayerAndSource () {
      if (this.$store.map.getLayer(this.layerName)) {
        this.$store.map.removeLayer(this.layerName)
        this.$store.map.removeSource(this.sourceName)
      }
    },
  },
}
</script>
