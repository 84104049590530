<template>
  <div class="step">
    <b-alert
      variant="info"
      :show="!hasAddress"
    >
      Geen adres gevonden!
    </b-alert>
    <div
      v-if="title"
      class="step__header"
    >
      <h4>{{ title }}</h4>
    </div>

    <slot />

    <div class="step__footer mt-2">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    hasAddress: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@use 'sass:math';


$compact: 0.8;
$spacing: 12px * $compact;

.step {
  &__header {
    padding: 0 0 $spacing;
    margin-bottom: math.div($spacing, 1.2);
    position: relative;
    border-bottom: 1px solid var(--border-color);

    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
      margin-top: 5px;
      font-weight: 400;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
