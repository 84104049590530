<template>
  <li
    class="FuseSearch_result pl-4 pr-3  py-1"
    @click.self="$emit('select', {item})"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: 'FuseSearchItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.FuseSearch_result {
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    left: 1.5rem;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    border-radius: 50%;
    opacity: 0.9;
    background-size: cover;
  }

  &:hover {
    background: var(--grey-darker-10);
    color: var(--grey-darker-40);

    &:after {
      opacity: 0.8;
    }
  }
}
</style>
