<template>
  <div class="UploadDashboard d-flex flex-column w-100 align-items-center">
    <div class="ImportPanelWrapper mt-5 mb-4 flex-grow-1">
      <header class="PanelHeader d-flex justify-content-between py-3 px-4 align-items-center">
        {{ $t('components.import.ImportPanel.title') }}
        <div class="ml-auto">
          {{ $t('components.import.ImportPanel.trial') }}
          <ToggleSwitch
            v-model="dryRun"
            class="m-0"
          />
        </div>
      </header>
      <section
        v-if="DeploymentCode==='nl'"
        class="ImportPanel d-flex flex-column p-4"
      >
        <!-- MRA-E -->
        <div class="d-flex flex-column p-4">
          <div class="ImportPanel d-flex mt-3 pb-3">
            <aside class="ImportPanel__Aside flex-shrink-0 mr-3">
              <h3 class="ImportPanel__Title">
                {{ $t('components.import.ImportPanel.mrae.title') }}
              </h3>
              <div class="w-75 mt-3">
                {{ $t('components.import.ImportPanel.mrae.description') }}
              </div>
            </aside>

            <div class="ImportPanel__Main flex-grow-1 mb-3">
              <div class="d-flex align-items-start pb-4">
                <span class="ImportPanel__Label mr-3">
                  {{ $t('components.import.ImportPanel.status.title') }}
                  <span v-if="apis.mrae.loading"> {{ $t('components.import.ImportPanel.status.loading') }} </span>
                  <span
                    v-else-if="apis.mrae.success"
                    class="text-success"
                  > {{ $t('components.import.ImportPanel.status.importStarted') }} </span>
                  <span
                    v-else-if="apis.mrae.error"
                    class="text-danger"
                  > {{ $t('components.import.ImportPanel.status.error') }} </span>
                  <span v-else> {{ $t('components.import.ImportPanel.status.notStarted') }} </span>
                </span>

                <b-button
                  variant="cta"
                  class="ml-3"
                  :disabled="apis.mrae.loading"
                  @click="handleSyncMRAE"
                >
                  {{ $t('components.import.ImportPanel.status.sync') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- RoyalHaskoning -->
        <div class="d-flex flex-column p-4">
          <div class="ImportPanel d-flex mt-3 pb-3">
            <aside class="ImportPanel__Aside flex-shrink-0 mr-3">
              <h3 class="ImportPanel__Title">
                {{ $t('components.import.ImportPanel.rhdhv.title') }}
              </h3>
              <div class="w-75 mt-3">
                {{ $t('components.import.ImportPanel.rhdhv.description') }}
              </div>
            </aside>

            <div class="ImportPanel__Main flex-grow-1 mb-3">
              <div class="d-flex align-items-start pb-4">
                <span class="ImportPanel__Label mr-3">
                  {{ $t('components.import.ImportPanel.status.title') }}
                  <span v-if="apis.rhdhv.loading"> {{ $t('components.import.ImportPanel.status.loading') }} </span>
                  <span
                    v-else-if="apis.rhdhv.success"
                    class="text-success"
                  >  {{ $t('components.import.ImportPanel.status.importStarted') }} </span>
                  <span
                    v-else-if="apis.rhdhv.error"
                    class="text-danger"
                  > {{ $t('components.import.ImportPanel.status.error') }} </span>
                  <span v-else> {{ $t('components.import.ImportPanel.status.notStarted') }} </span>
                </span>

                <b-button
                  variant="cta"
                  class="ml-3"
                  :disabled="apis.rhdhv.loading"
                  @click="handleSyncRHDHV"
                >
                  {{ $t('components.import.ImportPanel.status.sync') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <!-- LaadpaalNodig -->
        <div class="d-flex flex-column p-4">
          <div class="ImportPanel d-flex mt-3 pb-3">
            <aside class="ImportPanel__Aside flex-shrink-0 mr-3">
              <h3 class="ImportPanel__Title">
                {{ $t('components.import.ImportPanel.lpn.title') }}
              </h3>
              <div class="w-75 mt-3">
                {{ $t('components.import.ImportPanel.lpn.description') }}
              </div>
            </aside>

            <div class="ImportPanel__Main flex-grow-1 mb-3">
              <div class="d-flex align-items-start pb-4">
                <span class="ImportPanel__Label mr-3">
                  {{ $t('components.import.ImportPanel.status.title') }}
                  <span v-if="apis.lpn.loading"> {{ $t('components.import.ImportPanel.status.loading') }} </span>
                  <span
                    v-else-if="apis.lpn.success"
                    class="text-success"
                  >   {{ $t('components.import.ImportPanel.status.importStarted') }} </span>
                  <span
                    v-else-if="apis.lpn.error"
                    class="text-danger"
                  > {{ $t('components.import.ImportPanel.status.error') }} </span>
                  <span v-else> {{ $t('components.import.ImportPanel.status.notStarted') }} </span>
                </span>

                <b-button
                  variant="cta"
                  class="ml-3"
                  :disabled="apis.lpn.loading"
                  @click="handleSyncLPN"
                >
                  {{ $t('components.import.ImportPanel.status.sync') }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-else>
        <p>
          {{ $t('components.import.ImportPanel.noAPIs') }}
        </p>
      </section>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { Bugfender } from '@bugfender/sdk'

import { checkStatus } from '@/helpers/api'

import ToggleSwitch from '@/components/form/ToggleSwitch'

export default {
  name: 'ImportPanel',
  components: { ToggleSwitch },
  data() {
    return {
      dryRun: true,
      apis: {
        mrae: {
          success: false,
          error: false,
          loading: false,
        },
        rhdhv: {
          success: false,
          error: false,
          loading: false,
        },
        lpn: {
          success: false,
          error: false,
          loading: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters('deployment', ['DeploymentCode']),
  },
  watch: {},
  created() {},
  methods: {
    async handleSyncMRAE() {
      const token = await this.$auth.getTokenSilently()

      try {
        this.apis.mrae.loading = true

        fetch('/api/callable-mrae-import-chargingpoints-background', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ DRY_RUN: this.dryRun }),
        })
          .then(await checkStatus)

        this.apis.mrae.success = true

      } catch (e) {
        Bugfender.error('error in MRA-E import:', e)
        this.apis.mrae.error = true

      } finally {
        this.apis.mrae.loading = false
      }
    },
    async handleSyncRHDHV() {
      const token = await this.$auth.getTokenSilently()

      try {
        this.apis.rhdhv.loading = true

        fetch('/api/callable-rhdhv-import-chargingpoints-background', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ DRY_RUN: this.dryRun }),
        })
          .then(await checkStatus)

        this.apis.rhdhv.success = true

      } catch (e) {
        Bugfender.error('error in MRA-E import:', e)
        this.apis.rhdhv.error = true

      } finally {
        this.apis.rhdhv.loading = false
      }
    },
    async handleSyncLPN() {
      const token = await this.$auth.getTokenSilently()

      try {
        this.apis.lpn.loading = true

        fetch('/api/callable-lpn-import-chargingpoints-background', {
          method: 'POST',
          headers: {
            authorization: 'Bearer ' + token,
          },
          body: JSON.stringify({ DRY_RUN: this.dryRun }),
        })
          .then(await checkStatus)

        this.apis.lpn.success = true

      } catch (e) {
        Bugfender.error('error in MRA-E import:', e)
        this.apis.lpn.error = true

      } finally {
        this.apis.lpn.loading = false
      }
    },
  },
}
</script>

<style lang="scss">


/**
 * This component combines the html & css of three layered admin components,
 *  because it doesn't need any of the logic of those components
 * TODO: Fix html & CSS so that it makes sense as one component
 */
.UploadDashboard {
  &__Panel, &__Select {
    // width: calc(100% - 2rem);
    min-width: 1100px;
    max-width: 1100px;
  }

  &__Panel {
    // border: 1px solid red;
    background: white;
    box-shadow: 0px 1px 3px 0px #dfe2e5;
  }

  .vSelect {
    min-width: 300px;
  }
}

.ImportPanelWrapper {

  min-width: 1100px;
  max-width: 1100px;
  background: white;
  box-shadow: 0px 1px 3px 0px #dfe2e5;

  header {
    background: var(--primary);
    color: white;
    font-size: 1.5rem;
  }

  form {
    label {
      font-size: 1.1rem;
    }

    small {
      outline: none !important;
      font-size: 1rem;
    }
  }
}

.ImportPanel {
  &__Aside {
    width: 275px;
  }

  &__Main {
    font-size: 1.25rem;

    .form-control {
      width: 100px;
      font-size: 1.25rem;
    }
  }

  &__Input {
    position: relative;
    width: 360px;

    &:after {
      content: attr(data-intructions);
      position: absolute;
      top: 3.1rem;
      font-size: 1rem;
      left: 0;
      color: #7F8FA4;

    }
  }

  &__Download {
    text-decoration: underline;
    color: var(--cta);
  }

  .SvgIcon {
    flex-shrink: 0;
    font-size: 1.75rem;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Selecteer';
  }
}
</style>
