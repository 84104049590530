<template>
  <div class="TC-Container__validation">
    <b-button
      variant="outline-secondary"
      size="sm"
      class="mr-1 no-outlines TC-Container__validation--approved"
      :class="{'checked': validationChoice === VALIDATION_VOTE.APPROVED}"
      @click="$emit('choice', VALIDATION_VOTE.APPROVED)"
    >
      <b-icon-check />
    </b-button>
    <b-button
      variant="outline-secondary"
      size="sm"
      class="mr-1 no-outlines TC-Container__validation--rejected"
      :class="{'checked': validationChoice === VALIDATION_VOTE.REJECTED}"
      @click="$emit('choice', VALIDATION_VOTE.REJECTED)"
    >
      <b-icon-x />
    </b-button>
  </div>
</template>

<script>
import { VALIDATION_VOTE } from '@/../shared/valueholders/validation-vote'
import { BIconCheck, BIconX } from 'bootstrap-vue'

export default {
  components: { BIconCheck, BIconX },
  props: {
    validationChoice: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  created() {
    this.VALIDATION_VOTE = VALIDATION_VOTE
  },
}
</script>
