<template>
  <b-alert
    v-if="isAddressEditable && (! isAddressComplete || ! hasAddress)"
    variant="warning"
    show
  >
    {{ hasAddress ? "Adres onvolledig!" : "Onbekend adres!" }} <br>
    <small>Er missen adresgegevens, wilt u deze a.u.b. aanvullen?</small>
  </b-alert>
</template>

<script>
import chargingpointDataMixin from '@/mixins/chargingpoint/chargingpointDataMixin'

export default {
  mixins: [chargingpointDataMixin],
}
</script>
