<template>
  <div class="Factsheet__Footer mt-2 d-flex justify-content-between">
    <img
      class="Footer__Logo"
      :src="logoSrc"
    >

    <div class="Footer__Text font-italic">
      <slot />
    </div>

    <div
      v-if="generatedAt"
      class="Footer__Timestamp"
      v-text="generatedAt"
    />
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

export default {
  props: {
    smallLogo: {
      type: Boolean,
      required: false,
      default: false,
    },
    customLogo: {
      type: String,
      required: false,
      default: () => null,
    },
    generatedAt: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    logoSrc() {
      if (this.customLogo) {
        return image({ name: this.customLogo })
      }
      if (this.smallLogo) {
        return image({ name: 'factsheet/logo_small.png' })
      }
      return image({ name: 'factsheet/logo.png' })
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';

/**
 * Measurements are taken from a powerpoint "design", which has a different aspect ratio.
 * The factors below are used to help convert pp measurements into pixels
 */
$height: 1.96;
$width: 1.56;
$aspect: math.div($width + $height, 2);

.Factsheet__Footer {
  position: relative;

  .Footer {
    &__Logo {
      position: absolute;
      left: 15px * $width;
      bottom: 4px * $height;
      height: 60px * $aspect; // Aspect ratio...
      //max-width: 100px;
    }

    &__Background {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 357px; // * $aspect; // Aspect ratio...
      height: 74px; // * $aspect; // Aspect ratio...
    }

    &__Text {
      position: absolute;
      z-index: 99999;
      bottom: 8px * $height;
      width: 100%;
      height: 25px * $height;
      font-size: 7px * $aspect;

      > div {
        width: 580px * $width;
        margin: 0 auto;
      }
    }

    &__Timestamp {
      position: absolute;
      z-index: 99999;
      right: 2rem;
      bottom: 1rem;
      color: gray;
    }
  }
}
</style>
