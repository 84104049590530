<template>
  <div style="display: none;">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'MapBoxPopup',
  inject: {
    map: {
      default: null,
    },
    mapbox: {
      default: null,
    },
  },
  props: {
    anchor: {
      type: String,
      required: false,
      default: () => 'left',
    },
    coordinates: {
      type: Array,
      required: true,
    },
    offset: {
      type: [Number, Object, Array],
      default: () => [0, 0],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      popup: null,
    }
  },
  watch: {
    anchor() {
      if (! this.loaded) return

      this.popup.remove()

      this.$nextTick(this.createPopup)
    },
    coordinates(lngLat) {
      if (! this.loaded) return
      this.popup.setLngLat(lngLat)
    },
    show(next) {
      if (! this.loaded || ! this.map || ! this.popup) return

      if (next && ! this.popup.isOpen()) {
        this.popup.addTo(this.map)
      } else if (!next && this.popup.isOpen()) {
        this.popup.remove()
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.createPopup()
    })
  },
  beforeDestroy() {
    if (this.map && this.popup) {
      this.popup.remove()
    }
  },
  methods: {
    createPopup() {
      if (!this.mapbox) return

      this.map.flyTo({
        center: this.coordinates,
        essential: true,
        speed: 0.3,
      })

      this.popup = new this.mapbox.Popup({
        anchor: this.anchor,
        offset: this.offset,
      })

      this.popup.on('close', this.onClose)
      this.popup.on('open', this.onOpen)

      if (this.$slots.default !== undefined) {
        this.popup.setDOMContent(this.$slots.default[0].elm)
      }

      if (Array.isArray(this.coordinates)) {
        this.popup.setLngLat(this.coordinates)

        if (this.show && this.map) {
          this.popup.addTo(this.map)
        }
      }
      // Prevent browser's aria-hidden warning //
      this.popup._closeButton.ariaHidden = false

      this.loaded = true
    },
    onClose(popup) {
      this.$emit('close', popup)
    },
    onOpen(popup) {
      this.$emit('open', popup)
    },
  },
}
</script>
<style lang="scss">
.mapboxgl-popup-content {
  width: 260px;

}
</style>
