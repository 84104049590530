<template>
  <div>
    <!-- Introduction modal -->
    <b-modal
      v-if="showIntroduction"
      :id="tourName"
      :title="introductionTitle"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      modal-class="modal-release-notes"
      @close="handleOnSkip"
    >
      <slot name="introduction" />

      <b-button
        variant="primary"
        @click="handleOnStartTour"
      >
        Ontdek wat er nieuw is
      </b-button>
    </b-modal>

    <!-- Tour -->
    <v-tour
      :name="tourName"
      :options="options"
      :steps="filteredSteps"
      :callbacks="callbacks"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :highlight="tour.highlight"
            :class="{'v-step--wide': tour.steps[tour.currentStep].customOptions.wide}"
          >
            <div
              slot="header"
              class="v-step__header"
            >
              <div
                class="v-step__header__close"
                @click="tour.skip"
              >
                x
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="tour.steps[tour.currentStep].header"
                class="content"
                v-html="tour.steps[tour.currentStep].header.title"
              />
              <!--eslint-enable-->
            </div>

            <div slot="actions">
              <template v-if="!tour.steps[tour.currentStep].customOptions.noControls">
                <b-button
                  v-if="!tour.isFirst"
                  class="mr-1"
                  size="sm"
                  variant="outline-primary"
                  @click="tour.previousStep"
                >
                  Vorige
                </b-button>
                <b-button
                  v-if="!tour.isLast"
                  class="mr-1"
                  size="sm"
                  variant="primary"
                  @click="tour.nextStep"
                >
                  Volgende
                </b-button>
                <b-button
                  v-else
                  class="mr-1"
                  size="sm"
                  variant="primary"
                  @click="stopTour(tour.stop)"
                >
                  Sluiten
                </b-button>
              </template>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
const HAS_SEEN_TOUR = 'has-seen-tour'

export default {
  name: 'EvtoolsTour',
  props: {
    tourName: {
      type: String,
      required: true,
    },
    introductionTitle: {
      type: String,
      required: false,
      default: 'Nieuwe mogelijkheden',
    },
    steps: {
      type: Array,
      required: true,
    },
    begin: {
      type: Boolean,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        highlight: true,
        enabledButtons: {
          buttonSkip: false,
          buttonPrevious: true,
          buttonNext: true,
          buttonStop: true,
        },
      }),
    },
  },
  data() {
    return {
      showIntroduction: false,
      callbacks: {
        onSkip: this.handleOnSkip,
      },
    }
  },
  computed: {
    // Set an empty object as default customOptions and remove the steps that are not needed for this specific deployment
    filteredSteps () {
      return this.steps
        .map(step => step.customOptions ? step : { ...step, customOptions: {} })
        .filter(({ customOptions }) => customOptions.display ?? true)
    },
    hasIntroduction () {
      return !!this.$slots.introduction
    },
  },
  watch: {
    begin() {
      if (this.begin && this.filteredSteps.length) {
        this.$nextTick(() => this.$tours[this.tourName].start())
      }
    },
    // Check if the tour is ready to be initialized whenever the route changes
    '$route': 'checkToInitialize',
  },
  methods: {
    // Initialize the tour when ready, on the correct route, and the tour hasn't been seen yet
    checkToInitialize() {
      const isOnRelevantRoute = this.$router.currentRoute.name === this.route
      const hasNotSeenTourYet = localStorage.getItem(this.tourName) !== HAS_SEEN_TOUR
      if (isOnRelevantRoute && hasNotSeenTourYet) {
        this.$emit('initialize')

        // Open the introduction modal when initialized
        if (this.hasIntroduction) {
          this.showIntroduction = true
          this.$nextTick(() => this.$bvModal.show(this.tourName))
        }
      }
    },
    // Hide the introduction modal and start the tour after the modal has been closed
    handleOnStartTour() {
      this.$bvModal.hide(this.tourName)

      // wait until modal is closed
      setTimeout(() => this.$emit('start-tour'), 250)
    },
    // Since onFinish callback doesn't work properly, this workaround
    stopTour(stopFn) {
      stopFn()
      this.handleOnSkip()
    },
    handleOnSkip() {
      localStorage.setItem(this.tourName, HAS_SEEN_TOUR)
      this.$emit('end')
    },
  },
}
</script>

<style lang="scss">
$theme-colors-primary: #041E42;
$theme-colors-light: #EEEEEF;
$header-background-color: $theme-colors-primary;
$content-background-color: $theme-colors-light;

.modal-release-notes {
  .modal {
    &-header {
      background-color: $header-background-color;
      color: white;

      .close {
        color: white;
        opacity: 1;
        text-shadow: none;
      }
    }

    &-content {
      background-color: $content-background-color;
      color: $theme-colors-primary;
    }
  }
}

.v-tour {
  &__target--highlighted {
    pointer-events: none;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4) !important;
  }
}

.v-step {
  text-align: left !important;
  background-color: $content-background-color !important;
  color: $theme-colors-primary !important;

  &--wide {
    max-width: 600px !important;
  }

  &__header {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $theme-colors-primary !important;
    background-color: $header-background-color !important;
    color: white !important;
    font-size: 1.25rem;
    font-weight: 600;
    padding: .5rem 1rem !important;

    &__close {
      cursor: pointer;
      padding: .5rem .75rem .75rem;
      margin: -.5rem;
      float: right;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1;
    }
  }

  &__arrow {
    z-index: 0;

    &--dark::before {
      background-color: $content-background-color !important;
    }
  }

  .content-icon {
    width: 15px;
  }
}

.v-step[data-popper-placement^=right]>.v-step__arrow {
  left: 0 !important;
}
.v-step[data-popper-placement^=left]>.v-step__arrow {
  right: -10px !important;
}
</style>
