// Description: This file contains the active countries EVtools works in at the moment.

const COUNTRY = {
  NL: 'nl',
  BE: 'be',
}

const COUNTRIES = Object.values(COUNTRY)

module.exports = {
  COUNTRY,
  COUNTRIES,
}
